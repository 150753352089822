<template>
  <div class="meeting-hotel-list" v-if="orderList.length">
    <div class="main">
      <ul class="list">
        <li class="item" v-for="(item, index) in orderList" :key="index" @click="navigate(item)">
          <p class="hotel-title">
            <i class="text">{{item.hotelName}}</i>
          </p>
          <p class="address">
            <i class="text">{{item.hotelAddress}}</i>
          </p>
          <div class="contact">
            <p class="contact-man">
              <b class="type">联系人：</b>
              <i class="text">{{item.link_person}}</i>
            </p>
            <p class="contact-phone">
              <b class="type">联系电话：</b>
              <i class="text">{{item.link_phone}}</i>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <van-empty v-else image="search" description="列表为空" />
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      orderList: [],
      // 这个用来区分跳转的
      distinguish:true
    }
  },
  methods: {
    // 跳转酒店预订信息详情页
    navigate (item) {
      if (!item) return
      console.log(item);
      this.$store.commit('setMeetingHotelDetail', item)
      if(this.distinguish){
        // 为true就是查看全部的
        let id=item.meeting_rooms[0].checkIn[0].meeting_id
        this.$router.push('/meetinghoteldetail/' + id)
      }else{
        this.$router.push('/meetinghoteldetail/' + this.$route.params.id)
      }
      // this.$router.push('/meetinghoteldetail/' + this.$route.params.id)
    },
    // 请求数据，参数为会议id
   async  request (id) {
     const res=await axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetMeetingRoomRecord?open_id=${this.$store.state.user.openid || localStorage.openid}&MeetingId=${id}`)
        try{
          if (res.data.Data.length) {
            this.orderList = res.data.Data
          } else {
            console.log('获取数据为空')
          }
          this.distinguish=false
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        }catch(err){
            console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        }
    },

      async  requestAll () {
     const res=await axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetMeetingRoomRecord?open_id=${this.$store.state.user.openid || localStorage.openid}`)
        try{
          if (res.data.Data.length) {
            this.orderList = res.data.Data
          } else {
            console.log('获取数据为空')
          }
          this.distinguish=true
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        }catch(err){
            console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        }
    }
  },
  beforeRouteEnter (to, from, next) { // 路由进入拦截
    next(vm => {
      vm.$store.commit('routerGuardWechat', { // 调用守卫
        fromPath: from.path,
        _this: vm
      })
    })
  },
  beforeMount () {
    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit('loadHandler', { // 调用加载动画
        _this: this,
        boolean: true
      })
      if(this.$route.params.id==0){
        this.requestAll()
      }else{
        this.request(this.$route.params.id) // 调用请求方法
      }
      
     
      
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-hotel-list {
  .main {
    padding : 0.5rem 0 0.5rem ;
    .list {
      .item {
        background-color : #FFFFFF ;
        padding : 0.15rem ;
        border-radius : 0.05rem ;
        box-shadow : 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1) ;
        margin-top : 0.3rem ;
        &:first-of-type {
          margin-top : 0 ;
        }
        p {
          margin-top : 0.08rem ;
          &>:first-of-type {
            margin-top : 0 ;
          }
          .type {
            color : #8d8d8d ;
            font-size : 0.22rem ;
          }
          .text {
            font-size : 0.22rem ;
          }
        }
        .hotel-title {
          .text {
            font-weight : bold ;
            line-height : 0.3rem ;
          }
        }
        .address {
          .text {
            display : block ;
            color : #cfcfcf ;
            font-size : 0.24rem ;
          }
        }
        .contact {
          display : flex ;
          justify-content : space-between ;
          .contact-man {
            flex-shrink : 0 ;
          }
          .contact-phone {
            flex-grow : 1 ;
            word-break : break-all ;
            text-align : right ;
          }
        }
      }
    }
  }
}
</style>
