<template>
  <div class="tabbar" v-if="$store.state.tabbar.title.length">
    <div
    :class="index==num?'www on':'www'"
      v-for="(item, index) in $store.state.menu"
      @click="jump(item,index)"
      :key="index"
    >
      <i
         class="icon"
        :class="item.Code === 'app_ngv_list'? 'about-icon': item.Code === '10031'? 'meeting-icon': item.Code==='person'?'person-icon':'news-icon'"
      ></i>
      <b class="text">{{ item.Name }}</b>
    </div>
    <!-- <router-link to="/person" activeClass="on">
      <i class="icon person-icon"></i>
      <b class="text">个人中心</b>
    </router-link> -->

 <!-- <van-tabbar v-model="active">
  <van-tabbar-item   v-for="(item, index) in $store.state.menu"
      @click="jump(item,index)"
      :key="index">{{item.Name}}</van-tabbar-item>
  <van-tabbar-item icon="search">个人中心</van-tabbar-item>
</van-tabbar> -->
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data(){
    return {
      num:0
    }
  },
  methods:{
    jump(item,index){
      this.num=index
      this.$store.commit('publicIndex',index)
      // this.$router.push(item.LinkUrl)
      this.$router.push({
        path:item.LinkUrl,
        query:{
          index
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.tabbar {
  display: flex;
  width: 100%;
  max-width: 6.4rem;
  height: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.4rem 0rem rgba(0, 0, 0, 0.05);
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  .www{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .icon {
      width: 0.48rem;
      height: 0.48rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
    }
    .about-icon {
      background-image: url(../../public/wx_images/tabbar-icon-1a.png);
    }
    &.on .about-icon {
      background-image: url(../../public/wx_images/tabbar-icon-1b.png);
    }
    .news-icon {
      background-image: url(../../public/wx_images/tabbar-icon-2a.png);
    }
    &.on .news-icon {
      background-image: url(../../public/wx_images/tabbar-icon-2b.png);
    }
    .meeting-icon {
      background-image: url(../../public/wx_images/tabbar-icon-3a.png);
    }
    &.on .meeting-icon {
      background-image: url(../../public/wx_images/tabbar-icon-3b.png);
    }
    .person-icon {
      background-image: url(../../public/wx_images/tabbar-icon-4a.png);
    }
    &.on .person-icon {
      background-image: url(../../public/wx_images/tabbar-icon-4b.png);
    }
    .text {
      font-size: 0.26rem;
    }
    &.on .text {
      color: #95b3d7;
    }
  }
}
</style>
