<template>
  <div>
       <div v-if="images.length>=1" :style="{background: list[0].MeetingCoverImg==null?`url('${img}')`:`url('${$store.state.imageUrl+list[0].MeetingCoverImg}') center center / 100% no-repeat`}" class="a">
            <!-- <img :src="$store.state.imageUrl+list[0].MeetingCoverImg" alt=""> -->
            <div ></div>
        </div>
           <div v-else :style="{background: `url('${img}') center center / 100% no-repeat`}" class="a">
            <!-- <img :src="$store.state.imageUrl+list[0].MeetingCoverImg" alt=""> -->
            <div ></div>
        </div>
        <!-- 点击回到顶部 -->
        <Top :count="images.length" v-if="images.length>=1"></Top>
        <Popups></Popups>
        <div class="info-title">
            <h2 >{{$store.state.meetingInfo.title}}</h2>
            <div class="borwse">
                <p style="color:#eb6d49" >{{Click}}&nbsp;人已收看</p>
                <p>
                    <span class="handle" @click="popUp">活动信息</span>
                    <!-- 弹出层 -->
                    <!-- <van-popup v-model:show="isshow" class="">
                        111111
                    </van-popup> -->
                    <van-popup v-model:show="isshow"  position="bottom" :style="{ height: '70%' }" >
                         <p class="activity">活动信息</p>
                         <h4 class="activity-title">{{$store.state.meetingInfo.title}}</h4>
                         <div class="Time">
                            
                            <p style="margin-right:10px"> 时间:</p>
                            <div>
                                <p>{{$store.state.meetingInfo.start_tiem}}</p>
                                <p>{{$store.state.meetingInfo.end_tiem}}</p>
                            </div>
                         </div>   
                    </van-popup>
                    <span class="handle">分享</span>
                </p>
            </div>
        </div>
       <div class="photo-box" v-if="images.length>=1">
           <li  class="photo" v-for="(k,index) in list" :key="k.id" >
               <img :src="$store.state.imageUrl+k.CoverImg" alt="" @click="check(index)">
           </li>
            
           <div class="z" @click="qq">
               <li class="Like" v-show="show">
                    <!-- <p>{{}}</p> -->
                   <p v-if="images.length>=1" class="iconfont icon-aixin_shixin" @click="like" :style="{color:list[num].ClickLike==0?'#fff':'#eb6d49'}"></p>
                   <span v-if="images.length>=1">{{list[num].ClickLike==0?'喜欢':list[num].ClickLike}}</span>
                   <button @click="add" v-if="images.length>=1" class="alt" >{{list[num].Name}}</button>
               </li> 
           </div>
       </div>

        <li class="hint" v-else>
                暂未配置图片
        </li>
  </div>
</template>

<script>
import Preview from 'vue-photo-preview'
import Top from '@/components/Top'
import Popups from "@/components/Popups.vue"
import { ImagePreview } from 'vant';
import axios from "axios";
export default {
    components:{
        Preview,
        ImagePreview,
        Top,
        Popups
    },
    data(){
        return {
            list:[],
            num:1,
            timer:null,
            images:[],
            num:0,
            show:false,
            isshow:false,
            Click:0,
            img:require('../../public/wx_images/meetingcenter-img-1.jpg')
        }
    },
    created(){
        this.getPhoto()
        
    },
    methods:{
        async like(){
            const json={
                Name:145,
                MasterUserId:localStorage.getItem('openid'),
                Master:this.list[this.num].Id
            }
            const res=await axios({
            url: this.$store.state.apiUrl +
            "/actionapi/TencentApi/MeetingImgLike",
            method:'post',
            data:json
            })
                
            try{
                
            }catch(err){

            }
        },
        async getPhoto(){
                var marstr=this.$route.params.id
                var openid=localStorage.getItem('openid')
                const res=await axios({
                url: this.$store.state.apiUrl +
                `/actionapi/TencentApi/GetMeetingImg?Master=${marstr}&Type=1&MasterUserId=${openid}`
                })
                
            try{
              
                this.list=res.data.Data
                this.Click=res.data.Click
                res.data.Data.forEach((k,index)=>{
                    this.images.push(this.$store.state.imageUrl+k.CoverImg)  
                })
            }catch(err){
                
            }
        },
        check(index){
            
            this.num=index
            this.show=true
            ImagePreview({images:this.images,startPosition:this.num,closeable: true,
            onChange:(w)=>{
                
                this.num=w
            },
            onClose:(e)=>{
                this.show=false
            }
            })
        },
        // 点击活动信息的弹出层
        popUp(){
            this.isshow=true
        }
    }
}
</script>

<style lang="scss" scoped>
.photo-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.photo{
     list-style: none;
     /* display: flex; */
     width: 49.5%;
     height: 150px;
     margin-top: 10px;
     background: #000;
    /* opacity: .4; */
    
    }
    img{
        width: 100%;
        height: 150px;
    }

    .mask{
        width: 45%;
        height: 150px;
        margin-top: 10px;
        background: #000;
        opacity: .4;
        position: absolute;
    }
    .z{
        position: fixed;
        bottom: 110px;
        right: 30px;
       color: white;
       /* font-size: 24px; */
       z-index: 999999999999;
       text-align: center;
    }
    .icon-aixin_shixin{
        font-size: 24px;
        /* #eb6d49 */
    }
    .Like{
        /* display: flex; */
        /* flex-wrap: wrap; */
        /* align-content: center; */
        text-align: center;
    }

    .info-title{
        padding:.3rem;
        h2{
            font-weight: 800;
        }
    }
    .borwse{
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        margin-top: 7px;
        .handle{
            padding: .03rem .13rem;
            border: 1px solid #ccc;
        }
        .handle:nth-child(1){
            margin-right: 10px;
        }
    }
    .a{
        width: 100%;
        height: 200px;
    }
    .alt{
        position: fixed;
        left: 20px;
        bottom: 40px;
    }

    .Time{
        display: flex;
        font-size: 14px;
        padding: .2rem;
    }
    .activity{
        // margin-right: 10px;
        font-size: 20px;
        padding: .2rem;
    }
    .activity-title{
        padding: .2rem;
        font-size: 18px;
    }
    .hint{
        width: 100%;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
        color: #ccc;
    }
</style>