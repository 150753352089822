<template>
  <div class="meeting-register form-container">
    <div class="main form-main">
      <div class="form form-content">
        <div class="item text-input Icon-input">
          <div class="title" >
            <span>个人形象照</span>
          </div>
          <div class="content">
            <van-uploader
            
              :after-read="uploadImgs"
              v-model="fileList"
              @delete="deleteImg"
              max-count="1"
            />
          </div>
        </div>
        
        <div class="item text-input name-input">
          <div class="title must" :class="nameClass">
            <span>姓名</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Name"
              @blur="verify('name')"
              placeholder="请输入参会人姓名"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
          <!-- 这个是用来标识的暂时用不到 -->
          <!-- <div class="tip">
            <p>姓名 提交后无法修改</p>
          </div> -->
        </div>
        <div class="item text-input phone-input">
          <div class="title must" :class="phoneClass">
            <span>手机号码</span>
          </div>
          <div class="content">
            <input
              type="text"
              maxlength="11"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="user.Phone"
              @blur="verify('phone')"
              @input="phone = numberVerify(user.Phone, 11, 'number')"
              placeholder="请输入手机号码"
            />
          </div>
        </div>

        <div class="item text-input Sex-input">
          <div class="title must" :class="SexClass">
            <span>性别</span>
          </div>

          <div class="content Sex">
            <!-- <input
              type="text"
              v-model.trim="user.Sex"
              @blur="verify('Sex')"
              placeholder="请输入性别"
            /> -->
            <p
              v-for="k in SexArry"
              :key="k.id"
              @click="getSex(k)"
              :class="user.Sex == k.id ? 'active' : ''"
            >
              {{ k.name }}
            </p>
            <!-- <p>女</p> -->
            <!-- 男 <input type="radio" name="sex" id="1"> -->
            <!-- 女<input type="radio" name="sex" id="2"> -->
          </div>
        </div>

        <div class="item text-input Idno-input">
          <div class="title must" :class="IdnoClass">
            <span>身份证号码</span>
          </div>
          <div class="content">
            <input
              type="text"
              maxlength="18"
              onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
              v-model.trim="user.IdNo"
              @blur="verify('Idno')"
              placeholder="请输入身份证号码"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
        </div>

        <div class="item text-input TechnologyJobTitle-input">
          <div class="title must" :class="TechnologyJobTitleClass">
            <text>职称</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.TechnologyJobTitle"
              @blur="verify('TechnologyJobTitle')"
              placeholder="请输入职称"
            />
          </div>
        </div>

        <div class="item text-input unit-input">
          <div class="title must" :class="unitClass">
            <text>工作单位</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Unit"
              @blur="verify('unit')"
              placeholder="请输入工作单位"
            />
          </div>
        </div>

        <div class="item text-input Department-input">
          <div class="title must" :class="DepartmentClass">
            <text>科室</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Department"
              @blur="verify('Department')"
              placeholder="请输入科室"
            />
          </div>
        </div>

        <div class="item text-input LearnName-input">
          <div class="title must" :class="LearnNameClass">
            <text>选择分会</text>
          </div>
          <div class="content">
            <input
              type="text"
              readonly
              @click="learnshow = true"
              v-model.trim="LearnName"
              @blur="verify('LearnName')"
              placeholder="选择分会"
            />
          </div>
          <van-popup
            v-model:show="learnshow"
            position="bottom"
            :style="{ height: '60%' }"
          >
            <div class="type">
              <!-- <p>选择类型</p> -->
              <div class="select-type">
                <li
                  v-for="(k, index) in typeList"
                  :key="index"
                  :class="num == index ? 'liang' : ''"
                  @click="learnselect(k, index)"
                >
                  {{ k.title }}
                </li>
              </div>
            </div>
          </van-popup>
        </div>

        <div class="item text-input Education-input">
          <div class="title" >
            <text>学历学位</text>
          </div>
          <div class="content" @click="skip('Education')">
            <input
              type="text"
              readonly
              v-model.trim="EducationName"
              @blur="verify('Education')"
              placeholder="请选择学历学位"
            />
          </div>
        </div>

        <div class="item text-input Site-input">
          <div class="title" >
            <text>联系地址</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Site"
              @blur="verify('Site')"
              placeholder="请输入联系地址"
            />
          </div>
        </div>

        <div class="item text-input email-input">
          <div class="title" >
            <text>电子邮件</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Email"
              @change="verify('email')"
              placeholder="请输入电子邮件"
            />
          </div>
        </div>

        <div class="item text-input TechnologyClass-input">
          <div class="title" >
            <text>工作经历学术成果，科研项目等</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Technology"
              @blur="verify('Technology')"
              placeholder="请输入工作经历学术成果，科研项目等"
            />
          </div>
        </div>

        <div class="item text-input Experience-input">
          <div class="title must" :class="ExperienceClass">
            <text>个人简介</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Experience"
              @blur="verify('Experience')"
              placeholder="请输入个人简介"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>社会任职</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Learn_Post"
              placeholder="请输入社会任职"
            />
          </div>
        </div>

        <div class="item text-input PartisanJobTitle-input">
          <div class="title" :class="PartisanJobTitleClass">
            <text>职务</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.JobTitle"
              @blur="verify('JobTitle')"
              placeholder="请输入职务"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>籍贯</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.NativePlace"
              placeholder="请输入籍贯"
            />
          </div>
        </div>

        <div class="item area-input subject-input">
          <div class="title">
            <span>民族</span>
          </div>
          <div class="content" @click="skip('Nation')">
            <input
              type="text"
              
              v-model.trim="NationName"
              placeholder="选择民族"
            />
          </div>
        </div>

        <div class="item area-input subject-input">
          <div class="title">
            <span>党派</span>
          </div>
          <div class="content" @click="skip('Partisan')">
            <input
              type="text"
              
              v-model.trim="PartisanName"
              placeholder="选择党派"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>办公电话</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.OfficePhone"
              placeholder="请输入办公电话"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>省</text>
          </div>
          <div class="content">
            <input
              @click="area = true"
              type="text"
              v-model.trim="ProvinceName"
              placeholder="请输入省"
            />
            <!-- 市区 -->
            <van-area
              v-show="area"
              title="标题"
              :area-list="areaList"
              :columns-num="2"
              @confirm="success"
              @cancel="area = false"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>市</text>
          </div>
          <div class="content">
            <input type="text" v-model.trim="CityName" placeholder="请输入市" />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>姓的拼音</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="xing"
              placeholder="请输入姓的拼音"
            />
          </div>
        </div>

        <div class="item text-input department-input">
          <div class="title">
            <text>名的拼音</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="ming"
              placeholder="请输入名的拼音"
            />
          </div>
        </div>

        <div class="item text-input PostCodeClass-input">
          <div class="title" :class="PostCodeClass">
            <text>邮政编码</text>
          </div>
          <div class="content">
            <input
              type="text"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="6"
              v-model.trim="user.PostCode"
              @blur="verify('PostCode')"
              placeholder="请输入邮政编码 6位数字"
            />
          </div>
        </div>

        <div class="item area-input subject-input">
          <div class="title">
            <text>出生年月</text>
          </div>
          <div class="content">
            <input
              @click="dateShow = true"
              type="text"
              v-model.trim="user.BirthDate"
              placeholder="请输入出生年月"
            />
          </div>
          <van-popup
            v-model:show="dateShow"
            position="bottom"
            :style="{ height: '60%' }"
          >
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="confirm"
              @cancel="cancel"
            />
          </van-popup>
        </div>

        <div class="item text-input department-input">
          <div class="title must">
            <text>签名</text>
          </div>
          <div class="content">
            <signature
              @saveImages="saveImages"
              @clearArea="clearArea"
              :RegisterFile="user.RegisterFile"
            ></signature>
          </div>
        </div>

        <div style="margin-top: 20px">
          <div>
            <input type="checkbox" name="" id="" class="check" @click="check" />
            本人自愿成为“ 广东省健康科普促进会
            {{
              LearnName
            }}
            ”会员，遵守该社会团体章程和各项规章制度，履行该社会团体赋予的权利和义务。
          </div>
        </div>

        <van-popup
          v-model:show="show"
          position="bottom"
          :style="{ height: '60%' }"
        >
          <div class="type">
            <!-- <p>选择类型</p> -->
            <div class="select-type">
              <li
                v-for="(k, index) in type"
                :key="index"
                :class="num == index ? 'liang' : ''"
                @click="select(k)"
              >
                {{ k.Name }}
              </li>
            </div>
          </div>
        </van-popup>

        <!-- <div @click="exportclick">
        导出word
        
      </div> -->
        <div class="item submit" @click="submitJudge">
          {{ z == "change" ? "修改" : "提交" }}
        </div>
      </div>
    </div>
    <!-- <img src="" alt="" ref="imgSrc"> -->
  </div>
</template>

<script>
import axios from "axios";
import { areaList } from "@vant/area-data";
import signature from "@/views/initiation/components/signature";
import { getBase64Sync, exportWord } from "@/utlis/exportclick.js";
export default {
  components: {
    signature,
  },

  props: {
    personage: Object,
    hint: String,
    requestId: Number,
  },
  data() {
    return {
      type: "",
      areaList: areaList,
      // 页面显示开关
      isShow: false,
      show: false,
      LearnName: "",
      learnshow: false,
      xing: "",
      ming: "",
      fileList: [],
      area: false,
      SexArry: [
        { name: "男", id: "男" },
        { name: "女", id: "女" },
      ],
      user: {
        Sex: 1,
        Name: "", //姓名
        Icon: "", //头像图片
        UserName: "", // 用户
        Password: "", // 密码
        Email: "", // 邮箱
        Education: "", //学历
        OpenId: localStorage.getItem("openid"), //微信opid
        MemberId: 1, //会员类型id
        RegisterFile: "", // 登记表或签名图片
        RoleId: 2, // 会员类型权限id
        RoleType: 2, //会员类型权限类型
        JobTitle: "", // 职务
        Unit: "", // 单位
        Site: "", //地址
        Phone: "", // 联系电话
        IdNo: "", //身份证号
        Sex: "", // 性别（男，女）
        Nation: "", // 民族
        Partisan: "", //党派
        PartisanDate: "1920-03-12", // 入党date
        PartisanJobTitle: "", // 党派任职
        Learn: "", // 学会id
        Province: "", // 省 编号
        City: "", // 市编号
        TechnologyJobTitle: "", // 技术职称
        Experience: "", //经历
        Technology: "", //技术、简介
        OfficePhone: "", //单位电话
        PostCode: "", //邮政编码
        BirthDate: "", // 出生date
        ZhangSan: "", //名称拼音（He-He）
        NativePlace: "", //籍贯
        Promise: 0, // 是否保证（1,0）
        Department: "", //科室
        Learn_Post: "",
      },
      // 姓名
      EducationName: "",
      ProvinceName: "",
      CityName: "",
      name: null,
      nameClass: "",
      // 手机号
      phone: "",
      phoneClass: "",
      // 工作单位
      unit: null,
      // 带class都是用来收存的
      unitClass: "",
      SexClass: "",
      DepartmentClass: "",
      SiteClass: "",
      TechnologyClass: "",
      emailClass: "",
      EducationClass: "",
      IconClass: "",
      TechnologyJobTitleClass: "",
      ExperienceClass: "",
      PostCodeClass: "",
      // 备注
      IdnoClass: "",
      // 科室/部门
      department: null,
      PartisanJobTitleClass: "",
      // 技术职称

      title: null,
      titleClass: "",
      // 最高学历
      dateShow: false,

      attendeeList: [],
      // 可选费用列表
      costList: [],
      type: "",
      typeName: "",
      NationName: "",
      PartisanName: "",
      data: null,
      minDate: new Date(1930, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1950, 0, 17),
      z: "",
      typeList: [],
      LearnNameClass: "",
      pitch: "",
      num: "",
      dataOld: null,
    };
  },

  created() {
    this.getTypeMsg();
    this.GetOrganize();
  },
  mounted() {},
  // AddUserApply
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      file.status = "uploading";
      file.message = "上传中...";
      // console.log(file);
      this.saveImages(file.content, "证件");
      setTimeout(() => {
        file.status = "done";
        file.message = "上传成功";
      }, 2000);
    },


    // 上传图片压缩
    uploadImgs(file) {
         file.status = "uploading";
        file.message = "上传中...";
      // 大于2.5MB的jpeg和png图片都缩小像素上传
      if (/\/(?:jpeg|png)/i.test(file.file.type) && file.file.size > 20) {
        
        // 创建Canvas对象(画布)
        let canvas = document.createElement("canvas");
        // 获取对应的CanvasRenderingContext2D对象(画笔)
        let context = canvas.getContext("2d");
        // 创建新的图片对象
        let img = new Image();
        // 指定图片的DataURL(图片的base64编码数据)
        img.src = file.content;
        // 监听浏览器加载图片完成，然后进行进行绘制
        img.onload = () => {
          // 指定canvas画布大小，该大小为最后生成图片的大小
        canvas.width = 180;
        canvas.height = 250;
          /* drawImage画布绘制的方法。(0,0)表示以Canvas画布左上角为起点，400，300是将图片按给定的像素进行缩小。
          如果不指定缩小的像素图片将以图片原始大小进行绘制，图片像素如果大于画布将会从左上角开始按画布大小部分绘制图片，最后的图片就是张局部图。*/

          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量
          file.content = canvas.toDataURL(file.file.type, 0.92);
          // this.$refs.imgSrc.src=file.content
          
          // 最后将base64编码的图片保存到数组中，留待上传。
        };
      }
      this.saveImages(file.content, "证件");
          setTimeout(() => {
           file.status = "done";
           file.message = "上传成功";
          }, 2000);
    },


    deleteImg(file) {
      this.user.Icon = "";
      this.IconClass = "empty";
    },

    getSex(k) {
      this.user.Sex = k.id;
      this.SexClass = "correct";
    },
    
    // 拿到基础数据
    getTypeMsg() {
      axios
        .get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetTypeMsg`)
        .then((res) => {
          this.data = res.data.Data;
          if (this.hint == "change") {
            this.z = this.hint;
            this.GetUserApplyMsg();
            this.pitch = "checked";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    GetOrganize() {
      axios({
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/GetOrganize",
      })
        .then((res) => {
          this.typeList = res.data.Data;
          this.typeList.forEach((key, index) => {
            if (key.id == this.$route.params.id) {
              this.LearnName = key.title;
              this.user.Learn = key.id;
              this.LearnNameClass = "correct";
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },


      
    // 协议
    check(e) {
      if (e.target.checked) {
        this.user.Promise = 1;
      } else {
        this.user.Promise = "";
      }
    },

    // 拿到市区
    success(array) {
      this.user.Province = array[0].code;
      this.ProvinceName = array[0].name;
      this.user.City = array[1].code;
      this.CityName = array[1].name;
      this.area = false;
    },

    // 电子签名的图片
    saveImages(img, hint) {
      var z = this.dataURLtoFile(img);
      var formData = new FormData();
      formData.append("file", z);
      this.onFile(formData, hint);
    },

    // 将base64图片转成可上传文件,
    dataURLtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];

      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },

    // 将图片转成路径
    onFile(file, hint) {
      axios({
        method: "post",
        url:
          this.$store.state.imageUrl + "/tools/upload_ajax.ashx?action=ImgUp",
        data: file,
      })
        .then((res) => {
          if (hint == "签名") {
            this.user.RegisterFile = res.data;
          } else {
            this.user.Icon = res.data;
            this.IconClass = "correct";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancel() {
      this.dateShow = false;
    },

    confirm(e) {
      this.user.BirthDate = this.transformTime(e);

      this.dateShow = false;
    },

    transformTime(date) {
      var d = new Date(date);
      var datetime =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return datetime;
    },

    clearArea() {
      this.user.RegisterFile = "";
    },

    skip(name) {
      
      this.typeName = name;
      this.show = true;
      if (name == "Nation") {
        this.type = this.data.Nation;
      } else if (name == "Partisan") {
        this.type = this.data.Partisan;
      } else {
        this.type = this.data.Education;
      }
    },

    learnselect(k, index) {
      this.num = index;
      this.LearnName = k.title;
      this.user.Learn = k.id;
      this.learnshow = false;
      this.LearnNameClass = "correct";
    },

    select(k) {
      this.num = k.Index;
      if (this.typeName == "Nation") {
        this.user.Nation = k.Index;
        this.NationName = k.Name;
      } else if (this.typeName == "Partisan") {
        this.user.Partisan = k.Index;
        this.PartisanName = k.Name;
      } else {
        this.user.Education = k.Index;
        this.EducationName = k.Name;
        this.EducationClass = "correct";
      }
      this.show = false;
    },
    async exportclick() {
      let data = {
        ...this.z,
        imgUrl: await getBase64Sync(this.z.img),
      };

      let imgSize = {
        imgUrl: [120, 40], //控制导出的word图片大小
      };
      exportWord("/initiation.docx", data, `入会申请.docx`, imgSize);
      //参数1：模板文档
      //参数2：字段参数
      //参数3：输出文档
      //参数4：图片大小
    },

    // 限制只能输n位数字
    numberVerify(data, num, type) {
      // console.log(data,num,type);
      if (data.length > num) data = data.substr(0, num);
      if (type === "number") data = data.replace(/\D/g, "");
      return data;
    },

    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "name":
            if (!this.user.Name) {
              this.nameClass = "empty";
            } else {
              this.nameClass = "correct";
            }
            break;
          case "phone":
            if (!this.user.Phone) {
              this.phoneClass = "empty";
            } else if (
              !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
                this.user.Phone
              )
            ) {
              this.phoneClass = "fail";
            } else {
              this.phoneClass = "correct";
            }
            break;
          case "JobTitle":
            if (!this.user.JobTitle) {
              this.PartisanJobTitleClass = "empty";
            } else {
              this.PartisanJobTitleClass = "correct";
            }
            break;
          case "PostCode":
            if (this.user.PostCode == "") {
              this.PostCodeClass = "";
            } else if (!/^[0-9]\d{5}$/.test(this.user.PostCode)) {
              this.PostCodeClass = "fail";
            } else {
              this.PostCodeClass = "";
            }
            break;
          case "unit":
            if (!this.user.Unit) {
              this.unitClass = "empty";
            } else {
              this.unitClass = "correct";
            }
            break;

          case "Idno":
            if (!this.user.IdNo) {
              this.IdnoClass = "empty";
            } else if (
              !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
                this.user.IdNo
              )
            ) {
              this.IdnoClass = "fail";
            } else {
              this.IdnoClass = "correct";
            }
            break;
          case "TechnologyJobTitle":
            if (!this.user.TechnologyJobTitle) {
              this.TechnologyJobTitleClass = "empty";
            } else {
              this.TechnologyJobTitleClass = "correct";
            }

            break;
          case "LearnName":
            if (this.LearnName) {
              this.LearnNameClass = "correct";
            } else {
              this.LearnNameClass = "empty";
            }
            break;

          case "Department":
            if (this.user.Department) {
              this.DepartmentClass = "correct";
            } else {
              this.DepartmentClass = "empty";
            }
            break;

          // case "Site":
          //   if (this.user.Site) {
          //     this.SiteClass = "correct";
          //   } else {
          //     this.SiteClass = "empty";
          //   }
          //   break;

          // case "Technology":
          //   if (this.user.Technology) {
          //     this.TechnologyClass = "correct";
          //   } else {
          //     this.TechnologyClass = "empty";
          //   }
          //   break;

          // case "Education":
          //   if (this.EducationName) {
          //     this.EducationClass = "correct";
          //   } else {
          //     this.EducationClass = "empty";
          //   }
          //   break;

          case "Experience":
            if (this.user.Experience) {
              this.ExperienceClass = "correct";
            } else {
              this.ExperienceClass = "empty";
            }
            break;
          case "department":
            if (this.department) {
              this.departmentClass = "correct";
            }
            break;

          // case "email":
          //   if (!this.user.Email) {
          //     this.emailClass = "empty";
          //   } else if (!/.+?@.+?\..+?/.test(this.user.Email)) {
          //     this.emailClass = "fail";
          //   } else {
          //     this.emailClass = "correct";
          //   }
        }
      }
    },

    GetUserApplyMsg() {
      axios({
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetUserApplyMsg?Id=${this.requestId}`,
      })
        .then((res) => {
          this.dataOld = res.data.Data[0];
          Object.keys(this.user).forEach((key) => {
            this.user[key] = this.dataOld[key];
          });

          this.typeList.forEach((k, index) => {
            var num = this.user.Learn;
            if (k.id == num) {
              this.LearnName = k.title;
            }
          });

          this.fileList.push({
            url: this.$store.state.imageUrl + this.user.Icon,
          });
          this.user.Id = this.requestId;
          let a = this.user.ZhangSan.indexOf("-");
          this.xing = this.user.ZhangSan.slice(0, a);
          this.ming = this.user.ZhangSan.slice(
            a + 1,
            this.user.ZhangSan.length
          );

          this.data.Education.forEach((key, index) => {
            if (this.user.Education == key.Index) {
              this.EducationName = key.Name;
            }
          });

          this.data.Nation.forEach((key, index) => {
            if (this.user.Nation == key.Index) {
              this.NationName = key.Name;
            }
          });

          this.data.Partisan.forEach((key, index) => {
            if (this.user.Partisan == key.Index) {
              this.PartisanName = key.Name;
            }
          });

          for (let key in this.areaList.province_list) {
            if (this.user.Province == key) {
              this.ProvinceName = this.areaList.province_list[key];
            }
            // console.log(key+':'+this.areaList.province_list[key]);
          }
          for (let key in this.areaList.city_list) {
            if (this.user.City == key) {
              this.CityName = this.areaList.city_list[key];
            }
          }
          this.user.BirthDate = this.user.BirthDate.slice(0, 10);
        })
        .catch((err) => {});
    },

    // 提交判断
    submitJudge() {
      // 加载动画
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      /* 标题处提示 */
      this.user.ZhangSan = this.xing + "-" + this.ming;
      this.verify();
      /* 提交验证 */
      if (!this.user.Name) {
        this.tip("请输入参会人姓名");
        return;
      } else if (!this.user.Phone) {
        this.tip("请输入手机号");
        return;
      } else if (!/^\d{11}$/.test(this.user.Phone)) {
        this.tip("请正确输入手机号");
        return;
      } else if (!this.user.Sex) {
        this.tip("请选择性别");
        this.SexClass = "empty";
      } else if (!this.user.IdNo) {
        this.tip("请输入身份证号");
        return;
      } else if (!this.user.TechnologyJobTitle) {
        this.tip("请输入职称");
        return;
      } else if (!this.user.Unit) {
        this.tip("请输入参会人工作单位");
        return;
      } else if (!this.user.Department) {
        this.tip("请输入科室");
        return;
      } else if (this.user.Learn == "") {
        this.tip("请选择分会");
        return;
      } else if (!this.user.Experience) {
        this.tip("请输入个人简介");
        return;
      } 
      
      else if(this.user.RegisterFile==''){
        this.tip("请输入签名");
        return
      }
      // else if (!this.user.Email) {
      //   this.tip("请输入邮箱");
      //   return;
      // }
      //  else if (!/.+?@.+?\..+?/.test(this.user.Email)) {
      //   this.tip("请输入正确邮箱");
      //   return;
      // } 
      // else if (!this.user.Technology) {
      //   this.tip("请输入工作经历学术成果，科研项目等");
      //   return;
      // } 
      else if (this.user.Promise !== 1) {
        this.tip("请勾选协议");
        return;
      } else {
        if (this.z == "change") {
          this.updata();
        } else {
          this.submit();
        }
      }
      // this.submit()
    },
    // 提交给服务器
    submit() {
      // 提交
      // 等要选单位会员时启用
      // var data=JSON.parse(localStorage.getItem('personage'))
      // this.user.MemberId=data.Id
      // this.user.RoleId=data.RoleId
      // this.user.RoleType=data.RoleType
      // var json=JSON.stringify(this.user)
      this.$store.commit("loadHandler", {
        // 调用加载动画
        text: "提交中...",
        _this: this,
        boolean: true,
      });
      axios({
        method: "post",
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/AddUserApply",
        data: this.user,
      })
        .then((res) => {
          //  let data2 = JSON.parse(res.data);
          //   console.log(data2.Msg);
          if (res.data.Msg == "提交成功!") {
            this.$store.commit("loadHandler", {
              // 调用加载动画
              text: "提交成功",
              _this: this,
              boolean: false,
            });
            this.$toast.success("提交成功");
            this.$router.push("/person");
            // this.tip('申请成功')
          } else {
            // let data = JSON.parse(res.data);
            // console.log(data.Msg);
            this.$toast.fail(res.data.Msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 调用加载动画

            _this: this,
            boolean: false,
          });
        });
    },

    updata() {
      if (this.dataOld.State == 4) {
        this.$toast.fail("已通过不可修改");
        return;
      }
      axios({
        method: "post",
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/UpdateUserApply",
        data: this.user,
      })
        .then((res) => {
          if (res.data.Msg == "保存成功!") {
            this.$toast.success("修改成功");
            this.$router.back();
          } else {
            this.$toast.fail(res.data.Msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 提示
    tip(text, type) {
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
    // 查询已报名列表

    // 验证当前提交值有没有和已报名的信息重复（姓名、单位、手机）
    verifyRepeat() {
      var boolean = true;
      if (this.attendeeList.length) {
        this.attendeeList.forEach((item) => {
          // 如果姓名、单位、手机都相同，且状态不是已取消
          if (
            this.name === item.attend_people &&
            this.phone === item.link_my &&
            this.unit === item.company &&
            item.state !== 99
          ) {
            // 如果查到的只是修改的id，跳过
            if (
              this.meetingRegDetail &&
              this.meetingRegDetail.MeetingRegisterId
            ) {
              if (
                this.meetingRegDetail.MeetingRegisterId !==
                item.MeetingRegisterId
              ) {
                boolean = false;
              }
            } else {
              boolean = false;
            }
          }
        });
      }
      return boolean;
    },
  },

  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      // 调用加载动画
      // this.$store.commit("loadHandler", {
      //   _this: this,
      //   boolean: true,
      // });
      // 调用查询已报名列表方法
      // this.requestAttendeeList(159);

      // 如果query里类型是modify，表示修改，先读取数据
      if (
        this.$route.query.type === "modify" &&
        this.$store.state.meetingRegDetail
      ) {
        this.meetingRegDetail = this.$store.state.meetingRegDetail;
        this.name = this.meetingRegDetail.attend_people;
        this.phone = this.meetingRegDetail.link_my;
        this.unit = this.meetingRegDetail.company;
        this.department = this.meetingRegDetail.departments;
        this.title = this.meetingRegDetail.title;
        this.qualification = this.meetingRegDetail.education;
        this.paymentMethod = this.meetingRegDetail.delivery_way;
        this.invoice = this.meetingRegDetail.IsInvoice;
        this.invoiceTitle = this.meetingRegDetail.invoiceTitle;
        this.tariffLine = this.meetingRegDetail.tariffLine;
        this.email = this.meetingRegDetail.email;
        this.note = this.meetingRegDetail.notes;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-register {
  padding: 0.2rem 0 0.5rem;
  .main {
    .form {
      .title {
        position: relative;
        &::after {
          display: block;
          padding-left: 0.4rem;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 0.27rem 0.27rem;
          line-height: 0.31rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .name-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入姓名！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit {
        padding: 5px;
      }
      .phone-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "手机号格式不正确！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入手机号！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入工作单位！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Idno-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "身份证格式不正确！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入身份证号！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .TechnologyJobTitle-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入职称！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .title-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .qualification-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .LearnName-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择分会";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .TechnologyClass-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }

      .Education-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择学历学位";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Experience-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入个人简介";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Site-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入联系地址";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Icon-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请上传个人形象照";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Department-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入科室";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .Sex-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择性别！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .email-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "邮箱格式不正确！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入邮箱！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }

      .PostCodeClass-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "邮政编码格式不正确！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入邮箱！";
            background-image: url(../../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
    }
  }
}

.type {
  padding: 10px;
}
.liang {
  background: #0089fd !important;
  color: white;
}
.select-type {
  display: flex;
  flex-wrap: wrap;
  li {
    // width: 20%;
    // border: 1px solid red;
    border-radius: 20px;
    background: #e1e6ea;
    text-align: center;
    padding: 5px 12px;
    margin-right: 10px;
    margin-top: 20px;
  }
}
.check-type {
  position: absolute;
  right: 20px;
  top: 45px;
}

.Sex {
  display: flex;

  p {
    width: 60px;
    height: 35px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 35px;
  }
}

.active {
  color: #fff;
  background: #16a0d3;
}
</style>
