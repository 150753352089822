<template>
  <div class="meeting-hotel" v-if="hotelList.length">
    <Popups></Popups>
    <div class="banner img-box">
      <img
        src="../../public/wx_images/background-meetinghotel.jpg"
        alt="学术会议海报"
      />
    </div>
    <div
      class="hotel-container"
      v-for="(hotelItem, hotelIndex) in hotelList"
      :key="hotelIndex"
    >
      <div class="main">
        <div class="hotel-title">
          <span class="name">{{ hotelItem.hotelName }}</span>
          <p class="address">{{ hotelItem.hotelAddress }}</p>
          <b class="minimum">CNY : {{ getMinimum(hotelItem.Rooms) }}</b>
        </div>
        <div class="room-container">
          <div class="room-title">
            <span>房型</span>
          </div>
          <div class="room-list">
            <div
              class="room-item"
              v-for="(roomItem, roomIndex) in hotelItem.Rooms"
              :key="roomIndex"
            >
              <div class="room-info">
                <span class="room-name">{{ roomItem.rommName }}</span>
                <b class="reference"
                  >CNY : {{ parseInt(roomItem.price).toFixed(2) }}</b
                >
              </div>
              <div class="room-stepper-input" v-if="!meetingHotelDetail">
                <!-- 修改不允许增减 -->
                <div class="stepper-title">
                  <span class="text">预订数量</span>
                  <b class="tip">该房型还剩 {{ roomItem.UsableNum }} 间</b>
                </div>
                <div class="stepper-content">
                  <van-stepper
                    v-model="stepper[roomItem.rommId].quantity"
                    min="0"
                    :max="parseInt(roomItem.UsableNum)"
                    @plus="stepperPlus(roomItem.rommId)"
                    @minus="stepperMinus(roomItem.rommId)"
                    @blur="stepperChange(roomItem.rommId)"
                  />
                </div>
              </div>
              <div
                class="check-in"
                v-show="stepper[roomItem.rommId].checkIn.length"
              >
                <div class="check-in-title">
                  <span class="text">入住登记</span>
                </div>
                <div class="check-in-form">
                  <div
                    class="input-container"
                    v-for="(checkInItem, checkInIndex) in stepper[
                      roomItem.rommId
                    ].checkIn"
                    :key="checkInIndex"
                  >

                    <!-- 入住时间 -->
                     <div class="text-input user-input">
                      <div class="title must">
                        <span>入住时间</span>
                      </div>
                      <div class="content">
                        <input
                          type="text"
                          v-model.trim="checkInItem.check_date"
                          placeholder="请输入入住时间格式2022-08-31"
                          
                        />
                      </div>
                    </div>

                    <div class="text-input user-input">
                      <div class="title must">
                        <span>姓名</span>
                      </div>
                      <div class="content">
                        <input
                          type="text"
                          v-model.trim="checkInItem.name"
                          placeholder="请输入入住人姓名"
                        />
                      </div>
                    </div>
                    <div class="text-input phone-input">
                      <div class="title must">
                        <span>手机号码</span>
                      </div>
                      <div class="content">
                        <input
                          type="tel"
                           pattern="\d*"
                          v-model.trim="checkInItem.phone"
                          placeholder="请输入入住人手机号码"
                          @blur="verify(checkInItem.phone)"
                          @input="
                            checkInItem.phone = textVerify(
                              checkInItem.phone,
                              11,
                              'number'
                            )
                          "
                        />
                      </div>
                    </div>
                    <div class="text-input unit-input">
                      <div class="title must">
                        <span>工作单位</span>
                      </div>
                      <div class="content">
                        <input
                          type="text"
                          v-model.trim="checkInItem.unit"
                          placeholder="请输入入住人工作单位"
                        />
                      </div>
                    </div>
                    <div class="text-input id-card-input">
                      <div class="title">
                        <span>身份证号码</span>
                      </div>
                      <div class="content">
                        <input
                          type="tel"
                          pattern="\d*"
                          v-model.trim="checkInItem.idCard"
                          placeholder="请输入入住人身份证号码"
                         onkeyup="this.value=this.value.replace(/[^0-9.]/g,'')"
                          maxlength="18"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tip">
      <div class="tip-title">
        <span class="text">温馨提示</span>
      </div>
      <div class="tip-content">
        <p class="text">实际房型数量待酒店确认!</p>
      </div>
    </div>
    <div class="item submit" @click="submit">立即提交</div>
  </div>
</template>

<script>
import axios from "axios";
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data() {
    return {
      // 酒店列表
      hotelList: [],
      // 酒店选择结果
      hotelSelect: [],
      // 步进器
      stepper: {},
      stepperFocus: false,
      // 酒店预订详情信息
      meetingHotelDetail: null,
      state:true,
      idstate:true,
      phonestate:true
    };
  },
  methods: {
    verify(hote){
 
      
        var type=typeof(hote)
  
      if(type=="string"){
            if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(hote)){
          this.tip("手机号码不正确");
          // 
          this.state=false
         
          return
      }
      }else{
        this.state=true
      hote.forEach((item,index)=>{
       
        
        item.checkIn.forEach((key)=>{
   
      if(/[^\u0000-\u00FF]/.test(key.idCard)){
        console.log('有中文');
         this.state=false
         this.tip('身份证请勿输入中文')
         return
      }else{
        // this.idstate=true
      }

      if(/^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/.test(key.check_date)){
         console.log('正确');
        // this.state=true
      }else{
        this.tip('填写入住时间格式2022-08-31')
        key.check_date=''
        this.state=false
        // debugger
        return 
      }

      if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(key.phone)){
          this.tip("手机格式不正确");
         key.phone=''
        this.state=false
          // console.log(this.hotelSelect);
          return
      }else{
        // this.state=true
      }
      })
    })
      }
       
    },
    // 获取最低价
    getMinimum(arr) {
      var num;
      var price;
      arr.forEach((item) => {
        if (typeof item.price !== "number") {
          price = parseInt(item.price);
        } else {
          price = item.price;
        }
        if (!num) {
          num = price;
        } else if (num > price) {
          num = price;
        }
      });
      return num.toFixed(2);
    },
    // 预订房型数量变化
    stepperChange(roomId) {
      var currentQuantity = this.stepper[roomId].quantity;
      var oldQuantity = this.stepper[roomId].checkIn.length;

      // 目前的入住登记数组和预订数量对比，少就添加，多就删除
      if (currentQuantity > oldQuantity) {
        var difference = currentQuantity - oldQuantity;
        for (var i = 0; i < difference; i++) {
          this.stepper[roomId].checkIn.push({
            name: null,
            phone: null,
            idCard: null,
            unit: null,
            check_date:null
          });
        }
      } else if (currentQuantity < oldQuantity) {
        this.stepper[roomId].checkIn.length = currentQuantity;
      }
    },
    // 预订房型增加
    stepperPlus(roomId) {
      this.stepper[roomId].checkIn.push({
        name: null,
        phone: null,
        idCard: null,
        unit: null,
        check_date:null
      });
    },
    // 预订房型减少
    stepperMinus(roomId) {
      this.stepper[roomId].checkIn.pop();
    },
    // 限制只能输n位 字符 或 限制为数字
    textVerify(data, num, type) {
      if (data.length > num) data = data.substr(0, num);
      if (type === "number") data = data.replace(/\D/g, "");
      return data;
    },
    // 必填判断 + 加进数组
    selectHandler() {
      var boolean = true;
      for (var prop in this.stepper) {
        if (this.stepper[prop].quantity) {
          // 把每个有值的步进器结果push进数组
          this.stepper[prop].checkIn.forEach((item) => {
            // 判断必填项
          
            if (!item.name) {
              this.tip("入住人姓名为必填信息！");
              boolean = false;
            } else if (!item.phone) {
              this.tip("入住人手机号码为必填信息！");
              boolean = false;
            } else if (!item.unit) {
              this.tip("入住人工作单位为必填信息！");
              boolean = false;idCard
            }
             else if (item.check_date=='') {
              this.tip("入住时间为必填信息！");
              boolean = false;
            }
          });

          // 加进对应的房型中
          if (this.meetingHotelDetail) {
            // 多一个roomName
            this.hotelSelect.push({
              meeting_room_id: prop,
              quantity: this.stepper[prop].quantity,
              checkIn: this.stepper[prop].checkIn,
              roomName: this.stepper[prop].roomName,
            });
          } else {
            this.hotelSelect.push({
              meeting_room_id: prop,
              quantity: this.stepper[prop].quantity,
              checkIn: this.stepper[prop].checkIn,
            });
          }
        }
      }
      return boolean;
    },
    // 提交
    submit() {
      this.hotelSelect = []; // 清空数组
  
      
      var boolean = this.selectHandler();
      this.verify(this.hotelSelect)
      // console.log(this.state);
      if(this.state==false){
        // console.log(this.state,this.idstate,this.phonestate);
        //  this.tip('填写完整')
         return
        }
    // debugger
      if (boolean && this.hotelSelect.length) {
         
        // debugger
        // 如果验证通过且数组有长度，提交给服务器
        if (this.meetingHotelDetail) {
          // 根据meetingHotelDetail有无数据判断应该提交到新增还是修改
          // console.log(this.hotelSelect);
     
          var obj = {
            open_id: this.$store.state.openid || localStorage.openid, // 微信openid
            meeting_id: this.$route.params.id, // 会议id
            meeting_rooms: this.hotelSelect,
          };
          var json = JSON.stringify(obj);
          // debugger
          axios({
            method: "post",
            url:
              this.$store.state.apiUrl +
              "/actionapi/TencentApi/UpdateHotelRoomReserve",
            headers: {
              "Content-Type": "application/json",
            },
            data: json,
          })
            .then((res) => {
             
              if (res.data.ResultCode === 1) {
                // 更新state的数据，因为修改成功，返回却是没改的数据
                this.meetingHotelDetail.meeting_rooms = this.hotelSelect;
                this.$store.commit(
                  "setMeetingHotelDetail",
                  this.meetingHotelDetail
                );

                this.$toast.clear();
                this.$toast.loading({
                  type: "success",
                  forbidClick: true,
                  message: res.data.Msg,
                  onClose: () => {
                    if (this.$store.state.history.length > 1) {
                      this.$store.commit("returnHandler", this);
                    } else {
                      this.$router.push("/");
                    }
                  },
                });
              } else {
                this.tip(res.data.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // eslint-disable-next-line no-redeclare
          var obj = {
            open_id: this.$store.state.openid || localStorage.openid, // 微信openid
            meeting_id: this.$route.params.id, // 会议id
            meeting_rooms: this.hotelSelect,
          };
          // eslint-disable-next-line no-redeclare
          var json = JSON.stringify(obj);
          // console.log(obj);
          axios({
            method: "post",
            url:
              this.$store.state.apiUrl +
              "/actionapi/TencentApi/AddHotelRoomReserve",
            headers: {
              "Content-Type": "application/json",
            },
            data: json,
          })
            .then((res) => {
            
              if (res.data.ResultCode === 1) {
                this.$toast.clear();
                this.$toast.loading({
                  type: "success",
                  forbidClick: true,
                  message: res.data.Msg,
                  onClose: () => {
                    if (this.$store.state.history.length > 1) {
                      this.$store.commit("returnHandler", this);
                    } else {
                      this.$router.push("/");
                    }
                  },
                });
              } else {
                this.tip(res.data.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.tip("提交值为空");
      }
    },
    // 提示
    tip(text, type) {
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
    // 请求酒店数据，参数为会议id
    requestHotelList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingHotelRoom?MeetingId=" +
            id
        )
        .then((res) => {
         
          if (res) {
            var obj = {};
            var arr = res.data.Data;

            if (this.meetingHotelDetail) {
              // 创建临时的酒店和房型数据
              var _hotelList = [];
              var _rooms = [];
              // 修改的话赋予现有的值
              arr.forEach((item1) => {
                // 如果某个酒店有房间数据且 目标数据hotelList为空（前面的酒店匹配到，后面就不用再找了）
                if (item1.Rooms.length && !this.hotelList.length) {
                  item1.Rooms.forEach((item2) => {
                    // 匹配对应房间id
                    var _obj = this.meetingHotelDetail.meeting_rooms.filter(
                      (item3) => {
                        return item3.meeting_room_id === item2.rommId;
                      }
                    )[0];
                    // 如果过滤出的对象有值
                    if (_obj) {
                      // 如果有值，可以判断为要改的酒店就是这个，但房间不能直接拿
                      if (!_hotelList.length) {
                        _hotelList.push(item1);
                        _hotelList[0].Rooms = [];
                      }
                      // 增加对应房间
                      _rooms.push(item2);
                      // 初始化步进器
                      obj[item2.rommId] = {};
                      obj[item2.rommId].quantity = _obj.quantity;
                      obj[item2.rommId].checkIn = _obj.checkIn;
                      obj[item2.rommId].roomName = _obj.roomName; // 为了修改完好赋值，尽量一致
                    }
                  });
                }
              });
              // 赋值
              _hotelList[0].Rooms = _rooms;
              this.hotelList = _hotelList;
            } else {
              this.hotelList = arr;
              // 初始化步进器结果，以房型id作为key
              this.hotelList.forEach((item1) => {
                if (item1.Rooms.length) {
                  item1.Rooms.forEach((item2) => {
                    obj[item2.rommId] = {};
                    obj[item2.rommId].quantity = 0;
                    obj[item2.rommId].checkIn = [];
                  });
                }
              });
            }

            this.stepper = obj;
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit("loadHandler", {
        // 调用加载动画
        _this: this,
        boolean: true,
      });

      // 如果query里类型是modify，表示修改，先读取数据
      if (
        this.$route.query.type === "modify" &&
        this.$store.state.meetingHotelDetail
      ) {
        this.meetingHotelDetail = this.$store.state.meetingHotelDetail;
      }

      // console.log(this.meetingHotelDetail);
      // 请求酒店列表
      this.requestHotelList(this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-hotel {
  width: 100%;
  max-width: 6.4rem;
  min-height: calc(100% - 0.6rem);
  padding-bottom: 0.5rem;
  background-color: #f5f5f5;
  box-sizing: border-box;
  position: absolute;
  .hotel-container {
    margin-top: 0.25rem;
    .main {
      padding: 0.3rem 0.2rem;
      background-color: #ffffff;
      border-radius: 0.1rem;
      box-sizing: border-box;
      .hotel-title {
        padding-bottom: 0.1rem;
        border-bottom: solid 1px #a6a6a6;
        .name {
          font-size: 0.28rem;
          font-weight: bold;
        }
        .address {
          height: 0.4rem;
          padding-left: 0.4rem;
          margin-top: 0.1rem;
          line-height: 0.4rem;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 0.3rem;
            height: 0.4rem;
            background: url(../../public/wx_images/meetinghotel-icon-1.png)
              no-repeat left center / 0.25rem 0.31rem;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .minimum {
          display: block;
          margin-top: 0.1rem;
          color: #eb6337;
          font-size: 0.3rem;
          font-weight: bold;
          &.minimum::after {
            content: "起";
            margin-left: 0.15rem;
            font-size: 0.24rem;
            font-weight: normal;
            vertical-align: top;
          }
        }
      }
      .room-container {
        .room-title {
          margin-top: 0.2rem;
          font-size: 0.26rem;
          font-weight: bold;
        }
        .room-list {
          .room-item {
            padding: 0.2rem;
            border: solid 1px #a6a6a6;
            border-radius: 0.1rem;
            margin-top: 0.2rem;
            .room-info {
              padding-bottom: 0.15rem;
              border-bottom: solid 1px #a6a6a6;
              .room-name {
                font-size: 0.28rem;
                font-weight: bold;
              }
              .reference {
                display: block;
                margin-top: 0.1rem;
                color: #eb6337;
                font-size: 0.3rem;
                font-weight: bold;
                &::after {
                  content: "参考价";
                  margin-left: 0.15rem;
                  font-size: 0.24rem;
                  font-weight: normal;
                  vertical-align: top;
                }
              }
            }
            .room-stepper-input {
              margin-top: 0.2rem;
              .stepper-title {
                position: relative;
                .text {
                  font-size: 0.26rem;
                  font-weight: bold;
                }
                .tip {
                  color: #ff0000;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              .stepper-content {
                margin-top: 0.2rem;
              }
            }
            .check-in {
              .check-in-title {
                margin-top: 0.2rem;
                font-size: 0.26rem;
                font-weight: bold;
                .text {
                }
              }
              .check-in-form {
                .input-container {
                  margin-top: 0.5rem;
                  &:first-of-type {
                    margin-top: 0.2rem;
                  }
                  .text-input {
                    display: flex;
                    margin-top: 0.2rem;
                    &:first-of-type {
                      margin-top: 0;
                    }
                    .title {
                      flex-shrink: 0;
                      width: 1.6rem;
                      line-height: 0.6rem;
                      &.must::before {
                        content: "* ";
                        color: #ff0000;
                      }
                    }
                    .content {
                      flex-grow: 1;
                      input {
                        width: 100%;
                        height: 0.6rem;
                        padding-left: 0.2rem;
                        border: solid 0.02rem #a6a6a6;
                        border-radius: 0.05rem;
                        box-sizing: border-box;
                        color: #333333;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  & > .tip {
    max-width: 6rem;
    padding: 0.3rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.1rem;
    box-sizing: border-box;
    margin: 0.2rem auto 0;
    .tip-title {
      height: 0.5rem;
      padding: 0 0.2rem;
      border-bottom: solid 1px #eeeeee;
      line-height: 0.5rem;
      .text {
        color: #e96857;
        font-size: 0.26rem;
        font-weight: bold;
      }
    }
    .tip-content {
      padding: 0.2rem 0.2rem 0;
      .text {
        font-size: 0.22rem;
        line-height: 0.34rem;
      }
    }
  }
  .submit {
    width: 2.4rem;
    height: 0.56rem;
    background-color: #4e9ef7;
    border-radius: 0.05rem;
    margin: 0.8rem auto;
    color: #ffffff;
    font-size: 0.22rem;
    text-align: center;
    line-height: 0.6rem;
  }
}
</style>
