<template>
  <div class="meeting list-common" v-show="$store.state.menu.length">
    <div class="main">
      <div class="banner img-box">
        <img
          :src="$store.state.imageUrl+$store.state.menu[$store.state.IndexNumber].cover_Img"
          alt="学术会议海报"
          :onerror="defaul"
        />
      </div>
      <van-tabs ref="tab" :before-change="beforeChange" sticky>
        <van-tab
          v-for="(value, key) in meetingData"
          :title="value.Name"
          :key="key"
        >
          <ul class="list">
            <li
              class="item"
              v-for="item in value.data"
              :key="item.article_id"
              @click="navigate(item.article_type, item.article_id, item.id,value.Name)"
            >
              <div class="text">
                <h4 class="title van-multi-ellipsis--l2">{{ item.title }}</h4>
                <b class="time">{{ item.add_time }}</b>
              </div>
              <div class="image" v-if="item.img_url">
                <van-image
                  width="1.5rem"
                  height="1.18rem"
                  :src="$store.state.imageUrl + item.img_url"
                >
                  <template v-slot:error>
                    <van-image
                      width="1.5rem"
                      height="1.18rem"
                      :src="require('../../public/wx_images/default-img-1.jpg')"
                    />
                  </template>
                </van-image>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 导航索引
      navIndex: 0,
      // 数据
      meetingData: [],
      index:this.$route.query.index,
       defaul:
        'this.src="' + require("../../public/wx_images/banner-meeting.jpg") + '"',
    };
  },
  methods: {
    // 切换进行前事件，返回ture才会进行切换
    beforeChange(_index) {
      if (_index === this.navIndex) return;  // 点击当前索引也会执行，所以判断
      this.navIndex = _index;

      if (!this.meetingData[_index].data) {
        this.$store.commit("loadHandler", {
          // 调用加载动画
          _this: this,
          boolean: true,
        });
        this.request(_index, this.meetingData[_index].ArticleCate); // 调用加载方法
      }
      return true;
    },
    // 请求数据，参数为菜单索引和二级菜单的id
    request(listIndex, listId) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetArticlelist?ArticleCate=" +
            listId
        )
        .then((res) => {
          if (res.data.Data.length) {
            this.meetingData[listIndex].data = this.timeHandler(res.data.Data); // 顺便调用去掉时间T字符的方法
            if (listIndex === 0) this.$refs.tab.scrollTo(0); // 插件有点bug，如果索引是0手动滑到第一页
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },

    judge(){
        // 判断总菜单列表和学术会议菜单列表，没有就过滤出学术会议菜单列表
       if (!this.$store.state.menu.length) {
         console.log("菜单列表为空");
       } else {
         this.$store.commit("loadHandler", {
           // 调用加载动画
           _this: this,
           boolean: true,
         });
   
         var str = this.title;
         var index = this.$store.state.tabbar.title.indexOf(str);
         this.meetingData = this.$store.state.menu.filter((item) => {
           return item.Name === this.$store.state.tabbar.title[this.index];
         })[0].Items;
   
         // 获取列表，第一次为第一个二级的列表，参数为菜单索引和二级菜单的识别id
         
         if(this.meetingData.length==0){
            this.$toast.fail('未配置')
          
          
         }else{
           
           this.request(0, this.meetingData[0].ArticleCate);
         }
        
       }
    },

    // 时间去掉一个T字符
    timeHandler(arr) {
      arr.forEach((item) => {
        item.add_time = item.add_time.replace(/T/, " ");
        item.img_url = item.img_url.replace(/~/, "");
      });
      return arr;
    },
    // 点击标题跳转
    navigate(type, meetingId, articleId,Name) {
      // console.log(Name);
      localStorage.setItem('ActicleDetailBackName',Name)
      // 数据给了一个类型，确定是不是会议通知，是的话，跳到会议中心
      if (type === "ax_meeting") {
        this.$router.push("/meetingcenter/" + meetingId);
      } else {
        this.$router.push("/articledetail/" + articleId);
      }
    },
    // 滚动事件
    scrollHandler() {
      this.scrollTop = document.documentElement.scrollTop;
    },
  },
  mounted() {
    this.judge()
  },

  watch:{
	   "$route.query": {
       immediate: true, // 第一次的数据，也要当做是一种变化
       // 处理函数（不能修改名字） handler('新的值'，'旧的值')
       handler(new_value, old_value) {
           // 执行代码部分
          //  console.log(new_value,old_value);
          this.index=new_value.index
          let num=this.$store.state.menu.length-2
          // console.log(num);
          // 个人中心是不需要的
          if(new_value.index&&new_value.index <= num){
            this.judge()
          }
       },
   },
  },

  // 缓存的钩子函数
  activated() {
    // 即使有缓存，导航插件还是会重置到第一个，所以手动滑到当前索引
    this.$refs.tab.scrollTo(this.navIndex);
    // 添加滚动事件
    window.addEventListener("scroll", this.scrollHandler);
    // 设置滚动条位置
    var timeout = setTimeout(() => {
      document.documentElement.scrollTop = this.$store.state.scrollTop.meeting;
      clearTimeout(timeout);
      timeout = null;
    }, 0);
  },
  deactivated() {
    // 清除滚动事件
    window.removeEventListener("scroll", this.scrollHandler);
    // 保留当前的滚动条位置
    this.$store.commit("setScrollTop", {
      type: "meeting",
      scrollTop: this.scrollTop,
    });
  },
};
</script>
