<template>
  <div class="meeting-download" v-if="downloadList.length" :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
    <Popups></Popups>
    <div class="main">
      <ul class="download-list">
        <li 
          class="item"
          v-for="(item, index) in downloadList"
          :key="index"
          @click="download($store.state.imageUrl + item.FilePath)"
        >
          <div class="image">
            <van-image
              v-if="item.image"
              width="1rem"
              height="1rem"
              :src="item.image"
            />
            <van-image
              v-else
              width="1rem"
              height="1rem"
              :src="require('../../public/wx_images/meetingdownload-img-1.jpg')"
            />
          </div>
          <div class="text">
            <h4 class="title van-multi-ellipsis--l2">{{ item.FileName }}</h4>
            <b class="time">{{ item.FileDesc }}</b>
          </div>
        </li>
      </ul>
    </div>
    <div  class="backUrl" ref="back" v-if="backShow" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
    <img :src="$store.state.imageUrl + img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data() {
    return {
       twoColor:localStorage.getItem('twoColor'),
       backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
      show:true,
      downloadList: [],
      ishow:false,
      size:0,
      contentSize:0,
      backShow:false
    };
  },
     created(){
      // localStorage.setItem('img_url',this.$store.state.meetingInfo.img_url)
    if(this.img_url!=='null'){
      this.show=false
    }else{
      this.show=true
    }
  },
    mounted(){
    this.size=document.documentElement.clientHeight; //实时高度

    setTimeout(()=>{
      this.distance()
    },100)
  },
  methods: {
      distance () {
        this.backShow=localStorage.getItem('img_url')?true:false
      var con=document.querySelector('.download-list')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight
     

      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    download(url) {
     
      // 有效下载链接，电脑直接下载，微信是跳手机浏览器
      if (!url.includes("doc")) this.$store.commit("setSessionStorage");

      location.href = url;
    },
    // 请求数据，参数为文章id
    request(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetAttachmentFile?MeetingId=" +
            id
        )
        .then((res) => {
        
          if (res) {
            this.downloadList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
  },
  beforeMount() {
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });

    this.request(this.$route.params.id); // 调用请求方法
  },
};
</script>

<style lang="scss" scoped>
.meeting-download {
   position: relative;
    min-height: calc(100vh - 0.6rem);
  .main {
     z-index: 99;
  position: relative;
    .download-list {
      .item {
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        border-bottom: solid 0.01rem #dddddd;
        .image {
          flex-shrink: 0;
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
        }
        .text {
          flex-grow: 1;
          .title {
            margin-bottom: 0.04rem;
            font-weight: bold;
            line-height: 0.36rem;
          }
          .time {
            color: #cccccc;
            font-size: 0.2rem;
            word-break: break-all;
          }
        }
      }
    }
  }
}
.backUrl{
  width: 100%;
  height: 200px;

 
   img{
    width: 100%;
   }
    background-size: contain;
   z-index: 9;
}
</style>
