<template>
  <div class="meeting-hotel-detail" v-if="hotelInfo">
    <div class="main">
      <div class="hotel-info">
        <div class="row row-1">
          <p class="hotel-title">
            <b class="type">酒店：</b>
            <i class="text">{{ hotelInfo.hotelName }}</i>
          </p>
        </div>
        <div class="row row-2">
          <p class="unit">
            <b class="type">地址：</b>
            <i class="text">{{ hotelInfo.hotelAddress }}</i>
          </p>
        </div>
        <div class="row row-3">
          <p class="name">
            <b class="type">联系人：</b>
            <i class="text">{{ hotelInfo.link_person }}</i>
          </p>
        </div>
        <div class="row row-4">
          <p class="phone">
            <b class="type">联系号码：</b>
            <i class="text">{{ hotelInfo.link_phone }}</i>
          </p>
        </div>
      </div>
      <div
        class="room-info"
        v-for="(item, index) in hotelInfo.meeting_rooms"
        :key="index"
      >
        <div class="title">
          <div class="room-title">
            <span class="text">{{ item.roomName }}</span>
          </div>
          <div class="booking-quantity">
            <b class="type">预订数：</b>
            <span class="text">{{ item.quantity }}</span>
          </div>
        </div>
        <ul class="list">
          <li class="item" v-for="(value, key) in item.checkIn" :key="key">
            <label class="checkbox-label">
              <div class="checkbox">
                <!-- 状态不是0，disabled -->
                <input
                  type="checkbox"
                  :disabled="!canceling || value.state != 0"
                  v-model="checkboxSelect[item.meeting_room_id].checkIn"
                  :value="value"
                />
              </div>
              <div
                class="checkbox-content"
                :class="{
                  border: canceling && value.state == 0,
                  on: checkboxSelect[item.meeting_room_id].checkIn.includes(
                    value
                  ),
                }"
              >
                <div class="row row-1">
                  <div class="name">
                    <b class="type">入住人：</b>
                    <span class="text">{{ value.name }}</span>
                  </div>
                  <div class="phone">
                    <b class="type">联系号码：</b>
                    <span class="text">{{ value.phone }}</span>
                  </div>
                </div>
                <div class="row row-2">
                  <div class="id-card">
                    <b class="type">身份证：</b>
                    <i class="text">{{ value.idCard }}</i>
                  </div>
                </div>
                <div class="row row-3">
                  <div class="unit">
                    <b class="type">工作单位：</b>
                    <i class="text">{{ value.unit }}</i>
                  </div>
                </div>
                
                <div class="row row-4">
                  <div class="time">
                    <b class="type">预订时间：</b>
                    <i class="text">{{ value.add_time }}</i>
                  </div>
                </div>

                  <div class="row row-4">
                  <div class="time">
                    <b class="type">入住时间：</b>
                    <i class="text">{{ value.check_date }}</i>
                  </div>
                </div>
                <!-- 取消状态不是默认的时候显示取消时间和明细状态 -->
                <div
                  class="row row-5"
                  :class="{ 'zero-distance': value.state == 3 }"
                  v-if="value.state"
                >
                  <div class="refund-time">
                    <b class="type">{{
                      value.state == 71
                        ? "审批时间："
                        : value.state == 99
                        ? "取消时间："
                        : ""
                    }}</b>
                    <span class="text">{{
                      value.state == 71
                        ? value.agree_time
                        : value.state == 99
                        ? value.call_off_time
                        : ""
                    }}</span>
                  </div>
                  <div
                    class="refund-state"
                    :class="{ position: value.state == 3 }"
                  >
                    <span
                      class="text"
                      :class="value.state == 99 ? 'blue' : 'orange'"
                      >{{
                        value.state == 3
                          ? "已申请取消"
                          : value.state == 71
                          ? "取消不通过"
                          : value.state == 99
                          ? "已取消"
                          : ""
                      }}</span
                    >
                  </div>
                </div>
                <!-- 有审批意见且在默认状态才显示审批意见 -->
                <div class="row row-6" v-if="value.state == 71 && value.views">
                  <div class="refund-opinion">
                    <b class="type">审批意见：</b>
                    <span class="text">{{ value.views }}</span>
                  </div>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div class="button">
        <div class="modify-button" v-show="!canceling" @click="modifyOrder">
          <span class="text">修改入住信息</span>
        </div>
        <div class="submit-button" v-show="canceling" @click="cancelOrder">
          <span class="text">立即提交</span>
        </div>
        <div class="cancel-button" @click="cancelButtonClick">
          <span class="text">{{ canceling ? "取消" : "取消预订" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 酒店预订信息
      hotelInfo: null,
      // 取消预订选择中
      canceling: false,
      // 复选结果
      checkboxSelect: [],
      // 要取消的房间
      cancelRooms: [],
    };
  },
  created(){
    
  },
  methods: {
    // 跳转到酒店预订修改信息
    modifyOrder() {
      this.$router.push({
        path: "/meetinghotel/" + this.$route.params.id,
        query: {
          type: "modify",
        },
      });
    },
    // 取消预订按钮事件
    cancelButtonClick() {
      this.canceling = !this.canceling;
      if (this.canceling) {
        this.$store.commit("tipHandler", {
          _this: this,
          type: "fail",
          text: "请选择要取消预订的房间，然后提交",
        });
      }
    },
    // 取消预订，由于比较敏感，添加确认弹窗
    cancelOrder() {
      // 清空要取消的房间
      this.cancelRooms = [];
      // 调用获取要取消的房间方法
      this.getCancelRooms();
      // 如果数组有长度，发送给服务器
      if (this.cancelRooms.length) {
        // 要发送的数据
        var obj = {
          open_id: this.$store.state.openid || localStorage.openid, // 微信openid
          meeting_id: this.$route.params.id, // 会议id
          meeting_rooms: this.cancelRooms,
        };
        var json = JSON.stringify(obj);
        // 取消确认
        this.$dialog
          .confirm({
            title: "是否确定要取消预订？",
          })
          .then(() => {
            // 加载动画
            this.$toast.loading({
              message: "提交中...",
              forbidClick: true,
              duration: 0,
            });
            // 提交表单给后端
            axios({
              method: "post",
              url:
                this.$store.state.apiUrl +
                "/actionapi/TencentApi/UpdateHotelRoomCancel",
              headers: {
                "Content-Type": "application/json",
              },
              data: json,
            })
              .then((res) => {
                this.$toast.clear();
                if (res.data.ResultCode === 1) {
                  this.$toast.clear();
                  this.$toast.loading({
                    type: "success",
                    forbidClick: true,
                    message: res.data.Msg,
                    onClose: () => {
                      if (this.$store.state.history.length > 1) {
                        this.$store.commit("returnHandler", this);
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                } else {
                  this.tip(res.data.Msg);
                }
              })
              .catch((err) => {
                console.log(err);
                this.$toast.clear();
              });
          })
          .catch(() => {
            console.log("用户点击了取消");
          });
      } else {
        this.tip("请选择要取消的房间");
      }
    },
    // 过滤出要取消的房间
    getCancelRooms() {
      for (var prop in this.checkboxSelect) {
        // 过滤状态为取消中和已取消的选项
        var arr = this.checkboxSelect[prop].checkIn;
        arr = arr.filter((item) => {
          // eslint-disable-next-line eqeqeq
          return item.state == 0;
        });
        if (arr.length) {
          // 加进对应的房型中
          this.cancelRooms.push({
            meeting_room_id: prop,
            quantity: arr.length,
            checkIn: arr,
            roomName: this.checkboxSelect[prop].roomName,
          });
        }
      }
    },
    // 提示
    tip(text, type) {
      this.$toast.clear();
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    // 有openid的情况下才赋值
    if (this.$store.state.user.openid || localStorage.openid) {
      if (this.$store.state.meetingHotelDetail) {
        this.hotelInfo = this.$store.state.meetingHotelDetail;
        // 初始化选择结果，以房型id作为key
     
        var obj = {};
        var time=[]
        this.hotelInfo.meeting_rooms.forEach((item,index) => {
          // console.log(item.checkIn);
          time=item.checkIn
          if (item.checkIn.length) {
            obj[item.meeting_room_id] = {};
            obj[item.meeting_room_id].checkIn = [];
            obj[item.meeting_room_id].roomName = item.roomName;
          }
        });

       
        time.forEach((item,index)=>{
          // console.log(item);
       if(item.check_date!==''){
          // item.check_date=item.check_date.split('')
          // // item.check_date=item.check_date.splice(0,9)
          // item.check_date=item.check_date.join()
          // item.check_date=item.check_date.replace(/,/g,'')
       }
        })
        this.checkboxSelect = obj;
      } else {
        console.log("没有数据");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-hotel-detail {
  min-height: 2.83rem;
  padding-bottom: 0.5rem;
  background: url(../../public/wx_images/background-examine.png) no-repeat
    center top / 100% 2.83rem;
  box-sizing: border-box;
  overflow: hidden;
  .main {
    .hotel-info {
      background-color: #ffffff;
      padding: 0.3rem 0.35rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.65rem;
      .row {
        margin-top: 0.15rem;
        &:first-of-type {
          margin-top: 0;
        }
        p {
          display: flex;
          .type {
            flex-shrink: 0;
            color: #8d8d8d;
            font-size: 0.22rem;
          }
          .text {
            flex-grow: 1;
            font-size: 0.22rem;
          }
        }
        .hotel-title {
          .text {
            font-weight: bold;
          }
        }
        .time,
        .phone {
          display: block;
        }
      }
    }
    .room-info {
      background-color: #ffffff;
      padding: 0.25rem 0.35rem 0.4rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.5rem;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0 0.02rem;
        line-height: 0.3rem;
        .room-title {
          flex-grow: 1;
          .text {
            font-weight: bold;
          }
        }
        .booking-quantity {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          height: 0.3rem;
          .type {
            color: #8d8d8d;
            font-size: 0.22rem;
          }
          .text {
            color: #33ccff;
          }
        }
      }
      .list {
        margin-top: 0.2rem;
        .item {
          box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
          margin-top: 0.2rem;
          position: relative;
          &:first-of-type {
            margin-top: 0;
          }
          .checkbox-label {
            .checkbox {
              display: none;
            }
            .checkbox-content {
              padding: 0.15rem 0.2rem;
              border: 1px solid transparent;
              border-radius: 0.05rem;
              &::after {
                content: "";
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                background: url(../../public/wx_images/meetingregpay-icon-1.png)
                  no-repeat center center / 100% 100%;
                opacity: 0;
                position: absolute;
                right: 0;
                bottom: 0;
                transition: 0.3s;
              }
              &.border {
                border-color: #a6a6a6;
              }
              &.border.on {
                border-color: #1a90ff;
                &::after {
                  opacity: 1;
                }
              }
              .row {
                margin-top: 0.1rem;
                &:first-of-type {
                  margin-top: 0;
                }
                & > div {
                  .type {
                    color: #8d8d8d;
                    font-size: 0.22rem;
                  }
                  .text {
                    font-size: 0.22rem;
                    word-break: break-all;
                  }
                }
              }
              .row-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                  flex-grow: 1;
                  .text {
                    font-weight: bold;
                  }
                }
                .phone {
                  flex-shrink: 0;
                  .text {
                    font-weight: bold;
                  }
                }
              }
              .row-3 {
                .unit {
                  display: flex;
                  .type {
                    flex-shrink: 0;
                  }
                  .text {
                    flex-grow: 1;
                  }
                }
              }
              .row-5 {
                display: flex;
                justify-content: space-between;
                margin-top: 0.1rem;
                &.zero-distance {
                  // 如果是取消中状态，不要margin
                  margin-top: 0;
                }
                .refund-time {
                  .type {
                    color: #8d8d8d;
                    font-size: 0.22rem;
                  }
                }
                .refund-state {
                  &.position {
                    // 如果是取消中状态，最下面就剩一个状态，加个定位到预订时间哪一行好看点
                    position: absolute;
                    right: 0.2rem;
                    bottom: 0.15rem;
                  }
                  .text {
                    font-size: 0.22rem;
                    &.orange {
                      color: #ff9900;
                    }
                    &.blue {
                      color: #33ccff;
                    }
                  }
                }
              }
              .row-6 {
                margin-top: 0.1rem;
                .refund-opinion {
                  display: flex;
                  .type {
                    flex-shrink: 0;
                    color: #8d8d8d;
                    font-size: 0.22rem;
                  }
                  .text {
                    flex-grow: 1;
                    font-size: 0.22rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.8rem;
      .modify-button,
      .submit-button,
      .cancel-button {
        width: 5rem;
        height: 0.56rem;
        padding-left: 0.05rem;
        background-color: #4e9ef7;
        border-radius: 0.05rem;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 0.24rem;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 0.6rem;
      }
      .cancel-button {
        background-color: #ff4500;
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
