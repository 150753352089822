<template>
  <div>
    <div class="box" v-if="list.length!==0">
      <div v-for="(item, index) in list" :key="index" class="item" @click="btn(item)">
        <img :src="this.$store.state.imageUrl+item.Icon" alt=""  :onerror="this.default" />
        <p class="name">{{ item.Name }}</p>
        <p class="item-post">{{ item.Unit }}</p>
        <p>{{ item.JobTitle }}</p>
      </div>
    </div>
    <van-empty v-else image="search" description="列表为空" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id: this.$route.params.id,
      default:'this.src="'+require('../../../public/wx_images/mo.png')+'"',
      imagse: [],
      list: [],
      Jobs:'主委,副主委'
    };
  },
  created() {
    this.GetMember();
  },
  methods: {
    
    GetMember(){
        axios.get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMember?Id=${this.id}&Jobs=${this.Jobs}`
        )
        .then((res) => {
          this.list=res.data.Data
        })
        .catch((err) => {
          console.log(err);
        });
        },
    btn(item) {
      // console.log(item);
      this.$router.push('/healthDetails/'+item.Id);
      // this.$router.push({name:'healthDetails',params:{item}});
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 20px;
  display: flex;
  flex: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box::after {
  // content: "";
  // flex: auto;
}
.item {
  width: 2.51rem;
  height: 4.3rem;
  padding: 0px 10px 5px 10px;
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 2rem;
    height: 2.775rem;
  }
  .name {
    font-size: 0.305rem;
    font-weight: 600;
    margin: 5px 0 5px 0;
  }
  .item-post {
    margin: 10px 0 10px 0;
  }
}
</style>
