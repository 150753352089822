<template>
  <div>
    <div class="box">
      <img src="@/assets/wx_images/logo.png" alt="" />
    </div>
    <div class="contact">
      <p class="odd">地&nbsp;址:</p>
      <span>{{ list.webaddress }}</span
      ><br />
      <p class="odd">电&nbsp;话:</p>
      <span>{{ list.webtel }}</span
      ><br />
      <p class="odd">传&nbsp;真:</p>
      <span>{{ list.webfax }}</span
      ><br />
      <p class="odd">邮&nbsp;箱:</p>
      <span>{{ list.mail }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const res = await axios({
        url: "/tools/upload_ajax.ashx?action=Config",
      });
      try {
        this.list = res.data;
     
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.box {
  width: 5rem;
  margin: 0.35rem auto;
}
img {
  width: 100%;
}
.contact {
  width: 4.75rem;
  height: 3.5rem;
  margin: 0.35rem auto;
}
.odd {
  display: inline-block;
  font-weight: 600;
  margin-right: 6px;
  margin-top: 0.25rem;
}
</style>
