<template>
  <div class="examine-list" v-if="isShow">
    <div class="main">
      <ul class="list" v-if="$route.query.type === 'ced'">
        <li class="item" v-for="item in applyList1" :key="item.id" @click="navigate(item.id, 'ced')">
          <div class="title">
            <h4 class="text">{{item.title}}</h4>
          </div>
          <div class="info">
            <b class="time">{{item.time}}</b>
            <i class="state" :class="(item.state ? 'orange' : 'blue')">{{(item.state ? '已通过' : '待审批')}}</i>
          </div>
        </li>
      </ul>
      <ul class="list" v-else>
        <li class="item" v-for="item in applyList2" :key="item.id" @click="navigate(item.id, 'meeting')">
          <div class="title">
            <h4 class="text">{{item.title}}</h4>
          </div>
          <div class="info">
            <b class="time">{{item.time}}</b>
            <i class="state" :class="(item.state ? 'orange' : 'blue')">{{(item.state ? '已通过' : '待审批')}}</i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
<script>
export default {
  data () {
    return {
      // 页面显示开关
      isShow: true,
      applyList1: [
        {
          id: '1',
          title: '人类先天畸形的发生与防治',
          time: '2018-04-21 13:00',
          state: 0
        },
        {
          id: '2',
          title: '1984年12月换届改选',
          time: '2020-04-21 13:00',
          state: 1
        },
        {
          id: '3',
          title: '全国结核和呼吸疾病诊治进展学习班',
          time: '2077-04-21 13:00',
          state: 0
        }
      ],
      applyList2: [
        {
          id: '1',
          title: '第十五届护理解剖学学术年会',
          time: '2018-04-21 13:00',
          state: 0
        },
        {
          id: '2',
          title: '1984年12月换届改选',
          time: '2020-04-21 13:00',
          state: 1
        },
        {
          id: '3',
          title: '1984年12月(地市合并后)换届改选(第四届)届改届改届改',
          time: '2077-04-21 13:00',
          state: 0
        }
      ]
    }
  },
  methods: {
    // 跳转申请详情页
    navigate (id, type) {
      this.$router.push({
        path: '/examinedetail/' + id,
        query: {
          type
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) { // 路由进入拦截
    next(vm => {
      // vm.$store.commit('routerGuard', { // 调用守卫
      //   fromPath: from.path,
      //   _this: vm
      // })
    })
  },
  beforeMount () { // 有token的情况下才显示
    if (this.$store.state.user.token || localStorage.token) this.isShow = true
  }
}
</script>

<style lang="scss" scoped>
.examine-list {
  .main {
    padding : 0.5rem 0 0.5rem ;
    .list {
      .item {
        display : flex ;
        justify-content : space-between ;
        background-color : #FFFFFF ;
        padding : 0.15rem ;
        border-radius : 0.05rem ;
        box-shadow : 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1) ;
        margin-top : 0.3rem ;
        &:first-of-type {
          margin-top : 0 ;
        }
        .title {
          flex-grow : 1 ;
          .text {
            font-weight : bold ;
            line-height : 0.3rem ;
          }
        }
        .info {
          flex-shrink : 0 ;
          width : 2rem ;
          text-align : right ;
          line-height : 0.3rem ;
          b {
            display : block ;
            color : #cfcfcf ;
            font-size : 0.2rem ;
          }
          i {
            display : block ;
            margin-top : 0.2rem ;
            &.blue {
              color : #FF9900 ;
            }
            &.orange {
              color : #33CCFF ;
            }
          }
        }
      }
    }
  }
}
</style>
