<template>
  <div class="about list-common" v-show="$store.state.menu.length">
    <div class="main">
      <!-- <button @click="$router.push('/Photo/'+145)">1111111</button> -->
      <div class="banner img-box">
        <img src="../../public/wx_images/banner-about.jpg" alt="关于我们海报" />
      </div>
      <van-tabs ref="tab" :before-change="beforeChange" sticky>
        <van-tab
          v-for="(value, key) in aboutData"
          :title="value.Name"
          :key="key"
        >
          <ul class="list">
            <li
              class="item"
              v-for="item in value.data"
              :key="item.id"
              @click="navigate(item.id, value.type, item.title,value.Name)"
            >
              <div class="text">
                <h4 class="title van-multi-ellipsis--l2">{{ item.title }}</h4>
                <b class="time" v-if="item.add_time">{{ item.add_time }}</b>
              </div>
              <div class="image" v-if="item.img_url">
                <van-image
                  width="1.5rem"
                  height="1.18rem"
                  :src="$store.state.imageUrl + item.img_url"
                >
                  <template v-slot:error>
                    <van-image
                      width="1.5rem"
                      height="1.18rem"
                      :src="require('../../public/wx_images/default-img-1.jpg')"
                    />
                  </template>
                </van-image>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Popups from '@/components/Popups'
export default {
  components:{
    Popups
  },
  data() {
    return {
      // 导航索引
      navIndex: 0,
      // 数据
      aboutData: [],
      index:this.$route.query.index
    };
  },
  methods: {
    // 切换进行前事件，返回ture才会进行切换
    beforeChange(_index) {
      if (_index === this.navIndex) return; // 点击当前索引也会执行，所以判断
      this.navIndex = _index;

      if (!this.aboutData[_index].data) {
        this.$store.commit("loadHandler", {
          // 调用加载动画
          _this: this,
          boolean: true,
        });
        this.request(_index, this.aboutData[_index].ArticleCate); // 调用加载方法
      }
      return true;
    },
    // 请求数据，参数为菜单索引和二级菜单的id（后需求：专科学会点击后是分会列表，点击某一分会是这个分会的新闻列表，再点击才是详情）
    request(listIndex, listId) {
      // 判断二级的id是不是分会列表的id（后端说不变）
      if (listId === "306") {
        axios
          .get(
            this.$store.state.apiUrl + "/actionapi/TencentApi/GetChapterLilt"
          )
          .then((res) => {
            if (res.data.Data.length) {
              // 分会列表赋值，新增一个type属性，以此来判断跳转
              this.aboutData[listIndex].data = res.data.Data;
              this.aboutData[listIndex].type = "branch";
              if (listIndex === 0) this.$refs.tab.scrollTo(0); // 插件有点bug，如果索引是0手动滑到第一页
            } else {
              console.log("获取数据为空");
            }

            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          });
      } else {
        axios
          .get(
            this.$store.state.apiUrl +
              "/actionapi/TencentApi/GetArticlelist?ArticleCate=" +
              listId
          )
          .then((res) => {
            if (res.data.Data.length) {
              this.aboutData[listIndex].data = this.timeHandler(res.data.Data); // 顺便调用去掉时间T字符的方法
              if (listIndex === 0) this.$refs.tab.scrollTo(0); // 插件有点bug，如果索引是0手动滑到第一页
            } else {
              console.log("获取数据为空");
            }

            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          });
      }
    },
    // 时间去掉一个T字符，因为后来拿到的图片地址有~，顺便也去掉
    timeHandler(arr) {
      arr.forEach((item) => {
        item.img_url = item.img_url.replace(/^~/, "");
        item.add_time = item.add_time.replace(/T/, " ");
      });
      return arr;
    },
    // 点击标题跳转
    navigate(id, type, title,Name) {
      // console.log(Name);
      localStorage.setItem('ActicleDetailBackName',Name)
      if (type === "branch") {
        this.$router.push({
          path: "/branchnews/" + id,
          query: {
            title,
          },
        });
      } else {
        this.$router.push("/articledetail/" + id);
      }
    },
    // 滚动事件
    scrollHandler() {
      this.scrollTop = document.documentElement.scrollTop;
    },
  },
  mounted() {
    
    this.$router.push({
      path:this.$store.state.menu[0].LinkUrl,
      query:{index:0}
    })
    // 判断总菜单列表和关于学会菜单列表，没有就过滤出关于学会菜单列表
    if (!this.$store.state.menu.length) {
      console.log("菜单列表为空");
    } else if (this.aboutData.length) {
      console.log("已有关于学会菜单列表");
    } else {
      this.$store.commit("loadHandler", {
        // 调用加载动画
        _this: this,
        boolean: true,
      });

      var str = this.title;
      var index = this.$store.state.tabbar.title.indexOf(str);
      console.log(index);
      this.aboutData = this.$store.state.menu.filter((item) => {
        return item.Name === this.$store.state.tabbar.title[0];
      })[0].Items;

      // 获取列表，第一次为第一个二级的列表，参数为菜单索引和二级菜单的识别id
      this.request(0, this.aboutData[0].ArticleCate);
    }
  },
  // 缓存的钩子函数
  activated() {
    // 即使有缓存，导航插件还是会重置到第一个，所以手动滑到当前索引
    this.$refs.tab.scrollTo(this.navIndex);
    // 添加滚动事件
    window.addEventListener("scroll", this.scrollHandler);
    // 设置滚动条位置
    var timeout = setTimeout(() => {
      document.documentElement.scrollTop = this.$store.state.scrollTop.about;
      clearTimeout(timeout);
      timeout = null;
    }, 0);
  },
  
  
  deactivated() {
    // 清除滚动事件
    window.removeEventListener("scroll", this.scrollHandler);
    // 保留当前的滚动条位置
    this.$store.commit("setScrollTop", {
      type: "about",
      scrollTop: this.scrollTop,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../public/css/list.css";
</style>
