<template>
  <div>
    <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      @search="inputChang"
    />
    <div>
      <p
        v-for="(item, index) in newlist"
        :key="index"
        class="item"
        @click="btn(item.id)"
      >
        {{ item.Branch_name }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      value: "",
      id: this.$route.params.id,
      list: [],
      newlist: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    async getdata() {
      const res = await axios({
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/GetExpert",
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { Rate: 1, SortId: this.id },
      });
      try {
        this.list = res.data.Data;
        this.newlist = this.list;
      } catch (err) {
        this.$store.commit("loadHandler", {
          // 结束加载动画
          _this: this,
          boolean: false, //false为停止
        });
        console.log(err);
      }
    },

    inputChang(val) {
      this.list.forEach((item) => {
        if (item.Branch_name.includes(this.value)) {
          this.newlist = [];
          this.newlist.push(item);
          if (this.value == "") {
            this.getdata();
            this.newlist = this.list;
          }
        }
      });
    },
    btn(id) {
      this.$router.push("/specialist/" + id);
    },
  },
};
</script>

<style scoped>
.item {
  height: 0.6rem;
  margin: 0 auto;
  line-height: 0.6rem;
  border-bottom: 1px solid #ccc;
  padding: 0 10px 0 10px;
  margin: 0 10px 0 10px;
}
</style>
