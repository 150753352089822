<template>
  <div>
    <div class="box">
      <div v-for="(item, index) in list" :key="index" class="item" @click="btn(item.Expert_id)">
        <img :src="this.$store.state.imageUrl+item.img_url" alt=""  :onerror="this.default" />
        <p class="name">{{ item.Expert_Name }}</p>
        <p class="item-post">{{ item.Position }}</p>
        <p>{{ item.Employer }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id: this.$route.params.id,
      default:'this.src="'+require('../../../public/wx_images/mo.png')+'"',
      imagse: [],
      list: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    async getdata() {
       const res = await axios({
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/GetExpert",
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { Rate: 2, BranchId: this.id },
      });
      try {
        res.data.Data.forEach(item=>{
        // 因为图片前面加了个~需要处理一下
        var objReplace = JSON.stringify(item) //首先给对象转换成字符串
        var newObjString = objReplace.replace('~', '') //替换成需要的字段名
        var newObj = JSON.parse(newObjString) //最后转换成对象就ok啦
        this.list.push(newObj)
       })
  
        
      } catch (err) {
         this.$store.commit('loadHandler', { // 结束加载动画
              _this: this,
              boolean: false
            })
        console.log(err);
      }
    },
    btn(id) {
      this.$router.push('/detailed/'+id);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 20px;
  display: flex;
  flex: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box::after {
  // content: "";
  // flex: auto;
}
.item {
  width: 2.51rem;
  height: 4.3rem;
  padding: 0px 10px 5px 10px;
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 2rem;
    height: 2.775rem;
  }
  .name {
    font-size: 0.305rem;
    font-weight: 600;
    margin: 5px 0 5px 0;
  }
  .item-post {
    margin: 10px 0 10px 0;
  }
}
</style>
