<template>
  <div class="meeting-agenda"  :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
    <Popups></Popups>
     <div class="main">
       <div class="agenda" ref="agenda">
       </div>
     </div>
     <div  class="backUrl" v-if="backShow" ref="back" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
       <img :src="$store.state.imageUrl + $store.state.meetingInfo.img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data () {
    return {
      agenda: null,
      twoColor:localStorage.getItem('twoColor'),
      backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
      show:true,
      ishow:false,
      size:0,
      contentSize:0,
      backShow:false
    }
  },
  created(){
    
     if(this.img_url!=='null'){
      this.show=false
     }else{
      this.show=true
     }
      this.twoColor=localStorage.getItem('twoColor')

  },
    mounted(){
   
  },
  methods: {
       distance () {
      this.backShow=localStorage.getItem('img_url')?true:false
      this.size=document.documentElement.clientHeight; //实时高度
      var con=document.querySelector('.agenda')
      this.contentSize=con.offsetHeight
      console.log(this.contentSize);
      var backSize=this.$refs.back.offsetHeight
      console.log(backSize);
      
      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + '/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=' + id + '&Field=agenda')
        .then((res) => {
          if (res.data.Data.length) {
            var html = res.data.Data[0].agenda
            this.agenda = html
            this.$refs.agenda.innerHTML = this.agenda
          } else {
            console.log('获取数据为空')
          }
             setTimeout(()=>{
      this.distance()
    },100)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
          // this.distance()
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    }
  },
  mounted () {
    this.$store.commit('loadHandler', { // 调用加载动画
      _this: this,
      boolean: true
    })

    this.request(this.$route.params.id) // 调用请求方法
  }
}
</script>

<style lang="scss" scoped>
 .meeting-agenda {
  /* // width: 100%;
  // box-sizing: border-box; */
   position: relative;
   min-height: calc(100vh - 0.6rem);
   
}
   .img-container {
      /* // padding : 0.3rem 0 0.5rem ; */
      text-align : center ;
    }
  .main {
  z-index: 99;
  position: relative;

 
  }
     .agenda {
      padding : 0.3rem 0 0.5rem ;
      max-width: 375px;
      max-height: 100%;
    }
    .agenda p img{
      max-width: 375px;
    }
.backUrl{
  width: 100%;
  height: 200px;

  background-size: contain;
   z-index: 9;
   img{
    width: 100%;
   }
}
</style>
