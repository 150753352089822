<template>
  <div>
    <div class="box-item">
      <p
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="select(item.id)"
      >
        &nbsp; {{ item.specialty }}&nbsp;
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    select(id) {
      this.$router.push("/Expertsclassify/" + id);
    },
    async getdata() {
      var obj = {
        Rate: 0,
      };
      try {
        const res = await axios({
          url: this.$store.state.apiUrl + "/actionapi/TencentApi/GetExpert",
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: {
            Rate: 0,
          },
        });
        this.list = res.data.Data;
        this.$store.commit("loadHandler", {
          // 结束加载动画
          _this: this,
          boolean: false,
        });
      } catch (err) {
        this.$store.commit("loadHandler", {
          // 结束加载动画
          _this: this,
          boolean: false,
        });
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.box-item {
  margin-top: 20px;
}
.item {
  height: 0.6rem;
  font-weight: 600;
  display: inline-block;
  margin: 0 auto;
  color: rgb(129, 159, 178);
  line-height: 0.6rem;
  border: 2px solid rgb(129, 159, 178);
  padding: 0 10px 0 10px;
  margin: 0.225rem 10px 0 10px;
  border-radius: 30px;
}
</style>
