import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Meeting from '../views/Meeting.vue'
import Person from '../views/Person.vue'
import BranchNews from '../views/BranchNews.vue'
import ArticleDetail from '../views/ArticleDetail.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Retrieve from '../views/RetrievePassword.vue'
import CEDeclare from '../views/CEDeclare.vue'
import ExamineList from '../views/ExamineList.vue'
import ExamineDetail from '../views/ExamineDetail.vue'
import PersonInfo from '../views/PersonInfo.vue'
import PersonModify from '../views/PersonModify.vue'
import MeetingCenter from '../views/MeetingCenter.vue'
import MeetingInvitation from '../views/MeetingInvitation.vue'
import MeetingInfo from '../views/MeetingInfo.vue'
import MeetingOrg from '../views/MeetingOrg.vue'
import MeetingContact from '../views/MeetingContact.vue'
import MeetingDownload from '../views/MeetingDownload.vue'
import MeetingTraffic from '../views/MeetingTraffic.vue'
import MeetingPlayback from '../views/MeetingPlayback.vue'
import MeetingNotice from '../views/MeetingNotice.vue'
import MeetingHotel from '../views/MeetingHotel.vue'
import MeetingAgenda from '../views/MeetingAgenda.vue'
import MeetingPerson from '../views/MeetingPerson.vue'
import MeetingPersonInfo from '../views/MeetingPersonInfo.vue'
import MeetingRegister from '../views/MeetingRegister.vue'
import MeetingArticle from '../views/MeetingArticle.vue'
import MeetingRegPay from '../views/MeetingRegPay.vue'
import MeetingWelcome from '../views/MeetingWelcome.vue'
import MeetingPayList from '../views/MeetingPayList.vue'
import MeetingPayDetail from '../views/MeetingPayDetail.vue'
import MeetingHotelList from '../views/MeetingHotelList.vue'
import MeetingHotelDetail from '../views/MeetingHotelDetail.vue'
import MeetingRegList from '../views/MeetingRegList.vue'
import MeetingRegDetail from '../views/MeetingRegDetail.vue'
import MeetingRefund from '../views/MeetingRefund.vue'
import GetCodeBase from '../views/GetCodeBase.vue'
import GetCodeUserInfo from '../views/GetCodeUserInfo.vue'
// 专家路由
import database from '@/views/database/database1.vue'
import Expertsclassify from '../views/database/Expertsclassify2.vue'
import specialist from '../views/database/specialist3.vue'
import detailed from '../views/database/detailed.vue'
// 委员路由
import committee from '../views/committee/committee.vue'
import updatainfo from '../views/UpdataInfo.vue'
import Gallery from '../views/Gallery'
import Photo from '../views/Photo'
import MeetingLive from '../views/MeetingLive'
import MeetingLiveDetail from '../views/MeetingLiveDetail'
// 促健会
import personInform from '../views/PersonInform.vue'
import member from '../views/member'  
import entrance from '../views/member/entrance.vue'  
import business from '../views/member/components/business.vue'
import certificate from '../views/member/components/certificate.vue'
import PayRecord from '../views/member/components/PayRecord.vue'
import record from '../views/member/components/record.vue'
import initiation from '../views/initiation'
import apply from '../views/ApplyList'
import applyDetails from '../views/ApplyList/applyDetails.vue'
import auditDetails from '../views/ApplyList/auditDetails.vue'
import learnaudit from '../views/LearnAudit'
import healthSpecialist from '../views/healthSpecialist'
import healthItemDetails from '../views/healthSpecialist/Itemdetails.vue'
import healthDetails from '../views/healthSpecialist/details.vue'

// 推荐码
import Themenu from '../views/Themenu.vue'
import demo from '../views/demo.vue'
import Referral from '../views/Referral.vue'
import contactUs from '../views/contact/contactUs.vue'
import Alipay from '../views/Alipay.vue'
import GalleryDetail from '../views/GalleryDetail.vue'
import MeetingExpert from '../views/MeetingExpert.vue'
import wordtes from '../views/wordtes.vue'
import store from '../store' // 因为路由守卫无法用this，现在要修改vuex变量
import axios from 'axios' // 取菜单列表需要请求
import Test from './test.vue'

// 测试
// import z from '../store/z.vue'
const routes = [
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      keepAlive: true
    }
  },

  {
    path:'/wordtes',
    name:'wordtes',
    component:wordtes
  },
  {
    path:'/Test',
    name:'Test',
    component:Test
  },
  // 活动学习
  {
    path: '/demo',
    name: 'demo',
    component: demo,

  },
  // 促健会
  {
    path:'/personInform',
    name:'personInform',
    component:personInform
  },
  {
    path:'/member',
    name:'member',
    component:member
  },
  {
    path:'/entrance',
    name:'entrance',
    component:entrance
  },
  {
    path:'/business',
    name:'business',
    component:business
  },
  {
    path:'/certificate',
    name:'certificate',
    component:certificate
  },
  {
    path:'/PayRecord',
    name:'PayRecord',
    component:PayRecord
  },
  {
    path:'/record',
    name:'record',
    component:record
  },
  {
    path:'/initiation/:id',
    name:'initiation',
    component:initiation
  },
  {
    path:'/apply',
    name:'apply',
    component:apply
  },
  {
    path:'/applyDetails',
    name:'applyDetails',
    component:applyDetails
  },
  {
    path:'/auditDetails',
    name:'auditDetails',
    component:auditDetails
  },
  {
    path:'/learnaudit',
    name:'learnaudit',
    component:learnaudit
  },
    {
    path:'/healthSpecialist',
    name:'healthSpecialist',
    component:healthSpecialist
  },
  {
    path:'/healthDetails/:id',
    name:'healthDetails',
    component:healthDetails
  },
  {
    path:'/healthItemDetails/:id',
    name:'healthItemDetails',
    component:healthItemDetails
  },
  // 委员开始
  {
    path: '/committee/:ReferralCode',
    name: 'committee',
    component: committee,

  },
  {
    path: '/updatainfo/:ReferralCode',
    name: 'updatainfo',
    component: updatainfo,

  },
  {
    path: '/Themenu/:id',
    name: 'Themenu',
    component: Themenu,
    meta: {
      keepAlive: true
    }
  },
  // 委员结束

  // 专家测试开始
  {
    path: '/database',
    name: 'database',
    component: database,

  }, {
    path: '/Expertsclassify/:id',
    name: 'Expertsclassify',
    component: Expertsclassify,

  },
  {
    path: '/detailed/:id',
    name: 'detailed',
    component: detailed,

  }, {
    path: '/specialist/:id',
    name: 'specialist',
    component: specialist,

  },
  // 专家测试结束

  //  联系我们开始
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs,

  },
  // 联系我们结束
  // 推荐码开始
  {
    path: '/Referral',
    name: 'Referral',
    component: Referral,

  },
  // 推荐码结束

  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/meeting',
    name: 'Meeting',
    component: Meeting,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/person',
    name: 'Person',
    component: Person
  },
  {
    path: '/branchnews/:id',
    name: 'BranchNews',
    component: BranchNews,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/articledetail/:id',
    name: 'ArticleDetail',
    component: ArticleDetail
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/retrieve',
    name: 'Retrieve',
    component: Retrieve
  },
  {
    path: '/cedeclare',
    name: 'CEDeclare',
    component: CEDeclare
  },
  {
    path: '/examinelist',
    name: 'ExamineList',
    component: ExamineList
  },
  {
    path: '/examinedetail/:id',
    name: 'ExamineDetail',
    component: ExamineDetail
  },
  {
    path: '/personinfo',
    name: 'PersonInfo',
    component: PersonInfo
  },
  {
    path: '/personmodify',
    name: 'PersonModify',
    component: PersonModify
  },
  {
    path: '/meetingcenter/:id',
    name: 'MeetingCenter',
    component: MeetingCenter
  },
  {
    path: '/meetinginvitation/:id',
    name: 'meetinginvitation',
    component: MeetingInvitation
  },
  {
    path: '/meetinginfo/:id',
    name: 'MeetingInfo',
    component: MeetingInfo
  },
  {
    path: '/meetingorg/:id',
    name: 'MeetingOrg',
    component: MeetingOrg
  },
  {
    path: '/meetingcontact/:id',
    name: 'MeetingContact',
    component: MeetingContact
  },
  {
    path: '/meetingdownload/:id',
    name: 'MeetingDownload',
    component: MeetingDownload
  },
  {
    path: '/meetingtraffic/:id',
    name: 'MeetingTraffic',
    component: MeetingTraffic
  },
  {
    path: '/meetingplayback/:id',
    name: 'MeetingPlayback',
    component: MeetingPlayback
  },
  {
    path: '/meetingnotice/:id',
    name: 'MeetingNotice',
    component: MeetingNotice
  },
  {
    path: '/meetinghotel/:id',
    name: 'MeetingHotel',
    component: MeetingHotel
  },
  {
    path: '/meetingagenda/:id',
    name: 'MeetingAgenda',
    component: MeetingAgenda
  },
  {
    path: '/meetingperson/:id',
    name: 'MeetingPerson',
    component: MeetingPerson
  },
  {
    path: '/meetingpersoninfo/:id',
    name: 'MeetingPersonInfo',
    component: MeetingPersonInfo
  },
  {
    path: '/meetingregister/:id',
    name: 'MeetingRegister',
    component: MeetingRegister
  },
  {
    path: '/meetingarticle/:id',
    name: 'MeetingArticle',
    component: MeetingArticle
  },
  {
    path: '/meetingregpay/:id',
    name: 'MeetingRegPay',
    component: MeetingRegPay
  },
  {
    path: '/meetingwelcome/:id',
    name: 'MeetingWelcome',
    component: MeetingWelcome
  },
  {
    path: '/meetingpaylist/:id',
    name: 'MeetingPayList',
    component: MeetingPayList
  },
  {
    path: '/meetingpaydetail/:id',
    name: 'MeetingPayDetail',
    component: MeetingPayDetail
  },
  {
    path: '/meetingrefund/:id',
    name: 'MeetingRefund',
    component: MeetingRefund
  },
  {
    path: '/meetinghotellist/:id',
    name: 'MeetingHotelList',
    component: MeetingHotelList
  },
  {
    path: '/meetinghoteldetail/:id',
    name: 'MeetingHotelDetail',
    component: MeetingHotelDetail
  },
  {
    path: '/meetingreglist/:id',
    name: 'MeetingRegList',
    component: MeetingRegList
  },
  {
    path: '/meetingregdetail/:id',
    name: 'MeetingRegDetail',
    component: MeetingRegDetail
  },
  {
    path: '/getcodebase',
    name: 'GetCodeBase',
    component: GetCodeBase
  },
  {
    path: '/getcodeuserinfo',
    name: 'GetCodeUserInfo',
    component: GetCodeUserInfo
  },
  {
    path: '/alipay/:id',
    name: 'Alipay',
    component: Alipay
  },
  {
    path: '/Gallery/:id',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/GalleryDetail',
    name: 'GalleryDetail',
    component: GalleryDetail
  },
  
  {
    path: '/Photo/:id',
    name: 'Photo',
    component: Photo
  },
  {
    path: '/MeetingLive/:id',
    name: 'MeetingLive',
    component: MeetingLive
  },
  {
    path: '/MeetingLiveDetail',
    name: 'MeetingLiveDetail',
    component: MeetingLiveDetail
  },
  {
    path: '/MeetingExpert/:id',
    name: 'MeetingExpert',
    component: MeetingExpert
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/about',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if(to.path=='/Activitycenter'){
    store.state.showtab=1
  }else if(to.path=='/Activityitem'){
    store.state.showtab=0
  }else if(to.path=='/activitypersonal'){
    store.state.showtab=3
  }else if(to.path=='/activityMy'){
    store.state.showtab=2
  }
  
  // 保存token和账号到vuex，如果local有值且vuex为空的话
  if (localStorage.user && !store.state.user.user && localStorage.token && !store.state.user.token) {
    store.commit('storeUserInfo', {
      user: localStorage.user,
      token: localStorage.token
    })
  }
  // 保存openid到vuex，如果local有值且vuex为空的话
  if (localStorage.openid && !store.state.user.openid) {
    store.commit('storeUserOpenid', localStorage.openid)
  }
  // 请求菜单，如果菜单为空
  if (!store.state.menu.length) {
    axios.get(store.state.apiUrl + '/actionapi/TencentApi/GetMeun')
      .then((res) => {
        if (res.data.Data.length) {
          store.commit('modifyMenu', res.data.Data)
          next()
        } else {
          console.log('获取的数组为空')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  } else {
    next()
  }
  // 控制navbar和tabbar显示
  if (to.path === '/news' || to.path === '/about' || to.path === '/meeting' || to.path === '/person') {
    store.state.isTabbarShow = true
  } else {
    store.state.isTabbarShow = false
  }
  // 记录 跳转历史
  var title = ''
  if (to.path === '/about') {
    title = '关于学会'
  } else if (/\/branchnews\/\d+/.test(to.path)) {
    title = '分会新闻'
  } else if (to.path === '/cedeclare') {
    title = '继教项目申报'
  } else if (/\/articledetail\/\d+/.test(to.path)) {
    title = '文章详情'
  } else if (to.path === '/examinelist') {
    title = '申请列表'
  } else if (/\/examinedetail\/\d+/.test(to.path)) {
    title = '申请详情'
  } else if (to.path === '/login') {
    title = '登录'
  } else if (to.path === '/meeting') {
    title = '学术会议'
  } else if (to.path === '/news') {
    title = '新闻通知'
  } else if (to.path === '/person') {
    title = '个人中心'
  } else if (to.path === '/personinfo') {
    title = '个人信息'
  } else if (to.path === '/personmodify') {
    title = '个人信息修改'
  }else if (to.path === '/entrance') {
    title = '会员中心'
  } else if (to.path === '/contactUs') {
    title = '联系我们'
  } else if (to.path === '/register') {
    title = '账号注册'
  }  else if (to.path === '/healthSpecialist') {
    title = '会员风采'
  } else if (to.path === '/MeetingLiveDetail') {
    title = '直播详情'
  } else if (to.path === '/retrieve') {
    title = '找回密码'
  }
  else if (to.path === '/personInform') {
    title = '消息通知'
  }
   else if (to.path === '/Referral') {
    title = '推荐码登录'
  }else if (to.path ==='/apply') {
    title = '入会信息' 
  } else if (to.path ==='/Themenu') {
    title = '科普继教' 
  } else if (to.path ==='/applyDetails') {
    title = '修改信息' 
  }  else if (to.path ==='/learnaudit') {
    title = '入会申请' 
  } else if (/\/specialist\/\d+/.test(to.path)) {
    title = '专家库'
  } else if (/\/initiation\/\d+/.test(to.path)) {
    title = '入会申请'
  } else if (/\/detailed\/\d+/.test(to.path)) {
    title = '专家库'
  } else if (/\/committee\/\d+/.test(to.path)) {
    title = '委员申请'
  } else if (/\/updatainfo\/\d+/.test(to.path)) {
    title = '修改资料'
  } else if (/\/meetingcenter\/\d+/.test(to.path)) {
    title = '会议中心'
  } else if (/\/meetingcontact\/\d+/.test(to.path)) {
    title = '联系我们'
  } else if (/\/meetingdownload\/\d+/.test(to.path)) {
    title = '资料下载'
  } else if (/\/meetinginfo\/\d+/.test(to.path)) {
    title = '基本信息'
  } else if (/\/meetinginvitation\/\d+/.test(to.path)) {
    title = '邀请函'
  } else if (/\/meetingorg\/\d+/.test(to.path)) {
    title = '组织机构'
  } else if (/\/meetingtraffic\/\d+/.test(to.path)) {
    title = '会场交通'
  } else if (/\/meetingplayback\/\d+/.test(to.path)) {
    title = '会议录播'
  } else if (/\/meetingnotice\/\d+/.test(to.path)) {
    title = '参会须知'
  } else if (/\/meetinghotel\/\d+/.test(to.path)) {
    title = '酒店预订'
  } else if (/\/meetingagenda\/\d+/.test(to.path)) {
    title = '大会议程'
  } else if (/\/meetingperson\/\d+/.test(to.path)||/\/MeetingPerson\/\d+/.test(to.path)) {
    title = '个人中心'
  } else if (/\/meetingpersoninfo\/\d+/.test(to.path)) {
    title = '个人信息'
  } else if (/\/meetingregister\/\d+/.test(to.path)) {
    title = '参会注册'
  } else if (/\/meetingarticle\/\d+/.test(to.path)) {
    title = '征文投稿'
  } else if (/\/meetingregpay\/\d+/.test(to.path)) {
    title = '参会费用'
  } else if (/\/meetingwelcome\/\d+/.test(to.path)) {
    title = '欢迎辞'
  } else if (/\/meetingpaylist\/\d+/.test(to.path)) {
    title = '支付订单记录'
  } else if (/\/meetingpaydetail\/\d+/.test(to.path)) {
    title = '订单详情'
  } else if (/\/meetingrefund\/\d+/.test(to.path)) {
    title = '申请退款'
  } else if (/\/meetinghotellist\/\d+/.test(to.path)) {
    title = '酒店预订记录'
  } else if (/\/meetinghoteldetail\/\d+/.test(to.path)) {
    title = '订单详情'
  } else if (/\/meetingreglist\/\d+/.test(to.path)) {
    title = '参会记录'
  } else if (/\/meetingregdetail\/\d+/.test(to.path)) {
    title = '参会信息'
  } else if (to.path === '/database') {
    title = '专家库'
  } else if (/\/Expertsclassify\/\d+/.test(to.path)) {
    title = '专家库'
  } else if (/\/MeetingLive\/\d+/.test(to.path)) {
    title = '会议直播'
  } else if (/\/Gallery\/\d+/.test(to.path)) {
    title = '云展厅'
  } 
  else if (/\/Photo\/\d+/.test(to.path)) {
    title = '图片直播'
  } else {
    title = '惠来县人民医院'
  }
  // 如果不是拿code的页面或者支付宝回调页面，记录历史
  if (to.path !== '/getcodebase' || to.path !== '/alipay') {
    if (sessionStorage.history) {
      store.commit('retrospectHistory')
    } else {
      store.commit('recordHistory', {
        path: to.path,
        title: title
      })
    }

    title = null
  }
})

export default router
