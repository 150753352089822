<template>
  <div class="getcodeuserinfo">
    <div @click="jump('/about')"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      code: null,
      url: location.href, // 获取code的回调网址
      // 湛江的
      // appid: 'wxe983fa3a8748b9a2', // 公众号的id
      // secret: 'f0ab213ef5ca909359c1d8e9c2752844', // 公众号开发者密码
      // 德加测试号的
      // appid:'wx8c05b3a95d6792a1',
      // secret:'5f1d0938f15f338d96c5c8ba02b2afad'
      // 促健会的
      appid: 'wxe6612b5250806f64', // 公众号的id
      secret: '32965e8c784c9b9d8d38f157f33c8223', // 公众号开发者密码
    }
  },
  methods: {
    
    jump(path){
      this.$router.push(path)
    },

    // 获取openid
    getOpenId () {
      var json = {
        appid: this.appid,
        secret: this.secret,
        code: this.code
      }
      json = JSON.stringify(json)
      // 提交给服务器
      axios({
        method: 'post',
        url: this.$store.state.apiUrl + '/actionapi/TencentApi/GetOpenId',
        headers: {
          'Content-Type': 'application/json'
        },
        data: json
      })
        .then((res) => {
          if (res.data && res.data.openid) {
            this.$store.commit('storeUserOpenid', res.data.openid) // 储存openid

            this.$toast.loading({
              type: 'success',
              forbidClick: true,
              message: '授权成功',
              onClose: () => {
                // 跳转目标页，按正常来说是返回之前拦截的页面
                if (this.$store.state.history.length > 1) {
                  this.$store.commit('returnHandler', this)
                } else {
                  this.$router.push('/')
                }
              }
            })
          } else {
            this.$store.commit('tipHandler', {
              _this: this,
              type: 'fail',
              text: '获取授权失败',
              url: '/'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    if (!this.$store.state.user.openid && !localStorage.openid) {
      if (this.$route.query.code) { // 如果有code，请求openid
        console.log('created', this.$route.query.code)
        this.code = this.$route.query.code
        this.getOpenId()
      } else { // 如果没有code，请求code
        console.log('created', '没有code')
        this.$store.commit('setSessionStorage') // 跳转前记录历史
        window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appid + '&redirect_uri=' + this.url + '&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect'
      }
    } else {
      this.$toast.loading({
        type: 'success',
        forbidClick: true,
        message: '已授权',
        onClose: () => {
          // 跳转目标页，按正常来说是返回之前拦截的页面
          if (this.$store.state.history.length > 1) {
            this.$store.commit('returnHandler', this)
          } else {
            this.$router.push('/')
          }
        }
      })
    }
  }
}
</script>
