<template>
  <div class="meeting-register form-container" v-if="isShow">
    <div class="main form-main">
      <div class="form form-content">
        <div class="item text-input name-input">
          <div class="title must" :class="nameClass">
            <span>参会人姓名</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="name"
              @blur="verify('name')"
              placeholder="请输入参会人姓名"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
          <div class="tip">
            <p>姓名 提交后无法修改</p>
          </div>
        </div>
        <div class="item text-input phone-input">
          <div class="title must" :class="phoneClass">
            <span>手机号码</span>
          </div>
          <div class="content">
            <input
              type="text"
              oninput="value=value.replace(/[^\d]/g,'')"
          
              v-model.trim="phone"
              @blur="verify('phone')"
              @input="phone = numberVerify(phone, 11, 'number')"
              placeholder="请输入手机号码"
            />
          </div>
        </div>
        <div class="item text-input unit-input">
          <div class="title must" :class="unitClass">
            <span>工作单位</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="unit"
              @blur="verify('unit')"
              placeholder="请输入工作单位"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
          <div class="tip">
            <p>工作单位 提交后无法修改</p>
          </div>
        </div>
        <div
          class="item radio-input payment-method-input"
          v-if="costList.length"
        >
          <div class="title must" :class="paymentMethodClass">
            <text>缴费方式</text>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in paymentMethodOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="paymentMethod"
                    :value="value.value"
                    @change="verify('paymentMethod')"
                    :disabled="
                      $route.query.type === 'modify' &&
                      $store.state.meetingRegDetail
                    "
                  />
                </div>
                <div
                  class="text"
                  @click="hint(value)"
                  :class="{ checked: paymentMethod == value.value }"
                >
                  <span class="unit">{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
          <div class="tip">
            <p>缴费方式 提交后无法修改</p>
          </div>
        </div>
        <div class="item radio-input invoice-input" v-if="costList.length">
          <div class="title must" :class="invoiceClass">
            <text>是否需要发票</text>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in invoiceOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="invoice"
                    :value="value.value"
                    @change="verify('invoice')"
                  />
                </div>
                <div class="text" :class="{ checked: invoice == value.value }">
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input invoice-title-input" v-show="invoice">
          <div class="title must" :class="invoiceTitleClass">
            <span>发票抬头</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="invoiceTitle"
              @blur="verify('invoiceTitle')"
              placeholder="请输入发票抬头"
            />
          </div>
        </div>
        <div class="item text-input tariff-line-input" v-show="invoice">
          <div class="title must" :class="tariffLineClass">
            <span>税号</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="tariffLine"
              @blur="verify('tariffLine')"
              placeholder="请输入税号"
            />
          </div>
        </div>
        <div class="item text-input email-input" v-show="invoice">
          <div class="title must" :class="emailClass">
            <span>邮箱</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="email"
              @blur="verify('email')"
              placeholder="请输入接收发票的邮箱"
            />
          </div>
        </div>
        <div class="item text-input department-input">
          <div class="title" :class="departmentClass">
            <text>科室/部门</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="department"
              @blur="verify('department')"
              placeholder="请输入科室/部门"
            />
          </div>
        </div>
        <div class="item radio-input title-input">
          <div class="title" :class="titleClass">
            <text>技术职称</text>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in titleOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="title"
                    :value="value.value"
                    @change="verify('title')"
                  />
                </div>
                <div class="text" :class="{ checked: title == value.value }">
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item radio-input qualification-input">
          <div class="title" :class="qualificationClass">
            <text>最高学历</text>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in qualificationOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="qualification"
                    :value="value.value"
                    @change="verify('qualification')"
                  />
                </div>
                <div
                  class="text"
                  :class="{ checked: qualification == value.value }"
                >
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input note-input">
          <div class="title" :class="noteClass">
            <span>备注</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="note"
              @blur="verify('note')"
              placeholder="请输入备注"
            />
          </div>
        </div>
        <div class="item submit" @click="submitJudge">立即提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      // 页面显示开关
      isShow: false,
      // 姓名
      name: null,
      nameClass: "",
      // 手机号
      phone: "",
      phoneClass: "",
      // 工作单位
      unit: null,
      unitClass: "",
      // 备注
      note: null,
      noteClass: "",
      // 科室/部门
      department: null,
      departmentClass: "",
      // 技术职称
      titleOption: [
        {
          value: "正高",
          text: "正高",
        },
        {
          value: "副高",
          text: "副高",
        },
        {
          value: "中级",
          text: "中级",
        },
        {
          value: "初级",
          text: "初级",
        },
        {
          value: "其他",
          text: "其他",
        },
      ],
      title: null,
      titleClass: "",
      // 最高学历
      qualificationOption: [
        {
          value: "博士研究生",
          text: "博士研究生",
        },
        {
          value: "硕士研究生",
          text: "硕士研究生",
        },
        {
          value: "本科",
          text: "本科",
        },
        {
          value: "大专",
          text: "大专",
        },
        {
          value: "中专",
          text: "中专",
        },
        {
          value: "其他",
          text: "其他",
        },
      ],
      qualification: null,
      qualificationClass: "",
      // 支付方式
      paymentMethodOption: [
        {
          value: "alipay",
          text: "微信支付",
        },
        {
          value: "giro",
          text: "单位公对公转账",
        },
      ],
      paymentMethod: null,
      paymentMethodClass: "",
      // 是否开具发票
      invoiceOption: [
        {
          value: 1,
          text: "需要",
        },
        {
          value: 0,
          text: "不需要",
        },
      ],
      invoice: null,
      invoiceClass: "",
      // 发票抬头
      invoiceTitle: null,
      invoiceTitleClass: "",
      // 税号
      tariffLine: null,
      tariffLineClass: "",
      // 邮箱
      email: null,
      emailClass: "",
      // 参会信息详情
      meetingRegDetail: null,
      // 已报名列表
      attendeeList: [],
      // 可选费用列表
      costList: [],
    };
  },
  methods: {

hint(i){
  if(i.value=='giro'){
    Dialog.confirm({
  title: '以下单位才能单位转账',
  message: '湛江中心人民医院、湛江市第一中医医院、湛江市第二中医医院、湛江市第四人民医院、中国人民解放军南部战区海军第一医院、湛江市妇幼保健计划生育服务中心',
  })
  .then(() => {
    // on confirm
  })
  .catch(()=>{

  })
  }else{
    return
  }
  

},

    // 限制只能输n位数字
    numberVerify(data, num, type) {
      if (data.length > num) data = data.substr(0, num);
      if (type === "number") data = data.replace(/\D/g, "");
      return data;
    },
    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "name":
            if (!this.name) {
              this.nameClass = "empty";
            } else {
              this.nameClass = "correct";
            }
            break;
          case "phone":
            if (!this.phone) {
              this.phoneClass = "empty";
            } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
              this.phoneClass = "fail";
            } else {
              this.phoneClass = "correct";
            }
            break;
          case "unit":
            if (!this.unit) {
              this.unitClass = "empty";
            } else {
              this.unitClass = "correct";
            }
            break;
          case "note":
            if (this.note) {
              this.noteClass = "correct";
            }
            break;
          case "department":
            if (this.department) {
              this.departmentClass = "correct";
            }
            break;
          case "title":
            if (this.title) {
              this.titleClass = "correct";
            }
            break;
          case "qualification":
            if (this.qualification) {
              this.qualificationClass = "correct";
            }
            break;
          case "paymentMethod":
            if (!this.paymentMethod) {
              this.paymentMethodClass = "empty";
            } else {
              this.paymentMethodClass = "correct";
            }
            break;
          case "invoice":
            if (!this.invoice && this.invoice !== 0) {
              this.invoiceClass = "empty";
            } else {
              this.invoiceClass = "correct";
            }
            break;
          case "invoiceTitle":
            if (!this.invoiceTitle) {
              this.invoiceTitleClass = "empty";
            } else {
              this.invoiceTitleClass = "correct";
            }
            break;
          case "tariffLine":
            if (!this.tariffLine) {
              this.tariffLineClass = "empty";
            } else {
              this.tariffLineClass = "correct";
            }
            break;
          case "email":
            if (!this.email) {
              this.emailClass = "empty";
            } else if (!/.+?@.+?\..+?/.test(this.email)) {
              this.emailClass = "fail";
            } else {
              this.emailClass = "correct";
            }
        }
      } else {
        if (!this.name) {
          this.nameClass = "empty";
        } else {
          this.nameClass = "correct";
        }
        if (!this.phone) {
          this.phoneClass = "empty";
        } else if (!/^\d{11}$/.test(this.phone)) {
          this.phoneClass = "fail";
        } else {
          this.phoneClass = "correct";
        }
        if (!this.unit) {
          this.unitClass = "empty";
        } else {
          this.unitClass = "correct";
        }
        if (this.note) {
          this.noteClass = "correct";
        }
        if (this.department) {
          this.departmentClass = "correct";
        }
        if (this.title) {
          this.titleClass = "correct";
        }
        if (this.qualification) {
          this.qualificationClass = "correct";
        }
        if (!this.paymentMethod) {
          this.paymentMethodClass = "empty";
        } else {
          this.paymentMethodClass = "correct";
        }
        if (!this.invoice && this.invoice !== 0) {
          this.invoiceClass = "empty";
        } else {
          this.invoiceClass = "correct";
        }
        if (!this.invoiceTitle) {
          this.invoiceTitleClass = "empty";
        } else {
          this.invoiceTitleClass = "correct";
        }
        if (!this.tariffLine) {
          this.tariffLineClass = "empty";
        } else {
          this.tariffLineClass = "correct";
        }
        if (!this.email) {
          this.emailClass = "empty";
        } else {
          this.emailClass = "correct";
        }
        if (!this.email) {
          this.emailClass = "empty";
        } else if (!/.+?@.+?\..+?/.test(this.email)) {
          this.emailClass = "fail";
        } else {
          this.emailClass = "correct";
        }
      }
    },
    // 提交判断
    submitJudge() {
      // 加载动画
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      /* 标题处提示 */
      this.verify();
      /* 提交验证 */
      if (!this.name) {
        this.tip("请输入参会人姓名");
      } else if (!this.phone) {
        this.tip("请输入手机号");
      } else if (!/^\d{11}$/.test(this.phone)) {
        this.tip("请正确输入手机号");
      } else if (!this.unit) {
        this.tip("请输入参会人工作单位");
      } else if (this.costList.length && !this.paymentMethod) {
        this.tip("请选择支付方式");
      } else if (this.costList.length && !this.invoice && this.invoice !== 0) {
        this.tip("请选择是否需要开具发票");
      } else {
        if (this.costList.length && this.invoice === 1) {
          if (!this.invoiceTitle) {
            this.tip("请输入发票抬头");
          } else if (!this.tariffLine) {
            this.tip("请输入税号");
          } else if (!this.email) {
            this.tip("请输入邮箱");
          } else if (!/.+?@.+?\..+?/.test(this.email)) {
            this.tip("邮箱格式错误");
          } else {
            // 调用验证重复方法
            var boolean = this.verifyRepeat();
          }
        } else {
          // 调用验证重复方法
          // eslint-disable-next-line no-redeclare
          var boolean = this.verifyRepeat();
        }
        // 判断重复验证有没有通过
        if (boolean) {
          this.submit();
        } else {
          this.tip("报名记录中检测到有相同姓名、手机、单位的用户");
        }
      }
    },
    // 提交给服务器
    submit() {
      // 提交
      var obj = {
        open_id: this.$store.state.user.openid || localStorage.openid, // 微信openid
        meeting_id: this.$route.params.id, // 会议id
        notes: this.note, // 备注
        attend_people: this.name, // 参会人员名称
        link_my: this.phone, // 联系方式
        company: this.unit, // 工作单位
        department: this.department, // 科室/部门
        title: this.title, // 职称
        qualification: this.qualification, // 学历
        paymentMethod: this.paymentMethod, // 支付方式
        invoice: this.invoice, // 是否开票
        invoiceTitle: this.invoiceTitle, // 发票抬头
        tariffLine: this.tariffLine, // 税号
        email: this.email, // 邮箱
      };

      if (this.meetingRegDetail && this.meetingRegDetail.MeetingRegisterId) {
        obj.sign_up_id = this.meetingRegDetail.MeetingRegisterId;
        var json = JSON.stringify(obj);
        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/UpdateMeetingSignUp",
          headers: {
            "Content-Type": "application/json",
          },
          data: json,
        })
          .then((res) => {
            if (res.data.ResultCode === 1) {
              // 提交成功
              // 更新state的数据，因为修改成功，返回却是没改的数据
              this.meetingRegDetail.IsInvoice = this.invoice;
              this.meetingRegDetail.attend_people = this.name;
              this.meetingRegDetail.company = this.unit;
              this.meetingRegDetail.delivery_way = this.paymentMethod;
              this.meetingRegDetail.departments = this.department;
              this.meetingRegDetail.education = this.qualification;
              this.meetingRegDetail.email = this.email;
              this.meetingRegDetail.invoiceTitle = this.invoiceTitle;
              this.meetingRegDetail.link_my = this.phone;
              this.meetingRegDetail.tariffLine = this.tariffLine;
              this.meetingRegDetail.title = this.title;
              this.meetingRegDetail.notes = this.note;
              this.$store.commit("setMeetingRegDetail", this.meetingRegDetail);

              this.$toast.clear();
              this.$toast.loading({
                type: "success",
                forbidClick: true,
                message: res.data.Msg,
                onClose: () => {
                  if (this.$store.state.history.length > 1) {
                    this.$store.commit("returnHandler", this);
                  } else {
                    this.$router.push("/");
                  }
                },
              });
            } else {
              // 提交失败
              this.tip(res.data.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // eslint-disable-next-line no-redeclare
        var json = JSON.stringify(obj);
        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/AddMeetingRegister",
          headers: {
            "Content-Type": "application/json",
          },
          data: json,
        })
          .then((res) => {
           
            if (res.data.ResultCode === 1) {
              // 提交成功
              this.$toast.clear();
              this.$toast.loading({
                type: "success",
                forbidClick: true,
                message: res.data.Msg,
                onClose: () => {
                  
                  // 报名通过审核才能下单，所以返回个人中心
             
                    this.$store.commit("setSessionStorage", {
                    type: "meetingreglist",
                    meetingId: this.$route.params.id,
                  });
               
                  // 返回上一级
                  this.$store.commit("returnHandler", this);
                },
              });
            } else {
              // 提交失败
              this.tip(res.data.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 提示
    tip(text, type) {
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
    // 查询已报名列表
    requestAttendeeList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMeetingSignUp?open_id=${
              this.$store.state.user.openid || localStorage.openid
            }&MeetingId=${id}`
        )
        .then((res) => {
          if (res.data.Data.length) {
            this.attendeeList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          // 调用查询可选费用列表方法
          this.requestCostList(this.$route.params.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询可选费用列表
    requestCostList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingFee?MeetingId=" +
            id
        )
        .then((res) => {
          if (res.data.Data && res.data.Data.length) {
            // 赋值给费用菜单，如果长度为0，不会显示费用相关的东西
            this.costList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          this.isShow = true; // 显示页面
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 验证当前提交值有没有和已报名的信息重复（姓名、单位、手机）
    verifyRepeat() {
      var boolean = true;
      if (this.attendeeList.length) { 
        this.attendeeList.forEach((item) => {
          
          // 如果姓名、单位、手机都相同，且状态不是已取消
          if (
            this.name === item.attend_people &&
            this.phone === item.link_my &&
            this.unit === item.company &&
            item.state !== 99
          ) {
            // 如果查到的只是修改的id，跳过
            if (
              this.meetingRegDetail &&
              this.meetingRegDetail.MeetingRegisterId
            ) {
              if (
                this.meetingRegDetail.MeetingRegisterId !==
                item.MeetingRegisterId
              ) {
                boolean = false;
              }
            } else {
              boolean = false;
            }
          }
        });
      }
      return boolean;
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      // 调用加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: true,
      });
      // 调用查询已报名列表方法
      this.requestAttendeeList(this.$route.params.id);

      // 如果query里类型是modify，表示修改，先读取数据
      if (
        this.$route.query.type === "modify" &&
        this.$store.state.meetingRegDetail
      ) {
        this.meetingRegDetail = this.$store.state.meetingRegDetail;
        this.name = this.meetingRegDetail.attend_people;
        this.phone = this.meetingRegDetail.link_my;
        this.unit = this.meetingRegDetail.company;
        this.department = this.meetingRegDetail.departments;
        this.title = this.meetingRegDetail.title;
        this.qualification = this.meetingRegDetail.education;
        this.paymentMethod = this.meetingRegDetail.delivery_way;
        this.invoice = this.meetingRegDetail.IsInvoice;
        this.invoiceTitle = this.meetingRegDetail.invoiceTitle;
        this.tariffLine = this.meetingRegDetail.tariffLine;
        this.email = this.meetingRegDetail.email;
        this.note = this.meetingRegDetail.notes;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-register {
  padding: 0.2rem 0 0.5rem;
  .main {
    .form {
      .title {
        position: relative;
        &::after {
          display: block;
          padding-left: 0.4rem;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 0.27rem 0.27rem;
          line-height: 0.31rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .name-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入姓名！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit{
        
        padding: 5px;
      }
      .phone-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "手机号格式不正确！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入手机号！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入工作单位！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .note-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .department-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .title-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .qualification-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .payment-method-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择支付方式！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .invoice-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择是否需要开具发票！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .invoice-title-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入发票抬头！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .tariff-line-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入税号！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .email-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "邮箱格式不正确！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入邮箱！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
    }
  }
}
</style>
