<template>
  <div v-if="$store.state.ListShow.ListShow">
    <div class="but" @click="showPopup">
      <span class="iconfont icon-fenlei" style="color:#95b3d7;"></span>
    </div>
     <van-popup v-model:show="show"  :position="$store.state.ListShow.ListStylePosition" :style="{ height: '100%' }">
      <ul style="margin-top:30px;width:200px">
        <li v-for="(k,index) in $store.state.routeArry" :key="index" :class="num==index?'item actitve':'item'" @click="skip(k,index)">
          <p v-if="$store.state.ListShow.ListImgShow">
            <img :src="$store.state.imageUrl+k.CoverImg" alt="" :onerror="defaul"/>

          </p>
            <span>{{k.Name}}</span>
        </li>
      </ul>
     </van-popup>
  </div>
</template>

<script>
import '../assets/download/font_3812170_1n53mwl9hjy/iconfont.css'
export default {
    props:{
        ListStylePosition:String,
        DataItem:Array,
        master:Number,
        ListImgShow:Boolean
    },
    data(){
        return {
            show:false,
              defaul:
        'this.src="' + require("../../public/wx_images/meetingcenter-img-1.jpg") + '"',
            data:[
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
              {name:'会议直播'},
            ],
            num:''
        }
    },
    methods:{
      showPopup() {
        this.show = true;
      },
      skip(k,index){
        this.num=index
        this.$router.push({name:k.Url,params:{id:this.$store.state.meetingInfo.id}})
      }
    }
}
</script>

<style lang="scss" scoped>
    .but{
        width: 20px;
        height: 20px;
        /* background: #000; */
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 999;
    }
    .item{
      width:100%;height:50px;
      line-height: 50px;
      /* margin-left: 20px; */
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      p{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
      }
    }

    .actitve{
      background: #95b3d7;
      color: white;
    }
</style>