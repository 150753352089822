<template>
  <div class="personinfo">
    <ul class="list" v-if="userInfo">
      <li
        class="item name"
        @click="navigate('name', userInfo.real_name, 'text')"
      >
        <b class="type">姓名</b>
        <span class="text">{{ userInfo.real_name }}</span>
        <i class="icon"></i>
      </li>
      
      <!-- <li class="item sex" @click="navigate('sex', userInfo.sex, 'radio')">
        <b class="type">性别</b>
        <span class="text">{{userInfo.sex}}</span>
        <i class="icon"></i>
      </li>
      <li class="item area">
        <b class="type">所属省市</b>
        <span class="text">{{userInfo.area}}</span>
        <i class="icon"></i>
      </li> -->
      <li class="item phone">
        <b class="type">联系号码</b>
        <span class="text">{{ userInfo.mobile }}</span>
        <i class="icon"></i>
      </li>
      <li class="item email">
        <b class="type">电子邮箱</b>
        <span class="text">{{ userInfo.email }}</span>
        <i class="icon"></i>
      </li>
      <!-- <li class="item qualification">
        <b class="type">最高学历</b>
        <span class="text">{{userInfo.qualification}}</span>
        <i class="icon"></i>
      </li>
      <li class="item title">
        <b class="type">技术职称</b>
        <span class="text">{{userInfo.title}}</span>
        <i class="icon"></i>
      </li>
      <li class="item unit">
        <b class="type">工作单位</b>
        <span class="text">{{userInfo.unit}}</span>
        <i class="icon"></i>
      </li>
      <li class="item department">
        <b class="type">科室部门</b>
        <span class="text">{{userInfo.department}}</span>
        <i class="icon"></i>
      </li>
      <li class="item post">
        <b class="type">行政职务</b>
        <span class="text">{{userInfo.post}}</span>
        <i class="icon"></i>
      </li>
      <li class="item is-doctor">
        <b class="type">是否基层医生</b>
        <span class="text">{{userInfo.isDoctor}}</span>
        <i class="icon"></i>
      </li> -->
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userInfo: null,
    };
  },

  created(){
    this.request()
  },
  methods: {
    // 跳转个人信息修改
    navigate(key, value, type) {
      this.$router.push({
        path: "/personmodify",
        query: {
          key,
          value,
          type,
        },
      });
    },


    // 请求个人信息
    request() {
      axios({
        method: "get",
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetUserInfo?UserName=${
            this.$store.state.user.user || localStorage.user
          }`,
        headers: {
          Token: this.$store.state.user.token || localStorage.token,
        },
      })
        .then((res) => {
          if (res.data.ResultCode === "0") {
            // 状态码为0是无效令牌
            localStorage.token = "";
            localStorage.user = "";
            this.$store.commit("tipHandler", {
              _this: this,
              text: "请登录",
              type: "fail",
              url: "/login",
            });
          } else if (res.data.Data.length) {
            // 有数据，赋值
            this.userInfo = res.data.Data[0];
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      // vm.$store.commit("routerGuard", {
      //   // 调用守卫
      //   fromPath: from.path,
      //   _this: vm,
      // });
    });
  },
  beforeMount() {
    // if (this.$store.state.user.token || localStorage.token) {
    //   this.$store.commit("loadHandler", {
    //     // 调用加载动画
    //     _this: this,
    //     boolean: true,
    //   });

    //   this.request(); // 调用请求个人信息方法
    // }
  },
};
</script>

<style lang="scss" scoped>
.personinfo {
  background-color: #eeeeee;
  .list {
    .item {
      display: flex;
      align-items: center;
      height: 0.88rem;
      padding: 0 0.25rem;
      background-color: #ffffff;
      line-height: 0.88rem;
      position: relative;
      .type {
        flex-shrink: 0;
        font-size: 0.26rem;
      }
      .text {
        flex-grow: 1;
        padding: 0 0.1rem 0 0.3rem;
        box-sizing: border-box;
        color: #979797;
        font-size: 0.26rem;
        text-align: right;
      }
      .icon {
        flex-shrink: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: url(../../public/wx_images/personinfo-icon-1.png) no-repeat
          center center / 100%;
      }
      &::after {
        content: "";
        display: block;
        width: calc(100% - 0.5rem);
        height: 0.01rem;
        min-height: 1px;
        background-color: #cccccc;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .email,
    .title {
      &::after {
        display: none;
      }
    }
    .qualification,
    .unit {
      margin-top: 0.15rem;
    }
  }
}
</style>
