<template>
  <div @click="conversion">
    <!-- 遮罩层，暂时不用 -->
    <!-- <van-popup v-model:show="show" position="bottom" :style="{ height: '30%' }" >
            
    <div class="type">
        <p>选择类型</p>
        <div class="select-type">
            <li v-for="(k,index) in type" :key="index" :class="num==index?'liang':''" @click="select(k,index)">{{k.Name}}</li>
        </div>
    </div>
    </van-popup> -->
    <Unit v-if="num==1" :unit="unit"></Unit>
    <Personage v-else-if="num==0" :personage="personage"></Personage>
    <!-- 点击提交成功就显示这个组件 -->
    <Auditpass v-if="isshow"></Auditpass>
  </div>
</template>

<script>
import Auditpass from './auditpass.vue'
import axios from 'axios'
import Unit from './components/unit.vue'
import Personage from './components/personage.vue'
export default {
    components:{
        Auditpass,
          Unit,
        Personage
    },
       data() {
         return {
           username: "",
           password: "",
           show:true,
           isshow:false,
           num:0,
           type:[],
           personage:null,
           unit:null
         };
       },

       created(){
        this.GetMemberLilt()
       },
       methods: {

        GetMemberLilt(){
          axios.get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMemberLilt`
        )
        .then((res) => {
          this.type=res.data.Data
        })
        .catch((err) => {
          console.log(err);
        });
        },

        conversion(){
          if(this.num==9){
            this.show=true
          }
        },

         onSubmit(values) {
          //  console.log("submit", values);
         },
         select(k,index){
          if(k.Id==1){
            localStorage.setItem('personage',JSON.stringify(k))
          }else{
            localStorage.setItem('unit',JSON.stringify(k))
          }
          this.num=index
          this.show=false
         }
       },
        beforeRouteEnter(to, from, next) {
    // 路由进入拦截

    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });

    });
  },

};
</script>

<style lang="scss" scoped>
    .form{
        margin-top: 60px;
    }
    .type{
        padding: 10px;
    }
    .liang{
        background: #0089fd !important;
        color: white;
    }
    .select-type{
        display: flex;
        li{
            width: 20%;
            // border: 1px solid red;
            border-radius: 20px;
            background: #e1e6ea;
            text-align: center;
            padding: 6px 3px;
            margin-right: 10px;
            margin-top: 20px;
        }
    }
    .check-type{
      position: absolute;
      right: 20px;
      top: 45px;
    }
</style>
