<template>
  <div
    class="meeting-info meeting-info-common"
    :style="
      show == true
        ? `background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`
        : `background:${backcolor}`
    "
  >

    <!-- <div v-else class="backUrl"></div> -->
    <Popups></Popups>
    <div class="main">
      <div class="article" v-if="meetingInfo">
        <div class="content" v-html="meetingInfo"></div>
      </div>
    </div>
      <div  class="backUrl" v-if="backShow" ref="back" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
        <img :src="$store.state.imageUrl+img_url" alt="">
      </div>
  </div>
</template>

<script>
import axios from "axios";
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },  
  data() {
    return {
      backcolor: localStorage.getItem("oneColor"),
      twoColor: localStorage.getItem("twoColor"),
      img_url: "",
      backShow:false,
      images: require("../../public/wx_images/meetingcenter-img-1.jpg"),
      show: true,
      meetingInfo: "",
            ishow:false,
      size:0,
      contentSize:0,
    };
  },
  created() {
    this.img_url = localStorage.getItem("img_url");
    if (this.img_url !== "null") {
      this.show = false;
    } else {
      this.show = true;
    }
  },
    mounted(){
    
       this.size=document.documentElement.clientHeight; //实时高度


  },
  methods: {

      distance () {
      var con=document.querySelector('.content')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight
      

      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    // 请求数据，参数为会议id
    request(id) {
  
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=" +
            id +
            "&Field=meeting_content"
        )
        .then((res) => {
          // console.log(res);
          if (res.data.Data.length) {
            this.meetingInfo = res.data.Data[0].meeting_content;
            if(this.meetingInfo==''){
              // console.log(222222222222);
              this.meetingInfo=''
            }
          } else {
            console.log("获取数据为空");
          }

              setTimeout(()=>{
              this.distance()
              },100)
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
          this.backShow=localStorage.getItem('img_url')?true:false
          // var z=localStorage
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
  },
  beforeMount() {
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });

    this.request(this.$route.params.id); // 调用请求方法
  },
};
</script>

<style lang="scss" scoped>
::v-deep .content {
  font-family: "微软雅黑";
}
.meeting-info {
  position: relative;
  min-height: calc(100vh - 0.6rem);
}
.backUrl {
  width: 100%;
  height: 200px;

img{
  width: 100%;
  height: 100%;
}

}
.main {
  z-index: 99;
  position: relative;
}
</style>
