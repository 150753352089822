<template>
  <div>
    <div class="box">
      <!-- <div class="hint"><span> *</span> 为必填项</div> -->

      <div class="caId">

       <ul class="caIdz">
        <p>个人照片 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

        <span class="star">*</span>
        <van-image
          width="100"
          height="100"
          @click="$refs.file.click()"
          :src="userurl"
          class="images"
        />
      </ul>

        <ul class="caIdf">
          <p>身份证正面  &nbsp;&nbsp;</p>
          <span class="star">*</span>
          <van-image
            width="100"
            height="100"
            @click="$refs.file2.click()"
            :src="cardurl"
            class="images"
          />
        </ul>
        <ul class="caIdz">
          <p>身份证反面  &nbsp;&nbsp;</p>
          <span class="star">*</span>
          <!-- <van-uploader  /> -->
          <van-image
            width="100"
            height="100"
            class="images"
            @click="$refs.file3.click()"
            :src="cardSideurl"
          />
        </ul>
      </div>
      <div class="year">
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.year"
          name="出生年月"
          label="出生年月"
          placeholder="填写出生年月"
          @click="show = true"
        >
        </van-field>
      </div>
      <van-datetime-picker
        v-if="show"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :rules="[{ required: true, message: '请填写出生年月' }]"
        :max-date="maxDate"
        @confirm="finish"
        @cancel="clear"
      />

      <van-form @submit="onSubmit" class="form">
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.email"
          name="电子邮箱"
          label="电子邮箱"
          placeholder="填写电子邮箱"
          :rules="[{ required: true, message: '请填写邮箱' },{pattern :verifyEmail,message:'邮箱格式不正确'}]"
          @blur="verify('email')"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.username"
          name="姓名"
          label="姓名"
          placeholder="填写姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.native"
          name="籍贯"
          label="籍贯"
          placeholder="填写籍贯"
          :rules="[{ required: true, message: '请填写籍贯' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.card"
          name="身份证"
          label="身份证"
          placeholder="填写身份证"
          :rules="[{ required: true, message: '请填写身份证' },{pattern :verifyCard,message:'身份证格式不正确'}]"
        />
        <!-- <nation></nation> -->
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.phone"
          type="text"
          name="密码"
          label="手机号"
          placeholder="填写手机号"
          maxlength="11"
          :rules="[{ required: true, message: '请填写手机号' },{pattern:verifyPhone,message:'手机号格式不正确'}]"
          @blur="verify('phone')"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.works"
          name="工作单位"
          label="工作单位"
          placeholder="填写工作单位"
          :rules="[{ required: true, message: '请填写工作单位' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.education"
          name="主要学历 、经历等"
          label="主要学历 、经历等"
          type="textarea"
          placeholder="填写主要学历 、经历等"
          :rules="[{ required: true, message: '请填写主要学历 、经历等' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.communication"
          name="通信地址"
          label="通信地址"
          placeholder="填写通请填写街道门牌号"
          :rules="[{ required: true, message: '请填写通信地址' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.office"
          name="办公电话"
          label="办公电话"
          placeholder="填写办公电话"
          :rules="[{ required: true, message: '请填写办公电话' }]"
        />
        <span class="starfield">*</span>
        <van-radio-group
          v-model="userInfo.radio"
          direction="horizontal"
          class="radio"
          @click="a"
        >
          <van-radio name="男" icon-size="18px">男</van-radio>
          <van-radio name="女" icon-size="18px">女</van-radio>
        </van-radio-group>
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.administrative"
          name="所属科室"
          label="所属科室"
          placeholder="填写所属科室"
          :rules="[{ required: true, message: '请填写所属科室' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.deed"
          rows="2"
          autosize
          label="主要事迹、论文、技术等"
          type="textarea"
          maxlength="500"
          placeholder="请输入主要事迹、论文、技术等"
          show-word-limit
        />
         <span class="starfield">*</span>
        <van-field
          v-model="userInfo.Social_service"
          rows="2"
          autosize
          label="社会兼职
   （何年、何届、任何职）"
             type="textarea"
             maxlength="500"
             placeholder="请输入社会兼职
   （何年、何届、任何职）"
          show-word-limit
        />
        <span class="starfield">*</span>
        <van-field
          v-model="userInfo.postal"
          name="邮政编码"
          label="邮政编码"
          placeholder="填写邮政编码"
          :rules="[{ required: true, message: '请填写邮政编码' }]"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="provinceName"
          name="省/直辖市"
          label="省/直辖市"
          placeholder="填写省/直辖市"
          @click="area = true"
        />
        <van-area
          title="省/直辖市"
          :area-list="areaList"
          v-if="area"
          :columns-num="2"
          @confirm="success"
          @cancel="cancel"
        />
        <span class="starfield">*</span>
        <van-field
          v-model="cityName"
          name="市/区"
          label="市/区"
          :rules="[{ required: true, message: '请填写市/区' }]"
          placeholder="填写市/区"
        />
        <span class="starfield">*</span>
        <div class="warpper">
          <van-field
            v-model="nationName"
            name="民族"
            label="民族"
            placeholder="填写民族"
            @click="nationShow = true"
          />
          <li class="jiao" @click="close"></li>
          <ul class="public" v-if="nationShow">
            <li
              v-for="(item, index) in nationlist"
              :key="index"
              @click="getnation(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <span class="starfield">*</span>
        <div class="warpper">
          <van-field
            v-model="partyName"
            name="党派"
            label="党派"
            placeholder="填写党派"
            @click="partyShow = true"
          />
          <li class="jiao" @click="partyclose"></li>
          <ul class="public" v-if="partyShow">
            <li
              v-for="(item, index) in Partisanlist"
              :key="index"
              @click="getparty(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <span class="starfield">*</span>
        <van-field
          v-if="cadreShow"
          v-model="userInfo.cadre"
          name="党内职务"
          label="党内职务"
          :rules="[{ required: true, message: '请填写党内职务' }]"
          placeholder="填写党内职务"
        />
        <span class="starfield">*</span>
        <div class="warpper">
          <van-field
            v-model="technologyShowName"
            name="技术职称"
            label="技术职称"
            placeholder="选择技术职称"
            @click="technologyShow = true"
          />
          <li class="jiao" @click="technologyclose"></li>
          <ul class="public" v-if="technologyShow">
            <li
              v-for="(item, index) in Dutieslist"
              :key="index"
              @click="gettechnology(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <span class="starfield">*</span>
        <van-field
        v-if="userInfo.technology==0"
          v-model="userInfo.technologyName"
          name="技术职称"
          label="技术职称"

          placeholder="上分类没有可填写职称"
           :rules="[{ required: true, message: '请填写职称' }]"
        />
        <span class="starfield">*</span>
        <div class="warpper">
          <van-field
            v-model="dutyShowName"
            name="职务"
            label="职务"
            placeholder="选择职务"
            @click="dutyShow = true"
          />
          <li class="jiao" @click="dutyclose"></li>
          <ul class="public" v-if="dutyShow">
            <li
              v-for="(item, index) in Ranklist"
              :key="index"
              @click="getduty(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <span class="starfield"  v-if="userInfo.duty==0">*</span>
        <van-field
        v-if="userInfo.duty==0"
          v-model="userInfo.dutyName"
          name="职务"
          label="职务"
          placeholder="上分类没有可填写职务"
           :rules="[{ required: true, message: '请填写职务' }]"
        />

        <div style="margin: 16px"></div>
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @submit="submit"
          >提交</van-button
        >
        <input type="file" ref="file" @change="onFile" hidden />
        <input type="file" ref="file2" @change="onFile2" hidden />
        <input type="file" ref="file3" @change="onFile3" hidden />
        <!-- <van-button @click="onFile">提交</van-button> -->
      </van-form>
    </div>
  </div>
</template>

<script>
// import nation from "@/views/committee/components/nation.vue";
// import basicsInfo from "@/views/committee/components/basicsInfo.vue";
import { areaList } from "@vant/area-data";
import axios from "axios";
export default {
  data() {
    return {
      nationlist: [],
      Partisanlist: [],
      Dutieslist: [],
      Ranklist: [],
      accountData: JSON.parse(localStorage.getItem("accountData")),

      show3: false,
      userInfo: {
        // 社会兼职
        Social_service:"",
        // 推荐码识别id
        ReferralCode: this.$route.params.ReferralCode,
        //  党内职务
        cadre: "",
        // 省
        province: 0,
        // 市
        city: 0,
        // 性别
        radio: "男",
        // 电子邮箱
        email: "",
        // 民族索引
        nation: 0,
        // 姓名
        username: "",
        // 籍贯
        native: "",
        // 身份证
        card: "",
        // 手机号
        phone: "",
        //   党员
        party: 0,
        // 工作单位
        works: "",
        // 主要学历经验
        education: "",
        // 通信地址
        communication: "",
        // 办公电话
        office: "",
        // 科室
        administrative: "",
        // 邮政编码
        postal: "",
        // 主要事迹
        deed: "",
        //   技术称职
        technology: 0,
        technologyName: "",
        // 职务
        duty: 0,
        dutyName: "",
        // 出生日
        year: "",

        // 个人照片
        userimage: "",
        //  正面身份证
        cardimage: "",
        //  背面身份证
        cardSide: "",
      },
      nationName: "汉族",
      partyName: "无",
      
      
      technologyShowName:'',
      dutyShowName:'',
      provinceName: "",
      cityName: "",
      // 个人照片
      userurl: "",
      //  正面身份证
      cardurl: "",
      //  背面身份证
      cardSideurl: "",
      show: false,
      minDate: new Date(1950, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1980, 1, 1),
      areaList,
      area: false,
      cadreShow: false,
      dutyShow: false,
      technologyShow: false,
      partyShow: false,
      nationShow: false,
      odd: true,
      img: "",
      verifyEmail:/.+?@.+?\..+?/,
      verifyPhone:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      verifyCard:/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
    };
  },

  created() {
    //  this.$store.commit('loadHandler', { // 调用加载动画
    //   _this: this,
    //   boolean: true
    // })
    // console.log(this.userInfo.ReferralCode);
    this.nationlist = this.accountData.Nation;
    this.Partisanlist = this.accountData.Partisan;
    this.Dutieslist = this.accountData.Duties;
    this.Ranklist = this.accountData.Rank;
    // this.$store.commit('loadHandler', { // 结束加载动画
    //         _this: this,
    //         boolean: false
    //       })

  },
  methods: {
    async onFile() {
      const file = this.$refs.file.files[0];
      var formData = new FormData();
      formData.append("file", file);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
        this.userurl = this.$store.state.imageUrl + res.data;
        this.userInfo.userimage = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async onFile2() {
      const file2 = this.$refs.file2.files[0];
      var formData = new FormData();
      formData.append("file2", file2);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
        this.cardurl = this.$store.state.imageUrl + res.data;
        this.userInfo.cardimage = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async onFile3() {
      const file3 = this.$refs.file3.files[0];
      var formData = new FormData();
      formData.append("file3", file3);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
        this.cardSideurl = this.$store.state.imageUrl + res.data;
        this.userInfo.cardSide = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async referproposer() {
      var json = this.userInfo;
      const res = await axios({
        method: "post",
        url:
          this.$store.state.apiUrl +
          "/actionapi/TencentApi/AddCcouncilReferralInfo",
        data: json,
      });
      try {
        this.$toast.success(res.data.Msg);
      } catch (err) {
        this.$toast.fail(res.data.Msg);
        console.log(err);
      }
    },
    showPopup() {
      this.show3 = true;
    },
    //   循环找到拿到name赋值到输入框
    getnation(index) {
      this.nationlist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.nation = item.Index;
          this.nationName = item.Name;
        }
      });
      this.nationShow = false;
      //  console.log(this.userInfo.userimage);
    },
    getparty(index) {
      this.Partisanlist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.party = item.Index;
          this.partyName = item.Name;
        }
      });
      if (index == 0) {
        this.cadreShow = false;
      } else {
        this.cadreShow = true;
      }
      this.partyShow = false;
    },
    gettechnology(index) {
      this.Dutieslist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.technology = item.Index;
          this.technologyShowName = item.Name;
        }
      });
      this.technologyShow = false;
    },
    getduty(index) {
      this.Ranklist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.duty = item.Index;
          this.dutyShowName = item.Name;
        }
      });
      this.dutyShow = false;
    },
    // 这个组件拿到的是标准时间，需要处理成2022-5-25格式的
    finish(value) {
      const resDate =
        this.currentDate.getFullYear() +
        "-" +
        this.p(this.currentDate.getMonth() + 1) +
        "-" +
        this.p(this.currentDate.getDate());
      this.userInfo.year = resDate;
      this.show = false;
  
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    clear() {
      this.show = false;
    },
    onSubmit(values) {
      this.referproposer();
      
 
    },

    verify(type){
      if(type){
    
        switch (type) {
          case "email":
            if (!/.+?@.+?\..+?/.test(this.userInfo.email)) {
              console.log('错误');
            } else {
              // this.nameClass = "correct";
            }
          break;

          case "phone":
            if (!/.+?@.+?\..+?/.test(this.userInfo.phone)) {
              
            } else {
              // this.nameClass = "correct";
            }
          break;
      }
    }
    },

    // verifyEmail(val){
    //     return /.+?@.+?\..+?/.test(val)
    // },
    // 选择地区点击确定时的事件
    success(array) {
      var region = [];
      array.forEach((item) => {
        region.push(item.name);
      });
      const str = region.splice(1, 1);
      this.provinceName = region.join("");
      this.cityName = str.join("");
      this.userInfo.province = array[0].code;
      this.userInfo.city = array[1].code;

      this.area = false;
    },
    cancel() {
      this.area = false;
    },
    // 这是关闭选择的
    close() {
      if (this.nationShow == false) {
        this.nationShow = true;
      } else {
        this.nationShow = false;
      }
    },

    partyclose() {
      if (this.partyShow == false) {
        this.partyShow = true;
      } else {
        this.partyShow = false;
      }
    },
    technologyclose() {
      if (this.technologyShow == false) {
        this.technologyShow = true;
      } else {
        this.technologyShow = false;
      }
    },
    dutyclose() {
      if (this.dutyShow == false) {
        this.dutyShow = true;
      } else {
        this.dutyShow = false;
      }
    },
    afterRead(file) {
      this.userInfo.userimage = this.userurl[0].content;
      this.userInfo.cardimage = this.cardurl[0].content;
      this.userInfo.cardSide = this.cardSideurl[0].content;
    },
    // 表单提交验证
    submit() {
      //  正面身份证

      //  背面身份证

      console.log("成功！！！！！！！！！！！！！！！！");
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  position: relative;
}
.hint {
  height: 0.7rem;
  font-size: 0.3rem;
  line-height: 0.7rem;
  border-bottom: 2px solid black;
  span {
    font-size: 0.45rem;
    color: red;
  }
}
.star {
  position: absolute;
  left: -0.19rem;
  font-size: 0.39rem;
  color: red;
  // z-index: 99;
}
.van-field {
  position: relative;
}
.year {
  position: relative;
}
.starfield {
  position: absolute;
  left: 0.03rem;
  margin-top: 0.185rem;
  font-size: 0.39rem;
  color: red;
  z-index: 9;
}
.ger {
  margin-top: 20px;
  position: relative;
  margin-left: 0.76rem;
  display: flex;
}
.caId {
  margin: 20px 0 20px 0px;
}
.caIdz {
  position: relative;
  display: flex;
  margin-left: 16px;
}
.caIdf {
  position: relative;
  margin: 20px 0;
  display: flex;
  margin-left: 16px;
}
.box {
  padding: 0 20px 30px 20px;
}
.radio {
  margin: 10px 0 10px 15px;
}
.textarea {
  margin-top: 10px;
  margin-left: 15px;
}
textarea {
  color: #ccc;
}
.public {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  width: 150px;
  z-index: 99;
  background: white;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding-top: 10px;

  li {
    text-align: center;
    height: 25px;
    width: 100%;
    overflow: hidden;
    line-height: 25px;
  }
  li:hover {
    background: pink;
  }
}
.jiao {
  width: 15px;
  height: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  box-sizing: border-box;
  border: 10px solid transparent;
  border-top-color: rgb(238, 236, 236);
}
.warpper {
  position: relative;
}
.van-button {
   color: white;
  width: 5.3rem;
  margin: 0  auto;
  background: #1989fa;
}
.images{
  margin-left: 24px;
}
</style>
