<template>
  <div class="meeting-welcome meeting-info-common" ref="q" :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
    <div class="main" >
      <ul class="article">
        <li class="row"  v-for="(item, index) in data" :key="index">
          <h5 class="title" >{{item.title}}</h5>
          <p id="content" v-html="item.content"></p>
        </li>
      </ul>
    </div>
    <div  class="backUrl" v-if="backShow" @click="scrollToTop" ref="back" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
      <img :src="$store.state.imageUrl+img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      twoColor:localStorage.getItem('twoColor'),
       backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
      show:true,
      ishow:false,
      size:0,
      contentSize:0,
      backShow:false,
      data: [
        {
          title: '',
          content: null,
          
        }
      ]
    }
  },
     created(){
     
       
    if(this.img_url!=='null'){
      this.show=false
    }else{
      this.show=true
    }
    
  },
  mounted(){
    
       this.size=document.documentElement.clientHeight; //实时高度

    setTimeout(()=>{
    
    },100)
  },
  methods: {
       distance () {
         this.backShow=localStorage.getItem('img_url')?true:false
      var con=document.querySelector('#content')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight
   

      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + '/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=' + id + '&Field=welcome')
        .then((res) => {
          if (res.data.Data.length) {
            var html = res.data.Data[0].welcome
            this.data[0].content = html
          } else {
            console.log('获取数据为空')
          }

          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })

        // var j=s.offsetTop
        //  console.log(j);
        //  console.log(a);
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    }
  },
  watch:{
//当数据成功获取后playlistArr改变,执行以下代码
      // data:{
      //   immediate:true,
      //   handler(){
      //        this.$nextTick(()=>{
      //       var h = document.getElementById("content").offsetHeight;
      //       console.log(h);
      //       if(h<500){
      //       //显示message
      //           this.ishow = true;
      //       }
      //       else{
      //       //不显示message
      //           this.ishow = false;
      //       }
      //   })
      //   }
       
        
      // }
},

  beforeMount () {
    this.$store.commit('loadHandler', { // 调用加载动画
      _this: this,
      boolean: true
    })

    this.request(this.$route.params.id) // 调用请求方法
  }
}
</script>
<style lang="scss" scoped>
.meeting-welcome{
 position: relative;
  min-height: calc(100vh - 0.6rem);
}
.backUrl{
  width: 100%;

 img{
  width: 100%;
   height: 100%;
 }
 
   
  // background-size: contain;
   z-index: 9;
}
.main{
  z-index: 99;
  position: relative;
}
</style>
