<template>
  <div>
    <ul>
      <li class="item">
        <div>
          <p></p>
        </div>

        <div class="ticket">
          申请开票
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// 我的缴费
export default {
    data(){
        return {
            
        }
    }
}
</script>

<style scoped>
  .item{
    width: 90%;
    height: 50px;
    background: red;
    margin: 20px auto 0 auto;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 7px;
    position: relative;
  }
  .ticket{
    position: absolute;
    right: 10px;
  }
</style>