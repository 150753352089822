<template>
  <div>
    <div style="position: relative;">
        <img v-if="img_url==''||img_url==null" class="imgs" :src="img" alt="">
        <img v-else class="imgs" :src="$store.state.imageUrl+img_url" alt="">

        <div>
            <h2 style="padding:.2rem">{{title}}</h2>
        </div>

        <div class="eye">
            <span class="iconfont icon-yanjing"></span>
            <span style="font-size:12px;">{{Click}}</span>
        </div>
    </div>
    <Popups></Popups>
    <div class="live-box" v-if="show">
        <ul class="tab">
            <li v-for="(k,index) in list" :key="k.Id"  :class="num==index?'z actitve' :'z'" @click="add(k,index)">
                {{k.Name}}
                <p class="across" v-show="num==index"></p>
            </li>
            <div class="matter" v-html="list[num].Note "></div>
        </ul>
        <!-- <li class="focus" @click="attention" ref="attention">关注</li> -->
        
    </div>
    
    <div v-else>
        <ul class="tab">
            <li v-for="(k,index) in endList" :key="k.Id"  :class="num==index?'z actitve' :'z'" @click="add(k,index)">
                {{k.Name}}
                <p class="across" v-show="num==index"></p>
            </li>

            <div class="matter-false">
                <div v-for="(z,index) in Item" :key="index" class="topic" @click="skipClick(z)">
                    <li style="width:40%">
                       <img :src="$store.state.imageUrl+z.CoverImg" alt="" style="width:100%">
                    </li>
                    <li class="hint-info">
                        <p>{{z.Name}}</p>
                        <p class="em">视频 | {{z.StartTime}}</p>
                        <p class="em">
                            <span class="iconfont icon-yanjing"></span>{{z.ClickBrowse}}热度
                        </p>
                    </li>
                </div>
            </div>
        </ul>
    </div>
  </div>
</template>

<script>
import '../assets/download/font_3812170_1n53mwl9hjy/iconfont.css'
import axios from "axios";
import Popups from "@/components/Popups.vue"
export default {
    components:{
        Popups
    },
    data(){
        return {
            ind:'001',
            list:[],
            img_url:localStorage.getItem('img_url'),
            endList:[{Name:'话题列表',Id:'0001'}],
            num:0,
              img:require('../../public/wx_images/meetingcenter-img-1.jpg'),
              Click:0,
            //   列表数组
            Item:[],
            start_tiem:localStorage.getItem('start_tiem'),
            show:false,
            title:localStorage.getItem('title')
        }
    },
    created(){
        // 判断会议是否开始
        this.begin()
        this.getLive()
        this.getLiveItem()
    },
    methods:{
        

        begin(){
            // 获取当前时间
            var date = new Date();
            var now = date.getTime();
            //   获取开始时间戳
            var current=new Date(this.start_tiem)
            var time=current.getTime()
            if(now-time<0){
              this.show=true
            }
        },

        async getLive(){
            const res=await axios({
                url: this.$store.state.apiUrl +
                `/actionapi/TencentApi/GetWapLiveConfig?MeetingId=${this.$route.params.id}`
                })
                
            try{

                this.list=res.data.Data
                this.Click=res.data.Click
            }catch(err){
                console.log(err);
            }
        },


        async getLiveItem(){
            const res=await axios({
                url: this.$store.state.apiUrl +
                `/actionapi/TencentApi/GetMeetingLiveMsg?MeetingId=${this.$route.params.id}`
                })
                
            try{
                this.Item=res.data.Data

            }catch(err){
                    console.log(err);
            }
        },
        
        add(k,index){
            this.num=index
            this.ind=k.Id
            if(k.Url=='Gallery'||k.Url=='Photo'){
                this.$router.push(`/${k.Url}/${this.$route.params.id}`)
            }
        },

        async skipClick(k){
            const res=await axios({
               url: this.$store.state.apiUrl +
                `/actionapi/TencentApi/MeetingLiveMsgClick?Id=${k.Id}`
            })
             try{
               var z=k.Url.indexOf('video',1)
               if(z==-1){
                location.href=k.Url
               }else{
                var data=JSON.stringify(k)
                this.$router.push({name:'MeetingLiveDetail',params:{data}})
               }
                
                }catch(err){
                    console.log(err);
                }
                // location.href=k.Url
               

        },
        attention(){
            this.$refs.attention.style.backgroundImage='linear-gradient(to right, #fff, #0095fe)';
            this.$refs.attention.style.color='#fff'
        }
    }
}
</script>

<style lang="scss" scoped>
li{
    list-style: none;
}
    .live-box{
        display: flex ;
        justify-content: space-around;
    }
    .imgs{
        width: 100%;     
    }
    .icon-yanjing{   
        font-size: 13px;
        font-weight: 800;
        margin-right: 4px;
    }
    .eye{
        position: absolute;
        top: 10px;
        right: 10px;
        // width: 70px;
        padding: .01rem .22rem;
        line-height: 20px;
        border-radius: 25px;
        background: #000;
        opacity: .5;
        box-sizing: border-box;
        span{
            color: white;
        }
    }
    .tab{
        flex:1;
        display: flex;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
    }
    .z{
        width: 20%; 
        text-align: center;
        position: relative;
    }

    // .z::after{
    //    content: '';
    //    display: block;
    //    width: 25px;
    //    height: 3px;
    //    background-color: #0095fe;
    //    -webkit-border-radius: 15px;
    //    -moz-border-radius: 15px;
    //    border-radius: 15px;
    //    position: absolute;
    //    bottom: 0rem;
    //    left: 50%;
    //    transform: translateX(-50%);
    // }
    .actitve{
        // position: relative;
        color:#0095fe;
        // // padding: 0 .3rem;
        // box-sizing: border-box;
        // border-bottom: 2px solid #0095fe;
        
    }
    .focus{
        width: 22%;
        line-height: 40px;
        color: #000;
        font-weight: 700;
        font-size:14px;
    }
    .across{
        height: 3px;
        width: 30px;
        background: #0095fe;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
    ::v-deep .matter{
        width: 95%;
        position: absolute;
        top: 50px;
        text-align: left;
        // padding: .1rem .1rem .1rem .1rem;
        // margin: 10px;
        p{
            padding: .1rem;
            // height: 23px;
            // line-height: 23px;
        img{
            width: 100% !important;
            height: 200px !important;
        }
        }
       
    }
    .matter-false{
         width: 95%;
        position: absolute;
        top: 50px;
        text-align: left;
        p{
             height: 23px;
            line-height: 23px;
        }
    }
    .topic{
        display: flex;
        padding: .2rem 0;
        // justify-content: space-around;
    }
    .hint-info{
            padding: .1rem  0; 
            margin-left: 5px;
        // display: flex;
        // align-items: center;
    }
    .em{
        color:#747474;
        font-size: 12px;
    }
</style>