<template>
  <div>
    <Navbar :title="text"></Navbar>
    <div class="box-t">
      <li
        class="item"
        v-for="item in list"
        :key="item.id"
        @click="navigate(item.id,item.article_type,item.article_id)"
      >
        <p class="text" :class="{ textw: item.img_url !== '' }">
          {{ item.title }}
        </p>
        <img
          v-if="item.img_url !== ''"
          :src="this.$store.state.imageUrl + item.img_url"
          :onerror="this.default"
          alt=""
        />

        <span class="itme">{{ item.add_time }}</span>
      </li>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbarnume.vue";
import axios from "axios";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      text: "健康生活",
      // show: this.$store.state.menudisplay.show,
      list: [],
      id: "",
      type:'',
      default:'this.src="'+require('../../public/wx_images/default-img-1.jpg')+'"'
    };
  },
  created() {
    this.getid();
    this.init();


  },
  methods: {
    getid() {
      var str = this.$route.path.split("");
      this.id= str.splice(9, 3).join("");
   
      if(this.id==142){
        this.text='学会新闻'
      }else if(this.id==330){
        this.text='会议注册'
      }else if(this.id==146){
        this.text='会议通知'
      }else if(this.id==300){
        this.text='分会动态'
      }else if(this.id==151){
        this.text='健康生活'
      }
    },
    async init() {
      const res = await axios({
        url:
          this.$store.state.apiUrl +
          "/actionapi/TencentApi/GetArticlelist?ArticleCate=" +
          this.id,
      });
      try {
        this.list = res.data.Data;
     
        this.list = this.timeHandler(res.data.Data);
      } catch (err) {
        console.log(err);
      }
    },

    timeHandler(arr) {
      arr.forEach((item) => {
        item.img_url = item.img_url.replace(/^~/, "");
        item.add_time = item.add_time.replace(/T/, " ");
      });
      return arr;
    },
    navigate(articleId,type,meetingId) {
      if (type === "ax_meeting") {
        this.$router.push("/meetingcenter/" + meetingId);
      } else {
        this.$router.push("/articledetail/" + articleId);
      }
    },
  },
};
</script>

<style scoped>

.box-t {
  height: 100%;
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
   box-sizing: border-box;
}
.item {
  border-bottom: 1px #ccc solid;
  position: relative;
  flex: 100%;
  box-sizing: border-box;
  height: 1.6rem;
  line-height: 0.2rem;
  width: 95%;
  box-sizing: border-box;
  /* padding: 0.18rem 0 0.2rem; */
  display: flex;
  align-items: center;
  /* margin: 0.25rem 0 0 0; */
  margin: 0rem auto;
  /* border-radius: 8px; */
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.text {
  font-size: 0.23rem;
  line-height: 0.38rem;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 9px;
   box-sizing: border-box;
  /* -webkit-box-orient: vertical; */
}
.textw {
  width: 70%;
  /* height: 66%; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
img {
  position: absolute;

  right: 0.16rem;
  top: 0.16rem;
  /* float: right;
    margin-bottom: 70px; */
  width: 94px;
  height: 80px;
}
.itme {
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  color: #ccc;
  font-size: 00.2rem;
}
</style>
