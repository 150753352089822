<template>
  <div>
        <!-- <div style="width:100%;height:190px">
            <img :src="$store.state.imageUrl+data.CoverImg" alt="" style="width:100%">
        </div> -->
         <div v-if="img_url==null||img_url==''" :style="{background: `url('${img}') center center / 100% no-repeat`}" class="a"></div>
         <div v-else :style="{background: `url('${$store.state.imageUrl+img_url}') center center / 100% no-repeat`}" class="a"></div>
        <Popups></Popups>
        <div v-if="data!==''">
            
            <p class="title">云展厅</p>
            <ul class="show">
                
                <li v-for="(k,index) in data" :key="index" class="item" @click="skip(k)" :style="{background:k.CoverImg==''?'#9fcef4':`url(${$store.state.imageUrl + k.CoverImg})`}">
                    <!-- 用不了 -->
                    <!-- <img :src="$store.state.imageUrl+k.CoverImg" alt=""> -->
                    <span class="iconfont icon-fangzi" :style="{color:k.Color}"></span>
                    <p class="name" :style="{color:k.Color}">{{k.Name}}</p>
                </li>
            </ul>
        </div>
  </div>
</template>
           
<script>
import axios from "axios";
import '../assets/download/font_3812170_1n53mwl9hjy/iconfont.css'
import Popups from "@/components/Popups.vue"
export default {
    
    components:{
        Popups
    },
    data(){
        return {
            data:'',
            Item:[],
            num:'',
            img_url:localStorage.getItem('img_url'),
            img:require('../../public/wx_images/meetingcenter-img-1.jpg')
        }
    },
    created(){
        this.GetShowRoom()
    },
    methods:{
        async GetShowRoom(){
                var marstr=this.$route.params.id
                const res=await axios({
                    url: this.$store.state.apiUrl +
                    `/actionapi/TencentApi/GetShowRoom?ParentMaster=0&MeetingId=${marstr}`
                })
            try{
                
                this.data=res.data.Data
                this.Item=res.data.Data[0].Item
                var obj=JSON.parse(this.Item[0].ShowRoomCoverImg)
            }catch(err){
                console.log(err);
            }
        },

        skip(k){
            var str=JSON.stringify(k)
            this.$router.push({name:'GalleryDetail',params:{data:k.Item,id:k.Id,parentId:this.$route.params.id}})
        }
    },
    
}
</script>

<style lang="scss" scoped>
    *{
        margin: 0;
        padding: 0;
    }   

    .title{
        margin: 25px auto 10px auto; 
        text-align: center;
        font-size: 18px;
        color: #006cff;
        font-weight: 800;
    }

    .title::after{
      content: "";
      font-size: 0.14rem;
      line-height: 1.5;
      font-family: iconfont;
      background-image: linear-gradient(90deg,rgba(54, 127, 230, 0),var(--skin-color));
      background-clip: text;
      color: transparent;
      margin-right: 0.04rem;
      padding: 0px 0.04rem;
      color: #000;
    }

    .show{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 .25rem;
    }
    .item{
        width: 48.5%;
        height: 116px;
        border-radius: 10px;
        background: #9fcef4;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .name{
        color: white;
        text-align: center;
        font-size: 15px;
        margin-top: 5px;
    }
    .icon-fangzi{
        color: white;
        font-size: 28px;
    }
        .a{
        width: 100%;
        height: 190px;
    }
    
  .Popups{
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 999999999999999999999999999;
  }
</style>