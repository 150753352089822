<template>
  <div class="meeting-reg-list" v-if="orderList.length">
    <div class="main">
      <ul class="list">
        <li class="item" v-for="(item, index) in orderList" :key="index" @click="navigate(item)">
          <div class="title">
            <h4 class="text">{{item.meeting_title}}</h4>
          </div>
          <div class="content">
            <div class="content-item">
              <p class="unit">
                <b class="type">工作单位：</b>
                <i class="text">{{item.company}}</i>
              </p>
            </div>
            <div class="content-item mixture">
              <p class="department">
                <b class="type">科室/部门：</b>
                <i class="text">{{item.departments}}</i>
              </p>
              <p class="state">
                <i class="text" :class="item.state == 108 ? 'blue' : 'orange'">{{shateHandler(item.state)}}</i>
              </p>
            </div>
            <div class="content-item signup">
              <p class="name">
                <b class="type">参会人：</b>
                <i class="text">{{item.attend_people}}</i>
              </p>
              <p class="time">
                <span class="text">{{item.add_time}}</span>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <van-empty v-else image="search" description="列表为空" />
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      orderList: [],
      distinguish:true
    }
  },
  methods: {
    // 跳转参会报名信息详情页
    navigate (item) {
    
      if (!item) return
      this.$store.commit('setMeetingRegDetail', item)
     if(this.distinguish){
        this.$router.push('/meetingregdetail/' +item.meeting_id)
     }else{
      this.$router.push('/meetingregdetail/' + this.$route.params.id)
     }
      // this.$router.push('/meetingregdetail/' + this.$route.params.id)
    },
    // 时间去掉一个T字符，还有毫秒
    timeHandler (arr) {
      arr.forEach((item) => {
        item.add_time = item.add_time.replace(/T/, ' ')
        item.add_time = item.add_time.replace(/(.+?)(\..+)/, '$1')
      })
      return arr
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetMeetingSignUp?open_id=${this.$store.state.user.openid || localStorage.openid}&MeetingId=${id}`)
        .then((res) => {
       
          if (res.data.Data.length) {
            this.orderList = res.data.Data
            this.orderList = this.timeHandler(this.orderList) // 顺便调用去掉时间T字符的方法
          } else {
            console.log('获取数据为空')
          }
          this.distinguish=false
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    },

     requestAll () {
      axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetMeetingSignUp?open_id=${this.$store.state.user.openid || localStorage.openid}`)
        .then((res) => {
       
          if (res.data.Data.length) {
            this.orderList = res.data.Data
            this.orderList = this.timeHandler(this.orderList) // 顺便调用去掉时间T字符的方法
          } else {
            console.log('获取数据为空')
          }
          this.distinguish=true
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    },
    // 报名状态判断
    shateHandler (state) {
      var str = ''
      if (typeof state === 'string') {
        state = parseInt(state)
      } else if (typeof state !== 'number') {
        return
      }
      switch (state) {
        case 1:
          str = '待审批'
          break
        case 2:
          str = '未缴费'
          break
        case 108:
          str = '已报名'
          break
        case 71:
          str = '审批不通过'
          break
        case 103:
          str = '取消中'
          break
        case 99:
          str = '已取消'
      }
      return str
    }
  },
  beforeRouteEnter (to, from, next) { // 路由进入拦截
    next(vm => {
      if(from.fullPath==`/meetingregister/${vm.$route.params.id}`){
             vm.$store.commit('loadHandler', { // 调用加载动画
        _this: vm,
        boolean: true
      })
        
        vm.request(vm.$route.params.id)
        // 加个定时器，确保请求拿到数据
        setTimeout(()=>{
          // 加个标识，区分是报完名直接来的还是自己点进来的
          vm.orderList[0]['case']=1
          vm.navigate(vm.orderList[0])
        },500)

      }
      vm.$store.commit('routerGuardWechat', { // 调用守卫
        fromPath: from.path,
        _this: vm
      })
    })
  },
  beforeMount () {

    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit('loadHandler', { // 调用加载动画
        _this: this,
        boolean: true
      })
       if(this.$route.params.id==0){
        this.requestAll()
      }else{
        this.request(this.$route.params.id) // 调用请求方法
      }
    
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-reg-list {
  .main {
    padding : 0.5rem 0 0.5rem ;
    .list {
      .item {
        background-color : #FFFFFF ;
        padding : 0.15rem ;
        border-radius : 0.05rem ;
        box-shadow : 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1) ;
        margin-top : 0.3rem ;
        &:first-of-type {
          margin-top : 0 ;
        }
        .title {
          .text {
            font-weight : bold ;
            line-height : 0.3rem ;
          }
        }
        .content {
          .content-item {
            margin-top : 0.1rem ;
            &>p {
              .type {
                color : #8d8d8d ;
                font-size : 0.22rem ;
              }
              .text {
                font-size : 0.22rem ;
              }
            }
          }
          .mixture {
            display : flex ;
            justify-content : space-between ;
            .department {
              flex-grow : 1 ;
              display : flex ;
              .type {
                flex-shrink : 0 ;
              }
              .text {
                flex-grow : 1 ;
              }
            }
            .state {
              flex-shrink : 0 ;
              width : 1.4rem ;
              text-align : right ;
              .text {
                display : block ;
                &.orange {
                  color : #FF9900 ;
                }
                &.blue {
                  color : #33CCFF ;
                }
              }
            }
          }
          .signup {
            display : flex ;
            justify-content : space-between ;
            .name {
              flex-grow : 1 ;
              line-height : 0.3rem ;
              .text {
                font-weight : bold ;
              }
            }
            .time {
              flex-shrink : 0 ;
              width : 2rem ;
              text-align : right ;
              line-height : 0.3rem ;
              .text {
                display : block ;
                color : #cfcfcf ;
                font-size : 0.2rem ;
              }
            }
          }
        }
      }
    }
  }
}
</style>
