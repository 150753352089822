<template>
  <div class="examine-detail" v-if="isShow">
    <div class="main">
      <div class="title">
        <div class="row row-1">
          <p class="name">
            <b class="type">申请人：</b>
            <i class="text">{{ applyInfo.name }}</i>
          </p>
          <p class="time">
            <b class="type">提交时间：</b>
            <i class="text">{{ applyInfo.time }}</i>
          </p>
        </div>
        <div class="row row-2">
          <p class="mold">
            <b class="type">申请类型：</b>
            <i class="text">{{ applyInfo.type }}</i>
          </p>
        </div>
        
        <div class="row row-3">
          <p class="project">
            <b class="type">项目名称：</b>
            <i class="text">{{ applyInfo.project }}</i>
          </p>
        </div>
        
        <div class="row row-4">
          <p class="state">
            <b class="type">当前状态：</b>
            <i class="text" :class="applyInfo.state ? 'orange' : 'blue'">{{
              applyInfo.state ? "已通过" : "审批中"
            }}</i>
          </p>
        </div>
      </div>
      <div class="process">
        <ul class="list">
          <li
            class="item"
            v-for="(item, index) in applyInfo.process"
            :key="index"
          >
            <div class="main-info">
              <div class="name">
                <span class="text">{{ item.name }}</span>
              </div>
              <div class="unit">
                <span class="text">{{ item.unit }}</span>
              </div>
              <div class="opinion">
                <b class="type">意见：</b>
                <span class="text">{{
                  item.opinion ? item.opinion : "未审批"
                }}</span>
              </div>
            </div>
            <div class="auxiliary-info">
              <div class="time">
                <span class="text">{{ item.time }}</span>
              </div>
              <div class="state">
                <span class="text" :class="item.state ? 'orange' : 'blue'">{{
                  item.state ? "已通过" : "待审批"
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 页面显示开关
      isShow: true,
      applyInfo: {},
      applyInfo1: {
        id: "1",
        name: "张福全",
        time: "2018-04-20 13:00",
        type: "继续教育项目申报",
        project: "人类先天畸形的发生与防治",
        state: 0,
        process: [
          {
            name: "王主任",
            unit: "市继续医学教育委员会",
            time: "2018-04-23 13:00",
            opinion: "",
            state: 0,
          },
          {
            name: "刘主任",
            unit: "市继续医学教育委员会办公室或学科组",
            time: "2018-04-22 13:00",
            opinion: "医学发展任重而道远",
            state: 1,
          },
          {
            name: "李主任",
            unit: "县（市、区）卫计局（市继续医学教育委员会）、市直属驻市单位、市级学术团体",
            time: "2018-04-21 13:00",
            opinion: "医学发展任重而道远",
            state: 1,
          },
        ],
      },
      applyInfo2: {
        id: "1",
        name: "张福全",
        time: "2018-04-20 13:00",
        type: "会议报名",
        project: "第十五届护理解剖学学术年会",
        state: 1,
        process: [
          {
            name: "王主任",
            unit: "市继续医学教育委员会",
            time: "2018-04-23 13:00",
            opinion: "同意",
            state: 1,
          },
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      // vm.$store.commit("routerGuard", {
      //   // 调用守卫
      //   fromPath: from.path,
      //   _this: vm,
      // });
    });
  },
  beforeMount() {
    // 有token的情况下才显示
    // if (this.$store.state.user.token || localStorage.token) this.isShow = true;

    if (this.$route.query.type === "ced") {
      this.applyInfo = this.applyInfo1;
    } else {
      this.applyInfo = this.applyInfo2;
    }
    console.log(this.applyInfo);
  },
};
</script>

<style lang="scss" scoped>
.examine-detail {
  min-height: 2.83rem;
  padding-bottom: 0.1rem;
  background: url(../../public/wx_images/background-examine.png) no-repeat
    center top / 100% 2.83rem;
  box-sizing: border-box;
  overflow: hidden;
  .main {
    .title {
      background-color: #ffffff;
      padding: 0.25rem 0.35rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.65rem;
      .row {
        margin-top: 0.2rem;
        &:first-of-type {
          margin-top: 0;
        }
        .type {
          color: #8d8d8d;
          font-size: 0.22rem;
        }
        .text {
          font-size: 0.22rem;
          &.blue {
            color: #ff9900;
          }
          &.orange {
            color: #33ccff;
          }
        }
      }
      .row-1 {
        display: flex;
        justify-content: space-between;
        .name {
          .text {
            color: #33ccff;
          }
        }
        .time {
        }
      }
      .row-3 {
        .project {
          display: flex;
          .type {
            flex-shrink: 0;
          }
        }
      }
    }
    .process {
      padding: 0.3rem 0.2rem 0.2rem;
      .list {
        .item {
          display: flex;
          justify-content: space-between;
          background-color: #ffffff;
          padding: 0.15rem 0.2rem;
          border-radius: 0.05rem;
          box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
          margin-top: 0.2rem;
          &:first-of-type {
            margin-top: 0;
          }
          .main-info {
            display: flex;
            flex-direction: column;
            .name {
              .text {
                font-weight: bold;
              }
            }
            .unit {
              .text {
                color: #8d8d8d;
                font-size: 0.2rem;
              }
            }
            .opinion {
              margin-top: 0.2rem;
              color: #8d8d8d;
              font-size: 0.2rem;
              .text {
              }
            }
          }
          .auxiliary-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-shrink: 0;
            width: 1.8rem;
            text-align: right;
            .time {
              .text {
                color: #cccccc;
                font-size: 0.2rem;
              }
            }
            .state {
              .text {
                font-size: 0.22rem;
                &.blue {
                  color: #ff9900;
                }
                &.orange {
                  color: #33ccff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
