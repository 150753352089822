<template>
  <div class="meeting-contact meeting-info-common" :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
     
    <!-- <div v-else class="backUrl"></div> -->
    <Popups></Popups>
    <div class="main">
      <ul class="article">
        <li class="row" v-for="(item, index) in data" :key="index">
          <h5 class="title" v-if="item.title" v-html="item.title"></h5>
          <p class="content" v-if="item.content" v-html="item.content"></p>
        </li>
      </ul>
    </div> 
     <div  class="backUrl" v-if="backShow" ref="back" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
      <img :src="$store.state.imageUrl + img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data () {
    return {
       twoColor:localStorage.getItem('twoColor'),
       backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
      show:true,
      ishow:false,
      backShow:false,
      size:0,
      contentSize:0,
      data: [
        {
          title: '',
          content: null
        }
      ]
    }
  },

  created(){
    if(this.img_url!=='null'){
      this.show=false
    }else{
      this.show=true
    }
  },

  mounted(){
    //实时高度
     this.size=document.documentElement.clientHeight;
    setTimeout(()=>{
      this.distance()
    },100)
  },

  methods: {
      distance () {
      this.backShow=localStorage.getItem('img_url')?true:false
      var con=document.querySelector('.row')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight
      if(this.size - this.contentSize > backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
      // console.log(this.ishow);
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + '/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=' + id + '&Field=contact')
        .then((res) => {
          if (res.data.Data.length) {
            var html = res.data.Data[0].contact
            html = html.replace(/(<p.+?)(style=".+?")(>)/, '$1$3')
            this.data[0].content = html
          } else {
            console.log('获取数据为空')
          }
          
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
          
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    }
  },
  beforeMount () {
    this.$store.commit('loadHandler', { // 调用加载动画
      _this: this,
      boolean: true
    })

    this.request(this.$route.params.id) // 调用请求方法
  }
}
</script>
<style lang="scss" scoped>
.meeting-contact{
 position: relative;
  min-height: calc(100vh - 0.6rem);
}
.backUrl{
  width: 100%;
  height: 200px;

 img{
  width: 100%;
 }
   
}


.main{
  z-index: 99;
  position: relative;
}
</style>
