<template>
  <div class="meeting-pay-detail" v-if="attendeeInfo">
    <div class="main">
      <div class="attendee-info">
        <div class="row row-1">
          <p class="meeting-title">
            <b class="type">会议：</b>
            <i class="text"
              >{{ attendeeInfo.meeting_title }}</i
            >
          </p>
        </div>
        <div class="row row-2">
          <p class="name">
            <b class="type">参会人：</b>
            <i class="text">{{ attendeeInfo.attend_people }}</i>
          </p>
        </div>
        <div class="row row-3">
          <p class="time">
            <b class="type">报名时间：</b>
            <i class="text">{{ attendeeInfo.add_time }}</i>
          </p>
        </div>
        <div class="row row-4">
          <p class="phone">
            <b class="type">联系号码：</b>
            <i class="text">{{ attendeeInfo.link_my }}</i>
          </p>
        </div>
        <div class="row row-5">
          <p class="unit">
            <b class="type">工作单位：</b>
            <i class="text">{{ attendeeInfo.company }}</i>
          </p>
        </div>
        <div class="row row-6" v-if="attendeeInfo.departments">
          <p class="department">
            <b class="type">科室/部门：</b>
            <i class="text">{{ attendeeInfo.departments }}</i>
          </p>
        </div>
        <div class="row row-7" v-if="attendeeInfo.title">
          <p class="attendee-title">
            <b class="type">职称：</b>
            <i class="text">{{ attendeeInfo.title }}</i>
          </p>
        </div>
        <div class="row row-8" v-if="attendeeInfo.education">
          <p class="qualification">
            <b class="type">最高学历：</b>
            <i class="text">{{ attendeeInfo.education }}</i>
          </p>
        </div>
        <div
          class="row row-9"
          v-if="costList.length && attendeeInfo.delivery_way"
        >
          <p class="payment-method">
            <b class="type">支付方式：</b>
            <i class="text">{{
              attendeeInfo.delivery_way === "alipay" ? "微信支付" : "单位转账"
            }}</i>
          </p>
        </div>
        <div class="row row-10" v-if="costList.length && attendeeInfo.drawee">
          <p class="drawee">
            <b class="type">付款人：</b>
            <i class="text">{{ attendeeInfo.drawee }}</i>
          </p>
        </div>
        <div
          class="row row-11"
          v-if="costList.length && attendeeInfo.IsInvoice"
        >
          <p class="invoice-title">
            <b class="type">发票抬头：</b>
            <i class="text">{{ attendeeInfo.invoiceTitle }}</i>
          </p>
        </div>
        <div
          class="row row-12"
          v-if="costList.length && attendeeInfo.IsInvoice"
        >
          <p class="tariff-line">
            <b class="type">发票税号：</b>
            <i class="text">{{ attendeeInfo.tariffLine }}</i>
          </p>
        </div>
        <div
          class="row row-13"
          v-if="costList.length && attendeeInfo.IsInvoice"
        >
          <p class="email">
            <b class="type">邮箱：</b>
            <i class="text">{{ attendeeInfo.email }}</i>
          </p>
        </div>
        <div class="row row-14" v-if="attendeeInfo.state">
          <p class="state">
            <b class="type">当前状态：</b>
            <i
              class="text"
              :class="attendeeInfo.state == 108 ? 'blue' : 'orange'"
              >{{ attendanceShateHandler(attendeeInfo.state) }}</i
            >
          </p>
        </div>
        <div class="row row-15" v-if="attendeeInfo.notes">
          <p class="note">
            <b class="type">备注：</b>
            <i class="text">{{ attendeeInfo.notes }}</i>
          </p>
        </div>
        <div class="row row-16" v-if="attendeeInfo.views">
          <p class="opinion">
            <b class="type">审批意见：</b>
            <i class="text">{{ attendeeInfo.views }}</i>
          </p>
        </div>
      </div>
      <div class="order-info" v-if="orderInfo.length">
        <div class="title">
          <span class="text">已提交费用：</span>
        </div>
        <ul class="list">
          <li class="item" v-for="(item, index) in orderInfo" :key="index">
            <div class="row row-1">
              <div class="order-number">
                <b class="type">订单号：</b>
                <i class="text">{{ item.bill_number }}</i>
              </div>
            </div>
            <div class="row row-2">
              <div class="cost-title">
                <span class="text">{{ item.pay_name }}</span>
              </div>
              <div class="price"> 
                <span class="text">{{
                  parseInt(item.pay_amount).toFixed(2)
                }}</span>
              </div>
            </div>
            <div class="row row-3">
              <div class="pay-state">
                <span
                  class="text"
                  :class="
                    item.is_pay == 1 ||
                    (item.is_pay == 2 && item.is_refunded == 0)
                      ? 'blue'
                      : 'orange'
                  "
                  >{{ costShateHandler(item.is_pay, item.is_refunded) }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="button">
        <!-- 取消中或已取消不能修改 -->
        <div
          class="modify-button"
          v-if="attendeeInfo.state != 99 && attendeeInfo.state != 103"
          @click="navigate('modify')"
        >
          <span class="text">修改参会信息</span>
        </div>
        <!-- 只有未缴费和已缴费状态且有可选费用才能下单 -->
        <div
          class="order-button"
          v-if="
            (attendeeInfo.state == 2 ||
              attendeeInfo.state == 107 ||
              attendeeInfo.state == 108) &&
            costList.length
          "
          @click="navigate('order')"
        >
          <span class="text">进入下单页面</span>
        </div>
        <!-- 只有在审批不通过时才能重新提交 -->
        <div
          class="resubmit-button"
          v-if="attendeeInfo.state == 71"
          @click="resubmit"
        >
          <span class="text">重新提交</span>
        </div>
        <!-- 取消中或已取消不能再提交 -->
        <div
          class="cancel-button"
          v-if="attendeeInfo.state != 99 && attendeeInfo.state != 103"
          @click="formShow = true"
        >
          <span class="text">取消报名</span>
        </div>
      </div>
    </div>
    <!-- 取消报名输入框（弹窗） -->
    <van-popup
      v-model:show="formShow"
      :style="{
        width: '90%',
        padding: '40px 0.35rem',
        boxSizing: 'border-box',
      }"
      closeable
      round
    >
      <div class="form form-content">
        <div class="item text-input reason-input">
          <div class="title must" :class="reasonClass">
            <span>取消原因</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="reason"
              @blur="verify('reason')"
              placeholder="请输入取消原因"
            />
          </div>
        </div>
        <div class="item submit" @click="cancelRegister">立即提交</div>
        <div>{{state}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 参会人信息
      attendeeInfo: null,
      // 已提交费用信息
      orderInfo: [],
      // 可选费用列表
      costList: [],
      // 取消报名原因
      formShow: false,
      reason: null,
      reasonClass: "",
      // 微信用户名
      userName: null,
      // 已报名列表
      attendeeList: [],
      state:'',
      orderState:''
    };
  },
  methods: {
    // 请求这个参会记录已提交的费用信息
    requestSubmittedCost(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetPayOrderBySignUp?SignUpId=${id}`
        )
        .then((res) => {
         
          if (res.data.Data.length) {
            this.orderInfo = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          // 调用查询可选费用列表方法
          this.requestCostList(this.$route.params.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取微信用户名
    requestUserInfo() {
      axios({
        method: "get",
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetWechatUserInfo?openId=${
            this.$store.state.user.openid || localStorage.openid
          }`,
      })
        .then((res) => {
          if (res.data.Data.length) {
            // 如果有数据，拿到用户名
            this.userName = res.data.Data[0].nickname;
          } else {
            console.log("获取用户信息列表为空");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 报名状态判断
    attendanceShateHandler(state) {
      var str = "";
      if (typeof state === "string") {
        state = parseInt(state);
      } else if (typeof state !== "number") {
        return;
      }
      switch (state) {
        case 1:
          str = "待审批";
          break;
        case 2:
          str = "未缴费";
          break;
        case 107:
          str = "未缴清必选费用";
          break;
        case 108:
          str = "已报名";
          break;
        case 71:
          str = "审批不通过";
          break;
        case 103:
          str = "取消中";
          break;
        case 99:
          str = "已取消";
      }
      
      return str;
    },
    // 明细状态判断（一个是总订单状态，一个是明细状态）
    costShateHandler(payState, refundedState) {
      var str = "";
      if (typeof payState === "string") {
        payState = parseInt(payState);
      } else if (typeof payState !== "number") {
        return;
      }
      if (typeof refundedState === "string") {
        refundedState = parseInt(refundedState);
      } else if (typeof refundedState !== "number") {
        return;
      }
      switch (payState) {
        case 0:
          str = "未支付";
          break;
        case 1:
          str = "已支付";
          break;
        case 2:
          if (refundedState) {
            str = "已退款";
          } else {
            str = "已支付";
          }
          break;
        case 109:
          str = "已退款";
          break;
        case 99:
          str = "已取消";
      }
this.orderState=str
      return str;
    },
    // 重复提交
    resubmit() {
      // 要发送的对象
      var obj = {
        sign_up_id: this.attendeeInfo.MeetingRegisterId,
      };
      var json = JSON.stringify(obj);

      this.$dialog
        .confirm({
          title: "是否确定要重新提交？",
        })
        .then(() => {
          // 加载动画
          this.$toast.loading({
            message: "提交中...",
            forbidClick: true,
            duration: 0,
          });
          // 提交表单给后端
          axios({
            method: "post",
            url:
              this.$store.state.apiUrl +
              "/actionapi/TencentApi/ResubmitMeetingSignUp",
            headers: {
              "Content-Type": "application/json",
            },
            data: json,
          })
            .then((res) => {
              // 如果状态码正确
              if (res.data.ResultCode === 1) {
                // 提示并返回
                this.$toast.loading({
                  type: "success",
                  forbidClick: true,
                  message: "提交成功",
                  onClose: () => {
                    // 返回上一级
                    if (this.$store.state.history.length > 1) {
                      this.$store.commit("returnHandler", this);
                    } else {
                      this.$router.push("/");
                    }
                  },
                });
              } else {
                // 提交失败
                this.tip(res.data.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("用户点击了取消");
        });
    },
    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "reason":
            if (!this.reason) {
              this.reasonClass = "empty";
            } else {
              this.reasonClass = "correct";
            }
        }
      } else {
        if (!this.reason) {
          this.reasonClass = "empty";
        } else {
          this.reasonClass = "correct";
        }
      }
    },
    // 取消报名提交
    cancelRegister() {
      // 提交验证
      if (this.attendeeInfo.state === 99 || this.attendeeInfo.state === 103) {
        this.tip("已取消参会或者已提交取消申请");
      } else if (!this.reason) {
        this.tip("请输入取消原因");
      } else {
        // 要发送的对象
        var obj = {
          meeting_id: this.$route.params.id,
          apply_user: this.userName,
          OpenId: this.$store.state.user.openid || localStorage.openid,
          sign_up_id: this.attendeeInfo.MeetingRegisterId,
          Reasons: this.reason,
          bill_number: "",
        };
        var json = JSON.stringify(obj);

        this.$dialog
          .confirm({
            title: "是否确定要取消报名？",
          })
          .then(() => {
            // 加载动画
            this.$toast.loading({
              message: "提交中...",
              forbidClick: true,
              duration: 0,
            });
            // 提交表单给后端
            axios({
              method: "post",
              url:
                this.$store.state.apiUrl + "/actionapi/TencentApi/SignUpCancel",
              headers: {
                "Content-Type": "application/json",
              },
              data: json,
            })
              .then((res) => {
                // 如果状态码正确
                if (res.data.ResultCode === 1) {
                  // 提示并返回
                  this.$toast.loading({
                    type: "success",
                    forbidClick: true,
                    message: "提交成功",
                    onClose: () => {
                      // 返回上一级
                      if (this.$store.state.history.length > 1) {
                        this.$store.commit("returnHandler", this);
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                } else {
                  // 提交失败
                  this.tip(res.data.Msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
            console.log("用户点击了取消");
          });
      }
    },
    // 查询可选费用列表
    requestCostList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingFee?MeetingId=" +
            id
        )
        .then((res) => {
          if (res.data.Data && res.data.Data.length) {
            // 赋值给费用菜单，如果长度为0，不会显示进入下单页面按钮
            this.costList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 跳转，根据类型跳转到参会注册或者下单页面
    navigate(type) {
      // 修改信息
      if (type === "modify") {
        if (this.attendeeInfo.state === 99 || this.attendeeInfo.state === 103) {
          this.tip("已取消参会或者已提交取消申请");
        } else if (this.attendeeInfo) {
          this.$router.push({
            path: "/meetingregister/" + this.$route.params.id,
            query: {
              type,
            },
          });
        } else {
          this.tip("没有参会信息数据");
        }
        // 下单
      } else if (type === "order") {
        if (
          this.attendeeInfo.state !== 2 &&
          this.attendeeInfo.state !== 107 &&
          this.attendeeInfo.state !== 108
        ) {
          this.tip("只有在未缴费或已报名状态才可以下单");
        } else if (
          this.attendeeInfo.MeetingRegisterId &&
          this.attendeeInfo.delivery_way
        ) {
          this.$router.push({
            path: "/meetingregpay/" + this.$route.params.id,
            query: {
              
              type,
              orderState:this.orderState,
              MeetingRegisterId: this.attendeeInfo.MeetingRegisterId,
              paymentMethod: this.attendeeInfo.delivery_way,
            },
          });
        } else {
          this.tip("没有报名记录id或者支付方式数据");
        }
      }
    },
    // 提示
    tip(text, type) {
      this.$toast.clear();
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
  
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });

      if(from.name=='MeetingRegPay'){
        // 删除这个标识，如果不删就会反复跳转
        delete vm.$store.state.meetingRegDetail.case
        return
      }
      if(vm.$store.state.meetingRegDetail.case){
         setTimeout(()=>{
          vm.navigate('order')
        },100)
      }
    });
  },
  beforeMount() {
    // 有openid的情况下才赋值
    if (this.$store.state.user.openid || localStorage.openid) {
      this.attendeeInfo = this.$store.state.meetingRegDetail;
   
      if (this.attendeeInfo.MeetingRegisterId) {
        this.$store.commit("loadHandler", {
          // 调用加载动画
          _this: this,
          boolean: true,
        });
        // 调用查询已提交费用方法
        this.requestSubmittedCost(this.attendeeInfo.MeetingRegisterId);
        // 请求微信用户信息（在提交时会用到）
        this.requestUserInfo();
      } else {
        console.log("没有会议报名记录id");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-pay-detail {
  min-height: 2.83rem;
  padding-bottom: 0.5rem;
  background: url(../../public/wx_images/background-examine.png) no-repeat
    center top / 100% 2.83rem;
  box-sizing: border-box;
  overflow: hidden;
  .main {
    .attendee-info {
      background-color: #ffffff;
      padding: 0.25rem 0.35rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.65rem;
      .row {
        margin-top: 0.15rem;
        &:first-of-type {
          margin-top: 0;
        }
        p {
          display: flex;
          .type {
            flex-shrink: 0;
            color: #8d8d8d;
            font-size: 0.22rem;
          }
          .text {
            flex-grow: 1;
            font-size: 0.22rem;
            &.orange {
              color: #ff9900;
            }
            &.blue {
              color: #33ccff;
            }
          }
        }
        .meeting-title,
        .name {
          .text {
            font-weight: bold;
          }
        }
        .time,
        .phone,
        .tariff-line,
        .email {
          display: block;
        }
      }
    }
    .order-info {
      background-color: #ffffff;
      padding: 0.25rem 0.35rem 0.4rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.5rem;
      .title {
        font-weight: bold;
      }
      .list {
        margin-top: 0.2rem;
        .item {
          padding: 0.15rem 0.2rem;
          border-radius: 0.05rem;
          box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
          margin-top: 0.2rem;
          &:first-of-type {
            margin-top: 0;
          }
          .row {
            margin-top: 0.05rem;
          }
          .row-1 {
            .order-number {
              .type {
                color: #8d8d8d;
                font-size: 0.22rem;
              }
              .text {
                color: #33ccff;
                font-size: 0.22rem;
                word-break: break-all;
              }
            }
          }
          .row-2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .cost-title {
              .text {
                font-weight: bold;
              }
            }
            .price {
              .text {
                color: #eb6337;
                &::before {
                  content: "￥";
                }
              }
            }
          }
          .row-3 {
            margin-top: 0.1rem;
            text-align: right;
            .pay-state {
              .text {
                font-size: 0.22rem;
                &.orange {
                  color: #ff9900;
                }
                &.blue {
                  color: #33ccff;
                }
              }
            }
          }
        }
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
      > div {
        width: 5rem;
        height: 0.56rem;
        padding-left: 0.05rem;
        border-radius: 0.05rem;
        box-sizing: border-box;
        margin-top: 0.3rem;
        color: #ffffff;
        font-size: 0.24rem;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 0.6rem;
      }
      .modify-button {
        background-color: #4e9ef7;
      }
      .order-button,
      .resubmit-button {
        background-color: #ff9900;
      }
      .cancel-button {
        background-color: #ff0045;
      }
    }
  }
  .form {
    .item {
      margin-top: 0.3rem;
      .title {
        position: relative;
        &::after {
          display: block;
          padding-left: 0.4rem;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 0.27rem 0.27rem;
          line-height: 0.31rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.reason-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入取消原因！";
            background-image: url(../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
    }
    .submit {
      margin: 0.6rem auto 0;
    }
  }
}
</style>
