<template>
  <div class="cedeclare form-container" v-if="isShow">
    <div class="main form-main">
      <div class="header form-header">
        <em class="title">继续教育项目申报</em>
        <span class="tip">*为必填项</span>
      </div>
      <div class="form form-content">
        <div class="item classify">基本信息：</div>
        <div class="item text-input project-name-input">
          <div class="title must" :class="projectNameClass">
            <span>项目名称</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="projectName"
              @blur="verify('projectName')"
              placeholder="请输入项目名称"
            />
          </div>
        </div>
        
        <div class="item area-input subject-input">
          <div class="title must" :class="subjectClass">
            <span>所在学科（二、三级学科）</span>
          </div>
          <div class="content" @click="subjectShow = true">
            <input
              type="text"
              disabled
              v-model="subject"
              placeholder="请选择所在学科（二、三级学科）"
            />
          </div>
          <van-popup v-model:show="subjectShow" position="bottom">
            <van-picker
              show-toolbar
              title="选择学科"
              :columns="subjectOption"
              @cancel="subjectShow = false"
              @confirm="subjectSelect"
            />
          </van-popup>
        </div>
        <div class="item text-input post-code-input">
          <div class="title must" :class="postCodeClass">
            <span>邮政编码</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="postCode"
              @blur="verify('postCode')"
              placeholder="请输入邮政编码"
              @input="postCode = numberVerify(postCode, 6)"
            />
          </div>
        </div>
        <div class="item area-input declare-date-input">
          <div class="title must" :class="declareDateClass">
            <span>申报日期</span>
          </div>
          <div class="content" @click="declareDateShow = true">
            <input
              type="text"
              disabled
              v-model="declareDate"
              placeholder="请选择申报日期"
            />
          </div>
          <van-popup v-model:show="declareDateShow" position="bottom">
            <van-datetime-picker
              v-model="declareCurrentDate"
              type="date"
              title="选择年月日"
              :min-date="declareMinDate"
              :max-date="declareMaxDate"
              @cancel="declareDateShow = false"
              @confirm="declareDateSelect"
            />
          </van-popup>
        </div>
        <div class="item classify">项目简况：</div>
        <div class="item text-input hold-mode-input">
          <div class="title must" :class="holdModeClass">
            <span>举办方式</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="holdMode"
              @blur="verify('holdMode')"
              placeholder="请输入举办方式"
            />
          </div>
        </div>
        <div class="item area-input hold-start-input">
          <div class="title must" :class="holdStartClass">
            <span>举办开始时间</span>
          </div>
          <div class="content" @click="holdStartShow = true">
            <input
              type="text"
              disabled
              v-model="holdStart"
              placeholder="请选择举办开始时间"
            />
          </div>
          <van-popup v-model:show="holdStartShow" position="bottom">
            <van-datetime-picker
              :v-model="holdStartCurrentDate"
              type="date"
              title="选择年月日"
              :min-date="holdStartMinDate"
              :max-date="holdStartMaxDate"
              @cancel="holdStartShow = false"
              @confirm="holdStartSelect"
            />
          </van-popup>
        </div>
        <div class="item area-input hold-end-input">
          <div class="title must" :class="holdEndClass">
            <span>举办结束时间</span>
          </div>
          <div class="content" @click="holdEndShow = true">
            <input
              type="text"
              disabled
              v-model="holdEnd"
              placeholder="请选择举办结束时间"
            />
          </div>
          <van-popup v-model:show="holdEndShow" position="bottom">
            <van-datetime-picker
              :v-model="holdEndCurrentDate"
              type="date"
              title="选择年月日"
              :min-date="holdEndMinDate"
              :max-date="holdEndMaxDate"
              @cancel="holdEndShow = false"
              @confirm="holdEndSelect"
            />
          </van-popup>
        </div>
        <div class="item text-input assessment-method-input">
          <div class="title must" :class="assessmentMethodClass">
            <span>考核方式</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="assessmentMethod"
              @blur="verify('assessmentMethod')"
              placeholder="请输入考核方式"
            />
          </div>
        </div>
        <div class="item text-input teaching-object-input">
          <div class="title must" :class="teachingObjectClass">
            <span>教学对象</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="teachingObject"
              @blur="verify('teachingObject')"
              placeholder="请输入教学对象"
            />
          </div>
        </div>

        <div class="item text-input enrollment-input">
          <div class="title must" :class="enrollmentClass">
            <span>拟招生数</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="enrollment"
              @blur="verify('enrollment')"
              placeholder="请输入拟招生数"
              @input="enrollment = numberVerify(enrollment, 10)"
            />
          </div>
        </div>
        <div class="item text-input all-class-hours-input">
          <div class="title must" :class="allClassHoursClass">
            <span>教学总时数</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="allClassHours"
              @blur="verify('allClassHours')"
              placeholder="请输入教学总时数"
              @input="allClassHours = numberVerify(allClassHours, 10)"
            />
          </div>
        </div>
        <div class="item text-input theory-class-hours-input">
          <div class="title must" :class="theoryClassHoursClass">
            <span>讲授理论时数</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="theoryClassHours"
              @blur="verify('theoryClassHours')"
              placeholder="请输入讲授理论时数"
              @input="theoryClassHours = numberVerify(theoryClassHours, 10)"
            />
          </div>
        </div>
        <div class="item text-input practice-class-hours-input">
          <div class="title must" :class="practiceClassHoursClass">
            <span>实验（技术示范）时数</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="practiceClassHours"
              @blur="verify('practiceClassHours')"
              placeholder="请输入实验（技术示范）时数"
              @input="practiceClassHours = numberVerify(practiceClassHours, 10)"
            />
          </div>
        </div>
        <div class="item text-input grant-credit-input">
          <div class="title must" :class="grantCreditClass">
            <span>拟授学员学分</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="grantCredit"
              @blur="verify('grantCredit')"
              placeholder="请输入拟授学员学分"
            />
          </div>
        </div>
        <div class="item text-input venue-input">
          <div class="title must" :class="venueClass">
            <span>举办地点</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="venue"
              @blur="verify('venue')"
              placeholder="请输入举办地点"
            />
          </div>
        </div>
        <div class="item text-input organizer-input">
          <div class="title must" :class="organizerClass">
            <span>主办单位</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="organizer"
              @blur="verify('organizer')"
              placeholder="请输入主办单位"
            />
          </div>
        </div>
        <div class="item text-input organizer-contacts-input">
          <div class="title must" :class="organizerContactsClass">
            <span>主办单位联系人</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="organizerContacts"
              @blur="verify('organizerContacts')"
              placeholder="请输入主办单位联系人"
            />
          </div>
        </div>
        <div class="item text-input organizer-phone-input">
          <div class="title must" :class="organizerPhoneClass">
            <span>主办单位联系电话</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="organizerPhone"
              @blur="verify('organizerPhone')"
              placeholder="请输入主办单位联系电话"
            />
          </div>
        </div>
        <div class="item text-input applicant-input">
          <div class="title must" :class="applicantClass">
            <span>申报单位</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="applicant"
              @blur="verify('applicant')"
              placeholder="请输入申报单位"
            />
          </div>
        </div>
        <div class="item text-input applicant-contacts-input">
          <div class="title must" :class="applicantContactsClass">
            <span>申报单位联系人</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="applicantContacts"
              @blur="verify('applicantContacts')"
              placeholder="请输入申报单位联系人"
            />
          </div>
        </div>
        <div class="item text-input applicant-phone-input">
          <div class="title must" :class="applicantPhoneClass">
            <span>申报单位联系电话</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="applicantPhone"
              @blur="verify('applicantPhone')"
              placeholder="请输入申报单位联系电话"
            />
          </div>
        </div>
        <div class="item classify">项目负责人简况：</div>
        <div class="item text-input director-name-input">
          <div class="title must" :class="directorNameClass">
            <span>项目负责人姓名</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="directorName"
              @blur="verify('directorName')"
              placeholder="请输入项目负责人姓名"
            />
          </div>
        </div>
        <div class="item radio-input director-sex-input">
          <div class="title must" :class="directorSexClass">
            <span>项目负责人性别</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in sexOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="directorSex"
                    :value="value.value"
                    @change="verify('directorSex')"
                  />
                </div>
                <div
                  class="text"
                  :class="{ checked: directorSex == value.value }"
                >
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input director-age-input">
          <div class="title must" :class="directorAgeClass">
            <span>项目负责人年龄</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="directorAge"
              @blur="verify('directorAge')"
              placeholder="请输入项目负责人年龄"
              @input="directorAge = numberVerify(directorAge, 3)"
            />
          </div>
        </div>
        <div class="item radio-input director-title-input">
          <div class="title must" :class="directorTitleClass">
            <span>项目负责人职称</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in titleOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="directorTitle"
                    :value="value.value"
                    @change="verify('directorTitle')"
                  />
                </div>
                <div
                  class="text"
                  :class="{ checked: directorTitle == value.value }"
                >
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input director-post-input">
          <div class="title must" :class="directorPostClass">
            <span>项目负责人职务</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="directorPost"
              @blur="verify('directorPost')"
              placeholder="请输入项目负责人职务"
            />
          </div>
        </div>
        <div class="item radio-input director-qualification-input">
          <div class="title must" :class="directorQualificationClass">
            <span>项目负责人最高学历</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in qualificationOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="directorQualification"
                    :value="value.value"
                    @change="verify('directorQualification')"
                  />
                </div>
                <div
                  class="text"
                  :class="{ checked: directorQualification == value.value }"
                >
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input director-address-input">
          <div class="title must" :class="directorAddressClass">
            <span>项目负责人通讯地址</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="directorAddress"
              @blur="verify('directorAddress')"
              placeholder="请输入项目负责人通讯地址"
            />
          </div>
        </div>
        <div class="item text-input director-phone-input">
          <div class="title must" :class="directorPhoneClass">
            <span>项目负责人联系电话</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="directorPhone"
              @blur="verify('directorPhone')"
              placeholder="请输入项目负责人联系电话"
            />
          </div>
        </div>
        <div class="item text-input director-post-code-input">
          <div class="title must" :class="directorPostCodeClass">
            <span>项目负责人邮政编码</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="directorPostCode"
              @blur="verify('directorPostCode')"
              placeholder="请输入项目负责人邮政编码"
              @input="directorPostCode = numberVerify(directorPostCode, 6)"
            />
          </div>
        </div>
        <div class="item text-input annex-input">
          <div class="title">
            <span>项目补充</span>
          </div>
          <div class="content">
            <div class="annex-download">
              <span class="download-button">附件下载</span>
              <b class="tip">下载附件 > > > 填写附件 > > > 上传附件</b>
            </div>
            <div class="annex-upload">
              <label>
                <span class="upload-button">上传附件</span>
                <input type="file" ref="file" @change="fileHandler" />
              </label>
              <b class="tip">{{ annex ? annex.name : "未选择任何文件" }}</b>
            </div>
          </div>
        </div>
        <div class="item submit" @click="submit">立即提交</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 页面显示开关
      isShow: false,
      // 项目名称
      projectName: null,
      projectNameClass: "",
      // 二、三级学科
      subjectOption: [
        {
          text: "基础形态",
          children: [
            "组织胚胎学",
            "解剖学",
            "遗传学",
            "病理学",
            "寄生虫学",
            "微生物学",
          ],
        },
        {
          text: "基础机能",
          children: [
            "生理学",
            "生物化学",
            "生物物理学",
            "药理学",
            "细胞生物学",
            "病生理学",
            "免疫学",
            "基础医学其他学科",
          ],
        },
        {
          text: "临床内科学",
          children: [
            "心血管病学",
            "呼吸病学",
            "胃肠病学",
            "血液病学",
            "肾脏病学",
            "内分泌学",
            "神经内科学",
            "传染病学",
            "精神卫生学",
            "内科学其他学科",
          ],
        },
        {
          text: "临床外科学",
          children: [
            "普通外科学",
            "心胸外科学",
            "烧伤外科学",
            "神经外科学",
            "泌尿外科学",
            "显微外科学",
            "骨外科学",
            "肿瘤外科学",
            "颅脑外科学",
            "整形、器官移植外科学",
            "麻醉学",
            "皮肤、性病学",
            "外科学其他学科",
          ],
        },
        {
          text: "妇产科学",
          children: ["妇科学", "产科学", "妇产科学其他学科"],
        },
        {
          text: "儿科学",
          children: [
            "儿科内科学",
            "儿科外科学",
            "新生儿科学",
            "儿科学其他学科",
          ],
        },
        {
          text: "眼、耳鼻喉学科",
          children: ["耳鼻喉科", "眼科学"],
        },
        {
          text: "口腔医学学科",
          children: [
            "口腔内科学",
            "口腔外科学",
            "口腔正畸学",
            "口腔修复学",
            "口腔学其他学科",
          ],
        },
        {
          text: "影像医学学科",
          children: [
            "放射诊断学",
            "超声诊断学",
            "放射肿瘤学",
            "影像医学其他学科",
          ],
        },
        {
          text: "急诊学",
          children: ["急诊学"],
        },
        {
          text: "医学检验",
          children: ["医学检验"],
        },
        {
          text: "公共卫生与预防医学",
          children: [
            "劳动卫生与环境卫生学",
            "营养与食品卫生学",
            "儿少卫生与妇幼卫生学",
            "卫生毒理学",
            "统计流行病学",
            "卫生检验学",
            "公共卫生与预防医学其他学科",
          ],
        },
        {
          text: "药学",
          children: [
            "临床药学和临床药理学",
            "药剂学",
            "药物分析学",
            "药事管理学",
            "药学其他学科",
          ],
        },
        {
          text: "护理学",
          children: [
            "内科护理学",
            "外科护理学",
            "妇产科护理学",
            "儿科护理学",
            "护理其他学科",
          ],
        },
        {
          text: "医学教育与卫生管理",
          children: ["医学教育", "卫生管理"],
        },
        {
          text: "全科医学",
          children: ["全科医学"],
        },
      ],
      subjectShow: false,
      subject: null,
      subjectClass: "",
      // 邮政编码
      postCode: null,
      postCodeClass: "",
      // 申报日期
      declareDateShow: false,
      declareMinDate: new Date(2000, 0, 1),
      declareMaxDate: new Date(2100, 0, 1),
      declareCurrentDate: new Date(),
      declareDate: null,
      declareDateClass: "",
      // 举办方式
      holdMode: null,
      holdModeClass: "",
      // 举办开始时间
      holdStartShow: false,
      holdStartMinDate: new Date(),
      holdStartMaxDate: new Date(2100, 0, 1),
      holdStartCurrentDate: new Date(),
      holdStart: null,
      holdStartClass: "",
      holdStartDate: null, // 用于计算时间戳
      // 举办结束时间
      holdEndShow: false,
      holdEndMinDate: new Date(),
      holdEndMaxDate: new Date(2100, 0, 1),
      holdEndCurrentDate: new Date(),
      holdEnd: null,
      holdEndClass: "",
      holdEndDate: null, // 用于计算时间戳
      // 举办天数，不出现在表单，自己计算
      holdDays: null,
      // 考核方式
      assessmentMethod: null,
      assessmentMethodClass: "",
      // 教学对象
      teachingObject: null,
      teachingObjectClass: "",
      // 拟招生数
      enrollment: null,
      enrollmentClass: "",
      // 教学总学时数
      allClassHours: null,
      allClassHoursClass: "",
      // 讲授理论时数
      theoryClassHours: null,
      theoryClassHoursClass: "",
      // 实验（技术示范）时数
      practiceClassHours: null,
      practiceClassHoursClass: "",
      // 拟授学员学分
      grantCredit: null,
      grantCreditClass: "",
      // 举办地点
      venue: null,
      venueClass: "",
      // 主办方
      organizer: null,
      organizerClass: "",
      organizerContacts: null,
      organizerContactsClass: "",
      organizerPhone: null,
      organizerPhoneClass: "",
      // 申报方
      applicant: null,
      applicantClass: "",
      applicantContacts: null,
      applicantContactsClass: "",
      applicantPhone: null,
      applicantPhoneClass: "",
      // 负责人
      directorName: null,
      directorNameClass: "",
      sexOption: [
        {
          value: 1,
          text: "男",
        },
        {
          value: 2,
          text: "女",
        },
      ],
      directorSex: null,
      directorSexClass: "",
      directorAge: null,
      directorAgeClass: "",
      titleOption: [
        {
          value: 1,
          text: "正高",
        },
        {
          value: 2,
          text: "副高",
        },
        {
          value: 3,
          text: "中级",
        },
        {
          value: 4,
          text: "初级",
        },
        {
          value: 5,
          text: "其他",
        },
      ],
      directorTitle: null,
      directorTitleClass: "",
      directorPost: null,
      directorPostClass: "",
      qualificationOption: [
        {
          value: 1,
          text: "博士研究生",
        },
        {
          value: 2,
          text: "硕士研究生",
        },
        {
          value: 3,
          text: "本科",
        },
        {
          value: 4,
          text: "大专",
        },
        {
          value: 5,
          text: "中专",
        },
        {
          value: 6,
          text: "其他",
        },
      ],
      directorQualification: null,
      directorQualificationClass: "",
      directorAddress: null,
      directorAddressClass: "",
      directorPhone: null,
      directorPhoneClass: "",
      directorPostCode: null,
      directorPostCodeClass: "",
      // 附件
      annex: null,
    };
  },
  methods: {
    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "projectName":
            if (!this.projectName) {
              this.projectNameClass = "empty";
            } else {
              this.projectNameClass = "correct";
            }
            break;
          case "postCode":
            if (!this.postCode) {
              this.postCodeClass = "empty";
            } else if (!/^\d{6}$/.test(this.postCode)) {
              this.postCodeClass = "fail";
            } else {
              this.postCodeClass = "correct";
            }
            break;
          case "holdMode":
            if (!this.holdMode) {
              this.holdModeClass = "empty";
            } else {
              this.holdModeClass = "correct";
            }
            break;
          case "assessmentMethod":
            if (!this.assessmentMethod) {
              this.assessmentMethodClass = "empty";
            } else {
              this.assessmentMethodClass = "correct";
            }
            break;
          case "teachingObject":
            if (!this.teachingObject) {
              this.teachingObjectClass = "empty";
            } else {
              this.teachingObjectClass = "correct";
            }
            break;
          case "enrollment":
            if (!this.enrollment) {
              this.enrollmentClass = "empty";
            } else {
              this.enrollmentClass = "correct";
            }
            break;
          case "allClassHours":
            if (!this.allClassHours) {
              this.allClassHoursClass = "empty";
            } else {
              this.allClassHoursClass = "correct";
            }
            break;
          case "theoryClassHours":
            if (!this.theoryClassHours) {
              this.theoryClassHoursClass = "empty";
            } else {
              this.theoryClassHoursClass = "correct";
            }
            break;
          case "practiceClassHours":
            if (!this.practiceClassHours) {
              this.practiceClassHoursClass = "empty";
            } else {
              this.practiceClassHoursClass = "correct";
            }
            break;
          case "grantCredit":
            if (!this.grantCredit) {
              this.grantCreditClass = "empty";
            } else {
              this.grantCreditClass = "correct";
            }
            break;
          case "venue":
            if (!this.venue) {
              this.venueClass = "empty";
            } else {
              this.venueClass = "correct";
            }
            break;
          case "organizer":
            if (!this.organizer) {
              this.organizerClass = "empty";
            } else {
              this.organizerClass = "correct";
            }
            break;
          case "organizerContacts":
            if (!this.organizerContacts) {
              this.organizerContactsClass = "empty";
            } else {
              this.organizerContactsClass = "correct";
            }
            break;
          case "organizerPhone":
            if (!this.organizerPhone) {
              this.organizerPhoneClass = "empty";
            } else {
              this.organizerPhoneClass = "correct";
            }
            break;
          case "applicant":
            if (!this.applicant) {
              this.applicantClass = "empty";
            } else {
              this.applicantClass = "correct";
            }
            break;
          case "applicantContacts":
            if (!this.applicantContacts) {
              this.applicantContactsClass = "empty";
            } else {
              this.applicantContactsClass = "correct";
            }
            break;
          case "applicantPhone":
            if (!this.applicantPhone) {
              this.applicantPhoneClass = "empty";
            } else {
              this.applicantPhoneClass = "correct";
            }
            break;
          case "directorName":
            if (!this.directorName) {
              this.directorNameClass = "empty";
            } else {
              this.directorNameClass = "correct";
            }
            break;
          case "directorSex":
            if (!this.directorSex) {
              this.directorSexClass = "empty";
            } else {
              this.directorSexClass = "correct";
            }
            break;
          case "directorAge":
            if (!this.directorAge) {
              this.directorAgeClass = "empty";
            } else {
              this.directorAgeClass = "correct";
            }
            break;
          case "directorTitle":
            if (!this.directorTitle) {
              this.directorTitleClass = "empty";
            } else {
              this.directorTitleClass = "correct";
            }
            break;
          case "directorPost":
            if (!this.directorPost) {
              this.directorPostClass = "empty";
            } else {
              this.directorPostClass = "correct";
            }
            break;
          case "directorQualification":
            if (!this.directorQualification) {
              this.directorQualificationClass = "empty";
            } else {
              this.directorQualificationClass = "correct";
            }
            break;
          case "directorAddress":
            if (!this.directorAddress) {
              this.directorAddressClass = "empty";
            } else {
              this.directorAddressClass = "correct";
            }
            break;
          case "directorPhone":
            if (!this.directorPhone) {
              this.directorPhoneClass = "empty";
            } else {
              this.directorPhoneClass = "correct";
            }
            break;
          case "directorPostCode":
            if (!this.directorPostCode) {
              console.log(1);
              this.directorPostCodeClass = "empty";
            } else if (!/^\d{6}$/.test(this.directorPostCode)) {
              this.directorPostCodeClass = "fail";
            } else {
              console.log(2);
              this.directorPostCodeClass = "correct";
            }
        }
      } else {
        if (!this.projectName) {
          this.projectNameClass = "empty";
        } else {
          this.projectNameClass = "correct";
        }
        if (!this.subject) {
          this.subjectClass = "empty";
        } else {
          this.subjectClass = "correct";
        }
        if (!this.postCode) {
          this.postCodeClass = "empty";
        } else if (!/^\d{6}$/.test(this.postCode)) {
          this.postCodeClass = "fail";
        } else {
          this.postCodeClass = "correct";
        }
        if (!this.declareDate) {
          this.declareDateClass = "empty";
        } else {
          this.declareDateClass = "correct";
        }
        if (!this.holdMode) {
          this.holdModeClass = "empty";
        } else {
          this.holdModeClass = "correct";
        }
        if (this.holdStart) {
          if (this.holdEnd) {
            if (this.verifyholdDate()) {
              this.holdEndClass = "correct";
              this.holdStartClass = "correct";
            } else {
              this.holdEndClass = "fail";
              this.holdStartClass = "fail";
            }
          } else {
            this.holdStartClass = "correct";
            this.holdEndClass = "empty";
          }
        } else if (this.holdEnd) {
          this.holdEndClass = "correct";
          this.holdStartClass = "empty";
        } else {
          this.holdEndClass = "empty";
          this.holdStartClass = "empty";
        }
        if (!this.assessmentMethod) {
          this.assessmentMethodClass = "empty";
        } else {
          this.assessmentMethodClass = "correct";
        }
        if (!this.teachingObject) {
          this.teachingObjectClass = "empty";
        } else {
          this.teachingObjectClass = "correct";
        }
        if (!this.enrollment) {
          this.enrollmentClass = "empty";
        } else {
          this.enrollmentClass = "correct";
        }
        if (!this.allClassHours) {
          this.allClassHoursClass = "empty";
        } else {
          this.allClassHoursClass = "correct";
        }
        if (!this.theoryClassHours) {
          this.theoryClassHoursClass = "empty";
        } else {
          this.theoryClassHoursClass = "correct";
        }
        if (!this.practiceClassHours) {
          this.practiceClassHoursClass = "empty";
        } else {
          this.practiceClassHoursClass = "correct";
        }
        if (!this.grantCredit) {
          this.grantCreditClass = "empty";
        } else {
          this.grantCreditClass = "correct";
        }
        if (!this.venue) {
          this.venueClass = "empty";
        } else {
          this.venueClass = "correct";
        }
        if (!this.organizer) {
          this.organizerClass = "empty";
        } else {
          this.organizerClass = "correct";
        }
        if (!this.organizerContacts) {
          this.organizerContactsClass = "empty";
        } else {
          this.organizerContactsClass = "correct";
        }
        if (!this.organizerPhone) {
          this.organizerPhoneClass = "empty";
        } else {
          this.organizerPhoneClass = "correct";
        }
        if (!this.applicant) {
          this.applicantClass = "empty";
        } else {
          this.applicantClass = "correct";
        }
        if (!this.applicantContacts) {
          this.applicantContactsClass = "empty";
        } else {
          this.applicantContactsClass = "correct";
        }
        if (!this.applicantPhone) {
          this.applicantPhoneClass = "empty";
        } else {
          this.applicantPhoneClass = "correct";
        }
        if (!this.directorName) {
          this.directorNameClass = "empty";
        } else {
          this.directorNameClass = "correct";
        }
        if (!this.directorSex) {
          this.directorSexClass = "empty";
        } else {
          this.directorSexClass = "correct";
        }
        if (!this.directorAge) {
          this.directorAgeClass = "empty";
        } else {
          this.directorAgeClass = "correct";
        }
        if (!this.directorTitle) {
          this.directorTitleClass = "empty";
        } else {
          this.directorTitleClass = "correct";
        }
        if (!this.directorPost) {
          this.directorPostClass = "empty";
        } else {
          this.directorPostClass = "correct";
        }
        if (!this.directorQualification) {
          this.directorQualificationClass = "empty";
        } else {
          this.directorQualificationClass = "correct";
        }
        if (!this.directorAddress) {
          this.directorAddressClass = "empty";
        } else {
          this.directorAddressClass = "correct";
        }
        if (!this.directorPhone) {
          this.directorPhoneClass = "empty";
        } else {
          this.directorPhoneClass = "correct";
        }
        if (!this.directorPostCode) {
          this.directorPostCodeClass = "empty";
        } else if (!/^\d{6}$/.test(this.directorPostCode)) {
          this.directorPostCodeClass = "fail";
        } else {
          this.directorPostCodeClass = "correct";
        }
      }
    },
    // 文件上传
    fileHandler(e) {
      console.log(this.$refs.file.files);
      this.annex = this.$refs.file.files[0];
      /* 1111var data = new FormData()
      var file = this.$refs.file.files[0] // 由于时间和条件原因，这里只给小明上传一张写真照
      data.append('json[0][image]', file)
      console.log(data) */
      /* var form = new FormData()
      var file = this.$refs.file.files[0]
      form.append('file', 123)
      form.set('file', file)
      form.append('id', 123)
      console.log(form.get('file')) */
      /* for (var i = 0, len = json.length; i < len; i++) {
        data.append('json[' + i + '][name]', json[i].name)
        data.append('json[' + i + '][age]', json[i].age)
        data.append('json[' + i + '][family][father]', json[i].family.father)
        data.append('json[' + i + '][family][mother]', json[i].family.mohter)
        for (var j = 0, len2 = json[i].skills.length; j < len2; j++) {
          data.append('skills[' + i + '][' + j + ']', json[i].skills[j])
        }
      } */
      /* axios.post(api.sendJSON, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (res) {
        console.log(res)
      }).catch(function (error) {
        console.log(error)
      }) */
    },
    // 所在学科选中事件
    subjectSelect(data) {
      
      this.subject = `${data[0].text}-${data[1]}`;
      this.subjectShow = false;
      this.subjectClass = "correct";
    },
    // 限制只能输n位数字
    numberVerify(data, num) {
      if (data.length > num) data = data.substr(0, num);
      data = data.replace(/\D/g, "");
      return data;
    },
    // 申报日期选中事件
    declareDateSelect(data) {
      this.declareDate = `${data.getFullYear()}-${
        data.getMonth() + 1
      }-${data.getDate()}`;
      this.declareDateShow = false;
      this.declareDateClass = "correct";
    },
    // 举办开始时间选中事件
    holdStartSelect(data) {
      this.holdStart = `${data.getFullYear()}-${
        data.getMonth() + 1
      }-${data.getDate()}`;
      this.holdStartShow = false;
      this.holdStartDate = data;

      if (!this.holdEnd) {
        // 如果结束时间没值，提示是对的，如果有值，则需要进行比较
        this.holdStartClass = "correct";
      } else {
        if (this.verifyholdDate()) {
          this.holdEndClass = "correct";
          this.holdStartClass = "correct";
        } else {
          this.holdEndClass = "fail";
          this.holdStartClass = "fail";
        }
      }
    },
    // 举办结束时间选中事件
    holdEndSelect(data) {
      this.holdEnd = `${data.getFullYear()}-${
        data.getMonth() + 1
      }-${data.getDate()}`;
      this.holdEndShow = false;
      this.holdEndDate = data;

      if (!this.holdStart) {
        // 如果开始时间没值，提示是对的，如果有值，则需要进行比较
        this.holdEndClass = "correct";
      } else {
        if (this.verifyholdDate()) {
          this.holdEndClass = "correct";
          this.holdStartClass = "correct";
        } else {
          this.holdEndClass = "fail";
          this.holdStartClass = "fail";
        }
      }
    },
    // 验证举办开始时间和结束时间
    verifyholdDate() {
      var difference;
      var startTime = this.holdStartDate;
      var endTime = this.holdEndDate;

      startTime = startTime.toString();
      endTime = endTime.toString();
      startTime = startTime.replace(/\d{2}:\d{2}:\d{2}/, "00:00:00");
      endTime = endTime.replace(/\d{2}:\d{2}:\d{2}/, "00:00:00");
      startTime = new Date(startTime).getTime();
      endTime = new Date(endTime).getTime();
      difference = endTime - startTime;

      if (difference >= 0) {
        return true;
      } else {
        return false;
      }
    },
    // 获取当前时间，没有时分秒
    getDate() {
      var date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      console.log(date);
      return date;
    },
    // 举办开始时间和结束时间处理，获取举办天数
    getHoldDays() {
      var day;
      var startTime = this.holdStartDate;
      var endTime = this.holdEndDate;

      startTime = startTime.toString();
      endTime = endTime.toString();
      startTime = startTime.replace(/\d{2}:\d{2}:\d{2}/, "00:00:00");
      endTime = endTime.replace(/\d{2}:\d{2}:\d{2}/, "00:00:00");
      startTime = new Date(startTime).getTime();
      endTime = new Date(endTime).getTime();
      day = (endTime - startTime) / 86400000 + 1;

      if (day < 1) {
        this.holdDays = null;
      } else {
        this.holdDays = day;
      }
    },
    // 提交事件
    submit() {
      /* 标题处提示 */
      this.verify();
      /* 提交验证 */
      if (!this.projectName) {
        this.tip("请输入项目名称");
      } else if (!this.subject) {
        this.tip("请选择所在学科");
      } else if (!this.postCode) {
        this.tip("请输入邮政编码");
      } else if (!/^\d{6}$/.test(this.postCode)) {
        this.tip("请正确输入邮政编码");
      } else if (!this.declareDate) {
        this.tip("请输入申报日期");
      } else if (!this.holdMode) {
        this.tip("请输入举办方式");
      } else if (!this.holdStart) {
        this.tip("请选择举办开始时间");
      } else if (!this.holdEnd) {
        this.tip("请选择举办结束时间");
      } else {
        this.getHoldDays();
        if (!this.holdDays) {
          this.tip("举办开始时间不能晚于结束时间");
        } else if (!this.assessmentMethod) {
          this.tip("请输入考核方式");
        } else if (!this.teachingObject) {
          this.tip("请输入教学对象");
        } else if (!this.enrollment) {
          this.tip("请输入拟招生数");
        } else if (!/^\d+$/.test(this.enrollment)) {
          this.tip("请正确输入拟招生数");
        } else if (!this.allClassHours) {
          this.tip("请输入教学总学时数");
        } else if (!/^\d+$/.test(this.allClassHours)) {
          this.tip("请正确输入教学总学时数");
        } else if (!this.theoryClassHours) {
          this.tip("请输入讲授理论时数");
        } else if (!/^\d+$/.test(this.theoryClassHours)) {
          this.tip("请正确输入讲授理论时数");
        } else if (!this.practiceClassHours) {
          this.tip("请输入实验（技术示范）时数");
        } else if (!/^\d+$/.test(this.practiceClassHours)) {
          this.tip("请正确输入实验（技术示范）时数");
        } else if (!this.grantCredit) {
          this.tip("请输入拟授学员学分");
        } else if (!this.venue) {
          this.tip("请输入举办地点");
        } else if (!this.organizer) {
          this.tip("请输入主办单位");
        } else if (!this.organizerContacts) {
          this.tip("请输入主办单位联系人");
        } else if (!this.organizerPhone) {
          this.tip("请输入主办单位联系电话");
        } else if (!this.applicant) {
          this.tip("请输入申报单位");
        } else if (!this.applicantContacts) {
          this.tip("请输入申报单位联系人");
        } else if (!this.applicantPhone) {
          this.tip("请输入申报单位联系电话");
        } else if (!this.directorName) {
          this.tip("请输入负责人姓名");
        } else if (!this.directorSex) {
          this.tip("请选择负责人性别");
        } else if (!this.directorAge) {
          this.tip("请输入负责人年龄");
        } else if (!/^\d+$/.test(this.directorAge)) {
          this.tip("请正确输入负责人年龄");
        } else if (!this.directorTitle) {
          this.tip("请选择负责人职称");
        } else if (!this.directorPost) {
          this.tip("请输入负责人职务");
        } else if (!this.directorQualification) {
          this.tip("请选择负责人最高学历");
        } else if (!this.directorAddress) {
          this.tip("请输入负责人地址");
        } else if (!this.directorPhone) {
          this.tip("请输入负责人联系电话");
        } else if (!this.directorPostCode) {
          this.tip("请输入负责人邮政编码");
        } else if (!/^\d{6}$/.test(this.directorPostCode)) {
          this.tip("请正确输入负责人邮政编码");
        } else {
          console.log("通过验证");
          console.log("项目名称", this.projectName);
          console.log("所在学科", this.subject);
          console.log("邮政编码", this.postCode);
          console.log("申报日期", this.declareDate);
          console.log("举办方式", this.holdMode);
          console.log("举办开始时间", this.holdStart);
          console.log("举办结束时间", this.holdEnd);
          console.log("举办天数", this.holdDays);
          console.log("考核方式", this.assessmentMethod);
          console.log("教学对象", this.teachingObject);
          console.log("拟招生数", this.enrollment);
          console.log("教学总学时数", this.allClassHours);
          console.log("讲授理论时数", this.theoryClassHours);
          console.log("实验（技术示范）时数", this.practiceClassHours);
          console.log("拟授学员学分", this.grantCredit);
          console.log("举办地点", this.venue);
          console.log("主办方", this.organizer);
          console.log("主办方联系人", this.organizerContacts);
          console.log("主办方联系电话", this.organizerPhone);
          console.log("申报方", this.applicant);
          console.log("申报方联系人", this.applicantContacts);
          console.log("申报方联系电话", this.applicantPhone);
          console.log("负责人名字", this.directorName);
          console.log("负责人性别", this.directorSex);
          console.log("负责人年龄", this.directorAge);
          console.log("负责人职称", this.directorTitle);
          console.log("负责人职位", this.directorPost);
          console.log("负责人学历", this.directorQualification);
          console.log("负责人地址", this.directorAddress);
          console.log("负责人联系电话", this.directorPhone);
          console.log("负责人邮政编码", this.directorPostCode);
        }
      }
    },
    // 提示
    tip(text) {
      this.$toast.fail(text);
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuard", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    // 有token的情况下才显示
    if (this.$store.state.user.token || localStorage.token) this.isShow = true;
  },
};
</script>

<style lang="scss" scoped>
.header {
  .title {
    background-image: url(../../public/wx_images/cedeclare-icon-1.png);
    background-size: 0.27rem 0.27rem;
  }
}

.annex-input {
  .content {
    margin-top: 0.1rem;
    .annex-download {
      .download-button {
        display: inline-block;
        padding: 0.1rem 0.2rem;
        background-color: #95b3d7;
        border-radius: 0.05rem;
        margin-right: 0.2rem;
        color: #ffffff;
        line-height: normal;
      }
      .tip {
        color: #333333;
      }
    }
    .annex-upload {
      .upload-button {
        display: inline-block;
        padding: 0.1rem 0.2rem;
        background-color: #95b3d7;
        border-radius: 0.05rem;
        margin-right: 0.2rem;
        color: #ffffff;
        line-height: normal;
      }
      .tip {
        color: #333333;
      }
      input {
        display: none;
      }
    }
  }
}

/* 表单输入提示 */
.form {
  .title {
    position: relative;
    &::after {
      display: block;
      padding-left: 0.4rem;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 0.27rem 0.27rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .project-name-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目名称！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .subject-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请选择所在学科！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .post-code-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "邮政编码格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请输入邮政编码！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .declare-date-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请选择申报日期！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .hold-mode-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入举办方式！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .hold-start-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "举办开始时间不能晚于结束时间！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请选择举办开始时间！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .hold-end-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "举办开始时间不能晚于结束时间！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请选择举办结束时间！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .assessment-method-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入考核方式！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .teaching-object-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入教学对象！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .enrollment-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入拟招生数！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .all-class-hours-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入教学总时数！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .theory-class-hours-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入讲授理论时数！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .practice-class-hours-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入实验时数！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .grant-credit-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入拟授学员学分！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .venue-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入举办地点！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .organizer-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入主办单位！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .organizer-contacts-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入主办单位联系人！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .organizer-phone-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入主办单位联系电话！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .applicant-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入申报单位！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .applicant-contacts-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入申报单位联系人！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .applicant-phone-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入申报单位联系电话！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-name-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人姓名！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-sex-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请选择项目负责人性别！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-age-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人年龄！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-title-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请选择项目负责人职称！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-post-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人职务！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-qualification-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人最高学历！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-address-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人通讯地址！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-phone-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入项目负责人联系电话！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .director-post-code-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "邮政编码格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请输入项目负责人邮政编码！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
}
</style>
