<template>
  <div>
    <div class="music-box" @click="noteTrun">
        <!-- <img src="../assets/music.png" alt="" ref="note"> -->
        <span class="iconfont icon-yinle" ref="note" ></span>
    </div>
    <audio :src="$store.state.imageUrl+music" autoplay loop ref="audio"></audio>
    <!-- <button @click="clear" style="margin-top:400px">2222222</button> -->
  </div>
</template>

<script>
import '../assets/download/font_3812170_1n53mwl9hjy/iconfont.css'
export default {
    props:{
        music:String
    },
    data(){
        return  {
            trun:false,
            num:0,
            timer:null
        }
    },
    mounted(){
        this.noteTrun()

    },
    activated(){
        
    },
    methods:{
       
        noteTrun(){
            if(this.music==''){
                return
            }else{
                 let player=this.$refs.audio
                 this.trun=!this.trun
                 if(this.trun){
                     this.timer= setInterval(()=>{
                     this.num=this.num+8
                     if(this.$refs.note!==null){
                         this.$refs.note.style.transform = `rotate(${this.num+'deg'})`
                     }else{
                        clearInterval(this.timer)
                     }
                    
                       
                     },100)
                     player.play()
                 }else{
                      clearInterval(this.timer)
                      player.pause()
                 }
            }
        },
     
    },

}
</script>

<style scoped>
.music-box{
    width: 50px;
    height:50px;
    border-radius: 50%;
    border: 3px solid #707070;
    position: relative;
    box-sizing: border-box;
    /* margin-left: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}
.icon-yinle{
        /* display: inline-block; */
    font-size: 33px;
    color: #707070;

}
</style>