<template>
  <div>
    <div class="box">
  

      <div class="caId">
        <ul class="reverse">
          <p>身份证正面&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <van-image
            width="100"
            height="100"
            class="images"
            @click="$refs.file2.click()"
            :src="this.$store.state.imageUrl + userInfo.cardimage"
          />
        </ul>

      <ul class="reverse">
        <p>个人照片&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <van-image
          width="100"
          height="100"
          class="images"
          @click="$refs.file.click()"
          :src="this.$store.state.imageUrl + userInfo.userimage"
        />
      </ul>
        <ul class="reverse">
          <p>身份证反面&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <van-image
            width="100"
            height="100"
            class="images"
            @click="$refs.file3.click()"
            :src="this.$store.state.imageUrl + userInfo.cardSide"
          />
        </ul>
      </div>

      <van-field
        v-model="userInfo.year"
        name="出生年月"
        label="出生年月"
        placeholder="填写出生年月"
        @click="show = true"
      />
      <van-datetime-picker
        v-if="show"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="finish"
        @cancel="clear"
      />

      <van-form @submit="onSubmit">
        <van-field
          v-model="userInfo.email"
          name="电子邮箱"
          label="电子邮箱"
          placeholder="填写电子邮箱"
        />
        <van-field
          v-model="userInfo.username"
          name="姓名"
          label="姓名"
          placeholder="填写姓名"
        />
        <van-field
          v-model="userInfo.native"
          name="籍贯"
          label="籍贯"
          placeholder="填写籍贯"
        />
        <van-field
          v-model="userInfo.card"
          name="身份证"
          label="身份证"
          placeholder="填写身份证"
        />
        <!-- <nation></nation> -->
        <van-field
          v-model="userInfo.phone"
          type="text"
          name="密码"
          label="手机号"
          placeholder="填写手机号"
        />
        <van-field
          v-model="userInfo.works"
          name="工作单位"
          label="工作单位"
          placeholder="填写工作单位"
        />
        <van-field
          v-model="userInfo.education"
          name="主要学历 、经历等"
          label="主要学历 、经历等"
          type="textarea"
          placeholder="填写主要学历 、经历等"
        />
        <van-field
          v-model="userInfo.communication"
          name="通信地址"
          label="通信地址"
          placeholder="填写通请填写街道门牌号"
        />
        <van-field
          v-model="userInfo.office"
          name="办公电话"
          label="办公电话"
          placeholder="填写办公电话"
        />

        <van-radio-group
          v-model="userInfo.radio"
          direction="horizontal"
          class="radio"
          @click="a"
        >
          <van-radio name="男" icon-size="18px">男</van-radio>
          <van-radio name="女" icon-size="18px">女</van-radio>
        </van-radio-group>
        <van-field
          v-model="userInfo.administrative"
          name="所属科室"
          label="所属科室"
          placeholder="填写所属科室"
        />
        <van-field
          v-model="userInfo.deed"
          rows="2"
          autosize
          label="主要事迹、论文、技术等"
          type="textarea"
          maxlength="500"
          placeholder="请输入主要事迹、论文、技术等"
          show-word-limit
        />

        <van-field
          v-model="userInfo.Social_service"
          rows="2"
          autosize
          label="社会兼职（何年、何届、任何职）"
          type="textarea"
          maxlength="500"
          placeholder="请输入社会兼职"
          show-word-limit
        />
        <van-field
          v-model="userInfo.postal"
          name="邮政编码"
          label="邮政编码"
          placeholder="填写邮政编码"
        />

        <van-field
          v-model="showdata.provinceName"
          name="省/直辖市"
          label="省/直辖市"
          placeholder="填写省/直辖市"
          @click="area = true"
        />
        <van-area
          title="省/直辖市"
          :area-list="areaList"
          v-if="area"
          :columns-num="2"
          @confirm="success"
          @cancel="cancel"
        />
        <van-field
          v-model="showdata.cityName"
          name="市/区"
          label="市/区"
          placeholder="填写市/区"
        />
        <div class="warpper">
          <van-field
            v-model="showdata.nationName"
            name="民族"
            label="民族"
            placeholder="填写民族"
            @click="nationShow = true"
            @change="changnation"
          />
          <li class="jiao" @click="close"></li>
          <ul class="public" v-if="nationShow">
            <li
              v-for="(item, index) in nationlist"
              :key="index"
              @click="getnation(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>

        <!-- <div class="warpper">
          <van-field
            v-model="EducationName"
            name="学历"
            label="学历"
            placeholder="填写学历"
            @click="EducationShow = true"
          />
          <li class="jiao" @click="Educationclose"></li>
          <ul class="public" v-if="EducationShow">
            <li
              v-for="(item, index) in Educationlist"
              :key="index"
              @click="getEducation(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div> -->

        <div class="warpper">
          <van-field
            v-model="showdata.partyName"
            name="党派"
            label="党派"
            placeholder="填写党派"
            @click="partyShow = true"
          />
          <li class="jiao" @click="partyclose"></li>
          <ul class="public" v-if="partyShow">
            <li
              v-for="(item, index) in Partisanlist"
              :key="index"
              @click="getparty(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <van-field
          v-if="cadreShow"
          v-model="userInfo.cadre"
          name="党内职务"
          label="党内职务"
          placeholder="填写党内职务"
        />

        <div class="warpper">
          <van-field
            v-if="show1"
            v-model="showdata.technologyShowName"
            name="技术职称"
            label="技术职称"
            placeholder="选择技术职称"
            @click="technologyShow = true"
            @change="changeinput"
          />
          <li class="jiao" @click="technologyclose"></li>
          <ul class="public" v-if="technologyShow">
            <li
              v-for="(item, index) in Dutieslist"
              :key="index"
              @click="gettechnology(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <van-field
          v-if="userInfo.technology == 0"
          v-model="userInfo.technologyName"
          name="技术职称"
          label="技术职称"
          placeholder="上分类没有可填写职称"
        />
        <div class="warpper">
          <van-field
            v-if="show2"
            v-model="showdata.dutyShowName"
            name="职务"
            label="职务"
            placeholder="选择职务"
            @click="dutyShow = true"
          ></van-field>
          <li class="jiao" @click="dutyclose"></li>
          <ul class="public" v-if="dutyShow">
            <li
              v-for="(item, index) in Ranklist"
              :key="index"
              @click="getduty(item.Index)"
            >
              {{ item.Name }}
            </li>
          </ul>
        </div>
        <van-field
          v-if="userInfo.duty == 0"
          v-model="userInfo.dutyName"
          name="职务"
          label="职务"
          placeholder="上分类没有可填写职务"
        />
         <div @click.prevent="exportclick" v-if="derive==2">
           <button  class="btn">导出文件</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 点击导出生成文件
         </div>
          <div @click.prevent="deriveShow" v-if="derive==1">
           <button  class="btn">导出文件</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 点击导出生成文件
         </div>

        
        <div style="margin: 16px"></div>
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @submit="submit"
          >提交修改</van-button
        >
        <input type="file" ref="file" @change="onFile" hidden />
        <input type="file" ref="file2" @change="onFile2" hidden />
        <input type="file" ref="file3" @change="onFile3" hidden />
        <!-- <van-button @click="onFile">提交</van-button> -->
      </van-form>
      <!-- 弹出框 -->
      
     <div class="mask" v-if="showdocx"></div>
<div class="hint-box" v-if="showdocx">
   <img src="../../public/wx_images/toal.png" alt="" class="imgurl">
   <p class="option">
     <span @click="showdocx=false">确认</span>
   </p>
</div>
    </div>
  </div>
</template>

<script>
// import nation from "@/views/committee/components/nation.vue";
// import basicsInfo from "@/views/committee/components/basicsInfo.vue";
// import { Dialog } from 'vant';
import { areaList } from "@vant/area-data";
import axios from "axios";
// 引入导出word方法
import {getBase64Sync, exportWord} from '@/utlis/exportclick.js'
export default {

  data() {
    return {
      imgUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F0G020114924%2F200G0114924-15-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1657615606&t=f99a086a28b96cdb70debdfe33ed8c2f',
      derive:localStorage.getItem('derive'),
      showdocx:false,
      Personallist: [],
      nationlist: [],
      Partisanlist: [],
      Dutieslist: [],
      Ranklist: [],
      Educationlist: [],
      accountData: JSON.parse(localStorage.getItem("accountData")),

      show3: false,
      userInfo: {
        // 社会兼职
        Social_service:"",
        // 推荐码识别id
        Id: 0,
        ReferralCode: this.$route.params.ReferralCode,
        //  党内职务
        cadre: "",
        // 省
        province: 0,
        // 市
        city: 0,
        // 学历
        // 性别
        radio: "",
        // 电子邮箱
        email: "",
        // 民族索引
        nation: 0,
        // 姓名
        username: "",
        // 籍贯
        native: "",
        // 身份证
        card: "",
        // 手机号
        phone: "",
        //   党员
        party: 0,

        // 工作单位
        works: "",
        // 主要学历经验
        education: "",
        // 通信2地址
        communication: "",
        // 办公电话
        office: "",
        // 科室
        administrative: "",
        // 邮政编码
        postal: "",
        // 主要事迹
        deed: "",
        //   技术称职
        technology: 0,
        technologyName: "",
        // 职务
        duty: 0,
        dutyName: "",
        // 出生日
        year: "",
        // 个人照片
        userimage: "",
        //  正面身份证
        cardimage: "",
        //  背面身份证
        cardSide: "",
      },
      showdata: {
        nationName: "汉族",
        partyName: "无",
        technologyShowName: "",
        dutyShowName: "",
        EducationName: "中专",
        provinceName: "",
        cityName: "",
        // 个人照片
        userurl: "",
        //  正面身份证
        cardurl: "",
        //  背面身份证
        cardSideurl: "",
      },
      userphoto: "",
      show: false,
      minDate: new Date(1950, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1980, 1, 1),
      areaList,
      area: false,
      EducationShow: false,
      cadreShow: false,
      dutyShow: false,
      technologyShow: false,
      partyShow: false,
      nationShow: false,
      odd: true,
      show1: true,
      show2: true,
    };
  },

  created() {
    //   赋值
 
    this.nationlist = this.accountData.Nation;

    this.Partisanlist = this.accountData.Partisan;
    this.Dutieslist = this.accountData.Duties;
    this.Ranklist = this.accountData.Rank;
    this.Educationlist = this.accountData.Education;
    // console.log(this.$route.params.ReferralCode);
    this.ReferralCode = this.$route.params.ReferralCode;
    this.init();
    this.provincinit();
    this.changeinput();
    this.plan();
  
  },

  methods: {
    deriveShow(){
this.showdocx=true
localStorage.setItem('derive',2)
this.derive=localStorage.getItem('derive')
    },
    // 处理导出四个索引
    plan() {
      if (this.Personallist[0].duty == 0) {
        this.showdata.dutyShowName = this.userInfo.dutyName;
      }
      if (this.Personallist[0].technology == 0) {
        this.showdata.technologyShowName = this.userInfo.technologyName;
      }
    },
    provincinit() {
      for (let key in this.areaList.province_list) {
        if (this.userInfo.province == key) {
          this.showdata.provinceName = this.areaList.province_list[key];
        }
        // console.log(key+':'+this.areaList.province_list[key]);
      }
      for (let key in this.areaList.city_list) {
        if (this.userInfo.city == key) {
          this.showdata.cityName = this.areaList.city_list[key];
        }
      }
    },

    init() {
      this.Personallist = this.accountData.Personal;
   
      const index3 = this.Personallist[0].nation;

      this.showdata.nationName = this.nationlist[index3].Name;
      const index2 = this.Personallist[0].technology;
      this.showdata.technologyShowName = this.Dutieslist[index2].Name;
      const index1 = this.Personallist[0].duty;

      this.showdata.dutyShowName = this.Ranklist[index1].Name;
      this.userInfo.administrative = this.Personallist[0].administrative;
      this.userphoto = this.$store.state.imageUrl + this.Personallist[0].userimage;
      // 我当时是真傻，用了最土狗的方法,,不敢改了
      this.userInfo.card = this.Personallist[0].card;
      this.userInfo.technologyName = this.Personallist[0].technologyName;
      this.userInfo.dutyName = this.Personallist[0].dutyName;
      this.userInfo.Social_service = this.Personallist[0].Social_service;
      this.userInfo.party = this.Personallist[0].party;
      this.userInfo.duty = this.Personallist[0].duty;
      this.userInfo.technology = this.Personallist[0].technology;
      this.userInfo.nation = this.Personallist[0].nation;
      this.userInfo.communication = this.Personallist[0].communication;
      this.userInfo.deed = this.Personallist[0].deed;
      this.userInfo.education = this.Personallist[0].education;
      this.userInfo.email = this.Personallist[0].email;
      this.userInfo.native = this.Personallist[0].native;
      this.userInfo.office = this.Personallist[0].office;
      this.userInfo.phone = this.Personallist[0].phone;
      this.userInfo.postal = this.Personallist[0].postal;
      this.userInfo.username = this.Personallist[0].username;
      this.userInfo.year = this.Personallist[0].year;
      this.userInfo.city = this.Personallist[0].city;
      this.userInfo.works = this.Personallist[0].works;
      this.userInfo.radio = this.Personallist[0].radio;
      this.userInfo.userimage = this.Personallist[0].userimage;
      this.userInfo.cardSide = this.Personallist[0].cardSide;
      this.userInfo.cardimage = this.Personallist[0].cardimage;
      this.userInfo.province = this.Personallist[0].province;
      this.userInfo.Id = this.Personallist[0].Id;
      //   出生日期的处理
      this.userInfo.year = this.userInfo.year.replace("0:00:00", "");
    },
    async Updata() {
      var json = this.userInfo;

      var res = await axios({
        method: "post",
        url:
          this.$store.state.apiUrl +
          "/actionapi/TencentApi/UpdateCcouncilReferralInfo",
        data: json,
      });
      try {
     
        localStorage.removeItem("accountData");
        this.$toast.success(res.data.Msg);
      } catch (err) {
        console.log(err);
      }
    },
    async onFile() {
      const file = this.$refs.file.files[0];
      var formData = new FormData();
      formData.append("file", file);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
    
        this.showdata.userurl = this.$store.state.imageUrl + res.data;
      
        this.userInfo.userimage = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async onFile2() {
      const file2 = this.$refs.file2.files[0];
      var formData = new FormData();
      formData.append("file2", file2);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
        this.showdata.cardurl = this.$store.state.imageUrl + res.data;
        this.userInfo.cardimage = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async onFile3() {
      const file3 = this.$refs.file3.files[0];
      var formData = new FormData();
      formData.append("file3", file3);
      const res = await axios({
        method: "post",
        url: "/tools/upload_ajax.ashx?action=ImgUp",
        data: formData,
      });
      try {
        this.showdata.cardSideurl = this.$store.state.imageUrl + res.data;
        this.userInfo.cardSide = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    showPopup() {
      this.show3 = true;
    },
    //   循环找到拿到name赋值到输入框
    getnation(index) {
      this.nationlist.forEach((item, i) => {
        if (index == item.Index) {
          this.userInfo.nation = item.Index;
      
          this.showdata.nationName = item.Name;
        }
      });
      this.nationShow = false;
      //  console.log(this.userInfo.userimage);
    },
    changeinput() {
      if (this.userInfo.technologyName !== "") {
        this.show1 = false;
      } else {
        this.show1 = true;
      }
      if (this.userInfo.dutyName !== "") {
        this.show2 = false;
      } else {
        this.show2 = true;
      }
    },
    getparty(index) {
      this.Partisanlist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.party = item.Index;
          this.showdata.partyName = item.Name;
        }
      });
      if (index == 0) {
        this.cadreShow = false;
      } else {
        this.cadreShow = true;
      }
      this.partyShow = false;
    },
    gettechnology(index) {
      this.Dutieslist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.technology = item.Index;
          this.showdata.technologyShowName = item.Name;
        }
      });
      this.technologyShow = false;
    },
    getduty(index) {
      this.Ranklist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.duty = item.Index;

          this.showdata.dutyShowName = item.Name;
        }
      });

      this.dutyShow = false;
    },
    getEducation(index) {
      this.Educationlist.forEach((item, i) => {
        if (index == i) {
          this.userInfo.Education = item.Index;
          this.showdata.EducationName = item.Name;
        }
      });

      this.EducationShow = false;
    },

    // 这个组件拿到的是标准时间，需要处理成2022-5-25格式的
    finish(value) {
      const resDate =
        this.currentDate.getFullYear() +
        "-" +
        this.p(this.currentDate.getMonth() + 1) +
        "-" +
        this.p(this.currentDate.getDate());
      this.userInfo.year = resDate;
      this.show = false;
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    clear() {
      this.show = false;
    },
    onSubmit(values) {
      this.getnation();
      this.Updata();

  
    },
    // 选择地区点击确定时的事件
    success(array) {
      this.userInfo.province = array[0].code;
      this.userInfo.city = array[1].code;
      var region = [];
      array.forEach((item) => {
  
        region.push(item.name);
      });
      const str = region.splice(1, 1);
      this.showdata.provinceName = region.join("");
      this.showdata.cityName = str.join("");
      this.area = false;
    },
    cancel() {
      this.area = false;
    },
    // 这是关闭选择的
    close() {
      if (this.nationShow == false) {
        this.nationShow = true;
      } else {
        this.nationShow = false;
      }
    },

    partyclose() {
      if (this.partyShow == false) {
        this.partyShow = true;
      } else {
        this.partyShow = false;
      }
    },
    technologyclose() {
      if (this.technologyShow == false) {
        this.technologyShow = true;
      } else {
        this.technologyShow = false;
      }
    },
    dutyclose() {
      if (this.dutyShow == false) {
        this.dutyShow = true;
      } else {
        this.dutyShow = false;
      }
    },
    Educationclose() {
      if (this.EducationShow == false) {
        this.EducationShow = true;
      } else {
        this.EducationShow = false;
      }
    },
 


    	async  exportclick (){
			let  data= {
        // 需要转的数据
          ...this.userInfo,
          ...this.showdata,
          imgUrl: await getBase64Sync(this.userphoto),
        
		 }


        let imgSize = {
            imgUrl:[93, 120], //控制导出的word图片大小
        };
        exportWord("/Template.docx", data, `委员申请表-${this.userInfo.username}.docx`, imgSize);
        //参数1：模板文档 
        //参数2：字段参数
        //参数3：输出文档
        //参数4：图片大小
        }
 
  },
};
</script>

<style lang="scss" >
.btn{
  margin-top: 0.4rem;
  margin-left: 0.25rem;
  margin-bottom: 0.4rem;
  width: 100px;
  height: 30px;
  background: #95b3d7;
  color: white;
}
.front {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-right: 50px;
}
.reverse {
  width: 90%;
  display: flex;
  // justify-content: space-around;
  margin-top: 5px;
  margin-left: 24px;
}
.ger {
  margin-top: 20px;
  // margin-left: 50px;
  width: 88%;
  display: flex;
  // margin-left: 16px;
  justify-content: space-around;
  // margin-right: 59px;
}
.caId {
  margin: 20px 0 20px -0.2rem;
  // display: flex;

  // justify-content: space-around;
}
.box {
  padding: 0 20px 30px 20px;
}
.radio {
  // margin-left: 15px;
  margin: 10px 0 10px 15px;
}
.textarea {
  margin-top: 10px;
  margin-left: 15px;
}
textarea {
  color: #ccc;
}
.public {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  width: 150px;
  z-index: 99;
  background: white;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding-top: 10px;

  li {
    text-align: center;
    // margin: 0px 10px 5px 10px;
    height: 25px;
    width: 100%;
    overflow: hidden;
    line-height: 25px;
  }
  li:hover {
    background: pink;
  }
}
.jiao {
  width: 15px;
  height: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  box-sizing: border-box;
  border: 10px solid transparent;
  border-top-color: rgb(238, 236, 236);
}
.warpper {
  position: relative;
}
.van-button {
  color: white;
  width: 5.3rem;
  margin: 0 auto;
  background: #1989fa;
}
.mask{
  width: 108%;
  height: 14rem;
  background: black;
  opacity: .3;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
 
}
.imgurl{

  // transform: translateX(-50%);
  margin-top: 10px;
  margin-left: 10px;
  opacity: 1;
width: 95%;
z-index: 999;
}
.hint-box{
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 0.34rem;
  transform: translateY(-50%);
  width: 90%;
  height: 8rem;
  background: white;
  z-index: 10000;
    margin: 0 auto;
}
.option{
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  span{
    font-size: 0.3rem;
    color: #f23f53;
  }
}

.images{
  margin-left: 24px;
}
</style>
