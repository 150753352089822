<template>
  <div>
    <ul>
      <li v-for="(k,index) in data" :key="index" class="item">
        <p>
          姓名：{{k.name}}
        </p>
        <p>
          职务：{{k.zw}}
        </p>
        <p>
          电话：{{k.dh}}
        </p>
        <p style="width:100%">
          地址：{{k.dz}}
        </p>
        <p style="width:100%">
          介绍：{{k.js}}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  // 我的名片
  data() {
    return {
      data: [
        {
          name: "高启强",
          zw: "会计",
          dh: "1389345678",
          dz: "海珠区新港东路启盛产业园108",
          js: " 通过使用微信JS-SDK，网页开发者可借助微信高效地使用拍照、选图、语音、位置等手机系统的能力，同时可以直接使用微信分享、扫一扫、卡券、支付等微信特有的能力，为微信用户提供更优质的网页体验。",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
  .item{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 20px auto;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%);
    color: white;
  }
  p{
    width: 45%;
    margin-top: 3px;
  }
</style>
