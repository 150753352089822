<template>
  <div class="meeting-traffic" v-if="this.destination.address">
    <Popups></Popups>
    <!-- 地图容器 -->
    <div id="map"></div>
    <div class="mask" v-if="show">
      <p>参会详细地址: {{ destination.address }}</p>
    </div>
    <!-- 搜索列表容器及按钮 -->
    <div
      id="place-container"
      :class="isHidden ? 'hidden' : ''"
      v-show="searchBegin"
    >
      <div id="place"></div>
      <i class="button" @click="isHidden = !isHidden"></i>
    </div>
    <!-- 线路规划容器 -->
    <div id="trans" ref="trans"></div>
    <!-- 功能按钮 -->
    <div id="button-list">
      <div class="destination-button button-item">
        <label class="destination-label button-label">
          <input
            class="input"
            type="checkbox"
            v-model="showMarker"
            @change="markerChange"
          />
          <div class="button" :class="{ on: showMarker }">会场</div>
        </label>
      </div>
      <div class="train-button button-item">
        <label class="train-label button-label" @click="input">
          <input
            class="input"
            type="radio"
            v-model="searchTarget"
            value="train"
            @change="searchChange"
          />
          <div class="button" :class="{ on: searchTarget == 'train' }">
            火车站
          </div>
        </label>
      </div>
      <div class="airport-button button-item">
        <label class="airport-label button-label" @click="input">
          <input
            class="input"
            type="radio"
            v-model="searchTarget"
            value="airport"
            @change="searchChange"
          />
          <div class="button" :class="{ on: searchTarget == 'airport' }">
            飞机场
          </div>
        </label>
      </div>
      <div class="traffic-reference-button button-item" v-if="trafficReference">
        <label
          class="traffic-reference-label button-label"
          @click="referenceShow = true"
        >
          <div class="button" :class="{ on: referenceShow }">交通参考</div>
        </label>
      </div>
    </div>
    <!-- 交通参考（弹窗） -->
    <van-popup
      v-model:show="referenceShow"
      :style="{
        width: '80%',
        height: '80%',
        padding: '40px 0.35rem',
        boxSizing: 'border-box',
      }"
      closeable
      round
    >
      <p class="text" v-html="trafficReference"></p>
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  setup() {
    const map = shallowRef(null);
    return {
      map,
    };
  },
  data() {
    return {
      // key和密钥
      key: "a231e8e64ba11e5d415a93169487620a",
      cipher: "ebec5d9071d7f460361010fff7443d96",
      // 会场信息
      show: true,
      destination: {
        name: null,
        city: null,
        address: null /* '广州市海珠区阅江西路222号' */,
        longitude: null,
        latitude: null,
      },
      // 交通参考
      referenceShow: false,
      trafficReference: null,
      // 会场标记对象
      marker: null,
      // 会场标记图标
      icon: require("../../public/wx_images/meetingtraffic-icon-1.png"),
      // 会场标记是否显示
      showMarker: true,
      // 检索对象
      placeSearch: null,
      // 检索对象
      searchTarget: null,
      // 公交路线
      trans: null,
      // 搜索状态开始，决定搜索列表容器是否隐藏
      searchBegin: false,
      // 是否收起搜索列表
      isHidden: false,
    };
  },
  methods: {
    // 请求地址，参数为会议id
    requestAddress(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=" +
            id +
            "&Field=site"
        )
        .then((res) => {
   
          if (res.data.Data.length) {
            // DOM初始化完成进行地图初始化，如果有地址的话
            if (res.data.Data[0].site) {
              this.destination.address = res.data.Data[0].site;
           
              this.initMap();
            }
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
     
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 请求交通方式数据，参数为会议id
    requestTrafficWay(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=" +
            id +
            "&Field=traffic_way"
        )
        .then((res) => {
          if (res.data.Data.length) {
            // 赋值
         
            if (res.data.Data[0].traffic_way) {
              /* this.trafficReference = res.data.Data[0].traffic_way */
              var html = res.data.Data[0].traffic_way;
              html = html.replace(/\n/g, "<br>");
              this.trafficReference = html;
            }
          } else {
            console.log("获取数据为空");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 初始化
    initMap() {
      // 密钥设置，此为静态不安全
      window._AMapSecurityConfig = {
        securityJsCode: this.cipher,
      };
      // 初始化
      AMapLoader.load({
        key: this.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.PlaceSearch", "AMap.Transfer"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("map", {
            // 设置地图容器id
            zoom: 18 /* , // 初始化地图级别
          center: [x, y] */, // 初始化地图中心点位置
            // center:[116,32]
          });
          this.getDestination(); // 调用获取会场信息方法
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取会场信息
    getDestination() {
      // eslint-disable-next-line no-undef
      AMap.plugin(["AMap.PlaceSearch"], () => {
        // eslint-disable-next-line no-undef
        var placeSearch = new AMap.PlaceSearch({
          extensions: "all", // 返回信息详略，默认为base（基本信息）
        });
        // 关键字查询
     
        placeSearch.search(this.destination.address, (status, result) => {
        
          if (status === "complete") {
            // 有可能目的地不是搜索结果的第一个，我提升一下准确度
            var arr = []; // 名称数组
            var index = 0; // 最佳索引
            var correctRate = 0; // 每个地点的正确率
            var maxCorrectRate = 0; // 最高的正确率
            result.poiList.pois.forEach((item) => {
              arr.push(item.name);
              // console.log(item.name);
            });
            arr.forEach((item, _index) => {
    
              correctRate = 0;
              for (var i = 0; i < item.length; i++) {
                // 查询关键词里面有没有这个字符（个人认为 括号 没必要搜索）
                if (!/[()]/.test(item[i])) {
                  if (this.destination.address.includes(item[i])) correctRate++;
                }
              }
              // 如果正确率大于最高正确率，刷新最高正确率
              if (correctRate > maxCorrectRate) {
                index = _index;
                maxCorrectRate = correctRate;
              }
            });
            // 搜索成功设置会场的城市和经纬度
            if (!this.destination.city) {
   
              this.destination.name = result.poiList.pois[0].name;
              this.destination.city = result.poiList.pois[0].cityname;
              this.destination.longitude =
                result.poiList.pois[0].location.lng;
              this.destination.latitude =
                result.poiList.pois[0].location.lat;
            }

            this.createMarker(); // 创建会场标记
          } else {
            console.log("查询失败");
          }
        });
      });
    },
    // 创建会场标记
    createMarker() {
      // eslint-disable-next-line no-undef
      this.marker = new AMap.Marker({
        content: `<div class="icon" style="width : 22px ; font-size : 0 ; position : relative">
                   <img class="img" src="${this.icon}" alt="" style="max-width : 100% !important"/>
                   <span style="padding : 0 5px ; background-color : #FFFFFF ; box-shadow : 0 2px 6px 0 rgb(114 124 245 / 50%) ; border-radius : 3px ; color : #333333 ; font-size : 14px ; white-space : nowrap ; position : absolute ; top : calc(100% + 5px) ; left : 50% ; transform : translateX(-50%)" ;>${this.destination.address}</span>
                 </div>`,
        position: [this.destination.longitude, this.destination.latitude],
        anchor: "bottom-center",
      });
      // 放置标记
      this.marker.setMap(this.map);
      // 设置地图中心点
      this.map.setCenter([
        this.destination.longitude,
        this.destination.latitude,
      ]);
    },
    // 删除会场标记
    deleteDestination() {
      this.marker.remove();
      this.marker = null;
    },
    // 搜索附近火车站
    searchTrain() {
      if (!this.searchBegin) {
        this.searchBegin = true; // 显示搜索列表容器
      } else {
        this.isHidden = false; // 点击搜索后应该为展开状态
      }
      if (this.trans) this.trans.clear(); // 清空现有路线
      if (this.placeSearch) this.clearMarker(); // 清理地图上的标记，主要是因为火车站和机场切换
      // eslint-disable-next-line no-undef
      AMap.plugin(["AMap.PlaceSearch"], () => {
        // 构造地点查询类
        // eslint-disable-next-line no-undef
        this.placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          city: this.destination.city, // 限制城市
          citylimit: true, // 是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          panel: "place", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        // 关键字查询
        this.placeSearch.search("火车站", function (status, result) {});
      });
      // 添加列表点击事件
      // eslint-disable-next-line no-undef
      AMap.Event.addListener(this.placeSearch, "listElementClick", (e) => {
        console.log(e.data.location.lng, e.data.location.lat); // 当前marker的经纬度信息
        this.transPlan(e.data.location.lng, e.data.location.lat); // 调用线路规划方法
      });
      // 添加列表点击事件
      // eslint-disable-next-line no-undef
      AMap.Event.addListener(this.placeSearch, "markerClick", (e) => {
        console.log(e.data.location.lng, e.data.location.lat); // 当前marker的经纬度信息
        this.transPlan(e.data.location.lng, e.data.location.lat); // 调用线路规划方法
      });
    },
    // 搜索附近飞机场
    searchAirport() {
      if (!this.searchBegin) {
        this.searchBegin = true; // 显示搜索列表容器
      } else {
        this.isHidden = false; // 点击搜索后应该为展开状态
      }
      if (this.trans) this.trans.clear(); // 清空现有路线
      if (this.placeSearch) this.clearMarker(); // 清理地图上的标记，主要是因为火车站和机场切换
      // eslint-disable-next-line no-undef
      AMap.plugin(["AMap.PlaceSearch"], () => {
        // 构造地点查询类
        // eslint-disable-next-line no-undef
        this.placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          city: this.destination.city, // 限制城市
          citylimit: true, // 是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          panel: "place", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        // 关键字查询
        this.placeSearch.search("飞机场", function (status, result) {});
      });
      // 添加列表点击事件
      // eslint-disable-next-line no-undef
      AMap.Event.addListener(this.placeSearch, "listElementClick", (e) => {
        console.log(e.data.location.lng, e.data.location.lat); // 当前marker的经纬度信息
        this.transPlan(e.data.location.lng, e.data.location.lat); // 调用线路规划方法
      });
      // 添加列表点击事件
      // eslint-disable-next-line no-undef
      AMap.Event.addListener(this.placeSearch, "markerClick", (e) => {
        console.log(e.data.location.lng, e.data.location.lat); // 当前marker的经纬度信息
        this.transPlan(e.data.location.lng, e.data.location.lat); // 调用线路规划方法
      });
    },
    // 启用线路规划
    transPlan(lng, lat) {
      if (this.$refs.trans.innerHTML) this.$refs.trans.innerHTML = ""; // 清空线路列表
      if (this.trans) this.trans.clear(); // 清空现有路线
      // 每次点击地点列表都查询公交线路，在this.trans里面没有找到两个地点的坐标，先不做return，要做也可以，但保留还是有用处的例如不知道滑到哪里，点一下刚刚点得列表可以回来
      // eslint-disable-next-line no-undef
      AMap.plugin(["AMap.Transfer"], () => {
        // 加载公交换乘插件
        var transOptions = {
          city: this.destination.city, // 公交城市
          map: this.map,
          panel: "trans",
          // eslint-disable-next-line no-undef
          policy: AMap.TransferPolicy.LEAST_TRANSFER, // 乘车策略
        };
        // eslint-disable-next-line no-undef
        this.trans = new AMap.Transfer(transOptions); // 构造公交换乘类
        // eslint-disable-next-line no-undef
        AMap.Event.addListener(this.trans, "complete", (res) => {}); // 返回导航查询结果
        // eslint-disable-next-line no-undef
        AMap.Event.addListener(this.trans, "error", function (e) {
          alert(e.info);
        }); // 返回错误信息
        // 根据起点和终点发起查询
        // eslint-disable-next-line no-undef
        this.trans.search(
          new AMap.LngLat(lng, lat),
          new AMap.LngLat(this.destination.longitude, this.destination.latitude)
        );
      });
    },
    // 清除火车站或者飞机场的标记
    clearMarker() {
      this.placeSearch.clear();
    },
    // 会场按钮事件
    markerChange() {
      this.show = true;
      if (this.showMarker && !this.marker) {
        this.createMarker(); // 创建
      } else if (this.marker) {
        this.deleteDestination(); // 删除
      }
    },
    // 搜索火车站或者飞机场按钮事件
    searchChange() {
      this.show = false;
      if (this.searchTarget === "train") {
        this.searchTrain(); // 搜索火车站
      } else if (this.searchTarget === "airport") {
        this.searchAirport(); // 搜索飞机场
      }
    },
  },
  mounted() {
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });

    this.requestAddress(this.$route.params.id); // 调用请求地址方法
    this.requestTrafficWay(this.$route.params.id); // 调用请求交通方式方法
  },
};
</script>

<style lang="scss" scoped>
@import "../../public/css/traffic.css"; // 修改地图样式

.meeting-traffic {
  width: 100%;
  max-width: 6.4rem;
  height: calc(100% - 0.6rem);
  position: absolute;
  overflow: hidden;
  #map {
    height: 50%;
  }
  #place-container {
    max-width: 68%;
    width: 280px;
    height: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    z-index: 1;
    #place {
      width: 100%;
      height: 100%;
    }
    .button {
      display: block;
      width: 20px;
      height: 40px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-image: url(../../public/wx_images/meetingtraffic-icon-2a.png);
      background-position: center center;
      background-size: 30px 30px;
      border: solid 1px #e8e8e8;
      border-right: none;
      border-radius: 5px 0 0 5px;
      box-sizing: border-box;
      box-shadow: -2px 2px 6px 0 rgb(114 124 245 / 20%);
      position: absolute;
      left: -19px;
      top: 50%;
      transform: translateY(-50%);
    }
    &.hidden {
      left: 100%;
      .button {
        background-image: url(../../public/wx_images/meetingtraffic-icon-2b.png);
      }
    }
  }
  #trans {
    height: 50%;
    overflow-y: scroll;
    .amap-call {
      display: none;
    }
  }
  #button-list {
    position: absolute;
    left: 5px;
    top: 0;
    .button-item {
      margin-top: 5px;
      .button-label {
        .input {
          display: none;
        }
        .button {
          padding: 4px 5px;
          background-color: #ffffff;
          border-radius: 5px;
          box-shadow: 0 2px 6px 0 rgb(149 179 215 / 60%);
          font-size: 14px;
          text-align: center;
          transition: 0.2s;
          &.on {
            background-color: #95b3d7;
            color: #ffffff;
          }
        }
      }
    }
    .destination-button {
      .destination-label {
        .button {
          text-indent: 4px;
          letter-spacing: 4px;
        }
      }
    }
  }
}
.mask {
  width: 300px;
  height: 1.5rem;
  background: white;
  margin: 20px auto;
}
</style>
