import { createStore } from 'vuex'

export default createStore({
  state: {
    // 打包这样，img为空api.gdhealth.org.cn
  // apiUrl: 'http://api.gdhealth.org.cn',
  apiUrl: 'http://api.ya2h.com',
  // apiUrl: 'http://192.168.3.132:9902/WebApi',
  // 拿到的图片路径，拼接的地址
  // imageUrl: 'http://gdhealth.org.cn',
  imageUrl: '',
  // imageUrl: '',
    // 账号信息
    // 活动底部切换
    showtab:0,
    user: {
      user: null,
      token: null,
      openid: null
    },
    menudisplay:{
      show:false,
    },
    routeArry:[],
    // 右上角导航栏的显示
    ListShow:{},
    meetingInfo:null,
    // tabbar
    tabbar: {
      title: [],
      show: true
    },
    // navbar
    navbar: {
      trigger: false,
      mainTitle: '',
      backTitle: ''
    },
    // 滚动条离顶部距离（四个数据缓存的页面需要，例如从详情页返回，滚动条跟详情页的位置，不太友好）
    
    scrollTop: { 
      about: 0,
      branchNews: 0,
      meeting: 0,
      news: 0
    },
    // 跳转历史
    history: [],
    // 公众号栏目菜单
    menu: [],
    // 支付订单详情，因为数据在请求列表时就已经拿到了
    meetingPayDetail: null,
    // 参会报名信息详情，因为数据在请求列表时就已经拿到了
    meetingRegDetail: null,
    // 酒店预订信息详情，因为数据在请求列表时就已经拿到了
    meetingHotelDetail: null,
        // 底部索引
        IndexNumber:''
  },
  mutations: {
    // 加载弹窗
    loadHandler (state, data) {
      if (data.boolean) {
        data._this.$toast.loading({
          message: data.text || '加载中...',
          forbidClick: true,
          duration: 0
        })
      } else {
        data._this.$toast.clear()
      }
    },
    // 提示弹窗
    tipHandler (state, data) {
      data._this.$toast.clear()
      data._this.$toast.loading({
        type: data.type,
        message: data.text || '提示',
        forbidClick: true,
        duration: 1500,
        onClose: () => {
          if (data.url) data._this.$router.push(data.url)
        }
      })
    },
    getRouteArry(state,data){
      state.routeArry=data
      // console.log(state.routeArry);
    },
    getListShow(state,data){
      state.ListShow.ListShow=data.ListShow
      state.ListShow.ListImgShow=data.ListImgShow
      state.ListShow.ListStylePosition=data.ListStylePosition
      // console.log(state.ListShow);
    },
    getMeeing(state,data){
      
      data.start_tiem=data.start_tiem.replace('T',' ')
      data.end_tiem=data.end_tiem.replace('T',' ')
      state.meetingInfo=data
      localStorage.setItem('img_url',data.img_url)
      // console.log(data.img_url);
    },
    // navbar点击了返回
    returnHandler (state, _this) {
      state.navbar.trigger = true
      // 支付宝支付完跳转到回调后按返回是支付宝，需要存一组历史记录，判断历史记录长度大于1就读取历史记录返回
      if (state.history.length > 1) {
        _this.$router.push(state.history[state.history.length - 2].path)
      } else {
        _this.$router.back()
      }
    },
    // menu赋值，还有tabbar标题
    modifyMenu (state, arr) {
      console.log(arr);
      arr.push({
        ID:'66',
        Name:'个人中心',
        Code:'person',
        LinkUrl:'person'
      })
      state.menu = arr

      arr.forEach((item, index) => {
        state.tabbar.title[index] = item.Name
      })
    },

    publicIndex(state,data){
      state.IndexNumber=data
    },
    // 储存账号信息 用户名和token
    storeUserInfo (state, data) {
      state.user.user = data.user
      state.user.token = data.token
    },
    // 储存账号信息 openid
    storeUserOpenid (state, openid) {
      state.user.openid = openid
      localStorage.openid = openid
    },
    // 记录跳转历史，根据 navTrigger 判断前进还是后退
    recordHistory (state, data) {
      if (state.navbar.trigger) {
        // 删
        var _index = null
        state.history.forEach((item, index) => {
          if (data.path === item.path) _index = index
        })
        state.history.splice(_index + 1)
        _index = null
        // 设
        state.navbar.mainTitle = state.history[state.history.length - 1].title
        if (state.history.length < 2 || data.path === '/login') {
          state.navbar.backTitle = '返回'
        } else {
          state.navbar.backTitle = state.history[state.history.length - 2].title
        }
        // 把路由后退的状态改回去
        state.navbar.trigger = false
      } else if (!state.history.length || state.history[state.history.length - 1].path !== data.path) {
        // 存
        state.history.push({
          path: data.path,
          title: data.title
        })
        // 设
        state.navbar.mainTitle = state.history[state.history.length - 1].title

        if (state.history.length < 2 || data.path === '/login') {
          state.navbar.backTitle = '返回'
        } else {
          state.navbar.backTitle = state.history[state.history.length - 2].title
        }
      }
    },
    // 设置navbar标题（分会新闻列表需要）
    setNavbarTitlle (state, title) {
      state.navbar.mainTitle = title
    },
    // 存储历史纪录到 sessionStorage
    setSessionStorage (state, data) {
      var json
      if (data && data.type === 'meetingperson') {
        // 跳转到会议个人中心，造的假历史
        state.history = [
          {
            path: '/meeting',
            title: '学术会议'
          },
          {
            path: '/meetingcenter/' + data.meetingId,
            title: '会议中心'
          },
          {
            path: '/meetingperson/' + data.meetingId,
            title: '个人中心'
          },
          {}
        ]
      } else if(data && data.type === 'meetingreglist'){
        state.history = [
          {
            path: '/meeting',
            title: '学术会议'
          },
          {
            path: '/meetingcenter/' + data.meetingId,
            title: '会议中心'
          },
          {
            path: '/meetingreglist/' + data.meetingId,
            title: '个人中心'
          },
          {}
        ]
      } else if (data && data.type === 'meetingpaylist') {
        // 跳转到会议订单列表，造的假历史
        state.history = [
          {
            path: '/meeting',
            title: '学术会议'
          },
          {
            path: '/meetingcenter/' + data.meetingId,
            title: '会议中心'
          },
          {
            path: '/meetingperson/' + data.meetingId,
            title: '个人中心'
          },
          {
            path: '/meetingpaylist/' + data.meetingId,
            title: '支付订单记录'
          },
          {}
        ]
      } else if (data && data.type === 'meetingcenter') {
        // 会议中心，造的假历史
        state.history = [
          {
            path: '/meeting',
            title: '学术会议'
          },
          {
            path: '/meetingcenter/' + data.meetingId,
            title: '会议中心'
          }
        ]
      } else {
        json = JSON.stringify(state.history)
        sessionStorage.history = json
      }
    },
    // 历史纪录找回，帅
    retrospectHistory (state) {
      var arr = JSON.parse(sessionStorage.history)
      // 存
      state.history = arr
      // 设
      state.navbar.mainTitle = state.history[state.history.length - 1].title

      if (state.history.length < 2 || state.history[state.history.length - 1].path === '/login') {
        state.navbar.backTitle = '返回'
      } else {
        state.navbar.backTitle = state.history[state.history.length - 2].title
      }

      sessionStorage.history = ''
    },
    // 设置会议支付订单详情
    setMeetingPayDetail (state, data) {
      state.meetingPayDetail = data
    },
    // 设置参会报名信息详情
    setMeetingRegDetail (state, data) {
      state.meetingRegDetail = data
    },
    // 设置酒店预订信息详情
    setMeetingHotelDetail (state, data) {
      state.meetingHotelDetail = data
    },
    // 上吧，我的守卫，账号版
    routerGuard (state, data) {
      if (!state.user.token && !localStorage.token) { // 没有 token 的倒霉蛋
        if (data.fromPath === '/login') { // 从登录页返回，直接返回上一页
          data._this.$store.commit('returnHandler', data._this) // 调用返回方法
        } else { // 不是从登录页返回就跳转到登录页
          data._this.$store.commit('tipHandler', {
            _this: data._this,
            text: '请登录',
            type: 'fail',
            url: '/login'
          })
        }
      }
    },
    // 上吧，我的守卫，微信版
    routerGuardWechat (state, data) {
      if (!state.user.openid && !localStorage.openid) { // 没有 openid 的倒霉蛋
        if (data.fromPath === '/getcodeuserinfo') { // 从授权页返回，直接返回上一页
          data._this.$store.commit('returnHandler', data._this) // 调用返回方法
          if (state.history.length > 1) { // 调用返回方法
            data._this.$store.commit('returnHandler', data._this)
          } else {
            data._this.$router.push('/')
          }
        } else { // 不是从登录页返回就跳转到登录页
          data._this.$store.commit('tipHandler', {
            _this: data._this,
            text: '请登录',
            type: 'fail',
            url: '/getcodeuserinfo'
          })
        }
      }
    },
    // 退出登录（清空账号、token和openid）
    logout (state) {
      localStorage.token = ''
      localStorage.user = ''
      localStorage.openid = ''
      state.user.token = ''
      state.user.user = ''
      state.user.openid = ''
    },
    // 保留滚动条高度
    setScrollTop (state, data) {
      state.scrollTop[data.type] = data.scrollTop
    }
  },
  actions: {
  },
  modules: {
  }
})
