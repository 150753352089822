// vue3项目，，vue2写法，，
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
// import preview from 'vue-photo-preview'
// 自定义字体

import './assets/b.myFont.css'
import 'vant/lib/index.css'
const app=createApp(App)
app.use(store)
// app.use(preview)
app.use(router)
app.use(Vant)

// app.use(wcSwiper)
app.mount('#app')

