<template>
  <div>
    <!-- <div class="box">
    <div class="" style="width:100%">
         <div  class="item" v-for="(k,index) in actions" :key="index" @click="skip(k)">
            {{k.name}} <span class="circle"></span>
        </div>
    </div> -->
<van-collapse v-model="activeNames">
  <van-collapse-item title="标题1" name="1">
    
  </van-collapse-item>
  <van-collapse-item title="标题2" name="2">内容</van-collapse-item>
  <van-collapse-item title="标题3" name="3">内容</van-collapse-item>
</van-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      actions: [{ name: "选项一" }, { name: "选项二" }, { name: "选项三" }],
       activeNames: ['1'],
    };
  },
  methods: {
    skip(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      //   this.show = false;
      //   Toast(item.name);
    },
  },
};
</script>

<style scoped>

</style>
