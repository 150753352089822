<template>
  <div class="meeting-refund form-container" v-if="orderDetail">
    <div class="main form-main">
      <div class="form form-content">
        <div
          class="item radio-input"
          v-for="(item, index) in orderDetail.Items"
          :key="index"
        >
          <div class="content">
            <div class="checkbox-container">
              <label class="radio-label">
                <div class="radio">
                  <input
                    type="checkbox"
                    v-model="checkboxSelect"
                    :value="item.item_id"
                  />
                </div>
                <div
                  class="text"
                  :class="{ on: checkboxSelect.includes(item.item_id) }"
                >
                  <div class="text-title">
                    <span>{{ item.pay_name }}</span>
                    <b>CNY : {{ parseInt(item.pay_price).toFixed(2) }}</b>
                  </div>
                  <div class="text-content">
                    <p>{{ item.introduce || "暂无介绍" }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input reason-input">
          <div class="title must" :class="reasonClass">
            <span>退款原因</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model="reason"
              @blur="verify('reason')"
              placeholder="请输入退款原因"
            />
          </div>
        </div>
        <div class="count">
          <b class="count-title">总计：</b>
          <b class="count-number">{{ getSum }}</b>
        </div>
        <div class="item submit" @click="submit">立即提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 订单详情
      orderDetail: null,
      // 选择结果
      checkboxSelect: [],
      // 退款原因
      reason: null,
      reasonClass: "",
      // 费用统计
      count: 0,
      // 微信用户名
      userName: null,
    };
  },
  methods: {
    // 获取微信用户名
    requestUserInfo() {
      axios({
        method: "get",
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetWechatUserInfo?openId=${
            this.$store.state.user.openid || localStorage.openid
          }`,
      })
        .then((res) => {
          if (res.data.Data.length) {
            // 如果有数据，拿到用户名
            this.userName = res.data.Data[0].nickname;
          } else {
            console.log("获取用户信息列表为空");
          }
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "reason":
            if (!this.reason) {
              this.reasonClass = "empty";
            } else {
              this.reasonClass = "correct";
            }
        }
      } else {
        if (!this.reason) {
          this.reasonClass = "empty";
        } else {
          this.reasonClass = "correct";
        }
      }
    },
    // 提交
    submit() {
      // 验证提示
      this.verify();

      // 提交验证
      if (!this.checkboxSelect.length) {
        this.tip("请选择退款费用");
      } else if (!this.reason) {
        this.tip("请输入退款原因");
      } else {
        // 处理发送数据
        var items = [];
        // 要退的费用
        this.orderDetail.Items.forEach((item) => {
          if (this.checkboxSelect.includes(item.item_id)) {
            items.push({
              payOrderItemId: item.item_id, // 缴费订单明细id
              refund_name: item.pay_name, // 费用名称
              refund_amount: item.pay_price, // 退款金额
            });
          }
        });
        // 要发送的对象
        var obj = {
          bill_number: this.orderDetail.bill_number,
          meeting_id: this.$route.params.id,
          apply_user: this.userName,
          OpenId: this.$store.state.user.openid || localStorage.openid,
          sign_up_id: this.orderDetail.sign_up_id,
          Reasons: this.reason,
          Items: items,
        };
        var json = JSON.stringify(obj);
        // 退款确认
        this.$dialog
          .confirm({
            title: "是否确定要申请退款？",
          })
          .then(() => {
            // 加载动画
            this.$toast.loading({
              message: "提交中...",
              forbidClick: true,
              duration: 0,
            });
            // 提交表单给后端
            axios({
              method: "post",
              url:
                this.$store.state.apiUrl +
                "/actionapi/TencentApi/PayOrderRefund",
              headers: {
                "Content-Type": "application/json",
              },
              data: json,
            })
              .then((res) => {
                this.$toast.clear();
                // 如果状态码正确
                if (res.data.ResultCode === 1) {
                  // 提示并跳转
                  this.$toast.loading({
                    type: "success",
                    forbidClick: true,
                    message: "提交成功",
                    onClose: () => {
                      // 记录历史到支付列表
                      this.$store.commit("setSessionStorage", {
                        type: "meetingpaylist",
                        meetingId: this.$route.params.id,
                      });
                      // 返回上一级
                      if (this.$store.state.history.length > 1) {
                        this.$store.commit("returnHandler", this);
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                } else {
                  // 提交失败
                  this.tip(res.data.Msg);
                }
              })
              .catch((err) => {
                this.$toast.clear();
                console.log(err);
              });
          })
          .catch(() => {
            console.log("用户点击了取消");
          });
      }
    },
    // 提示
    tip(text, type) {
      this.$toast.clear();
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
  },
  computed: {
    // 计算总数
    getSum() {
      var sum = 0;

      this.orderDetail.Items.forEach((item, index) => {
        if (this.checkboxSelect.includes(item.item_id)) {
          sum += parseInt(item.pay_price);
        }
      });
      return sum.toFixed(2);
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      // 调用加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: true,
      });
      // 赋值（要过滤已提交退款申请和退款成功的）
      var obj = this.$store.state.meetingPayDetail;
      var arr = obj.Items;
      console.log(arr);
      if (arr.length) {
        arr = arr.filter((item) => {
          // eslint-disable-next-line eqeqeq
      
          return item.is_refunded == 0;
          
        });
      
      }

      if (arr.length) {
      
        // 注意引用类型赋值问题
        this.orderDetail = JSON.parse(JSON.stringify(obj));
        this.orderDetail.Items = arr;
        // 请求微信用户信息（在提交时会用到）
        this.requestUserInfo();
      } else {
        this.$toast.loading({
          type: "fail",
          forbidClick: true,
          message: "没有可退的费用",
          onClose: () => {
            // 返回上一级
            if (this.$store.state.history.length > 1) {
              this.$store.commit("returnHandler", this);
            } else {
              this.$router.push("/");
            }
          },
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-refund {
  width: 100%;
  max-width: 6.4rem;
  min-height: calc(100% - 0.6rem);
  padding: 0.2rem 0 0.5rem;
  box-sizing: border-box;
  position: absolute;
  .main {
    .form {
      border-radius: 0.1rem;
      .item {
        padding: 0.2rem;
        margin-top: 0;
        .title {
          position: relative;
          &::after {
            display: block;
            padding-left: 0.4rem;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 0.27rem 0.27rem;
            line-height: 0.31rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &.reason-input {
          .title {
            &.correct::after {
              content: "";
              height: 0.3rem;
              background-image: url(../../public/wx_images/register-icon-3.png);
              color: #50bc93;
            }
            &.empty::after {
              content: "请输入退款原因！";
              background-image: url(../../public/wx_images/register-icon-4.png);
              color: #fd6b6d;
            }
          }
          .content {
            margin-top: 0.2rem;
          }
        }
        .content {
          margin-top: 0;
          .radio-label {
            width: 100%;
            margin-right: 0;
            .text {
              height: auto;
              padding: 0.25rem;
              border: solid 1px #a6a6a6;
              border-radius: 0.1rem;
              margin-top: 0.2rem;
              text-align: left;
              line-height: normal;
              position: relative;
              transition: 0.3s;
              overflow: hidden;
              &::after {
                content: "";
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                background: url(../../public/wx_images/meetingregpay-icon-1.png)
                  no-repeat center center / 100% 100%;
                opacity: 0;
                position: absolute;
                right: -0.01rem;
                bottom: -0.01rem;
                transition: 0.3s;
              }
              &.on {
                border-color: #1a90ff;
                &::after {
                  opacity: 1;
                }
              }
              .text-title {
                display: flex;
                flex-direction: column;
                padding-bottom: 0.15rem;
                border-bottom: solid 1px #a6a6a6;
                span {
                  margin-bottom: 0.2rem;
                  font-size: 0.28rem;
                  font-weight: bold;
                }
                b {
                  color: #eb6337;
                  font-size: 0.3rem;
                  font-weight: bold;
                }
              }
              .text-content {
                p {
                  margin-top: 0.2rem;
                  line-height: 0.4rem;
                }
              }
            }
            &:first-of-type .text {
              margin-top: 0;
            }
          }
        }
      }
      .submit {
        padding: 0;
        background-color: #4e9ef7;
        border-radius: 0.05rem;
        margin-top: 0.8rem;
      }
      .count {
        margin-top: 0.3rem;
        text-align: center;
        .count-title {
          margin-bottom: 0.2rem;
          font-size: 0.24rem;
          font-weight: bold;
        }
        .count-number {
          color: #eb6337;
          font-size: 0.3rem;
          font-weight: bold;
          &::before {
            content: "CNY : ";
          }
        }
      }
    }
  }
}
</style>
