<template>
  <div class="meeting-person-info">
    <ul class="list" v-if="userInfo">
      <li class="item portrait">
        <b class="type">头像</b>
        <div class="img-container">
          <van-image
            v-if="userInfo.headimgurl"
            width="1rem"
            height="1rem"
            :src="userInfo.headimgurl"
            style="border-radius: 0.12rem; overflow: hidden"
          />
          <van-image
            v-else
            width="1rem"
            height="1rem"
            :src="require('../../public/wx_images/meetingperson-icon-1.png')"
            style="border-radius: 0.12rem; overflow: hidden"
          />
        </div>
        <i class="icon"></i>
      </li>
      <li class="item name">
        <b class="type">姓名</b>
        <span class="text">{{ userInfo.nickname }}</span>
        <i class="icon"></i>
      </li>
      <!-- <li class="item sex">
        <b class="type">性别</b>
        <span class="text">{{userInfo.sex == '0' ? '男' : '女'}}</span>
        <i class="icon"></i>
      </li> -->
      <!-- <li class="item area">
        <b class="type">所属省市</b>
        <span class="text">{{userInfo.province}}&nbsp;&nbsp;{{userInfo.city}}</span>
        <i class="icon"></i>
      </li> -->
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    // 请求个人信息数据
    request() {
      axios({
        method: "get",
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetWechatUserInfo?openId=${
            this.$store.state.user.openid || localStorage.openid
          }`,
      })
        .then((res) => {
          if (res.data.Data.length) {
            // 如果有数据，赋值给用户信息
            this.userInfo = res.data.Data[0];
          }
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit("loadHandler", {
        // 调用加载动画
        _this: this,
        boolean: true,
      });

      this.request(); // 调用请求个人信息方法
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-person-info {
  background-color: #eeeeee;
  .list {
    .item {
      display: flex;
      align-items: center;
      height: 0.88rem;
      padding: 0 0.25rem;
      background-color: #ffffff;
      line-height: 0.88rem;
      position: relative;
      .type {
        flex-shrink: 0;
        font-size: 0.26rem;
      }
      .text {
        flex-grow: 1;
        padding: 0 0.1rem 0 0.3rem;
        box-sizing: border-box;
        color: #979797;
        font-size: 0.26rem;
        text-align: right;
      }
      .icon {
        flex-shrink: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: url(../../public/wx_images/personinfo-icon-1.png) no-repeat
          center center / 100%;
      }
      &::after {
        content: "";
        display: block;
        width: calc(100% - 0.5rem);
        height: 0.01rem;
        min-height: 1px;
        background-color: #cccccc;
        opacity: 0.5;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      &:last-of-type::after {
        display: none;
      }
    }
    .portrait {
      height: auto;
      padding: 0.12rem 0.25rem;
      .img-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        padding: 0 0.1rem 0 0.3rem;
        box-sizing: border-box;
        line-height: 0;
      }
    }
    .email,
    .title {
      &::after {
        display: none;
      }
    }
    .qualification,
    .unit {
      margin-top: 0.15rem;
    }
  }
}
</style>
