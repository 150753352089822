<template>
  <div class="login">
    <div class="title">
      <h3>Welcome</h3>
    </div>
    <div class="form">
      <div class="row row-1">
        <div class="icon">
          <i></i>
        </div>
        <div class="input">
          <input
            type="text"
            v-model="user"
            android:windowSoftInputMode="adjustResize"
          />
        </div>
      </div>
      <div class="row row-2">
        <div class="icon">
          <i></i>
        </div>
        <div class="input">
          <input type="password" v-model="password" />
        </div>
      </div>
      <div class="row row-3">
        <div class="remember">
          <label class="remember-label">
            <div class="checkbox">
              <input
                type="checkbox"
                v-model="remember"
                @change="rememberPassword"
              />
            </div>
            <!-- <div class="text">
                <i class="icon" :class="{'on' : remember}"></i>
                <span class="font">记住密码</span>
             </div> -->
          </label>
        </div>
        <div class="retrieve">
          <van-checkbox
            v-model="checked"
            shape="round"
            class="pass"
            ref="password"
            @click="cut"
            >记住密码</van-checkbox>
          <router-link to="/retrieve">找回密码？</router-link>
        </div>
      </div>
      <div class="row row-4">
        <div class="login-button" @click="submit">登录</div>
      </div>
      <!-- <div class="row row-5">
        <span class="text" @click="navigate('/register')">立即注册</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
const Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      user: "",
      password: "",
      remember: false,
      checked: false,
    };
  },
  mounted() {
    const username = localStorage.getItem("userId");
    if (username) {
      this.user = localStorage.getItem("userId");
      this.password = Base64.decode(localStorage.getItem("password")); // base64解密
      this.checked = true;
    }
  },
  methods: {
    cut() {},
    // 记住密码表单改值
    rememberPassword() {
      // console.log(this.remember);
    },
    // 提交登录
    submit() {
      // 加载动画
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
        duration: 0,
      });

      // 提交验证
      if (!this.user) {
        this.tip("请输入账号");
      } else if (!this.password) {
        this.tip("请输入密码");
      } else {
        console.log("正在提交");
        axios
          .get(
            this.$store.state.apiUrl +
              `/actionapi/TencentApi/Login?UserName=${this.user}&Pwd=${this.password}`
          )
          .then((res) => {
            if (res.data.ResultCode === 1) {
              this.$toast.loading({
                type: "success",
                forbidClick: true,
                message: res.data.Msg,
                onClose: () => {
                  // 储存token和账号
                  localStorage.user = this.user;
                  localStorage.token = res.data.Token;
                  this.$store.commit("storeUserInfo", {
                    user: this.user,
                    token: res.data.Token,
                  });

                  // 跳转目标页，按正常来说是返回之前拦截的页面
                  if (this.$store.state.history.length > 1) {
                    this.$store.commit("returnHandler", this);
                  } else {
                    this.$router.push("/");
                  }
                },
              });
            } else {
              this.$toast.loading({
                type: "fail",
                forbidClick: true,
                message: res.data.Msg,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.checked) {
        // base64加密
        const password = Base64.encode(this.password);
        localStorage.setItem("userId", this.user);
        localStorage.setItem("password", password);
      } else {
        localStorage.removeItem("userId");
        localStorage.removeItem("password");
      }
    },
    // 提示
    tip(text) {
      this.$toast.fail(text);
    },
    navigate(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.pass {
  position: absolute;
  left: 47px;
}
.van-badge__wrapper {
  border: 1px solid red;
  background: white;
}
.login {
  width: 100%;
  max-width: 6.4rem;
  min-height: calc(100% - 0.6rem);
  padding: 1.7rem 0 0.5rem;
  background: url(../../public/wx_images/background-login.jpg) no-repeat center
    top / 100% 100%;
  box-sizing: border-box;
  position: absolute;
  .title {
    h3 {
      color: #333333;
      font-size: 0.5rem;
      text-align: center;
    }
  }
  .form {
    width: 4.7rem;
    margin: 0.8rem auto 0;
    .row-1,
    .row-2 {
      display: flex;
      align-items: center;
      padding-bottom: 0.1rem;
      border-bottom: solid 0.02rem #333333;
      .icon {
        flex-shrink: 0;
        width: 0.75rem;
        i {
          display: block;
          width: 0.4rem;
          height: 0.4rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
        }
      }
      .input {
        flex-grow: 1;
        input {
          width: 100%;
          height: 0.5rem;
          color: #333333;
          font-size: 0.32rem;
          line-height: 0.5rem;
        }
      }
    }
    .row-1 {
      .icon {
        i {
          background-image: url(../../public/wx_images/login-icon-1.png);
        }
      }
      .input {
        input {
        }
      }
    }
    .row-2 {
      margin-top: 0.5rem;
      .icon {
        i {
          background-image: url(../../public/wx_images/login-icon-2.png);
        }
      }
      .input {
        input {
        }
      }
    }
    .row-3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.15rem;
      .remember {
        .remember-label {
          .checkbox {
            input {
              display: none;
            }
          }
          .text {
            display: flex;
            align-items: center;
            height: 0.22rem;
            font-size: 0;
            .icon {
              display: block;
              width: 0.22rem;
              height: 0.22rem;
              border: solid 0.02rem #333333;
              border-radius: 0.02rem;
              box-sizing: border-box;
              margin-right: 0.12rem;
              &.on {
                background: #ff5c57 url(../../public/wx_images/login-icon-3.png)
                  no-repeat center center / 0.15rem 0.11rem;
                border-color: #ff5c57;
              }
            }
            .font {
              display: inline-block;
              color: #333333;
              font-size: 0.2rem;
            }
          }
        }
      }
      .retrieve {
        a {
          color: #ff5c57;
          font-size: 0.2rem;
        }
      }
    }
    .row-4 {
      margin-top: 0.38rem;
      .login-button {
        height: 0.7rem;
        background-color: #ff4844;
        border-radius: 0.36rem;
        color: #ffffff;
        font-size: 0.28rem;
        text-align: center;
        line-height: 0.7rem;
      }
    }
    .row-5 {
      margin-top: 0.2rem;
      text-align: right;
      .text {
        display: inline-block;
        padding-bottom: 0.05rem;
        border-bottom: solid 0.01rem #ff4844;
        color: #ff4844;
        font-size: 0.2rem;
        &::before {
          content: "还没账号？";
          color: #333333;
        }
      }
    }
  }
}
</style>
