<template>
  <div class="meeting-pay-detail" v-if="orderDetail">
    <div class="main">
      <div class="title">
        <div class="row row-1">
          <p class="order-number">
            <b class="type">订单号：</b>
            <i class="text">{{ orderDetail.bill_number }}</i>
          </p>
        </div>
        <div class="row row-2">
          <p class="meeting-title">
            <b class="type">会议：</b>
            <i class="text">{{ orderDetail.meeting_title }}</i>
          </p>
        </div>
        <div class="row row-3">
          <p class="create-time">
            <b class="type">创建时间：</b>
            <i class="text">{{ orderDetail.create_time }}</i>
          </p>
        </div>
        <div class="row row-4" v-if="orderDetail.pay_time">
          <p class="pay-time">
            <b class="type">支付时间：</b>
            <i class="text">{{ orderDetail.pay_time }}</i>
          </p>
        </div>
        <div class="row row-5">
          <p class="total-amount">
            <b class="type">支付金额：</b>
            <i class="text">{{
              parseInt(orderDetail.total_amount).toFixed(2)
            }}</i>
          </p>
        </div>
        <div class="row row-6">
          <p class="pay-method">
            <b class="type">支付方式：</b>
            <i class="text">{{
              orderDetail.delivery_way === "alipay" ? "微信支付" : "单位转账"
            }}</i>
          </p>
        </div>
        <div class="row row-7">
          <p class="state">
            <b class="type">当前状态：</b>
            <i
              class="text"
              :class="
                orderDetail.is_pay == 1 || orderDetail.is_pay == 109
                  ? 'blue'
                  : 'orange'
              "
              >{{ shateHandler(orderDetail.is_pay) }}</i>
          </p>
        </div>
        <div class="row row-8">
          <p class="state">
            <b class="type">参会人：</b>
            <i class="text">{{ orderDetail.attend_people }}</i>
          </p>
        </div>
        <div class="row row-9">
          <p class="state">
            <b class="type">联系号码：</b>
            <i class="text">{{ orderDetail.link_my }}</i>
          </p>
        </div>
      </div>
      <div class="detail">
        <ul class="list">
          <li
            class="item"
            v-for="(item, index) in orderDetail.Items"
            :key="index"
          >
            <div class="row row-1">
              <div class="cost-title">
                <span class="text">{{ item.pay_name }}</span>
              </div>
              <div class="price">
                <span class="text">{{
                  parseInt(item.pay_amount).toFixed(2)
                }}</span>
              </div>
            </div>
            <!-- 退款状态不是默认的时候显示退款时间和明细状态 -->
            <div class="row row-2" v-if="item.is_refunded != 0">
              <div class="refund-time">
                <b class="type">{{
                  item.is_refunded == 109 && item.refund_time
                    ? "退款时间："
                    : ""
                }}</b>
                <span class="text">{{
                  item.is_refunded == 109 && item.refund_time
                    ? item.refund_time
                    : ""
                }}</span>
              </div>
              <div class="refund-state">
                <span
                  class="text"
                  :class="item.is_refunded == 109 ? 'blue' : 'orange'"
                  >{{
                    item.is_refunded == 3
                      ? "已申请退款"
                      : item.is_refunded == 109
                      ? "已退款"
                      : ""
                  }}</span
                >
              </div>
            </div>
            <!-- 有审批意见且在默认状态才显示审批意见 -->
            <div class="row row-3" v-if="item.is_refunded == 0 && item.views">
              <div class="refund-opinion">
                <b class="type">审批意见：</b>
                <span class="text">{{ item.views }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="button">
        <!-- 未支付状态，支付方式不是单位转账可以继续支付 -->
        <!-- <div class="continue-button" @click="continuePay" v-if="orderDetail.delivery_way !== 'giro' && orderDetail.is_pay == 0">
          <span class="text">继续支付</span>
        </div> -->
        <!-- 未支付状态可以取消 -->
        <div
          class="cancel-button"
          @click="cancelOrderConfirm"
          v-if="orderDetail.is_pay == 0"
        >
          <span class="text">取消订单</span>
        </div>
        <!-- 已支付、部分已退款状态可以退款 -->
        <div
          class="refund-button"
          @click="refundConfirm"
          v-if="
            orderDetail.delivery_way !== 'giro' &&
            (orderDetail.is_pay == 1 || orderDetail.is_pay == 2)
          "
        >
          <span class="text">申请退款</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 订单详情
      orderDetail: null,
      // 支付宝支付参数
      orderNo: null, // 后台订单号
      orderName: "订单", // 订单名称
      orderMoney: null, // 订单金额
      orderDesc: "订单描述", // 订单描述
      backUrl:
        /.+?\/\/.+?\//g.exec(location.href)[0] +
        "alipay/" +
        this.$route.params.id, // 回调地址，只要当前无路由的地址+目标路由
      noticeUrl: "https://qinglin.cc/test/receive", // 通知地址
      // 用户名（作为退款申请人）
      userName: null,
    };
  },
  methods: {
    // 报名状态判断
    shateHandler(state) {
      var str = "";
      if (typeof state === "string") {
        state = parseInt(state);
      } else if (typeof state !== "number") {
        return;
      }
      switch (state) {
        case 0:
          str = "未支付";
          break;
        case 1:
          str = "已支付";
          break;
        case 2:
          str = "部分已退款";
          break;
        case 109:
          str = "已退款";
          break;
        case 99:
          str = "已取消订单";
      }
      return str;
    },
    // 继续支付判断
    continuePay() {
      // 二次判断
      if (
        this.orderDetail.delivery_way !== "giro" &&
        this.orderDetail.is_pay === "0"
      ) {
        // 赋值订单号和订单金额并调用支付宝支付
        /* console.log(this.orderDetail.bill_number, this.orderDetail.total_amount)
        this.orderNo = this.orderDetail.bill_number
        this.orderMoney = this.orderDetail.total_amount
        this.alipay() */
        // 接受不了0.6的费率，放弃支付宝支付
        // console.log("放弃支付宝支付");
      } else {
        this.$store.commit("tipHandler", {
          _this: this,
          type: "fail",
          text: "订单为已支付状态或支付方式为单位转账",
        });
      }
    },
    // 支付宝支付
    alipay() {
      // 判断有没有拿到订单号和订单金额
      if (this.orderNo && this.orderMoney) {
        var _obj = {
          orderNo: this.orderNo,
          orderName: this.orderName,
          orderMoney: this.orderMoney,
          orderDesc: this.orderDesc,
          backUrl: this.backUrl,
          noticeUrl: this.noticeUrl,
        };
        var json = JSON.stringify(_obj);

        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/PaymentOrderApply",
          headers: {
            "Content-Type": "application/json",
          },
          data: json,
        })
          .then((res) => {
            if (res.data.ResultCode === 1) {
              // 把拿到的表单加到body中，在支付中转页再提交
              document.body.innerHTML += res.data.Content;
              this.$router.push("/alipay/" + this.$route.params.id);
            } else {
              this.tip(res.data.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$store.commit("tipHandler", {
          _this: this,
          type: "fail",
          text: "没有订单号或者订单金额",
        });
      }
    },
    // 取消订单确认
    cancelOrderConfirm() {
      // eslint-disable-next-line 
      if (this.orderDetail.is_pay == 0) {
        this.$dialog
          .confirm({
            title: "是否确定要取消订单？",
          })
          .then(() => {
            // 要发送的对象
            var obj = {
              bill_number: this.orderDetail.bill_number,
            };
            var json = JSON.stringify(obj);

            axios({
              method: "post",
              url:
                this.$store.state.apiUrl +
                "/actionapi/TencentApi/PayOrderCancel",
              headers: {
                "Content-Type": "application/json",
              },
              data: json,
            })
              .then((res) => {
                // 如果状态码正确
                if (res.data.ResultCode === 1) {
                  // 提示并跳转
                  this.$toast.loading({
                    type: "success",
                    forbidClick: true,
                    message: "提交成功",
                    onClose: () => {
                      // 返回上一级
                      if (this.$store.state.history.length > 1) {
                        this.$store.commit("returnHandler", this);
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                } else {
                  // 提交失败
                  this.tip(res.data.Msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
            console.log("用户点击了取消");
          });
      } else {
        this.$store.commit("tipHandler", {
          _this: this,
          type: "fail",
          text: "订单为已支付状态无法取消，只能退款",
        });
      }
    },
    // 申请退款跳转
    refundConfirm() {
      // 二次判断
      // eslint-disable-next-line eqeqeq
      if (
        this.orderDetail.delivery_way !== "giro" &&
        (this.orderDetail.is_pay == 1 || this.orderDetail.is_pay == 2)
      ) {
        // 跳转退款页面
        this.$router.push("/meetingrefund/" + this.$route.params.id);
      } else {
        this.$store.commit("tipHandler", {
          _this: this,
          type: "fail",
          text: "订单未支付、已退款、已取消或支付方式为单位转账",
        });
      }
    },
    // 提示
    tip(text, type) {
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    // 有openid的情况下才赋值
    if (this.$store.state.user.openid || localStorage.openid)
      this.orderDetail = this.$store.state.meetingPayDetail;
  },
};
</script>

<style lang="scss" scoped>
.meeting-pay-detail {
  min-height: 2.83rem;
  padding-bottom: 0.1rem;
  background: url(../../public/wx_images/background-examine.png) no-repeat
    center top / 100% 2.83rem;
  box-sizing: border-box;
  overflow: hidden;
  .main {
    .title {
      background-color: #ffffff;
      padding: 0.25rem 0.35rem;
      border-radius: 0.05rem;
      box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      margin-top: 0.65rem;
      .row {
        margin-top: 0.2rem;
        &:first-of-type {
          margin-top: 0;
        }
        .type {
          color: #8d8d8d;
          font-size: 0.22rem;
        }
        .text {
          font-size: 0.22rem;
          &.blue {
            color: #33ccff;
          }
          &.orange {
            color: #ff9900;
          }
        }
        .order-number {
          .text {
            color: #33ccff;
          }
        }
        .total-amount {
          .text {
            color: #eb6337;
            &::before {
              content: "￥";
            }
          }
        }
      }
      .row-3 {
        .project {
          display: flex;
          .type {
            flex-shrink: 0;
          }
        }
      }
    }
    .detail {
      padding: 0.3rem 0.2rem 0.2rem;
      .list {
        .item {
          background-color: #ffffff;
          padding: 0.15rem 0.2rem;
          border-radius: 0.05rem;
          box-shadow: 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
          margin-top: 0.2rem;
          &:first-of-type {
            margin-top: 0;
          }
          .row-1 {
            display: flex;
            justify-content: space-between;
            .cost-title {
              .text {
                font-weight: bold;
              }
            }
            .price {
              .text {
                color: #eb6337;
                &::before {
                  content: "￥";
                }
              }
            }
          }
          .row-2 {
            display: flex;
            justify-content: space-between;
            margin-top: 0.1rem;
            .refund-time {
              .type {
                color: #8d8d8d;
                font-size: 0.22rem;
              }
            }
            .refund-state {
              .text {
                font-size: 0.22rem;
                &.orange {
                  color: #ff9900;
                }
                &.blue {
                  color: #33ccff;
                }
              }
            }
          }
          .row-3 {
            margin-top: 0.1rem;
            .refund-opinion {
              display: flex;
              .type {
                flex-shrink: 0;
                color: #8d8d8d;
                font-size: 0.22rem;
              }
              .text {
                flex-grow: 1;
                font-size: 0.22rem;
              }
            }
          }
        }
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
      .continue-button,
      .cancel-button,
      .refund-button {
        width: 5rem;
        height: 0.56rem;
        padding-left: 0.05rem;
        background-color: #4e9ef7;
        border-radius: 0.05rem;
        box-sizing: border-box;
        margin-top: 0.3rem;
        color: #ffffff;
        font-size: 0.24rem;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 0.6rem;
      }
      .continue-button {
        background-color: #4e9ef7;
      }
      .cancel-button {
        background-color: #ff9900;
      }
      .refund-button {
        background-color: #ff0045;
      }
    }
  }
}
</style>
