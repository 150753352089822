<template>
  <div>
        <div class="box">
            <div class="success">
                <img src="../../../public/wx_images/success.png" alt="" style="width:100%">
            </div>
            <div class="title">
                <p>{{title}}</p>
            </div>
        </div>
  </div>
</template>

<script>

export default {
    components:{
      
    },
    data(){
        return {
            title:'申请成功'
        }
    }
}
</script>

<style scoped>
.box{
    /* height: 100vh; */
}
.success{
    width: 90px;
    height: 100px;
    margin: 30px auto 30px auto;
}
.title{ 
    text-align: center;
    font-size: 20px;
}
</style>


