<template>
  <div class="meeting-person" v-if="isShow">
    <div class="title">
      <div class="portrait">
        <van-image
          v-if="userInfo.headimgurl"
          width="1rem"
          height="1rem"
          :src="userInfo.headimgurl"
        />
        <van-image
          v-else
          width="1rem"
          height="1rem"
          :src="require('../../public/wx_images/meetingperson-icon-1.png')"
        />
      </div>
      <div class="info">
        <span class="name" v-if="userInfo.nickname">{{
          userInfo.nickname
        }}</span>
        <span class="unit" v-if="userInfo.unit">{{ userInfo.unit }}</span>
      </div>
    </div>
    <div class="content">
      <div class="user-info container-common">
        <div
          class="item item-user-info"
          @click="navigate('/meetingpersoninfo/' + this.$route.params.id)"
        >
          <span class="item-title">个人信息</span>
          <i class="item-aside"></i>
        </div>
      </div>
      <div class="record container-common">
        <div
          class="item item-register-record"
          @click="navigate('/meetingreglist/' + this.$route.params.id)"
        >
          <span class="item-title">报名记录</span>
          <i class="item-aside"></i>
        </div>

           <!-- <div
          class="item item-conference"
          @click="navigate('/apply')"
          v-if="partShow"
        >
          <span class="item-title">入会信息</span>
          <i class="item-aside"></i>
        </div> -->

        <div
          class="item item-pay-record"
          @click="navigate('/meetingpaylist/' + this.$route.params.id)"
        >
          <span class="item-title">支付记录</span>
          <i class="item-aside"></i>
        </div>
        <div
          class="item item-hotel-record"
          @click="navigate('/meetinghotellist/' + this.$route.params.id)"
        >
          <span class="item-title">酒店预订记录</span>
          <i class="item-aside"></i>
        </div>
      </div>
      <div class="passage container-common">
        <div class="item item-sign-up" @click="navigate(null, 'register')">
          <span class="item-title">报名参会</span>
          <i class="item-aside" :class="state.signUp === 2 ? 'green' : 'red'">{{
            setStateText("register")
          }}</i>
        </div>
        <div class="item item-hotel" @click="navigate(null, 'hotel')">
          <span class="item-title">酒店预订</span>
          <i class="item-aside" :class="state.hotel ? 'green' : 'red'">{{
            setStateText("hotel")
          }}</i>
        </div>
      </div>
      <div class="tip container-common">
        <div class="tip-title">
          <span class="text">温馨提示</span>
        </div>
        <div class="tip-content">
          <div class="row" v-for="(item, index) in contact" :key="index">
            <h5 class="row-title" v-if="item.title" v-html="item.title"></h5>
            <p
              class="row-content"
              v-if="item.content"
              v-html="item.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="logout container-common">
        <div class="item item-logout" @click="logout">
          <span class="item-title">退出登录</span>
          <i class="item-aside"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 页面显示开关
      isShow: false,
      // 部分显示开关
      partShow:false,
      // 用户信息
      userInfo: {},
      // 报名和预订酒店状态
      state: {
        signUp: null,
        hotel: null,
      },
      // 温馨提示（联系我们信息）
      contact: [
        {
          title: "",
          content: null,
        },
      ],
      // 订单信息（因为回调到这个页面）
      out_trade_no: null, // 后台订单号
      trade_no: null, // 支付宝订单号
    };
  },
  methods: {
    // 跳转
    navigate(url, type) {
      // 因为点击参会注册和酒店预订要根据状态跳转，所以要加判断
      if (type === "register") {
        /* if (this.state.signUp === 0) {
          this.$router.push('/meetingregselect/' + this.$route.params.id)
        } else if (this.state.signUp === 1) {
          this.$router.push('/meetingregpay/' + this.$route.params.id)
        } else {
          this.$router.push('/payorderlist/' + this.$route.params.id)
        } */
        this.$router.push("/meetingregister/" + this.$route.params.id);
      } else if (type === "hotel") {
        /* if (this.state.hotel) {
          this.$router.push('/hotelorder/' + this.$route.params.id)
        } else {
          this.$router.push('/meetinghotel/' + this.$route.params.id)
        } */
        this.$router.push("/meetinghotel/" + this.$route.params.id);
      } else {
        this.$router.push(url);
      }
    },
    // 根据参会注册和酒店预订情况设置状态文字
    setStateText(type) {
      var str;
      if (type === "register") {
        if (this.state.signUp === 0) {
          str = "未报名";
        } else if (this.state.signUp === 1) {
          str = "未缴费";
        } else if (this.state.signUp === 2) {
          str = "已缴费";
        }
      } else {
        if (this.state.hotel) {
          str = "已预订";
        } else if (this.state.hotel === 0) {
          str = "未预订";
        }
      }
      return str;
    },
    // 请求个人信息数据
    requestPersonInfo() {
      axios({
        method: "get",
        url:
          this.$store.state.apiUrl +
          `/actionapi/TencentApi/GetWechatUserInfo?openId=${
            this.$store.state.user.openid || localStorage.openid
          }`,
      })
        .then((res) => {
        
          if (res.data.Data.length) {
            // 如果有数据，赋值给用户信息
            this.userInfo = res.data.Data[0];
          }
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 请求联系我们信息
    requestContact(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingInfoByField?MeetingId=" +
            id +
            "&Field=contact"
        )
        .then((res) => {
      
          if (res.data.Data.length) {
            var html = res.data.Data[0].contact;
            html = html.replace(/(<p.+?)(style=".+?")(>)/, "$1$3");
            this.contact[0].content = html;
          } else {
            console.log("获取数据为空");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取酒店预订状态（通过获取酒店预订列表，然后用长度判断）
    requestHotelList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMeetingRoomRecord?userName=${
              this.$store.state.user.user || localStorage.user
            }&MeetingId=${id}`
        )
        .then((res) => {
       
          if (res.data.Data.length) {
            this.state.hotel = 1;
          } else {
            this.state.hotel = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取参会注册状态（通过获取参会注册列表，然后用长度判断）
    requestSignUp(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMeetingSignUp?UserName=${
              this.$store.state.user.user || localStorage.user
            }&MeetingId=${id}`
        )
        .then((res) => {
          if (res.data.Data && res.data.Data.length) {
            this.requestPayOrder(id); // 进一步判断是否缴费
          } else {
            this.state.signUp = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取参会注册状态（通过获取订单列表，然后遍历到一个是已支付的判断为已缴费）
    requestPayOrder(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetPayOrder?userName=${
              this.$store.state.user.user || localStorage.user
            }&MeetingId=${id}`
        )
        .then((res) => {
          if (res.data.Data.length) {
            var arr = res.data.Data;
            var bool = arr.some((item) => {
              return item.is_pay === "1";
            });
            if (bool) {
              this.state.signUp = 2;
            } else {
              this.state.signUp = 1;
            }
          } else {
            this.state.signUp = 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 退出登录
    logout() {
      this.$dialog
        .confirm({
          title: "是否退出登录",
        })
        .then(() => {
          this.$store.commit("logout"); // 清空数据
          this.$store.commit("tipHandler", {
            _this: this,
            type: "success",
            text: "已退出登录",
          });
          this.$store.commit("returnHandler", this); // 跳转
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    console.log(from);

    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    if(from.fullPath=="/person"||from.fullPath=='/apply'){
      vm.partShow=true
    }
    });
  },
  beforeMount() {
    console.log(this.$route);
    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit("loadHandler", {
        // 调用加载动画
        _this: this,
        boolean: true,
      });
      this.isShow = true;

      this.requestPersonInfo(); // 调用个人信息请求方法
     
      this.requestContact(this.$route.params.id); // 调用联系我们请求方法
      /* this.requestHotelList(this.$route.params.id) */ // 调用查询酒店预订列表方法
      /* this.requestSignUp(this.$route.params.id) */ // 调用查询参会注册列表方法
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-person {
  width: 100%;
  max-width: 6.4rem;
  min-height: calc(100% - 0.6rem);
  background-color: #f5f5f5;
  box-sizing: border-box;
  position: absolute;
  .title {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.2rem 0.3rem;
    background-color: #ffffff;
    .portrait {
      width: 1rem;
      height: 1rem;
      background-color: #95b3d7;
      border: solid 2px transparent;
      border-radius: 50%;
      margin-right: 0.2rem;
      overflow: hidden;
    }
    .info {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 0.26rem;
        font-weight: 700;
      }
      .unit {
        color: #767676;
        font-size: 0.2rem;
        margin-top: 0.05rem;
      }
    }
  }
  .content {
    padding: 0 0.2rem 0.5rem;
    .container-common {
      background-color: #ffffff;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      overflow: hidden;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
        padding: 0 0.2rem;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 6.4rem;
          height: 1px;
          background-color: #eeeeee;
          position: absolute;
          left: 0.7rem;
          bottom: -0.5px;
        }
        &:last-of-type::after {
          display: none;
        }
        .item-title {
          padding-left: 0.5rem;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 0.32rem 0.32rem;
          color: #000000;
          font-weight: bold;
        }
        .item-aside {
          padding-right: 0.3rem;
          line-height: 0;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            background: url(../../public/wx_images/personinfo-icon-1.png)
              no-repeat center center / 0.3rem 0.3rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &.red {
            color: #ea413d;
          }
          &.green {
            color: #50e8b7;
          }
        }
      }
      .item-user-info .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-7.png);
      }
      .item-register-record .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-5.png);
      }
      .item-pay-record .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-8.png);
      }
      .item-hotel-record .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-9.png);
      }
      .item-sign-up .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-3.png);
      }
      .item-hotel .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-4.png);
      }
      .item-logout .item-title {
        background-image: url(../../public/wx_images/meetingperson-icon-6.png);
      }
      .item-conference .item-title {
        background-image: url(../../public/wx_images/conference.png);
      }
    }
    .tip {
      .tip-title {
        height: 0.8rem;
        padding: 0 0.2rem;
        border-bottom: solid 1px #eeeeee;
        line-height: 0.8rem;
        .text {
          color: #e96857;
          font-size: 0.26rem;
          font-weight: bold;
        }
      }
      .tip-content {
        padding: 0 0.2rem 0.4rem;
        .row {
          margin-top: 0.3rem;
          .row-title {
            margin-bottom: 0.1rem;
            font-size: 0.24rem;
            font-weight: bold;
          }
          .row-content {
            font-size: 0.22rem;
            line-height: 0.34rem;
          }
        }
      }
    }
  }
}
</style>
