<template>
  <div class="navbar">
    <div class="button" @click="back">
      <b class="text">{{ $store.state.navbar.backTitle }}</b>
    </div>
    <div class="title">
      <span class="text">{{ $store.state.navbar.mainTitle }}</span>
    </div>
    <div>
      <!-- <Popups></Popups> -->
    </div>
  </div>
</template>
<script>
import Popups from '@/components/Popups'
export default {
  name: "navbar",
  components:{
    Popups
  },
  data() {
    return {
      history: null,
    };
  },
  methods: {
    back() {
      this.$store.commit("returnHandler", this);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 6.4rem;
  height: 0.6rem;
  background-color: #ffffff;
  box-shadow: 0 0.05rem 0.3rem 0.1rem rgba(0, 0, 0, 0.05);
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  .button {
    display: block;
    height: 100%;
    padding: 0 0.1rem;
    position: absolute;
    left: 0;
    top: 0;
    .text {
      padding-left: 0.35rem;
      background: url(../../public/wx_images/navbar-icon.png) no-repeat left
        center / 0.3rem 0.3rem;
      color: #95b3d7;
      font-size: 0.26rem;
      line-height: 0.6rem;
    }
  }
  .title {
    width: 45%;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 0.6rem;
    overflow: hidden;
    .text {
      font-size: 0.28rem;
    }
  }
}
</style>
