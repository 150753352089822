<template>
  <div class="container" :class="($store.state.isTabbarShow ? 'padding-bottom' : 'padding-top')">
    <navbar v-show="!$store.state.isTabbarShow"/>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.path" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive"/>
    </router-view>
    <tabbar v-show="$store.state.isTabbarShow"/>
    <!-- <activitytabbar v-if="showtabber"></activitytabbar> -->
  </div>
</template>

<script>
import navbar from './components/Navbar.vue'
import tabbar from './components/Tabbar.vue'
// import activitytabbar from '@/views/activity' 
export default {
  components: {
    navbar,
    tabbar,
    // activitytabbar
  },
  data () {
    return {
      showtabber:false,
      // 设置最大宽度
      maxWidth: 640
    }
  },
  // created(){
  //   if(this.$route.path=='activity'){
  //     this.showtabber=true
  //   }
  // },  
  methods: {
    // 监听窗口尺寸
    windowResize () {
      var _width = window.innerWidth
      if (_width > this.maxWidth) document.documentElement.style.fontSize = '100px'
    }
  },
  beforeCreate () {
    var mobileAgent = ['iphone', 'ipod', 'ipad', 'android', 'mobile', 'blackberry', 'webos', 'incognito', 'webmate', 'bada', 'nokia', 'lg', 'ucweb', 'skyfire']
    var browser = navigator.userAgent.toLowerCase()
    var jump = true
    for (var i = 0; i < mobileAgent.length; i++) {
      if (browser.indexOf(mobileAgent[i]) !== -1) {
        jump = false
        break
      }
    }
    if (jump) location.href = '/index.aspx'
  },
  beforeMount () {
    this.windowResize()
    window.onresize = this.windowResize
  }
}
</script>

<style>
/* 重置样式 */
*{ margin : 0 ; padding : 0 ; outline : none }
html { font-size : 15.625vw ; color : #333 }
body { max-width : 6.4rem ; background-color : #FFFFFF ; margin : auto !important ; font-size : 0.24rem }
p,input { font-size : inherit }
a{ color : inherit ; text-decoration : none ; outline-style : none ; border : none }
ul,ol,li{ list-style : none }
img{ vertical-align : middle ; border : none ; display : inline-block ; max-width : 100% ; max-height : 100% }
a,button,input,textarea{ -webkit-tap-highlight-color : rgba(0,0,0,0) ; tap-highlight-color : rgba(0,0,0,0) }
b { font-weight : normal }
em,i{ font-style : normal }
h1,h2,h3,h4,h5,h6{ font-weight : normal }
input, textarea { outline : none ; background : none ; border: none }
button { background-color : transparent ; border : none }

.clearfix:before,.clearfix:after{ content : '' ; display : block ; clear : both }
.main{ max-width : 6rem ; margin : 0 auto }
.img-box{ display : table-cell ; vertical-align : middle ; text-align : center }

/* 普通样式 */
.container.padding-top { padding-top : 0.6rem }
.container.padding-bottom { padding-bottom : 1rem }
</style>
