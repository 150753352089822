<template>
  <div>
    <div>
        <Unit v-if="MemberId==2" :hint="'change'" :requestId="$route.params.id"></Unit>
        <Personage v-else  :hint="'change'" :requestId="$route.params.id"></Personage>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Personage from '../initiation/components/personage.vue'
import Unit from '../initiation/components/unit.vue'
export default {
    components:{
        Personage,
        Unit
    },
    data(){
        return {
            id:this.$route.params.id,
            MemberId:this.$route.params.MemberId,
            data:null
        }
    },
    
    created(){
        // 拿到报名信息数据
        // this.GetUserApplyMsg()
    },

    methods:{
        GetUserApplyMsg(){
            axios({
                url:this.$store.state.apiUrl+`/actionapi/TencentApi/GetUserApplyMsg?Id=${this.id}`
            }).then((res)=>{
                this.data=res.data.Data[0]
            }).catch((err)=>{
                console.log(err);
            })
        }
    }
}
</script>

<style>

</style>