<template>
  <div class="meeting-playback" v-show="videoList.length" :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
    <Popups></Popups>
    <div class="video">
      <video src="" ref="video" controls></video>
    </div>
    <!-- <div class="video-nav">
      <van-swipe :loop="false" duration="800" :width="Math.round(150 * width)" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in videoList" :key="index">
          <div class="text" :class="navIndex === index ? 'on' : ''" @click="menuSelect(index)">{{item.title}}</div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="video-list">
      <van-swipe :loop="false" duration="800" :width="Math.round(150 * width)" :show-indicators="false" ref="videoSwiper">
        <van-swipe-item v-for="(item, index) in videoList[navIndex].list" :key="index">
          <div class="item" :class="videoIndex === index ? 'on' : ''" @click="videoSelect(index)">
            <div class="image">
              <van-image v-if="item.image" width="1.37rem" height="0.85rem" :src="item.image"/>
              <van-image v-else width="1.37rem" height="0.85rem" :src="require('../../public/wx_images/meetingplayback-img-1.jpg')"/>
            </div>
            <div class="text">{{item.title}}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="video-list">
      <van-swipe
        class="swipe"
        :loop="false"
        duration="800"
        :width="Math.round(150 * width)"
        :show-indicators="false"
        ref="videoSwiper"
      >
        <van-swipe-item v-for="(item, index) in videoList" :key="index">
          <div
            class="item"
            :class="videoIndex === index ? 'on' : ''"
            @click="videoSelect(index)"
          >
            <div class="image">
              <van-image
                v-if="item.image"
                width="1.37rem"
                height="0.85rem"
                :src="item.image"
              />
              <van-image
                v-else
                width="1.37rem"
                height="0.85rem"
                :src="
                  require('../../public/wx_images/meetingplayback-img-1.jpg')
                "
              />
            </div>
            <div class="text">{{ item.FileName }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="introduce" v-if="videoList.length">
      <div class="title">视频简介</div>
      <div class="content" v-html="videoList[videoIndex].FileDesc"></div>
    </div>
    <div class="share-button" @click="showQr">
      <i class="icon"></i>
    </div>
    <div class="share-content" v-show="qrShow" @click.self="qrShow = false">
      <div class="container">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="text">
          <div class="title">
            <b>标题：</b>
            <span v-if="videoList[videoIndex]">{{
              videoList[videoIndex].FileName
            }}</span>
          </div>
          <div class="url">
            <b>地址：</b>
            <span v-if="videoList[videoIndex]">{{
              videoList[videoIndex].FilePath
            }}</span>
           
          </div>
           <button class="butn skip" @click="btn">点击跳转</button>
        </div>
        <div class="close-button" @click="qrShow = false">
          <i class="icon"></i>
        </div>
      </div>
    </div>
    <div  class="backUrl" ref="back" v-if="backShow" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
    <img :src="$store.state.imageUrl + img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcodejs2";
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data() {
    return {
        twoColor:localStorage.getItem('twoColor'),
       backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
      show:true,
      // body宽 / 目标尺寸
      width: document.body.offsetWidth / 640,
      // 当前选择视频分类
      navIndex: 0,
      // 当前视频
      videoIndex: 0,
      // 视频数据
      videoList: [],
      // 分享二维码
      qrcode: null,
      qrShow: false,
      ishow:false,
      size:0,
      contentSize:0,
      backShow:false
    };
  },
  created(){
      if(this.img_url!=='null'){
      this.show=false
    }else{
      this.show=true
    }
  },
    mounted(){
    this.size=document.documentElement.clientHeight; //实时高度

    setTimeout(()=>{
      this.distance()
    },100)
  },
  methods: {
          distance () {
             this.backShow=localStorage.getItem('img_url')?true:false
      var con=document.querySelector('.swipe')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight
 

      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    // 请求数据，参数为会议id
    request(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingBroadcast?MeetingId=" +
            id
        )
        .then((res) => {
          if (res.data.Data.length) {
            this.videoList = res.data.Data;
        
            this.creatQrCode(this.videoList[this.videoIndex].FilePath); // 默认视频的二维码
            this.videoSelect(); // 默认视频
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    btn(){
      // var index=this.navIndex
      location.href=this.videoList[this.videoIndex].FilePath
    },
    /* // 视频分类选择
    menuSelect (index) {
      if (this.navIndex === index) return
      // 更新索引
      this.navIndex = index
      this.videoIndex = 0
      // 调用选中视频方法
      this.videoSelect()
      this.$refs.videoSwiper.swipeTo(0)
    }, */
    // 视频选择
    videoSelect(index) {
      this.navIndex=index
      if (this.videoIndex === index) return;
      if (index || index === 0) this.videoIndex = index;

      this.$refs.video.src = this.videoList[this.videoIndex].FilePath;
    },
    // 生成二维码
    creatQrCode(url) {
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 显示分享，先 生成二维码
    showQr() {
      this.qrcode.clear(); // 清除二维码
      this.qrcode.makeCode(this.videoList[this.videoIndex].FilePath); // 生成另一个二维码
      this.qrShow = true; // 显示
    },
  },
  mounted() {
    // 因为dom树完成 才能进行dom 操作
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });
    this.request(this.$route.params.id); // 调用请求方法
  },
  /* mounted () {
    this.videoSelect()
  } */
};
</script>

<style lang="scss" scoped>
.skip{
  position: absolute;
  bottom: 0;
  left: -50%;
  transform: translateX(50%);
  width: 100%;
  background: #4e9ef7;
  height: 0.6rem;
  color: white;
}
.backUrl{
  width: 100%;
  height: 200px;

 
   
    background-size: contain;
   z-index: 9;
}
.meeting-playback {
  width: 100%;
  max-width: 6.4rem;
  height: calc(100% - 0.6rem);
  background-color: #f5f5f5;
  box-sizing: border-box;
  position: absolute;
  overflow: auto;
  .video {
    font-size: 0;
    video {
      width: 100%;
      height: 3.56rem;
    }
  }
  .video-nav {
    width: 6rem;
    padding: 0.2rem 0 0.15rem;
    margin: 0 auto;
    overflow: hidden;
    .text {
      width: 1.37rem;
      height: 0.55rem;
      padding: 0 0.1rem;
      border: solid 1px #95b3d7;
      border-radius: 0.05rem;
      box-sizing: border-box;
      margin: 0 auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      line-height: 0.54rem;
      overflow: hidden;
      &.on {
        background-color: #95b3d7;
        color: #ffffff;
      }
    }
  }
  .video-list {
    width: 6rem;
    padding: 0.25rem 0.2rem 0.3rem;
    background-color: #ffffff;
    overflow: hidden;
    .item {
      width: 1.37rem;
      height: 0.85rem;
      border: solid 2px #ffffff;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      .image {
        font-size: 0;
      }
      .text {
        width: 100%;
        height: 0.34rem;
        padding: 0 0.1rem;
        background-image: linear-gradient(to top, #333333, transparent);
        box-sizing: border-box;
        color: #dddddd;
        font-size: 0.18rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        line-height: 0.34rem;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;
      }
      &.on {
        border-color: #95b3d7;
        .text {
          color: #ffffff;
        }
      }
    }
  }
  .introduce {
    background-color: #ffffff;
    margin-top: 0.2rem;
    .title {
      padding: 0.2rem 0;
      color: #95b3d7;
      border-bottom: solid 1px #eeeeee;
      border-radius: 0.05rem;
      font-size: 0.26rem;
      font-weight: bold;
      text-align: center;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 0.5rem;
        height: 0.05rem;
        background-color: #95b3d7;
        position: absolute;
        left: 50%;
        bottom: 0rem;
        transform: translateX(-50%);
      }
    }
    .content {
      font-size: 0.24rem;
      padding: 0.3rem 0.25rem;
      word-break: break-all;
      line-height: 0.4rem;
      text-indent: 2em;
    }
  }
  .share-button {
    width: 0.6rem;
    height: 0.6rem;
    background-color: rgba(255, 255, 255, 1);
    border: solid 1px #333333;
    border-radius: 50%;
    position: absolute;
    right: 0.15rem;
    top: 0.15rem;
    .icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url(../../public/wx_images/meetingtraffic-icon-3.png)
        no-repeat center center / 0.28rem 0.28rem;
    }
  }
  .share-content {
    width: 100%;
    max-width: 6.4rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 10;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 80%;
      padding: 0.8rem 0.4rem 0.5rem;
      background: #ffffff;
      border-radius: 0.1rem;
      box-sizing: border-box;
      margin: 1.6rem 0.4rem 0;
      position: relative;
      overflow: auto;
      height: 7.05rem;
      .qrcode {
      }
      .text {
        width: 100%;
        line-height: 0.4rem;
        .title {
          margin-top: 0.2rem;
        }
        .url {
          word-break: break-all;
        }
      }
      .close-button {
        width: 0.4rem;
        height: 0.4rem;
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
        .icon {
          display: block;
          width: 100%;
          height: 100%;
          background: url(../../public/wx_images/meetingplayback-icon-1.png)
            no-repeat center center / 100% 100%;
        }
      }
    }
  }
}
</style>
