<template>
  <div style="z-index: 9999999999;">
    <div class="box-time"  v-if="show">
        <span>距离大会开幕还有：</span>
        <div class="time">
             <!-- 天 -->
             <li>
                <span>{{d}}</span>
                <span>DAYS</span>
             </li>
             <!-- 时 -->
             <li>
                <span>{{h}}</span>
                <span>HOURS</span>
             </li>
             <!-- 分 -->
             <li>
                <span>{{m}}</span>
                <span>MINUTES</span>
             </li>
             <!-- 秒 -->
             <li>
                <span>{{s}}</span>
                <span>SECONDS</span>
             </li>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
      start_tiem:String
    },
    data(){
        return {
            time:'2022/12/23 23:45:09',
            d:'',
            h:'',
            m:'',
            s:'',
            timer:null,
            show:false
        }
    },
    mounted(){
        
        setTimeout(()=>{
            this.countTime()
        },100)
    },

    methods:{
  
     countTime() {

      //获取当前时间
      var date = new Date();
      var now = date.getTime();
      //设置截止时间
      var endDate = new Date(this.start_tiem);
    //   debugger
      var end = endDate.getTime();
      //时间差
      var leftTime = end - now;
      //定义变量 d,h,m,s保存倒计时的时间
    //   console.log(leftTime);
      if (leftTime >= 0) {
        this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24); 
        this.h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.m = Math.floor((leftTime / 1000 / 60) % 60);
        this.s = Math.floor((leftTime / 1000) % 60);
        this.show=true
      } else {
        clearTimeout(this.timer);
        this.show=false
        return;
      }

      //递归每秒调用countTime方法，显示动态时间效果
      this.timer = setTimeout(this.countTime, 1000);
     },
    },
    deactivated(){
        // console.log(1111111111111111111111111);
    },
    
    destroyed(){
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
    span{
        // display: inline-block;
    }
    li{
        list-style: none;
    }
    .box-time{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;
        // 使用z-index要使用position
        position: relative;
        z-index: 999999 !important;
    }
    .time{
        width: 60%;
        display: flex;
        justify-content: space-between;
        li{
            height: 40px;
            width: 50px;
            border-radius: 3px;
            background: #f2f2f2;
            margin-right: 5px;
            padding: .07rem;
            span{
                display: block;
            }
        }
        li span:first-child{
            height: 20px;
            line-height: 25px;
            font-weight: 700;
            font-size: 16px;
            color: #8d8e8f;
            border-bottom: 1px solid #bbbdc0;
            text-align: center;
        }

        li span:nth-child(2){
            color: rgb(169, 166, 166);
            font-size: 12px;
            margin-top: 3px;
            text-align: center;
        }
    }
</style>