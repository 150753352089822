<template>
  <div>
    <div class="dd" @click="getllq">
        {{code==""?'没有':'有'}}code
    </div>
  </div>
</template>

<script>
import * as dd from "dingtalk-jsapi";
export default {
  data(){
    return{
      code:''
    }
  },
  created() {
    this.getllq();
  },

  methods: {
   

    getllq() {  
      console.log(dd);
       dd.ready(function() {    
    dd.runtime.permission.requestAuthCode({        
        corpId: "dingac4d9d3ff6f02ca7", // 企业id        
        onSuccess: function(info) {            
            this.code = info.code, 
            this.goLogin(this.code)            
         },       
         onFail: function(fail) {         
               console.log('error')       
         }    
      })
    })
      // console.log(dd);
      // const zz = window.navigator.userAgent;
      // if (zz.indexOf("DingTalk") !== -1) {
      //   alert('这是钉钉')
      
         
     
      // }
      // const ua = window.navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //   alert("这是微信浏览器");
      // }
    },

    goLogin(code){
      alert(code)
    }
  },
};
</script>

<style>
.dd{
    width: 200px;
    height: 200px;
    background: red;
}
</style>
