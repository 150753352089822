<template>
  <div>
    <div class="top-info" v-show="show" @click="backTop">
        <p>
            <span>{{num}}</span>
            /
            <span>{{count}}</span>
        </p>
        <p style="text-align: center;">
            <span class="iconfont icon-xiangshang"></span><span style="font-weight: 400;font-size: .18rem;">TOP</span>
        </p>
    </div>
  </div>
</template>

<script>
import '../assets/download/font_3812170_1n53mwl9hjy/iconfont.css'
export default {
    props:{
        count:Number
    },
    data(){
        return {
            show:false,
            scrollTop:0
        }
    },
    computed:{
        num(){
            var num=Math.floor(this.scrollTop/50)>this.count ? this.count:Math.floor(this.scrollTop/50)
            
            return num
        }
    },
    mounted(){
      window.addEventListener('scroll',this.scrollToTop,true)

      },
      methods:{

     backTop () {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = document.documentElement.scrollTop - 200
        
        if (document.documentElement.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)

      // this.$emit('clear')
    },

  
      scrollToTop(el){
      let topBtn = document.getElementById("icon");
      let mask = document.querySelector(".top");
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||document.body.scrollTop;
       let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    //  console.log(scrollTop);
     this.scrollTop=scrollTop
      if(scrollTop>100){
     this.show=true
      }else{
        this.show=false
      }
      },
      //离开该页面需要移除这个监听事件不然会报错
      destroyed(){
        // 让这为true是要兼容移动端
      window.remmoveEventListenner("scroll",this.scrollToTop,true);
      }
      }
}
</script>

<style scoped>
.top-info{
    padding: 0.1rem 0.15rem 0.06rem 0.1rem;
    background-color: hsla(0,0%,100%,.7);
    position: fixed;
    left: 0;
    top: 2.1rem;
    z-index: 290;
    color: #333;
    font-size: .23rem;
    border-radius: 0 0.1rem 0.1rem 0;
}


.icon-xiangshang{
    font-size: .18rem;
    font-weight: 800;
    color: #333;
}
</style>