<template>
  <div class="person" v-if="isShow">
    <div class="banner img-box">
      <img src="../../public/wx_images/banner-person.jpg" alt="学术会议海报" />
    </div>
    <ul class="nav">
      <!-- <li
        class="item item-person-info"
        @click="navigate('/personinfo', '个人信息')"
      >
        <i class="icon"></i>
        <b class="text">个人信息</b>
      </li> -->


      <!-- <li
        class="item item-person-info"
        @click="navigate(`/initiation/0`, '个人信息')"
      >
        <i class="icon"></i>
        <b class="text">入会申请</b>
      </li> -->

      <!-- <li
        class="item item-person-info"
        @click="navigate(`/entrance`, '会员中心')"
      >
        <i class="icon"></i>
        <b class="text">会员中心</b>
      </li> -->

      <!-- 以后用到 -->
      <!-- <li
        class="item item-person-info"
        @click="navigate(`/MeetingPerson/1`, '个人中心')"
      >
        <i class="icon"></i>
        <b class="text">个人中心</b>
      </li> -->


            <!-- <li
        class="item item-person-info"
        @click="navigate(`/meetinghotellist/0`, '酒店预订记录')"
      >
        <i class="icon"></i>
        <b class="text">酒店预订记录</b>
      </li>

        <li
        class="item item-person-info"
        @click="navigate(`/apply`, '入会信息')"
      >
        <i class="icon"></i>
        <b class="text">入会信息</b>
      </li>


          <li
        class="item item-person-info"
        @click="navigate(`/meetingreglist/0`, '会议注册记录')"
      >
        <i class="icon"></i>
        <b class="text">会议注册记录</b>
      </li> -->
<!-- 
       <li
        class="item item-person-info"
        @click="navigate(`/learnaudit`, '个人信息')"
      >
        <i class="icon"></i>
        <b class="text">学会申请</b>
      </li> -->

      <!-- <li
        class="item item-ce-declare"
        @click="navigate('/cedeclare', '继教项目申报')"
      >
        <i class="icon"></i>
        <b class="text">继教项目申报</b>
      </li> -->
      <!-- <li
        class="item item-examine-list1"
        @click="navigate('/examinelist', '继教申报记录', 'ced')"
      >
        <i class="icon"></i>
        <b class="text">继教申报记录</b>
      </li> -->
      <!-- <li
        class="item item-examine-list2"
        @click="navigate('/examinelist', '会议注册记录', 'meeting')"
      >
        <i class="icon"></i>
        <b class="text">会议注册记录</b>
      </li> -->
      <!-- <li class="item item-login" @click="navigate('/login', '账号登录')">
        <i class="icon"></i>
        <b class="text">登录（方便展示）</b>
      </li> -->
      <!-- <li
        class="item item-referral" @click="navigate('/Referral', '推荐码登录')">
        <i class="icon"></i>
        <b class="text">委员申请</b>
      </li> -->

       <!-- <li
        class="item item-referral" @click="navigate('/initiation/0', '入会申请')">
        <i class="icon"></i>
        <b class="text">入会申请</b>
      </li>

      <li class="item item-member" @click="navigate('/healthSpecialist', '专家库')">
        <i class="icon"></i>
        <b class="text">会员风采</b>
      </li> -->



      <li class="item item-member" @click="navigate('/database', '专家库')">
        <i class="icon"></i>
        <b class="text">专家库</b>
      </li>

      <!-- <li class="item item-member" @click="navigate('/personInform', '消息通知')">
        <i class="icon"></i>
        <b class="text">消息通知</b>
      </li> -->
      <li class="item item-contact" @click="navigate('/contactUs', '联系我们')">
        <i class="icon"></i>
        <b class="text">联系我们</b>
      </li>

      <!-- <li class="item item-contact" @click="navigate('/Activityitem', '联系我们')">
        <i class="icon"></i>
        <b class="text">活动中心</b>
      </li> -->

<!-- 
      <li class="item item-logout" @click="logout">
        <i class="icon"></i>
        <b class="text">退出登录</b>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 页面显示开关
      isShow: true,
    };
  },
  methods: {
    // 跳转
    navigate(url, title, type) {
      if (type) {
        this.$router.push({
          path: url,
          query: {
            type,
          },
        });
      } else {
        this.$router.push(url);
      }
    },
    // 退出登录
    logout() {
      this.$dialog
        .confirm({
          title: "是否退出登录",
        })
        .then(() => {
          this.$store.commit("logout"); // 清空数据
          this.$store.commit("tipHandler", {
            _this: this,
            type: "success",
            text: "已退出登录",
            url: "/",
          });
          this.$router.push("/"); // 跳转
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      // vm.$store.commit("routerGuard", {
      //   // 调用守卫
      //   fromPath: from.path,
      //   _this: vm,
      // });
    });
  },
  beforeMount() {
    // 有token的情况下才显示
    // if (this.$store.state.user.token || localStorage.token) this.isShow = true; 
  },
};
</script>

<style lang="scss" scoped>
.person {
  .banner {
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.1rem;
    .item {
      width: 33.33%;
      padding: 0.25rem 0;
      text-align: center;
      .icon {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 0.64rem 0.64rem;
        border-radius: 50%;
        margin: 0 auto 0.1rem;
      }
      &.item-person-info .icon {
        background-color: #89c6ff;
        background-image: url(../../public/wx_images/person-icon-1.png);
      }
      &.item-ce-declare .icon {
        background-color: #b4a6fe;
        background-image: url(../../public/wx_images/person-icon-2.png);
      }
      &.item-examine-list1 .icon {
        background-color: #feb4a9;
        background-image: url(../../public/wx_images/person-icon-3.png);
      }
      &.item-examine-list2 .icon {
        background-color: #ffe56e;
        background-image: url(../../public/wx_images/person-icon-4.png);
      }
      &.item-login .icon {
        background-color: #fdc78b;
        background-image: url(../../public/wx_images/person-icon-5.png);
      }
      &.item-logout .icon {
        background-color: #fd6b77;
        background-image: url(../../public/wx_images/person-icon-5.png);
      }
      &.item-member .icon {
        background-color: #6b9cfddb;
        background-image: url(../../public/wx_images/person-icon-22.png);
      }
      &.item-contact .icon {
        background-color: #fd6be2;
        background-image: url(../../public/wx_images/meetingcenter-icon-5.png);
      }
      &.item-referral .icon {
        background-color: #6bfdf1;
        background-image: url(../../public/wx_images/meetingcenter-icon-4.png);
      }
      .text {
        color: #000000;
        font-size: 0.24rem;
      }
    }
  }
}
</style>
