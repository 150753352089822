<template>
  <div>
    <!-- 轮播图 -->
        <Carousel :images="images" :CoverImg="CoverImg"></Carousel>
        <div style="margin-top:10px" v-for="(k,index) in list" :key="index">
            <p class="title" >{{k.Name}}</p>
            <ul class="box">
                <!-- 列表图 -->
                <li class="item" v-for="(z,num) in k.ShowRoomListImg" :key="num" @click="skip(z)">
                    <img :src="$store.state.imageUrl+z.CoverImg" alt="" style="border-radius: 6px;">
                    <div class="info">
                       <p>{{z.Name}}</p>
                       <!-- <span>6观看</span> -->
                    </div>
                </li>
            </ul>
            <!-- 背景图 -->
            <li v-for="(c,aoumt) in k.ShowRoomBackgroundImg" :key="aoumt" style="padding: .2rem;">
                <div>
                    <p class="title">{{c.Name}}</p>
                </div>
                <img :src="$store.state.imageUrl+c.CoverImg" alt="" style="width:100%">
            </li>
        </div>
        
         <!-- 子级循环 -->
            <div>
                
            </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel'
import axios from "axios";
export default {
    components:{
        Carousel
    },

    data(){
        return {
            data:'',
            images:'',
            list:[],
            // 轮播图
            ShowRoomCoverImg:[],
            // 背景图
            ShowRoomBackgroundImg:[],
            id:this.$route.params.id,
            parentId:this.$route.params.parentId
        }
    },

    mounted(){
        if(this.$route.params.id){
            localStorage.setItem('GalleryDetailId',this.$route.params.id)
            localStorage.setItem('GalleryDetailparentId',this.$route.params.parentId)
        }
        this.GetShowRoom()
    },
    methods:{
        async GetShowRoom(){
            const res=await axios({
            url: this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetShowRoom?ParentMaster=${localStorage.getItem('GalleryDetailId')}&MeetingId=${localStorage.getItem('GalleryDetailparentId')}`
            })
                
            try{
            
                this.images=JSON.parse(res.data.Data[0].ShowRoomCoverImg)
                if(this.images.length==1){
                   this.CoverImg= this.images[0].CoverImg
                }
                
                res.data.Data.forEach((k,index)=>{
                    k.ShowRoomBackgroundImg=JSON.parse(k.ShowRoomBackgroundImg)
                    k.ShowRoomListImg=JSON.parse(k.ShowRoomListImg)
                    k.ShowRoomCoverImg =JSON.parse(k.ShowRoomCoverImg )
                })
                this.list=res.data.Data
            }catch(err){

            }
        },

        
        skip(z){
            location.href=z.Url
        }
    }
}
</script>

<style lang="scss" scoped>
*{
    margin: 0;
    padding: 0;
}   

    .box{
        width: 92%;
        background: #f5f9ff;
        padding: .2rem;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 10px auto;
    }

    li{
        list-style: none;
    }

    .item{
        width: 100%;
        height: 1.5rem;
        display: flex;
        margin: 0px auto 20px auto;
        img{
            width: 50%;
            height: 100%;
        }
    }

    .item:last-child{
        margin-bottom: 0;
    }

    .info{
        margin-left: 10px;
        position: relative;
        width: 50%;

        p{
            font-weight: 800;
        }

        span{
            position: absolute;
            bottom: 5px;
            left: 0;
            color: #ccc;
            font-size: 13px;
        }
    }

    .title{
        margin: 25px auto 10px auto; 
        text-align: center;
        font-size: 18px;
        color: #006cff;
        font-weight: 800;
    }

</style>