<template>
  <div >
    <div class="person">
      <img :src="this.$store.state.imageUrl+list.Icon"  :onerror="this.default" alt="" />
      <p>{{list.Name}}</p>
    </div>
    <div>
      <div>
        <h3>基本资料 :</h3>
        <van-cell-group inset>
          <van-cell title="所属单位" :value="list.Unit" />
          <van-cell title="所属分会" :value="LearnName" />
          <van-cell title="职务" :value="list.JobTitle" />
          <van-cell title="职称" :value="list.TechnologyJobTitle" />
        </van-cell-group>
      </div>
      <div class="part">
          <h3>社会兼职 :</h3>
          <p v-html="list.Learn_Post"></p>
      </div>
       <div class="brief">
          <h3>个人简介 :</h3>
          <p v-html="list.Experience"></p>
      </div>
      <div class="achievement">
          <h3>科研、学术成果 : </h3>
          <p class="articl" v-html="list.Technology"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id:this.$route.params.id,
     default:'this.src="'+require('../../../public/wx_images/mo.png')+'"',
      list: [],
      show:false,
      data:null,
      LearnName:''
    };
  },
  created() {
    this.GetUserApplyMsg();
    this.data=this.$route.params.item
  },
  methods: {
        GetUserApplyMsg(){
        axios({
            url:this.$store.state.apiUrl+`/actionapi/TencentApi/GetUserApplyMsg?Id=${this.id}`
        }).then((res)=>{
            this.list=res.data.Data[0]
            this.GetOrganize()
        }).catch((err)=>{
            console.log(err);
        })
        },

       GetOrganize(){
       axios({
          url:this.$store.state.apiUrl +"/actionapi/TencentApi/GetOrganize",
        }).then((res)=>{
          res.data.Data.forEach((key,index)=>{
            if(key.id==this.list.Learn ){
                this.LearnName=key.title
            }
          })
           
        }).catch((err)=>{
          console.log(err);
        })
    },
    modifyCss(html){
      html = html.replace('&ldquo;', ' ')
      html = html.replace('&rdquo;', ' ')
      html = html.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/ig, "")
      html = html.replace('南粤好医生', ' “南粤好医生” ')
      return html
    }
  },
};
</script>
<style lang="scss" scoped>
.van-cell{
  padding: 10px .225rem 10px .345rem;
  font-size: 0.26rem;
}
.person {
  margin: 20px auto;
  margin-bottom: 20px;
  width: 2.25rem;
  height: 3.695rem;
  text-align: center;
  img {
    width: 100%;
  }
  p {
    margin-top: 10px;
margin-bottom: 20px;
    font-size: 0.35rem;
  }
}

h3{
    color: #5580ad;
    padding-left: .489rem;
    margin-top: 20px;
}
.part p{
    margin-top: 10px;
    padding-left: .489rem;
    margin-right: 13px;

}
.brief{
    margin-top: 10px;
    p{
        padding: 10px 30px 10px 30px ;
    }
}
.achievement{
    margin-top: 10px;
}
.articl{
     padding: 10px 30px 10px 30px ;
}
</style>
