<template>
  <div class="register form-container">
    <div class="main form-main">
      <div class="header form-header">
        <em class="title">用户注册</em>
        <span class="tip">*为必填项</span>
      </div>
      <div class="form form-content">
        <div class="item text-input user-input">
          <div class="title must" :class="userClass">
            <span>用户名</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user"
              @blur="verifyUser"
              placeholder="请输入用户名"
            />
          </div>
          <div class="tip">
            <p>用户名可输入字母、数字、下划线，2-20位！</p>
          </div>
        </div>
        <div class="item text-input password-input">
          <div class="title must" :class="passwordClass">
            <span>设置密码</span>
          </div>
          <div class="content">
            <input
              type="password"
              v-model.trim="password"
              @blur="verifyPassword"
              placeholder="请输入密码"
            />
          </div>
          <div class="tip">
            <p>
              密码必须是字母大写、字母小写、数字、特殊字符中任意两种组合，8-20位！
            </p>
          </div>
        </div>
        <div class="item text-input confirm-input">
          <div class="title must" :class="confirmClass">
            <span>确认密码</span>
          </div>
          <div class="content">
            <input
              type="password"
              v-model.trim="passwordConfirm"
              @blur="verifyConfirm"
              placeholder="请再次输入密码"
            />
          </div>
        </div>
        <div class="item text-input name-input">
          <div class="title must" :class="nameClass">
            <span>真实姓名</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="name"
              @blur="verifyName"
              placeholder="请输入姓名"
            />
          </div>
        </div>
        <div class="item radio-input sex-input">
          <div class="title must" :class="sexClass">
            <span>性别</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in sexOption"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="sex"
                    :value="value.value"
                    @change="verifySex"
                  />
                </div>
                <div class="text" :class="{ checked: sex == value.value }">
                  <span>{{ value.text }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item text-input phone-input">
          <div class="title must" :class="phoneClass">
            <span>手机号码</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="phone"
              @blur="verifyPhone"
              @input="numberVerify"
              placeholder="请输入手机号码"
            />
          </div>
        </div>
        <div class="item text-input email-input">
          <div class="title must" :class="emailClass">
            <span>电子邮箱</span>
          </div>
          <div class="content">
            <input
              type="email"
              v-model.trim="email"
              @blur="verifyEmail"
              placeholder="请输入电子邮箱"
            />
          </div>
        </div>
        <div class="item text-input unit-input">
          <div class="title must" :class="unitClass">
            <span>工作单位</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="unit"
              @blur="verifyUnit"
              placeholder="请输入工作单位"
            />
          </div>
        </div>
        <div class="item submit" @click="submit">立即提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 用户名
      user: null,
      userClass: "",
      // 密码
      password: null,
      passwordClass: "",
      // 确认密码
      passwordConfirm: null,
      confirmClass: "",
      // 姓名
      name: null,
      nameClass: "",
      // 性别
      sexOption: [
        {
          value: "男",
          text: "男",
        },
        {
          value: "女",
          text: "女",
        },
      ],
      sex: null,
      sexClass: "",
      // 手机号
      phone: "",
      phoneClass: "",
      // 邮箱
      email: null,
      emailClass: "",
      // 工作单位
      unit: null,
      unitClass: "",
    };
  },
  methods: {
    // 验证用户名
    verifyUser() {
      if (!this.user) {
        this.userClass = "empty";
      } else if (!/^\w{2,20}$/.test(this.user)) {
        this.userClass = "fail";
      } else {
        // 调用加载动画
        this.$store.commit("loadHandler", {
          _this: this,
          boolean: true,
          text: "验证用户名",
        });
        // 数据库验证用户名是否存在
        axios
          .post(
            this.$store.state.apiUrl +
              "/actionapi/TencentApi/ValidationUserName?UserName=" +
              this.user
          )
          .then((res) => {
            // css名
            if (res.data.ResultCode) {
              this.userClass = "correct";
            } else {
              this.userClass = "occupy";
            }
            // 结束加载动画
            this.$store.commit("loadHandler", {
              _this: this,
              boolean: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 验证密码
    verifyPassword() {
      if (!this.password) {
        this.passwordClass = "empty";
      } else if (
        this.password.length < 8 ||
        this.password.length > 20 ||
        /^[a-z]+$/.test(this.password) ||
        /^[A-Z]+$/.test(this.password) ||
        /^[0-9]+$/.test(this.password) ||
        /^[`!@#$%^&*()\-+=/.,?><|\\[\]{}]+$/.test(this.password)
      ) {
        this.passwordClass = "fail";
      } else {
        this.passwordClass = "correct";
      }

      if (this.passwordConfirm) this.verifyConfirm();
    },
    // 验证重复密码
    verifyConfirm() {
      if (!this.passwordConfirm) {
        this.confirmClass = "empty";
      } else if (this.passwordConfirm !== this.password) {
        this.confirmClass = "error";
      } else {
        this.confirmClass = "correct";
      }
    },
    // 验证姓名
    verifyName() {
      if (!this.name) {
        this.nameClass = "empty";
      } else {
        this.nameClass = "correct";
      }
    },
    // 验证性别
    verifySex() {
      if (!this.sex) {
        this.sexClass = "empty";
      } else {
        this.sexClass = "correct";
      }
    },
    // 验证手机号码
    verifyPhone() {
      if (!this.phone) {
        this.phoneClass = "empty";
      } else if (!/^\d{11}$/.test(this.phone)) {
        this.phoneClass = "fail";
      } else {
        this.phoneClass = "correct";
      }
    },
    // 验证邮箱
    verifyEmail() {
      if (!this.email) {
        this.emailClass = "empty";
      } else if (!/.+?@.+?\..+?/.test(this.email)) {
        this.emailClass = "fail";
      } else {
        this.emailClass = "correct";
      }
    },
    // 验证工作单位
    verifyUnit() {
      if (!this.unit) {
        this.unitClass = "empty";
      } else {
        this.unitClass = "correct";
      }
    },
    // 限制只能输11位数字
    numberVerify() {
      if (this.phone.length > 11) this.phone = this.phone.substr(0, 11);
      this.phone = this.phone.replace(/\D/g, "");
    },
    // 城市选中事件
    areaSelect(data) {
      this.area = `${data[0].name}-${data[1].name}`;
      this.areaPopupShow = false;
    },
    // 城市选择取消
    areaCancel() {
      this.areaPopupShow = false;
    },
    // 日期选中事件
    dateSelect(data) {
      this.date = `${data.getFullYear()}-${
        data.getMonth() + 1
      }-${data.getDate()}`;
      this.datePopupShow = false;
    },
    // 提交事件
    submit() {
      // 加载动画
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      // 显示提示
      this.verifyPassword();
      this.verifyConfirm();
      this.verifyName();
      this.verifySex();
      this.verifyPhone();
      this.verifyEmail();
      this.verifyUnit();

      // 提交验证
      if (!this.user) {
        this.tip("请输入用户名");
        this.userClass = "empty";
      } else if (!/^\w{2,20}$/.test(this.user)) {
        this.tip("用户名格式错误");
        this.userClass = "fail";
      } else {
        // 数据库验证用户名是否存在
        axios
          .post(
            this.$store.state.apiUrl +
              "/actionapi/TencentApi/ValidationUserName?UserName=" +
              this.user
          )
          .then((res) => {
            // css名
            if (res.data.ResultCode) {
              this.userClass = "correct";

              if (!this.password) {
                this.tip("请输入密码");
              } else if (
                this.password.length < 8 ||
                /^[a-z]+$/.test(this.password) ||
                /^[A-Z]+$/.test(this.password) ||
                /^[0-9]+$/.test(this.password) ||
                /^[`!@#$%^&*()\-+=/.,?><|\\[\]{}]+$/.test(this.password)
              ) {
                this.tip("密码格式错误");
              } else if (!this.passwordConfirm) {
                this.tip("请再次输入密码");
              } else if (this.password !== this.passwordConfirm) {
                this.tip("两次输入密码不一致");
              } else if (!this.name) {
                this.tip("请输入真实姓名");
              } else if (!this.sex) {
                this.tip("请选择性别");
              } else if (!this.phone) {
                this.tip("请输入手机号");
              } else if (!/^\d{11}$/.test(this.phone)) {
                this.tip("手机号格式错误");
              } else if (!this.email) {
                this.tip("请输入邮箱");
              } else if (!/.+?@.+?\..+?/.test(this.email)) {
                this.tip("邮箱格式错误");
              } else if (!this.unit) {
                this.tip("请输入工作单位");
              } else {
                var json = {
                  UserName: this.user,
                  UserChName: this.name,
                  Password: this.password,
                  Phone: this.phone,
                  Mail: this.email,
                  Employer: this.unit,
                  Sex: this.sex,
                  UserType: 0,
                };
                json = JSON.stringify(json);
                // 提交给服务器
                axios({
                  method: "post",
                  url:
                    this.$store.state.apiUrl +
                    "/actionapi/TencentApi/AddUserRegister",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: json,
                })
                  .then((res) => {
                    if (res.data.ResultCode === 1) {
                      this.tip("注册成功", "success");
                      this.$store.commit("navbarHandler");
                      this.$router.back();
                    } else {
                      this.tip(res.data.Msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            } else {
              this.userClass = "occupy";
              this.tip("用户名已存在");
            }
          })
          .catch((err) => {
            console.log(err);
            this.submitting = false;
          });
      }
    },
    // 提示
    tip(text, type) {
      this.$toast.clear();
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
  },
  created() {
    /* this.$store.state.isTabbarShow = false */
  },
};
</script>

<style lang="scss" scoped>
/* 引入表单的样式，另外还有两个地方也需要用到，但是发现是全局的 */
@import "../../public/css/form.css";

.header {
  .title {
    background-image: url(../../public/wx_images/register-icon-1.png);
    background-size: 0.25rem 0.25rem;
  }
}

.form {
  .title {
    position: relative;
    &::after {
      display: block;
      padding-left: 0.4rem;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 0.27rem 0.27rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .user-input {
    .title {
      &.correct::after {
        content: "用户名可用";
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "用户名格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.occupy::after {
        content: "用户名已被占用，请重新输入！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "用户名不能为空！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .password-input {
    .title {
      &.correct::after {
        content: "密码填写正确";
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "密码格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "密码不能为空！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .confirm-input {
    .title {
      &.correct::after {
        content: "输入一致";
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.error::after {
        content: "两次输入的密码不一致！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请再次输入密码！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .name-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入姓名！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .sex-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请选择性别！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .phone-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "手机号格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请输入手机号码！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .email-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.fail::after {
        content: "邮箱格式不正确！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
      &.empty::after {
        content: "请输入邮箱！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
  .unit-input {
    .title {
      &.correct::after {
        content: "";
        height: 0.3rem;
        background-image: url(../../public/wx_images/register-icon-3.png);
        color: #50bc93;
      }
      &.empty::after {
        content: "请输入工作单位！";
        background-image: url(../../public/wx_images/register-icon-4.png);
        color: #fd6b6d;
      }
    }
  }
}
</style>
