<template>
  <div>

  </div>
</template>

<script>
export default {
  // 我的证书
    data(){
        return {
            
        }
    },
    methods:{

    }
}
</script>

<style>

</style>