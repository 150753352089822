<template>
  <div style="">
    <!-- <van-tabs v-model="active" line-width="100px" color="#3390ff" title-active-color="#3390ff"> -->
      <!-- <van-tab title="审核中"> -->
        <div v-if="show">
          <li class="item" @click="skip(k)" v-for="(k,index) in list" :key="index">
          <div class="type">
              <li>
                 {{k.MemberId==1?'个人会员':'单位会员'}}
              </li>
              <li :style="{color:k.State==1?'#33CCFF':'red'}" class="hint">
                {{ getState(k.State)}}
              </li>
          </div>
          <div class=" info">
            <p>工作单位：{{k.Unit}}</p>
            <p v-if="k.MemberId==1">职称：{{k.TechnologyJobTitle }}</p>
            <p v-if="k.MemberId==1">分会：{{k.title }}</p>
            <p v-else>法定代表人：{{k.LegalPerson }}</p>
          </div>

          <div class="time">
            {{k.AddTime}}
          </div>
        </li>
        </div>
      <van-empty description="暂未报名" v-else/>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      list:[],
      show:true,
      active:2,
      LearnId:[]
    }
  },

  created(){
    this.GetUserApply()
  },

  methods:{
    getState(state){
      if(state==4){
        return '已通过'
      }else{
        return '审核中'
      }
    },
    skip(k){
        this.$router.push({name:'applyDetails',params:{id:k.Id,MemberId:k.MemberId}})
    },
      GetOrganize(){
       axios({
          url:this.$store.state.apiUrl +"/actionapi/TencentApi/GetOrganize",
        }).then((res)=>{
          res.data.Data.forEach((key,index)=>{
            
            for (let i = 0; i < this.LearnId.length; i++) {
                if(key.id==this.LearnId[i]){
                  // console.log(key.title);


               }
            }


            
          })
            // if(this.hint=='change'){
            //   this.z=this.hint
            //   this.GetUserApplyMsg()
            // }
        }).catch((err)=>{
          console.log(err);
        })
    },
    GetUserApply(){
      axios({
        url:this.$store.state.apiUrl+`/actionapi/TencentApi/GetUserApply?OpenId=${localStorage.getItem('openid')}`
      }).then((res)=>{
        if(res.data.Data.length==0){
          this.show=false
        }
        this.list=res.data.Data
        this.list.forEach((item,index)=>{
          this.LearnId.push(item.Learn)
          item.AddTime=item.AddTime.split('')
          item.AddTime=item.AddTime.slice(0,10)
          item.AddTime=item.AddTime.join('')
        })
        this.GetOrganize()
      }).catch((err)=>{
        console.log(err);
      })
    }
  }
};
</script>

<style lang="scss" scoped>
 .item{
    width: 85%;
    margin: 0.3rem  auto;
    background-color : #FFFFFF ;
        padding : 0.15rem ;
        border-radius : 0.05rem ;
        box-shadow : 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1);
      
        .type{
          border-bottom: 1px solid #ccc;
          padding:3px  0 10px 0;
          font-weight: 800;
          display: flex;
          justify-content: space-between;
          .hint{
            font-weight: 400;
          }
        }
        .info{
           padding: 10px 0;
           border-bottom: 1px solid #ccc;
        }
        .time{
          padding: 5px 0 2px 0;
        }
 }
</style>
