<template>
  <div class="meeting-org meeting-info-common" v-if="data[0].content || data[1].content || data[2].content" :style="show==true?`background-image: linear-gradient(to bottom,${backcolor} , ${twoColor})`:`background:${backcolor}`">
    <Popups></Popups>
    <!-- <div v-else class="backUrl"></div> -->
    <div class="main">
      <ul class="article">
        <li class="row" v-for="(item, index) in data" :key="index" v-show="data[index].content">
          <h5 class="title" v-if="item.title">{{item.title}}</h5>
          <p class="content" v-html="item.content"></p>
        </li>
      </ul>
    </div>
      <div  class="backUrl" v-if="backShow" ref="back" :style="ishow==true?`position: fixed;bottom: 0;`:`''`">
      <img :src="$store.state.imageUrl+img_url" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Popups from "@/components/Popups.vue"
export default {
  components:{
    Popups
  },
  data () {
    return {
      twoColor:localStorage.getItem('twoColor'),
      backcolor:localStorage.getItem('oneColor'),
      img_url:localStorage.getItem('img_url'),
      images:require('../../public/wx_images/meetingcenter-img-1.jpg'),
              ishow:false,
      size:0,
      backShow:false,
      contentSize:0,
      show:true,
      data: [
        {
          title: '主办：',
          content: null
        },
        {
          title: '承办：',
          content: null
        },
        {
          title: '协办：',
          content: null
        }
      ]
    }
  },
    created(){
    if(this.img_url!=='null'){
      this.show=false
    }else{
      this.show=true
    }
  },
    mounted(){
    this.size=document.documentElement.clientHeight; //实时高度

 
  },
  methods: {

    
       distance () {
         this.backShow=localStorage.getItem('img_url')?true:false
      var con=document.querySelector('.content')
      this.contentSize=con.offsetHeight
      var backSize=this.$refs.back.offsetHeight


      if(this.size-this.contentSize>backSize){
        this.ishow=true
      }else{
        this.ishow=false
      }
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + '/actionapi/TencentApi/GetOrganize?MeetingId=' + id)
        .then((res) => {
          if (res.data.Data.length) {
            var resObj = res.data.Data[0]
            for (var prop in resObj) {
              if (resObj[prop]) resObj[prop] = resObj[prop].replace(/\n/g, '</br>')
            }
            if (resObj.auspice) this.data[0].content = resObj.auspice
            if (resObj.undertake) this.data[1].content = resObj.undertake
            if (resObj.co_operation) this.data[2].content = resObj.co_operation
          } else {
            console.log('获取数据为空')
          }

             setTimeout(()=>{
      this.distance()
    },100)

          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    }
  },
  beforeMount () {
    this.$store.commit('loadHandler', { // 调用加载动画
      _this: this,
      boolean: true
    })

    this.request(this.$route.params.id) // 调用请求方法
  }
}
</script>

<style lang="scss" scoped>
.meeting-org {
    position: relative;
    min-height: calc(100vh - 0.6rem);
  .main {
    .article {
      .row {
        .title {
          font-size : 16px ;
        }
        .content {
          font-size : 16px ;
        }
      }
    }
  }
}
.backUrl{
  width: 100%;
  height: 200px;

  img{
    width: 100%;
     height: 100%;
  }
  //  background-size: contain;
  z-index: 9;
}
.main{
  z-index: 99;
  position: relative;
}
</style>
