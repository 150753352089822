<template>
  <div>
        <div style="position: relative;">
        <!-- <img v-if="$store.state.meetingInfo.img_url==''||$store.state.meetingInfo.img_url==null" class="imgs" :src="img" :onerror="defaul" alt=""> -->
        <img v-if="data.Url==''" class="imgs" :src="$store.state.imageUrl+data.CoverImg" alt="" :onerror="defaul">
        <video v-else :src="data.Url" autoplay controls ></video>
        <div class="eye">
            <span class="iconfont icon-yanjing"></span>
            <span style="font-size:12px;">{{data.ClickBrowse}}</span>
        </div>
         <div class="live-box" >
        <ul class="tab">
            <li v-for="(k,index) in list" :key="k.Id"  :class="num==index?'z actitve' :'z'" @click="add(k,index)">
                {{k.Name}}
                <p class="across" v-show="num==index"></p>
            </li>
            <div class="matter" v-html="data.Agenda"></div>
        </ul>
        
        <!-- <li class="focus" @click="attention" ref="attention">关注</li> -->
        
        </div>
         
        <div class="title">
            <h2 style="padding:.2rem">{{data.Name}}</h2>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            data:null,
            list:[{Name:'会议议程',Id:'0002'},{Name:'互动',Id:'0002'}],
            num:0,
            ind:0,
             defaul:
        'this.src="' + require("../../public/wx_images/meetingcenter-img-1.jpg") + '"',
        }
    },

    created(){
        // 转成对象形式
        this.data=JSON.parse(this.$route.params.data)
    },

    methods:{
           add(k,index){
            this.num=index
            this.ind=k.Id
            if(k.Url=='Gallery'||k.Url=='Photo'){
                this.$router.push(`/${k.Url}/${this.$route.params.id}`)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    li{
        list-style: none;
    }

    .live-box{
        display: flex ;
        justify-content: space-around;
    }

    .imgs{
        width: 100%;     
    }

    .icon-yanjing{   
        font-size: 13px;
        font-weight: 800;
        margin-right: 4px;
    }

    .eye{
        position: absolute;
        top: 10px;
        right: 10px;
        padding: .01rem .22rem;
        line-height: 20px;
        border-radius: 25px;
        background: #000;
        opacity: .5;
        box-sizing: border-box;
        span{
            color: white;
        }
    }

    .tab{
        flex:1;
        display: flex;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
    }

    .z{
        width: 40%; 
        text-align: center;
        position: relative;
    }
    .actitve{
        color:#0095fe;
    }
    .focus{
        width: 22%;
        line-height: 40px;
        color: #000;
        font-weight: 700;
        font-size:14px;
    }
    .across{
        height: 3px;
        width: 30px;
        background: #0095fe;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
    ::v-deep .matter{
        width: 95%;
        position: absolute;
        top: 100px;
        text-align: left;
        // padding: .1rem .1rem .1rem .1rem;
        // margin: 10px;
        p{
            padding: .1rem;
            // height: 23px;
            // line-height: 23px;
        img{
            width: 100% !important;
            height: 200px !important;
        }
        }
       
    }
    .topic{
        display: flex;
        padding: .2rem 0;
        // justify-content: space-around;
    }
    .hint-info{
            padding: .1rem  0; 
            margin-left: 5px;
        // display: flex;
        // align-items: center;
    }
    .em{
        color:#747474;
        font-size: 12px;
    }
    .title{
        // padding: 0 .1rem;
        width: 95%;
        margin: 0 auto;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
    }

    video{
        width: 100%;
    }
</style>