<template>
  <div
    class="meeting-reg-pay form-container"
    v-if="costRadioList.length || costCheckboxList.length"
  >
    <div class="main form-main">
      <div class="form form-content">
        <div
          class="item radio-input"
          v-for="(item, index) in costRadioList"
          :key="index"
        >
          <div class="title must" v-if="item[0].ismandatory">
            <span>必选费用</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label
                class="radio-label"
                v-for="(value, key) in item"
                :key="key"
              >
                <div class="radio">
                  <input
                    type="radio"
                    v-model="radioSelect[index]"
                    :value="value.id"
                  />
                </div>
                <div
                  class="text"
                  :class="{ on: radioSelect[index] === value.id }"
                >
                  <div class="text-title">
                    <span>{{ value.name }}</span>
                    <b>CNY : {{ value.price.toFixed(2) }}</b>
                  </div>
                  <div class="text-content">
                    <p>{{ value.introduce || "暂无介绍" }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div
          class="item radio-input"
          v-for="(value, key) in costCheckboxList"
          :key="key"
        >
          <div class="title must" v-if="value.ismandatory">
            <span>必选费用</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label class="radio-label">
                <div class="radio">
                  <input
                    type="checkbox"
                    v-model="checkboxSelect"
                    :value="value.id"
                  />
                </div>
                <div
                  class="text"
                  :class="{ on: checkboxSelect.includes(value.id) }"
                >
                  <div class="text-title">
                    <span>{{ value.name }}</span>
                    <b>CNY : {{ value.price.toFixed(2) }}</b>
                  </div>
                  <div class="text-content">
                    <p>{{ value.introduce || "暂无介绍" }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <!-- 支付方式为支付宝才显示支付方式 -->
        <!-- <div class="item radio-input payment-method-input" v-if="$route.query.paymentMethod === 'alipay'">
          <div class="title must">
            <span>支付方式</span>
          </div>
          <div class="content">
            <div class="radio-container">
              <label class="radio-label" v-for="(value, key) in paymentMethodOption" :key="key">
                <div class="radio">
                  <input type="radio" v-model="paymentMethod" :value="value.value" :checked="value.default"/>
                </div>
                <div class="text" :class="{'checked' : paymentMethod == value.value}">
                  <img :src="value.icon" alt=""/>
                </div>
              </label>
            </div>
          </div>
        </div> -->
        <div class="count">
          <b class="count-title">费用总计：</b>
          <b class="count-number">{{ getSum }}</b>
        </div>
        <div class="item submit" @click="submit">立即提交</div>
      </div>

      <p>{{result}}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "md5";
/* import urlencode from 'urlencode' */

export default {
  data() {
    return {
      // 费用清单
      costList: [], // 显示的费用列表
      costTotalList: [], // 全部的费用列表
      orderRecord: [], // 已提交的费用
      costRadioList: [],
      radioSelect: [],
      costCheckboxList: [],
      checkboxSelect: [],
      // 支付方式，暂时默认支付宝
      paymentMethodOption: [
        /* {
          value: 'wechat',
          icon: require('../../public/wx_images/meetingregpay-icon-2.png')
        }, */
        {
          value: "alipay",
          icon: require("../../public/wx_images/meetingregpay-icon-3.png"),
        },
      ],
      paymentMethod: "alipay",
      // 选择结果
      cost: [],
      // 单价
      price: 500,
      // 费用统计
      count: 0,
      // 微信支付参数
      // 促健会的
      appid: "wxe6612b5250806f64", // 公众号的开发者id
      // 德加测试号appid
      // appid:'wx8c05b3a95d6792a1',
      key: "32965e8c784c9b9d8d38f157f33c8223", // 密码
      openid: null, // 从后端拿到的openid
      order: null, // 微信订单号
      // 支付宝支付参数
      orderNo: null, // 后台订单号
      orderName: "test", // 订单名称
      orderMoney: null, // 订单金额
      orderDesc: "test", // 订单描述
      backUrl:
        /.+?\/\/.+?\//g.exec(location.href)[0] +
        "alipay/" +
        this.$route.params.id, // 回调地址，只要当前无路由的地址+目标路由
      noticeUrl: "https://qinglin.cc/test/receive", // 通知地址



      // 微信支付用到的参数
      paySign:'',
      time:0,
      str1:'',
      signType:'',
      package:'',
      nonceStr:'',
      result:''
    };
  },
  methods: {
    // 限制只能输n位数字
    numberVerify(data, num) {
      if (data.length > num) data = data.substr(0, num);
      data = data.replace(/\D/g, "");
      return data;
    },
    // 获取随机字符串
    // getNonce() {
    //   var str = "";
    //   var num1, num2;
    //   for (var i = 0; i < 32; i++) {
    //     num1 = Math.random() - 0.5;
    //     num2 = Math.random() * 26;
    //     if (num1 > 0) {
    //       str += String.fromCharCode(Math.floor(num2 + 65));
    //     } else {
    //       str += String.fromCharCode(Math.floor(num2 + 97));
    //     }
    //   }
    //   return str;
    // },

    // 获取订单号
    getOrder() {
        var json={
        fee:this.orderMoney,
        body:"参会注册费",
        openid:localStorage.getItem('openid'),
        attach:"ceshi",
        bookingNo:this.orderNo,
        notify_url:"https://pay.weixin.qq.com/wxpay/pay.action"
        }
      
      axios({
        method: "post",
        url: this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetWXOrder",
        data: json,
      })
        .then((res) => {

          if (res.data) {
        this.order = res.data;
        this.orderMoney=0
        var obj=JSON.parse(res.data)
        this.time=obj.timeStamp
        this.signType=obj.signType
        this.paySign=obj.paySign
        this.package=obj.package
        this.nonceStr=obj.nonceStr
        this.$toast.loading({
        message: "提交中...",
        forbidClick: false,
        duration: 0,
        });
        this.pay();
          } else {
            console.log("后端没有返回订单号");
            this.tip('该订单已过期')
          }
        })
        .catch((err) => {
          console.log(err);
          this.orderMoney=0
          this.$toast.loading({
          message: "提交中...",
          forbidClick: false,
          duration: 0,
        });
      // this.$toast.fail(res.data);
      this.tip("获取失败");
        });
    },
    // 微信支付
    pay() {
      var payParameter = {
          appId: this.appid, // 公众号ID
        nonceStr: this.nonceStr, // 随机串
        package: this.package, // 订单号
        signType: this.signType,   // 微信签名方式
        timeStamp:this.time, //时间戳，自1970年以来的秒数
        // 微信签名，需要对以上五项进行字典排序，以key=value的格式用&连接成字符串再加上密码再进行MD5加密 */
        paySign:this.paySign,
      };
      
      WeixinJSBridge.invoke("getBrandWCPayRequest", payParameter, (res) => {
        this.result=res
        if (res.err_msg === "get_brand_wcpay_request:ok") {

          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          // window.location.href=`http://wx.zhanjma.org.cn/alipay/${this.$route.params.id}`
          this.$router.push({name:'Alipay',params:{id:this.$route.params.id,orderNo:this.orderNo}})
          // this.orderInfo()
          this.tip("支付成功");
        }else{
          this.$router.back()
          this.tip("取消支付");
        }
      });
    },

    // 成功后拿到订单信息

    // 支付宝支付
    alipay() {
      // 判断有没有拿到订单号和订单金额
      if (this.orderNo && this.orderMoney) {
        var _obj = {
          orderNo: this.orderNo,
          orderName: this.orderName,
          orderMoney: this.orderMoney,
          orderDesc: this.orderDesc,
          backUrl: this.backUrl,
          noticeUrl: this.noticeUrl,
        };
        var json = JSON.stringify(_obj);
  

        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/PaymentOrderApply",
          headers: {
            "Content-Type": "application/json",
          },
          data: json,
        })
          .then((res) => {
            if (res.data.ResultCode === 1) {
              // 把拿到的表单加到body中，在支付中转页再提交
              document.body.innerHTML += res.data.Content;
              this.$router.push("/alipay/" + this.$route.params.id);
            } else {
              this.tip(res.data.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("没有订单号或者订单金额");
      }
    },
    // 验证必选
    verify() {
      var boolean = true;
      this.costRadioList.forEach((item1) => {
        if (item1[0].ismandatory) {
          var _bool = item1.some((item2) => {
            return this.cost.includes(item2.id);
          });
          if (!_bool) {
            this.tip("有尚未选择的必选费用");
            boolean = false;
          }
        }
      });
      this.costCheckboxList.forEach((item) => {
        if (item.ismandatory) {
          var _bool = this.cost.includes(item.id);
          if (!_bool) {
            this.tip("有尚未选择的必选费用");
            boolean = false;
          }
        }
      });
   
      return boolean;
    },
    // 提交
    submit() {
      // 加载动画
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });

      // 把单选和复选的选择结果汇总到一起
      this.cost = [];
      if (this.radioSelect.length) {
        this.radioSelect.forEach((item) => {
          this.cost.push(item);
        });
      }
      if (this.checkboxSelect.length) {
        this.checkboxSelect.forEach((item) => {
          this.cost.push(item);
        });
      }

      // 如果this.cost有值，验证必选项并提交
      if (this.cost.length) {
        var boolean = this.verify(); // 调用验证必选方法

        if (boolean) {
          // 返回true表示通过
          // 提交表单给后端
          var json;
          var _obj = {
            meeting_id: this.$route.params.id, // 会议id
            open_id: this.$store.state.user.openid || localStorage.openid, // 微信openid
            sign_up_id: this.$route.query.MeetingRegisterId, // 报名记录id
            Items: [], // 用户选择的费用
          };

          this.cost.forEach((item1) => {
            var _arr = this.costList.filter((item2) => {
              return item2.id === item1;
            })[0];
            _obj.Items.push(_arr);
          });
          json = JSON.stringify(_obj);
     
          axios({
            method: "post",
            url:
              this.$store.state.apiUrl +
              "/actionapi/TencentApi/AddMeetingPayOrder",
            headers: {
              "Content-Type": "application/json",
            },
            data: json,
          })
            .then((res) => {
              // 如果状态码正确调用支付宝支付方法
              if (res.data.ResultCode === 1) {
                // 提交成功
                if (this.$route.query.paymentMethod === "alipay") {
                  // 如果支付方式是支付宝，进行支付宝支付
                  this.orderNo = res.data.ResultData[0].bill_number
                  localStorage.setItem('orderNo',res.data.ResultData[0].bill_number)
                  this.orderMoney = res.data.ResultData[0].total_amount
                  this.getOrder()
     
                } 
                else {
                  // 不是支付宝支付
                  this.$toast.clear();
                  this.$toast.loading({
                    type: "success",
                    forbidClick: true,
                    message: res.data.Msg,
                    onClose: () => {
                      this.$store.commit("setSessionStorage", {
                        type: "meetingperson",
                        meetingId: this.$route.params.id,
                      });
                      this.$store.commit("returnHandler", this);
                    },
                  });
                }
              } else {
                // 提交失败
                this.tip(res.data.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.tip("请选择费用");
      }

      // 调用获取订单和支付（微信）
      /* if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.getOrder, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.getOrder)
          document.attachEvent('onWeixinJSBridgeReady', this.getOrder)
        }
      } else {
        this.getOrder()
      } */
    },
    // 提示
    tip(text, type) {
      this.$toast.clear();
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
    // 过滤已提交的费用
    filterList() {
      var arr = this.costTotalList;
      if (this.orderRecord.length) {
      
        // 有提交过费用，以此过滤总菜单（未支付和已支付不可以）
        this.orderRecord.forEach((item1) => {
          // eslint-disable-next-line eqeqeq
          if (
            item1.is_pay == 0 ||
            item1.is_pay == 1 ||
            (item1.is_pay == 2 && item1.is_refunded == 0)
          ) {
            var feeId = item1.meeting_fee_id;
            // 获取对应id的对象
            var obj = arr.filter((item2) => {
              return item2.id === feeId;
            })[0];
            // 根据对象有无分组，做不同操作
            if (obj.grouping) {
              // 有分组，删除这个分组的所有
              arr = arr.filter((item3) => {
                return item3.grouping !== obj.grouping;
              });
            } else {
              // 没有分组，删除一条
              arr = arr.filter((item3) => {
                return item3.id !== obj.id;
              });
            }
          }
        });
      }
      if (arr.length) {
        // 过滤后的总菜单=要显示的菜单，再调用分组方法
        this.costList = arr;
        this.classifyInput();
      } else {
        // 如果过滤后的数组为空，说明所有类型的费用已提交过
        this.$toast.loading({
          type: "fail",
          forbidClick: true,
          message: "已提交过所有类型的费用",
          onClose: () => {
            // 跳转目标页，按正常来说是返回之前拦截的页面
            if (this.$store.state.history.length > 1) {
              this.$store.commit("returnHandler", this);
            } else {
              this.$router.push("/");
            }
          },
        });
      }
    },
    // 单选和复选分组
    classifyInput() {
      var groupingList = new Set();
      var _radioList = [];
      var _checkboxList = [];
      // 如果有分组值，放进set数组，这个数组不会重复，如果没有分组值，放进复选的数组
      this.costList.forEach((item) => {
        if (item.grouping) {
          groupingList.add(item.grouping);
        } else {
          _checkboxList.push(item);
        }
      });
      // 根据 分组值 数组，筛选出单选数组
      groupingList.forEach((item1) => {
        var _arr = this.costList.filter((item2) => {
          return item2.grouping === item1;
        });
        _radioList.push(_arr);
      });
      // 赋值
      this.costRadioList = _radioList;
      this.costCheckboxList = _checkboxList;

      // 结束加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: false,
      });
    },
    // 请求可选数据
    requestMenu(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingFee?MeetingId=" +
            id
        )
        .then((res) => {
         
          // 因为拿到的数据放在一起的，需要先分开一下
          if (res.data.Data && res.data.Data.length) {
            // 赋值给总菜单
            this.costTotalList = res.data.Data;
            // 调用获取已提交费用方法
            this.requestOrderRecord(this.$route.query.MeetingRegisterId);
          } else {
            console.log("获取数据为空");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 请求已提交数据
    requestOrderRecord(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetPayOrderBySignUp?SignUpId=${id}`
        )
        .then((res) => {
        
          var flag=false
          if(this.$route.query.paymentMethod=='alipay'&&this.$route.query.orderState=='未支付'){
              flag=true
              var num=0
              if(res.data.Data.length>1){
                res.data.Data.forEach((item,index)=>{
                  if(item.is_pay==0){
                    
                      num += item.pay_amount
                      this.orderNo=item.bill_number
                  }
               
                // debugger
              })
               
                this.orderMoney=num
            
              }else{
                this.orderMoney=res.data.Data[0].pay_amount
                this.orderNo=res.data.Data[0].bill_number
             
              }
              // this.orderNo=res.data.Data[0].bill_number
              this.getOrder()
              return
          }
          if (res.data.Data.length) {
            this.orderRecord = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          // 过滤已提交的费用
          if(flag==false){
            this.filterList()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    // 计算总数
    getSum() {
      var sum = 0;
      var _arr = [];

      if (this.radioSelect.length) {
        this.radioSelect.forEach((item) => {
          _arr.push(item);
        });
      }

      if (this.checkboxSelect.length) {
        this.checkboxSelect.forEach((item) => {
          _arr.push(item);
        });
      }

      this.costList.forEach((item, index) => {
        if (_arr.includes(item.id)) {
          sum += item.price;
        }
      });
      return sum.toFixed(2);
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
           vm.$store.commit('loadHandler', { // 调用加载动画
        _this: vm,
        boolean: false
      })
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    /* if (!this.$store.state.user.openid && !sessionStorage.openid) {
      // 如果没有存有openid的话跳转到拿openid页面
      this.$store.commit('setSessionStorage')
      this.$router.push('/getcodebase')
    } else {
      // 调用加载动画
      this.$store.commit('loadHandler', {
        _this: this,
        boolean: true
      })
      // 储存openid
      this.openid = this.$store.state.user.openid || sessionStorage.openid
      // 请求参会费用列表
      this.request(53)
    } */
    if (this.$store.state.user.openid || localStorage.openid) {
      // 调用加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: true,
      });
      this.requestMenu(this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-reg-pay {
  width: 100%;
  max-width: 6.4rem;
  min-height: calc(100% - 0.6rem);
  padding: 0.2rem 0 0.5rem;
  background-color: #f5f5f5;
  box-sizing: border-box;
  position: absolute;
  .main {
    .form {
      .item {
        padding: 0.2rem;
        background-color: #ffffff;
        border-radius: 0.1rem;
        .title {
          margin-bottom: 0.15rem;
        }
        .content {
          margin-top: 0;
          .title {
            font-size: 0.26rem;
            font-weight: bold;
          }
          .radio-label {
            width: 100%;
            margin-right: 0;
            .text {
              height: auto;
              padding: 0.25rem;
              border: solid 1px #a6a6a6;
              border-radius: 0.1rem;
              margin-top: 0.2rem;
              text-align: left;
              line-height: normal;
              position: relative;
              transition: 0.3s;
              overflow: hidden;
              &::after {
                content: "";
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                background: url(../../public/wx_images/meetingregpay-icon-1.png)
                  no-repeat center center / 100% 100%;
                opacity: 0;
                position: absolute;
                right: -0.01rem;
                bottom: -0.01rem;
                transition: 0.3s;
              }
              &.on {
                border-color: #1a90ff;
                &::after {
                  opacity: 1;
                }
              }
              .text-title {
                display: flex;
                flex-direction: column;
                padding-bottom: 0.15rem;
                border-bottom: solid 1px #a6a6a6;
                span {
                  margin-bottom: 0.2rem;
                  font-size: 0.28rem;
                  font-weight: bold;
                }
                b {
                  color: #eb6337;
                  font-size: 0.3rem;
                  font-weight: bold;
                }
              }
              .text-content {
                p {
                  margin-top: 0.2rem;
                  line-height: 0.4rem;
                }
              }
            }
            &:first-of-type .text {
              margin-top: 0;
            }
          }
        }
      }

      .payment-method-input {
        .content {
          .radio-label {
            width: 2.7rem;
            height: 0.86rem;
            margin-right: 0.2rem;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
            .text {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: 0.1rem;
              box-sizing: border-box;
              margin-top: 0;
              &::after {
                width: 0.3rem;
                height: 0.3rem;
              }
              &.checked {
                background-color: transparent;
                border-color: #1a90ff;
                &::after {
                  opacity: 1;
                }
              }
              /* &::after {
                display : none ;
              } */
            }
          }
        }
      }

      .submit {
        padding: 0;
        background-color: #4e9ef7;
        border-radius: 0.05rem;
      }

      .count {
        margin-top: 0.3rem;
        text-align: center;
        .count-title {
          margin-bottom: 0.2rem;
          font-size: 0.24rem;
          font-weight: bold;
        }
        .count-number {
          color: #eb6337;
          font-size: 0.3rem;
          font-weight: bold;
          &::before {
            content: "CNY : ";
          }
        }
      }
    }
  }
}
</style>
