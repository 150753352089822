<template>
  <div>
        <div v-html="Note">

        </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
          Note:localStorage.getItem('MeetingExpert')
        }
    },
    created(){

    },

    methods:{

    }
}
</script>

<style>

</style>