<template>
  <div class="branch-news list-common" v-show="branchNews.length">
    <div class="main">
      <div class="banner img-box">
        <img src="../../public/wx_images/banner-branch.jpg" alt="海报" />
      </div>
      <van-tabs ref="tab" :before-change="beforeChange" sticky>
        <van-tab
          v-for="(value, key) in branchNews"
          :title="value.Name"
          :key="key"
        >
          <ul class="list" v-if="value.Items.length">
            <li
              class="item"
              v-for="item in value.Items"
              :key="item.ID"
              @click="navigate(item.ID)"
            >
              <div class="text">
                <h4 class="title van-multi-ellipsis--l2">{{ item.Name }}</h4>
                <b class="time">{{ item.AddTime }}</b>
              </div>
              <div class="image" v-if="item.img_url">
                <van-image
                  width="1.5rem"
                  height="1.18rem"
                  :src="$store.state.imageUrl + item.img_url"
                />
              </div>
            </li>
          </ul>
          <van-empty v-else image="search" description="列表为空" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // 导航索引
      navIndex: 0,
      // 数据
      branchNews: [],
    };
  },
  methods: {
    // 切换进行前事件，返回ture才会进行切换
    beforeChange(_index) {
      if (_index === this.navIndex) return; // 点击当前索引也会执行，所以判断
      this.navIndex = _index;

      return true;
    },
    // 请求分会新闻（这里拿到的有二级菜单还有新闻列表）
    request(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetChapterLilt?id=" +
            id
        )
        .then((res) => {
          if (res.data.Data.length) {
            this.branchNews = res.data.Data;
            if (this.navIndex === 0) this.$refs.tab.scrollTo(0); // 插件有点bug，如果索引是0手动滑到第一页
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 点击标题跳转
    navigate(id) {
      this.$router.push({
        path: "/articledetail/" + id,
        query: {
          type: "branchnews",
        },
      });
    },
    // 滚动事件
    scrollHandler() {
      this.scrollTop = document.documentElement.scrollTop;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      // 调用加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: true,
      });
      // 设置navbar的标题
      if (this.$route.query.title)
        this.$store.commit("setNavbarTitlle", this.$route.query.title);
      // 调用请求分会新闻方法
      this.request(this.$route.params.id);
    }
  },
  // 缓存的钩子函数
  activated() {
    // 即使有缓存，导航插件还是会重置到第一个，所以手动滑到当前索引
    this.$refs.tab.scrollTo(this.navIndex);
    // 添加滚动事件
    window.addEventListener("scroll", this.scrollHandler);
    // 设置滚动条位置
    var timeout = setTimeout(() => {
      document.documentElement.scrollTop =
        this.$store.state.scrollTop.branchNews;
      clearTimeout(timeout);
      timeout = null;
    }, 0);
  },
  deactivated() {
    // 清除滚动事件
    window.removeEventListener("scroll", this.scrollHandler);
    // 保留当前的滚动条位置
    this.$store.commit("setScrollTop", {
      type: "branchNews",
      scrollTop: this.scrollTop,
    });
  },
};
</script>
