<template>
  <div class="getcode">
    
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      code: null,
      openid: null,
      url: location.href, // 获取code的回调网址
      appid: 'wx8c05b3a95d6792a1' // 公众号的开发者id
    }
  },
  methods: {
    // 获取openid
    getOpenId () {
      axios.get('https://qinglin.cc/test/getopenid?code=' + this.code)
        .then((res) => {
          if (res.data) {
            this.openid = res.data
            sessionStorage.openid = res.data
            this.$store.commit('storeUserOpenid', res.data)
          } else {
            console.log('没有拿到openid')
          }
          this.$router.back()
        })
        .catch((err) => {
          console.log(err)
          this.$router.back()
        })
    }
  },
  created () {
    if (!this.$store.state.user.openid && !this.openid) {
      if (this.$route.query.code) { // 如果有code，请求openid
        this.$store.commit('loadHandler', { // 调用加载动画
          _this: this,
          boolean: true
        })
        this.code = this.$route.query.code
        this.getOpenId()
      } else { // 如果没有code，请求code
        window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appid + '&redirect_uri=' + this.url + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      }
    } else {
      this.$router.back()
    }
  }
}
</script>
