<template>
  <div>
    <div class="box-item">
      <p
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="select(item.id)"
      >
       &nbsp; {{ item.title }}&nbsp;
      </p>
    </div>
  </div>
</template>
<script>

import axios from "axios";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.GetOrganize();
  },
  methods: {
        GetOrganize(){
       axios({
          url:this.$store.state.apiUrl +"/actionapi/TencentApi/GetOrganize",
        }).then((res)=>{
          this.list=res.data.Data

        }).catch((err)=>{
          console.log(err);
        })
    },
    select(id) {
      this.$router.push('/healthItemDetails/'+id);
    },
    
  },
};
</script>

<style scoped>
.box-item{
  margin-top: 20px;
}
.item {
  height: 0.6rem;
  font-weight: 600;

  display: inline-block;
  margin: 0 auto;
  color:  rgb(129, 159, 178);
  line-height: 0.6rem;
  border: 2px solid rgb(129, 159, 178);
  padding: 0 10px 0 10px;
  margin: .225rem 10px 0 10px;
  border-radius: 30px;
}
</style>
