<template>
<div v-if="show">
    <swiper v-if="images.length>1" class="swiper-container"  :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :pagination="{ clickable: true }"
      :autoplay="{ autoplay: true }"
      loop>
      <swiper-slide class="swiper-slide" v-for="(item, i) in images" :key="i">
        <img :src="$store.state.imageUrl+item.CoverImg" alt=""/>
      </swiper-slide>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
</swiper>
<img :src="$store.state.imageUrl+CoverImg" alt="" v-else>
</div>

</template>
<script>
  // Import Swiper Vue.js components
  // Import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
// Import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
    

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    props:{
      images:Array,
      CoverImg:String
    },
    data: function () {
      return {
        show:true,
        CoverImg:''
      //    images: [
      //   { src: require("../assets/wx_images/banner-bg3-m.png") },
      //   { src: require("../assets/wx_images/logo.png") },
      // ],
      };
    },
    created(){
      // console.log(typeof this.images);
      // debugger

      
    },
    mounted(){
       var z=typeof this.images
      if(z=='object'||z=="string"){
        this.show=false
      }
      console.log(this.images);
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
  };
</script>
<style scoped>
    .swiper-container {
  width: 100%;
  height: 3.34rem;
  /* border-radius: 0.1rem; */
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #248cc0;
  --swiper-navigation-color: #248cc0;
  --swiper-navigation-size: 20px;
}
.swiper-slide img{
  width: 100%;
}

</style>