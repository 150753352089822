<template>
  <div class="meeting-register form-container" v-if="isShow">
    <div class="main form-main">
      <div class="form form-content">

        <div class="item text-input unit-input">

          <div class="title must" :class="unitClass">
            <span>学会名称</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Name"
              @blur="verify('unit')"
              placeholder="请输入学会名称"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
          <!-- <div class="tip">
            <p>姓名 提交后无法修改</p>
          </div> -->
        </div>

        <div class="item text-input phone-input">
          <div class="title must" :class="phoneClass">
            <span>联系电话</span>
          </div>
          <div class="content">
            <input
              type="text"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="user.Phone"
              @blur="verify('phone')"
              @input="phone = numberVerify(phone, 11, 'number')"
              placeholder="请输入联系电话"
            />
          </div>
        </div>

        <div class="item text-input LegalPerson-input">
          <div class="title must" :class="LegalPersonClass">
            <span>申请人</span>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.ApplyName"
              @blur="verify('LegalPerson')"
              placeholder="请输入单位申请人"
              :disabled="
                $route.query.type === 'modify' && $store.state.meetingRegDetail
              "
            />
          </div>
          <!-- <div class="tip">
            <p>工作单位 提交后无法修改</p>
          </div> -->
        </div>


        <div
          class="item radio-input payment-method-input"
          v-if="costList.length"
        >
        
        
          <div class="tip">
            <p>缴费方式 提交后无法修改</p>
          </div>
        </div>
      
    
        <div class="item text-input name-input">
          <div class="title must" :class="nameClass">
            <text>邮箱</text>
          </div>
          <div class="content">
            <input
              type="text"
              v-model.trim="user.Email"
              @blur="verify('name')"
              placeholder="请输入邮箱"
            />
          </div>
        </div>
        
            <div class="item text-input LearnName-input">
          <div class="title must" :class="LearnNameClass">
            <text>申请时间</text>
          </div>
          <div class="content">
            <input
              type="text"
              @click="learnshow=true"
              v-model.trim="user.ApplyTime "
              @blur="verify('LearnName')"
              placeholder="申请时间"
            />
          </div>
             <van-popup v-model:show="learnshow" position="bottom" :style="{ height: '60%' }" >

                  <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
             </van-popup>
        </div>

        
        <div class="item text-input name-input">
          <div class="title must" :class="nameClass">
            <text>上传登记表</text>
          </div>
          <div class="content">
           <!-- <van-uploader :after-read="read">
             <van-button icon="plus" type="primary" >上传文件</van-button>
           </van-uploader> -->
            <input type="file" ref="file" @input="read">
          </div>
        </div>
        
        

        <div class="item submit" @click="submitJudge">{{z=="change"?'修改':'提交'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Dialog } from 'vant';
export default {
  props:['unit','hint',"requestId"],

  data() {
    return {
        
      user:{
       Name: '',//学会名称
       RegisterFile:'',// 登记表
       ApplyName:'' ,//申请人
       ApplyTime :'',//申请时间
       Phone:'',// 联系电话
       OpenId:localStorage.getItem('openid'),// 微信opeid
       Email:'',// 邮箱
      },

       minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
      LearnName:'',
      learnshow:false,
      LegalPersonClass:'',
      agreement:'22222222222',
      // 页面显示开关
      isShow: false,
      // 姓名
      name: null,
      nameClass: "",
      // 手机号
      phone: "",
      phoneClass: "",
      // 工作单位
      unit: null,
      unitClass: "",
      // 备注
      note: null,
      noteClass: "",
      // 科室/部门
      department: null,
      departmentClass: "",
      // 技术职称
     
      title: null,
      titleClass: "",
      // 最高学历

      LearnNameClass:'',

      invoice: null,
      invoiceClass: "",
      // 发票抬头
      invoiceTitle: null,
      invoiceTitleClass: "",
      // 税号
      tariffLine: null,
      tariffLineClass: "",
      // 邮箱
      email: null,
      emailClass: "",
      // 参会信息详情
      meetingRegDetail: null,
      // 已报名列表
      attendeeList: [],
      // 可选费用列表
      costList: [],
      type:[],
      num:'',
      z:''
    };
  },

  created(){
    this.GetOrganize()
    console.log(this.unit);
  },

    mounted(){
  
  },
    
  methods: {
    read(){
        var file=this.$refs.file.files[0]
        var formData=new FormData()
        formData.append('file',file)
        this.user.RegisterFile=formData
    },

    check(e){
        console.log(e.target.checked);
    },
hint(i){
  if(i.value=='giro'){
    Dialog.confirm({
  title: '以下单位才能单位转账',
  message: '湛江中心人民医院、湛江市第一中医医院、湛江市第二中医医院、湛江市第四人民医院、中国人民解放军南部战区海军第一医院、湛江市妇幼保健计划生育服务中心',
  })
  .then(() => {
    // on confirm
  })
  .catch(()=>{

  })
  }else{
    return
  }
  

},

    GetOrganize(){
       axios({
          url:this.$store.state.apiUrl +"/actionapi/TencentApi/GetOrganize",
        }).then((res)=>{
          this.type=res.data.Data
            if(this.hint=='change'){
              this.z=this.hint
              this.GetUserApplyMsg()
            }
        }).catch((err)=>{
          console.log(err);
        })
    },
    select(k,index){
       this.LearnName=k.title
      this.num=index
      this.user.Learn=k.id
     
      this.learnshow=false
    },

    // 限制只能输n位数字
    numberVerify(data, num, type) {
      if (data.length > num) data = data.substr(0, num);
      if (type === "number") data = data.replace(/\D/g, "");
      return data;
    },
    // 表单输入验证
    verify(type) {
      if (type) {
        switch (type) {
          case "name":
            if (!this.user.Name) {
              this.nameClass = "empty";
            } else {
              this.nameClass = "correct";
            }
            break;
          case "phone":
            if (!this.user.Phone) {
              this.phoneClass = "empty";
            } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.user.Phone)) {
              this.phoneClass = "fail";
            } else {
              this.phoneClass = "correct";
            }
            break;
          case "unit":
            if (!this.user.Unit) {
              this.unitClass = "empty";
            } else {
              this.unitClass = "correct";
            }
            break;
          case "LegalPerson":
            if (this.user.LegalPerson) {
              this.LegalPersonClass = "correct";
            }else{
              this.LegalPersonClass="empty"
            }
            break;
          case "LearnName":
            if (this.LearnName) {
              this.LearnNameClass = "correct";
            }else{
              this.LearnNameClass="empty"
            }
            break;
          case "title":
            if (this.title) {
              this.titleClass = "correct";
            }
            break;
          case "qualification":
            if (this.qualification) {
              this.qualificationClass = "correct";
            }
            break;
          case "paymentMethod":
            if (!this.paymentMethod) {
              this.paymentMethodClass = "empty";
            } else {
              this.paymentMethodClass = "correct";
            }
            break;
     
        }
      } else {
       
      }
    },
    // 提交判断
    submitJudge() {
      
      // 加载动画
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      /* 标题处提示 */
      this.verify();
      /* 提交验证 */
      if (!this.user.Name) {
        this.tip("请输入联系人");
        return
      } else if (!this.user.Phone) {
        this.tip("请输入手机号");
        return
      } else if (!/^\d{11}$/.test(this.user.Phone)) {
        this.tip("请正确输入手机号");
        return
      } else if (!this.user.Unit) {
        this.tip("请输入工作单位");
        return
      } else if (this.user.Learn=='') {
        this.tip("请选择分会");
        return
      }else{
        if(this.z=="change"){
          this.updata()
        }else{
          this.submit()
        }
      }
       

    },

    // 获取详情的接口
    GetUserApplyMsg(){
            axios({
                url:this.$store.state.apiUrl+`/actionapi/TencentApi/GetUserApplyMsg?Id=${this.requestId}`
            }).then((res)=>{
                console.log(res);
                // this.data=res.data.Data[0]
                  Object.keys(this.user).forEach((key) => {
                 this.user[key] = res.data.Data[0][key];
                   });
                   this.user.Id=this.requestId
                     var num=this.user.Learn
                   this.type.forEach((k,index)=>{
                   
                    if(k.id==num){
                      // console.log(k);
                      this.LearnName=k.title
                    }
                   })

            }).catch((err)=>{

            })
      },

    // 提交给服务器
    submit() {
        // console.log(this.unit);
        var unit=JSON.parse(localStorage.getItem('unit'))
        this.user.MemberId=unit.Id
        this.user.RoleId=unit.RoleId
        this.user.RoleType=unit.RoleType
      
        // obj.sign_up_id = this.meetingRegDetail.MeetingRegisterId;
        // var json = JSON.stringify(this.user);
        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/AddUserApply",
          data: this.user,
        })
          .then((res) => {
            console.log(res);
          if(res.data.Msg=="提交成功!"){
            this.tip('申请成功')
          }
          })
          .catch((err) => {
            console.log(err);
          });
      
      
    },


      updata() {
        axios({
          method: "post",
          url:
            this.$store.state.apiUrl +
            "/actionapi/TencentApi/UpdateUserApply",
          data: this.user,
        })  
          .then((res) => {
            console.log(res);
          if(res.data.Msg=="保存成功!"){
            this.tip('修改成功')
          }
          })
          .catch((err) => {
            console.log(err);
          });
      
      
    },

    // 提示
    tip(text, type) {
      if (type) {
        this.$toast.success(text);
      } else {
        this.$toast.fail(text);
      }
    },
    // 查询已报名列表
    requestAttendeeList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetMeetingSignUp?open_id=${
              this.$store.state.user.openid || localStorage.openid
            }&MeetingId=${id}`
        )
        .then((res) => {
          if (res.data.Data.length) {
            this.attendeeList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          // 调用查询可选费用列表方法
          this.requestCostList(this.$route.params.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询可选费用列表
    requestCostList(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingFee?MeetingId=" +
            id
        )
        .then((res) => {
          if (res.data.Data && res.data.Data.length) {
            // 赋值给费用菜单，如果长度为0，不会显示费用相关的东西
            this.costList = res.data.Data;
          } else {
            console.log("获取数据为空");
          }
          this.isShow = true; // 显示页面
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    // 验证当前提交值有没有和已报名的信息重复（姓名、单位、手机）
    verifyRepeat() {
      var boolean = true;
      if (this.attendeeList.length) { 
        console.log(this.attendeeList);
        this.attendeeList.forEach((item) => {
          
          // 如果姓名、单位、手机都相同，且状态不是已取消
          if (
            this.name === item.attend_people &&
            this.phone === item.link_my &&
            this.unit === item.company &&
            item.state !== 99
          ) {
            // 如果查到的只是修改的id，跳过
            if (
              this.meetingRegDetail &&
              this.meetingRegDetail.MeetingRegisterId
            ) {
              if (
                this.meetingRegDetail.MeetingRegisterId !==
                item.MeetingRegisterId
              ) {
                boolean = false;
              }
            } else {
              boolean = false;
            }
          }
        });
      }
      return boolean;
    },
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入拦截
    next((vm) => {
      vm.$store.commit("routerGuardWechat", {
        // 调用守卫
        fromPath: from.path,
        _this: vm,
      });
    });
  },
  beforeMount() {
    if (this.$store.state.user.openid || localStorage.openid) {
      // 调用加载动画
      this.$store.commit("loadHandler", {
        _this: this,
        boolean: true,
      });
      // 调用查询已报名列表方法
      this.requestAttendeeList(159);

      // 如果query里类型是modify，表示修改，先读取数据
      if (
        this.$route.query.type === "modify" &&
        this.$store.state.meetingRegDetail
      ) {
        this.meetingRegDetail = this.$store.state.meetingRegDetail;
        this.name = this.meetingRegDetail.attend_people;
        this.phone = this.meetingRegDetail.link_my;
        this.unit = this.meetingRegDetail.company;
        this.department = this.meetingRegDetail.departments;
        this.title = this.meetingRegDetail.title;
        this.qualification = this.meetingRegDetail.education;
        this.paymentMethod = this.meetingRegDetail.delivery_way;
        this.invoice = this.meetingRegDetail.IsInvoice;
        this.invoiceTitle = this.meetingRegDetail.invoiceTitle;
        this.tariffLine = this.meetingRegDetail.tariffLine;
        this.email = this.meetingRegDetail.email;
        this.note = this.meetingRegDetail.notes;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.meeting-register {
  padding: 0.2rem 0 0.5rem;
  .main {
    .form {
      .title {
        position: relative;
        &::after {
          display: block;
          padding-left: 0.4rem;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 0.27rem 0.27rem;
          line-height: 0.31rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .name-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入联系人名称！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit{
        
        padding: 5px;
      }
      .phone-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "手机号格式不正确！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入手机号！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .unit-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入单位名称！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .LegalPerson-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
           &.empty::after {
            content: "请输入法定代表人！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .LearnName-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
            &.empty::after {
            content: "请选择分会";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .title-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .qualification-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
        }
      }
      .payment-method-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择支付方式！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .invoice-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请选择是否需要开具发票！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .invoice-title-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入发票抬头！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .tariff-line-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.empty::after {
            content: "请输入税号！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
      .email-input {
        .title {
          &.correct::after {
            content: "";
            height: 0.3rem;
            background-image: url(../../../public/wx_images/register-icon-3.png);
            color: #50bc93;
          }
          &.fail::after {
            content: "邮箱格式不正确！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
          &.empty::after {
            content: "请输入邮箱！";
            background-image: url(../../../public/wx_images/register-icon-4.png);
            color: #fd6b6d;
          }
        }
      }
    }
  }
}


 .type{
        padding: 10px;
    }
    .liang{
        background: #0089fd !important;
        color: white;
    }
    .select-type{
        display: flex;
        flex-wrap: wrap;
        li{
            // width: 20%;
            // border: 1px solid red;
            border-radius: 20px;
            background: #e1e6ea;
            text-align: center;
            padding: 5px 12px;
            margin-right: 10px;
            margin-top: 20px;
        }
    }
    .check-type{
      position: absolute;
      right: 20px;
      top: 45px;
    }

    .van-button--normal{
        width: 150px;
    }
</style>





