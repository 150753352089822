<template>
  <div class="meeting-center" v-if="menu" :style="`background-image: linear-gradient(to bottom,${oneColor} , ${twoColor})`">
    <!-- 头部图片，title部分 -->
    <div class="banner img-box">
      <img
        v-if="menu.img_url"
        :src="$store.state.imageUrl + menu.img_url"
        alt="学术会议海报"
      />
      
      <div  class="img-container">
        <img
          v-if="img_url==''||img_url==null"
          src="../../public/wx_images/meetingcenter-img-1.jpg"
          alt="学术会议海报"
        />
         <img
           v-else
           :src="$store.state.imageUrl+img_url"
           alt="学术会议海报"
         />
      </div>
      <h2 class="title" v-if="img_url==''||img_url==null">{{ meetingTitle }}</h2>
    </div>
    <!-- 倒计时组件 -->
    <CountDown :start_tiem="start_tiem" style=" z-index:99999999"></CountDown>
    <!-- 列表弹出组件 -->
    <Popups class="Popups" :ListStylePosition="ListStylePosition" :ListImgShow="ListImgShow" :DataItem="DataItem" :master="master" v-if="ListShow"></Popups>
    <!-- 音乐组件 -->
    <Music class="music" :music="music" v-if="music !==''&&music!==null" @clear="clear"></Music>
    <!-- 图标部分 -->
    <div v-if="Initial==1" :style="{backgroundImage: `url(${$store.state.imageUrl+BackgroundImg})`}" class="BackgroundImg">
      <ul class="nav" >
      <li
        v-for="(k,index) in DataItem"
        :key="index"
        :class="Initial==1?`item ${icon[index]}`:`item ${icon[index]}` "
       
        @click="navigate(k)"
      >
        <i class="icon"></i>
        <b class="text">{{k.Name}}</b>
      </li>

      <!-- <li
        class="item invitation-item"
        v-if="menu.invite_on_ffo"
        @click="navigate('/meetinginvitation/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">邀请函</b>
      </li>
      
      <li
        class="item info-item"
        v-if="menu.basic_on_ffo"
        @click="navigate('/meetinginfo/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">基本信息</b>
      </li>
      <li
        class="item org-item"
        v-if="menu.organization_on_ffo"
        @click="navigate('/meetingorg/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">组织机构</b>
      </li>
      <li
        class="item agenda-item"
        v-if="menu.agenda_on_ffo"
        @click="navigate('/meetingagenda/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">大会议程</b>
      </li>
      <li
        class="item personreg-item"
        @click="navigate('/meetingregister/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">参会注册</b>
      </li>
      <li
        class="item traffic-item"
        v-if="menu.traffic_on_ffo"
        @click="navigate('/meetingtraffic/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">会场交通</b>
      </li>
      <li
        class="item hotel-item"
        v-if="menu.hotel_on_ffo"
        @click="navigate('/meetinghotel/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">酒店预订</b>
      </li>
      <li
        class="item person-item"
        @click="navigate('/meetingperson/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">个人中心</b>
      </li>
      <li
        class="item notice-item"
        v-if="menu.notice_on_ffo"
        @click="navigate('/meetingnotice/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">参会须知</b>
      </li>
      <li
        class="item contact-item"
        v-if="menu.contact_on_ffo"
        @click="navigate('/meetingcontact/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">联系我们</b>
      </li>
      <li
        class="item download-item"
        v-if="menu.data_on_ffo"
        @click="navigate('/meetingdownload/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">资料下载</b>
      </li>

      <li class="item live-item" v-if="menu.direct_on_ffo" @click="showQr">
        <i class="icon"></i>
        <b class="text">观看直播</b>
      </li>
      <li
        class="item playback-item"
        v-if="menu.recorded_broadcast_on_ffo"
        @click="navigate('/meetingplayback/' + $route.params.id)"
      >
        <i class="icon"></i>
        <b class="text">会议录播</b>
      </li> -->
    </ul>
    </div>
    
    <!-- 配置的 -->
   <div v-else :style="{backgroundImage: `url(${$store.state.imageUrl+BackgroundImg})`}" class="BackgroundImg">
     <ul class="nav-config" >
      <li v-for="(k,index) in DataItem" :key="index" class="item-config playback-item" @click="jump(k)">
         <img :src="$store.state.imageUrl+k.CoverImg" alt="" class="icon-config"  :onerror="defaul">
         <!-- <p class="icon-config" :style="{backgroundImage:`url('${$store.state.imageUrl+k.CoverImg}')`}"></p> -->
         <b class="text" :style="{color:k.Color}">{{k.Name}}</b>
      </li>
    </ul>
   </div>


    <div class="link-content" v-show="qrShow" @click.self="qrShow = false">
      <div class="container">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="text">
          <div class="url">
            <span v-if="menu.direct_url">{{ menu.direct_url }}</span>
          </div>
        </div>
        <div class="link-button" @click="navigateLink">
          <span class="button-text">跳转链接</span>
        </div>
        <div class="close-button" @click="qrShow = false">
          <i class="icon"></i>
        </div>
      </div>
    </div>
    <!-- 底部图片 -->
    <div class="BottomImg" v-if="BottomImg!==''">
      <img :src="$store.state.imageUrl+BottomImg" alt="">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcodejs2";
import Popups from "@/components/Popups.vue"
import Music from "@/components/Music.vue"
import CountDown from "@/components/CountDown.vue"
export default {
  components:{
    Popups,
    Music,
    CountDown
  },
  data() {
    return {
      // 显示菜单
      oneColor:'',
      twoColor:'',
      menu: null,
      // 会议名称
      meetingTitle: null,
      // 跳转二维码
      qrcode: null,
      qrShow: false,
      DataItem:'',
      BackgroundImg:'',
      BottomImg:'',
      ListStylePosition:'',
      music:'',
      ListShow:'',
      master:0,
      img_url:'',
      start_tiem:'',
      ListImgShow:'',
      Initial:0,
      icon:['welcome-item','invitation-item',
      'info-item','org-item','agenda-item',
      'personreg-item','traffic-item',
      'hotel-item','person-item','notice-item',
      'contact-item','article-item',
      'live-item','playback-item','download-item',
      'default-item','default-item','default-item','default-item',
      'default-item'],
          defaul:
        'this.src="' + require("../../public/wx_images/meetingcenter-img-1.jpg") + '"',
    }
  },
  methods: {
    // 请求数据，参数为会议id
    requestMenu(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetWapMenuConfig?MeetingId=" + 
            id
        )
        .then((res) => {
          
        //  console.log(res);
          if (res.data.Data.length) {
            var resObj = res.data.Data[0];
            // 如果有海报地址，去掉~，如果没有请求会议标题
            if (resObj.img_url) {
              resObj.img_url = resObj.img_url.replace(/^~/, "");
            
              this.$store.commit("loadHandler", {
                // 结束加载动画
                _this: this,
                boolean: false,
              });
             
            }else{
              // localStorage.setItem('img_url',null)
            }
            //  this.requestInfo(id);
            // 如果有直播链接，判断有没有协议，没有就加上
            if (resObj.direct_url && !/^http/.test(resObj.direct_url))
              resObj.direct_url = "http://" + resObj.direct_url;
            this.menu = resObj;
            // console.log(this.menu);
          } else {
            // console.log("获取数据为空");
            this.menu=[{name:1}]
            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },

    async updateInfo(){
      const res=await axios({url: this.$store.state.apiUrl +
            `/actionapi/TencentApi/MeetingClick?MeetingId=${this.$route.params.id}`
            })
            try{
              console.log(res);
            }catch(err){
              console.log(err);
            }
    },
    // 请求会议基本信息接口拿会议名称
    requestInfo(id) {
      axios
        .get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/GetMeetingInfo?MeetingId=" +
            id
        )
        .then((res) => {

        this.DataItem=res.data.DataItem
        this.$store.commit('getRouteArry',this.DataItem)
        // this.$store.commit('getRouteName',this.DataItem)
          if(res.data.Data.length!==0){
              this.$store.commit("loadHandler", {
                // 结束加载动画
                _this: this,
                boolean: false,
              });
          }
          if(res.data.Data[0].color1==''){
            localStorage.setItem('oneColor','#fff') 
          }else{
            localStorage.setItem('oneColor',res.data.Data[0].color1) 
            localStorage.setItem('twoColor',res.data.Data[0].color2) 
          }
          this.oneColor=res.data.Data[0].color1
          this.twoColor=res.data.Data[0].color2
          this.master=res.data.Data[0].id
          this.music=res.data.Data[0].Music
          this.BackgroundImg=res.data.Data[0].BackgroundImg
          this.BottomImg=res.data.Data[0].BottomImg
          this.ListStylePosition=res.data.Data[0].ListStylePosition
          this.ListShow=res.data.Data[0].ListShow
          this.$store.commit('getListShow',{ListShow:this.ListShow,ListImgShow:res.data.Data[0].ListImgShow,ListStylePosition:res.data.Data[0].ListStylePosition})
          this.img_url=res.data.Data[0].img_url
       
          this.start_tiem=res.data.Data[0].start_tiem.replaceAll('-','/')
          this.start_tiem=this.start_tiem.replace('T',' ')
          localStorage.setItem('start_tiem',this.start_tiem)
          localStorage.setItem('title',res.data.Data[0].title)
          this.ListImgShow=res.data.Data[0].ListImgShow
          this.Initial=res.data.Data[0].Initial
            this.$store.commit('getMeeing',res.data.Data[0])
          if (res.data.Data.length) {
            this.meetingTitle = res.data.Data[0].title;
          } else {
            console.log("获取数据为空");
          }

          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loadHandler", {
            // 结束加载动画
            _this: this,
            boolean: false,
          });
        });
    },
    clear(timer){
      // console.log(timer);
      clearInterval(timer)
    },
    // 路由跳转
    navigate(k) {
      // console.log(k);
      if(k.Url=="MeetingExpert"){
        localStorage.setItem("MeetingExpert",k.Note)
      }
      this.$router.push(`/${k.Url}/${this.master}`);
    },

    // 跳外链
    jump(k){
      // console.log(location.pathname);
      // location.pathname=k.Url+`/${this.master}`
      this.$router.push(`/${k.Url}/${this.master}`)
    },

    // 展示跳转内容
    showQr() {
      // 如果拿到的数据有链接才展示
      if (this.menu.direct_url) {
        if (!this.qrcode) this.creatQrCode(this.menu.direct_url);
        this.qrShow = true;
      } else {
        this.$toast.fail("暂无直播链接");
      }
    },
    // 跳转到第三方直播页面
    navigateLink() {
      this.qrShow = false;
      this.$store.commit("setSessionStorage");
      location.href = this.menu.direct_url;
    },
    // 生成二维码
    creatQrCode(url) {
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
  beforeMount() {
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });
    // 如果从PC扫码进入的是这个页面，点后退也不会回首页，现在加一个判断，如果当前历史记录只有一条，加一条
    if (this.$store.state.history.length === 1) {
      this.$store.commit("setSessionStorage", {
        type: "meetingcenter",
        meetingId: this.$route.params.id,
      });
    }
    // 调用请求方法
    this.requestInfo(this.$route.params.id)
    this.requestMenu(this.$route.params.id);
    this.updateInfo()
  },
};
</script>

<style lang="scss" scoped>
.meeting-center {
  width: 100%;
  max-width: 6.4rem;
  height: calc(100% - 0.6rem);
  box-sizing: border-box;
  position: absolute;
  overflow: auto;
  
  
  .banner {
     position: relative;
    .img-container {
      position: relative;
      z-index: 99999;
    }
  }

     .title {
        width: 80%;
        // height: 30px;
        color: #ffffff;
        font-size: 0.3rem;
        font-weight: bold;
        letter-spacing: 0.02rem;
        line-height: 0.5rem;
        position: absolute;
        left: 50%;
        top: 49%;
        transform: translate(-50%, -50%);
        text-align: left;
        z-index: 9999999;
      }


      .nav-config{
           display: flex;
    flex-wrap: wrap;
    padding: 0.1rem 0 0.4rem;
    .item-config {
      width: 33.33%;
      padding: 0.25rem 0;
      text-align: center;
      z-index: 9999;
      .icon-config {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 0.64rem 0.64rem;
        border-radius: 50%;

        margin: 0 auto 0.1rem;
      }
      .text {
        /* color : #000000 ; */
        font-size: 0.24rem;
        
      }
      }
      }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding: 0.1rem 0 0.4rem;
    .item {
      width: 33.33%;
      padding: 0.25rem 0;
      text-align: center;
      z-index: 9999;
      .icon {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 0.64rem 0.64rem;
        border-radius: 50%;
        margin: 0 auto 0.1rem;
      }
      &.welcome-item .icon {
        background-color: #89c6ff;
        background-image: url(../../public/wx_images/meetingcenter-icon-16.png);
      }
      &.invitation-item .icon {
        background-color: #b4a6fe;
        background-image: url(../../public/wx_images/meetingcenter-icon-1.png);
      }
      &.info-item .icon {
        background-color: #feb4a9;
        background-image: url(../../public/wx_images/meetingcenter-icon-2.png);
      }
      &.org-item .icon {
        background-color: #ffe56e;
        background-image: url(../../public/wx_images/meetingcenter-icon-3.png);
      }
      &.agenda-item .icon {
        background-color: #fdc78b;
        background-image: url(../../public/wx_images/meetingcenter-icon-10.png);
      }
      &.personreg-item .icon {
        background-color: #89c6ff;
        background-image: url(../../public/wx_images/meetingcenter-icon-5.png);
      }
      /* &.teamreg-item .icon {
        background-color : #5ad6fa ;
        background-image : url(../../public/wx_images/meetingcenter-icon-11.png) ;
      } */
      &.traffic-item .icon {
        background-color: #5ad6fa;
        background-image: url(../../public/wx_images/meetingcenter-icon-6.png);
      }
      &.hotel-item .icon {
        background-color: #89ea8b;
        background-image: url(../../public/wx_images/meetingcenter-icon-12.png);
      }
      &.person-item .icon {
        background-color: #50e8b7;
        background-image: url(../../public/wx_images/meetingcenter-icon-9.png);
      }
      &.notice-item .icon {
        background-color: #e1acfe;
        background-image: url(../../public/wx_images/meetingcenter-icon-13.png);
      }
      &.contact-item .icon {
        background-color: #b4a6fe;
        background-image: url(../../public/wx_images/meetingcenter-icon-8.png);
      }
      &.article-item .icon {
        background-color: #feb4a9;
        background-image: url(../../public/wx_images/meetingcenter-icon-4.png);
      }
      &.live-item .icon {
        background-color: #ffe56e;
        background-image: url(../../public/wx_images/meetingcenter-icon-14.png);
      }
      &.playback-item .icon {
        background-color: #fdc78b;
        background-image: url(../../public/wx_images/meetingcenter-icon-15.png);
      }
      &.download-item .icon {
        background-color: #50e8b7;
        background-image: url(../../public/wx_images/meetingcenter-icon-7.png);
      }
      &.default-item .icon {
        background-color: #d468e5;
        background-image: url(../../public/wx_images/meetingdefault.png);
      }
      .text {
        /* color : #000000 ; */
        font-size: 0.24rem;
      }
    }
  }
  .link-content {
    width: 100%;
    max-width: 6.4rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 10;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 80%;
      padding: 0.8rem 0.4rem 0.8rem;
      background: #ffffff;
      border-radius: 0.1rem;
      box-sizing: border-box;
      margin: 1.6rem 0.4rem 0;
      position: relative;
      overflow: auto;

      .qrcode {
      }
      .text {
        line-height: 0.4rem;
        .url {
          margin-top: 0.2rem;
          font-size: 0.3rem;
          word-break: break-all;
        }
      }
      .close-button {
        width: 0.4rem;
        height: 0.4rem;
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
        .icon {
          display: block;
          width: 100%;
          height: 100%;
          background: url(../../public/wx_images/meetingplayback-icon-1.png)
            no-repeat center center / 100% 100%;
        }
      }
      .link-button {
        width: 100%;
        height: 0.6rem;
        background-color: #4e9ef7;
        text-align: center;
        line-height: 0.6rem;
        position: absolute;
        bottom: 0;
        .button-text {
          color: #ffffff;
          font-size: 0.26rem;
    
        }
      }
    }
  }
  .BottomImg{
    position: fixed;
    bottom: 0;
    z-index: 99;
  }
  .BackgroundImg{
    background-repeat: no-repeat; background-size: 100% 100%;height:470px;overflow-y: auto;
  }

  .Popups{
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 9999999;
  }
  .music{
    position: absolute;
    top:7px;
    right: 12px;
  }
  
}
</style>
