<template>
  <div class="detail">
    <div class="main">
      <div class="title">
        <h3>{{ article.title }}</h3>
      </div>
      <div class="info">
        <p class="author" v-if="article.unit">
          <span class="unit">{{ article.unit }}</span>
          <span class="issuer">{{ article.issuer }}</span>
        </p>
        <p class="other">
          <span class="time">{{ article.add_time }}</span>
          <span class="browse">{{ article.click }}</span>
        </p>
      </div>
      <div class="body" v-html="article.content"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      article: {},
      
    };
  },
  methods: {
    // 调整引用的html样式
    modifyCss(html) {
      // 附件添加换行和去掉空格
      html = html.replace(/(&nbsp;)(.+)(附件：)/g, "$3");
      html = html.replace(/(附件：)/g, "$1</br>");
      // 删除表格的固定宽
      html = html.replace(/(<table.+?)(width:.+?px)(.+?>)/, "$1width:100%$3");
      html = html.replace(/(<table.+?)(width:.+?pt)(.+?>)/, "$1width:100%$3");
    
      return html;
    },
    // 时间去掉一个T字符
    timeHandler() {
      var str = this.article.add_time;
      str = str.replace(/T/, " ");
      return str;
    },
    // 请求数据，参数为文章id
    request(id) {
      if (this.$route.query.type === "branch") {
        // 正确是branchnews，这个接口多余，且数据有问题（时间要处理，浏览量也没有）
        axios
          .get(
            this.$store.state.apiUrl +
              "/actionapi/TencentApi/GetChapterArticle?id=" +
              id
          )
          .then((res) => {
            
            if (res) {
              this.article = res.data.Data[0];
              this.article.add_time = this.timeHandler();
              this.article.content = this.modifyCss(this.article.content);
              
            } else {
              console.log("获取数据为空");
            }

            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          });
      } else {
        axios
          .get(
            this.$store.state.apiUrl +
              "/actionapi/TencentApi/GetArticle?ArticleId=" +
              id
          )
          .then((res) => {
            if (res) {
             
              this.article = res.data.Data[0];
              console.log(this.article.title);
              if (this.article.title.indexOf("章程") != -1) {
                
              }

              // var str=JSON.stringify(this.article)
              // console.log(str);
              this.article.add_time = this.timeHandler();
              this.article.content = this.modifyCss(this.article.content);
            } else {
              console.log("获取数据为空");
            }

            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("loadHandler", {
              // 结束加载动画
              _this: this,
              boolean: false,
            });
          });
      }
    },
  },
  mounted(){
     this.$store.state.navbar.backTitle=localStorage.getItem('ActicleDetailBackName')
  },
  beforeMount() {
    this.$store.commit("loadHandler", {
      // 调用加载动画
      _this: this,
      boolean: true,
    });
    this.request(this.$route.params.id); // 调用请求方法
  },
};
</script>

<style lang="scss" scoped>
@import "../../public/css/richtext.css";
body{
  width: 100px !important;
  img{
    width: 100%;
  }
} 

section{
  max-width: 100%;
}

.detail {
  background-color: #ffffff;
  max-width: 100% !important;
  overflow-x: hidden;
  .main {
    .title {
      margin-top: 0.45rem;
      h3 {
        color: #080808;
        font-size: 0.34rem;
        line-height: 0.5rem;
      text-align: center;
      }
      font{
        width: 95%;
      }
    }
    .info {
    
      .author {
        margin-top: 0.22rem;
        .unit {
          margin-right: 0.2rem;
          color: #3c507a;
          font-size: 0.22rem;
        }
        .issuer {
          color: #3c507a;
          font-size: 0.22rem;
        }
      }
      .other {
        display: flex;
        align-items: center;
     
        margin-top: 0.12rem;
        .time {
          padding-left: 0.28rem;
          background: url(../../public/wx_images/detail-icon-1.png) no-repeat
            left center / 0.22rem 0.22rem;
          margin-right: 0.2rem;
          color: #b2b2b2;
          font-size: 0.18rem;
        }
        .browse {
          padding-left: 0.34rem;
          background: url(../../public/wx_images/detail-icon-2.png) no-repeat
            left center / 0.28rem 0.28rem;
          color: #b2b2b2;
          font-size: 0.18rem;
        }
      }
    }
    .content {
      margin-top: 0.2rem;
    }
  }
}
</style>
