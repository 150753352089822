<template>
  <div class="person-modify form-container">
    <div class="main form-main">
      <div class="form form-content">
        <div class="item text-input" v-if="this.$route.query.key == 'name'">
          <div class="title must">
            <text>真实姓名</text>
          </div>
          <div class="content">
            <input type="text" v-model.trim="result" placeholder="请输入姓名"/>
          </div>
        </div>
        <div class="item radio-input" v-if="this.$route.query.key == 'sex'">
          <div class="title must">
            <text>性别</text>
          </div>
          <div class="content">
            <div class="radio-container">
              <label class="radio-label" v-for="(value, key) in sexOption" :key="key">
                <div class="radio">
                  <input type="radio" v-model="result" :value="value.value"/>
                </div>
                <div class="text" :class="{'checked' : result == value.value}">
                  <span>{{value.text}}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="item submit" @click="submit">提交并保存</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 表单值
      result: null,
      // 性别
      sexOption: [
        {
          value: 1,
          text: '男'
        },
        {
          value: 2,
          text: '女'
        }
      ]
    }
  },
  methods: {
    submit () {
      if (this.result === this.$route.query.value) {
        console.log('没有改动，无需提交')
      } else {
        console.log('提交成功')
      }
    }
  },
  beforeMount () {
    if (this.$route.query.key === 'sex') {
      this.sexOption.forEach((item) => {
        if (this.$route.query.value === item.text) this.result = item.value
      })
    } else {
      this.result = this.$route.query.value
    }
  }
}
</script>

<style lang="scss" scoped>
.person-modify {
  .main {
    max-width : 6.4rem ;
    .form {
      display : flex ;
      flex-direction : column ;
      justify-content : center ;
      width : 100% ;
      max-width : 6.4rem ;
      height : calc(100% - 0.6rem) ;
      padding : 0 0.2rem ;
      box-sizing : border-box ;
      position : absolute ;
      top : 40% ;
      transform : translateY(-50%) ;
      .item {
        margin-top : 0 ;
      }
      .submit {
        margin : 0.8rem auto ;
      }
    }
  }
}
</style>
