<template>
  <div>
    <div class="person">
      <img :src="this.$store.state.imageUrl+list[0].img_url"  :onerror="this.default" alt="" />
      <p>{{list[0].Expert_Name}}</p>
    </div>
    <div>
      <div>
        <h3>基本资料 :</h3>
          <van-cell-group inset>
          <van-cell title="所属单位" :value="list[0].Employer" />
          <van-cell title="所属分会" :value="list[0].Branch" />
          <van-cell title="职务" :value="list[0].Position" />
          <van-cell title="职称" :value="list[0].Professional_title" /> 
        </van-cell-group>
      </div>
      <!-- 社会兼职 -->
      <div class="part">
          <h3>社会兼职 :</h3>
          <p v-html="list[0].Learn_Post"></p>
      </div>
      <!-- 个人简介 -->
       <div class="brief">
          <h3>个人简介 :</h3>
          <p v-html="list[0].About"></p>
         
      </div>
      <div class="achievement">
          <h3>科研、学术成果 : </h3>
          <p class="articl" v-html="list[0].Branch_office"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id:this.$route.params.id,
      default:'this.src="'+require('../../../public/wx_images/mo.png')+'"',
      list: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    async getdata() {
      const res = await axios({
        url: this.$store.state.apiUrl + "/actionapi/TencentApi/GetExpert",
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { Rate:3, Expert_id:this.id },
      });
      try {
       
       res.data.Data.forEach(item=>{
          // 因为图片前面加了个~需要处理一下
          var objReplace = JSON.stringify(item) //首先给对象转换成字符串
          var newObjString = objReplace.replace('~', '') //替换成需要的字段名
          var newObj = JSON.parse(newObjString) //最后转换成对象就ok啦
          this.list.push(newObj)
        })
      } catch (err) {
         this.$store.commit('loadHandler', { // 结束加载动画
              _this: this,
              boolean: false
            })
        console.log(err);
      }
    },
    modifyCss(html){
      html = html.replace('&ldquo;', ' ')
      html = html.replace('&rdquo;', ' ')
      html = html.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/ig, "")
      html = html.replace('南粤好医生', ' “南粤好医生” ')
      return html
    }
  },
};
</script>
<style lang="scss" scoped>
.van-cell{
  padding: 10px .225rem 10px .345rem;
  font-size: 0.26rem;
}
.person {
  margin: 20px auto;
  margin-bottom: 20px;
  width: 2.25rem;
  height: 3.695rem;
  text-align: center;
  img {
    width: 100%;
  }
  p {
    margin-top: 10px;
margin-bottom: 20px;
    font-size: 0.35rem;
  }
}

h3{
    color: #5580ad;
    padding-left: .489rem;
    margin-top: 20px;
}
.part p{
    margin-top: 10px;
    padding-left: .489rem;
    margin-right: 13px;

}
.brief{
    margin-top: 10px;
    p{
        padding: 10px 30px 10px 30px ;
    }
}
.achievement{
    margin-top: 10px;
}
.articl{
     padding: 10px 30px 10px 30px ;
}
</style>
