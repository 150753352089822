<template>
  <div class="box">
    <div class="container">
      <div class="tit">登录</div>
      <input type="text" placeholder="推荐码" v-model="usercode" />
      <van-icon name="friends" class="icon" />
      <button @click="codeLogin">登录</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      index: 1,
      usercode: "",
      list: [],
    };
  },
  created() {
    //   this.codeLogin()
     localStorage.setItem('derive',1)
  },
  methods: {
    async codeLogin() {
      if (this.usercode == "") {
        this.$toast.fail("请输入推荐码");
      } else {
        const res = await axios.get(
          this.$store.state.apiUrl +
            "/actionapi/TencentApi/ReferralCodeLogin?Code=" +
            this.usercode
        );
        try {
       
          if (res.data.Msg == "登录成功!") {
            this.list = res.data.Data;

            var ReferralCode = res.data.ReferralCode;
        
            let accountData = JSON.stringify(this.list);
            localStorage.setItem("accountData", accountData);
            if (this.list.Personal.length == 0) {
              this.$router.push(`/committee/${ReferralCode}`);
              this.$toast.success(res.data.Msg);
            } else {
              this.$router.push(`/updatainfo/${ReferralCode}`);
              this.$toast.success(res.data.Msg);
            }
          } else {
            this.$toast.fail(res.data.Msg);
          }
        } catch (err) {
          this.$toast.fail(res.data.Msg);
          console.log(err);
        }
      }
    },
  },
};
</script>

<style scoped>
* {
  /*初始化*/
  margin: 0;
  padding: 0;
}
.icon {
  position: absolute;
  right: 0.78rem;
  font-size: 0.34rem;
}
body {
  width: 100%;
  height: 100vh;
  /*弹性布局居中*/
  display: flex;
  justify-content: center;
  align-items: center;
  /*渐变背景*/
  background: linear-gradient(200deg, #e3c5eb, #a9c1ed);
  /*溢出影藏*/
  overflow: hidden;
}
.container {
  /*相对定位*/
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  margin: 50px auto;
  /*垂直配列*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5.8rem;
  height: 4rem;
  /*阴影*/
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.container .tit {
  font-size: 0.5rem;
  margin: 25px auto 30px auto;
}
.container input {
  width: 4.5rem;
  height: 0.49rem;
  text-indent: 8px;
  border: none;
  border-bottom: 1px solid #ddd;
  outline: none;
  margin: 12px auto;
}
.container button {
  width: 4.5rem;
  height: 0.7rem;
  margin: 25px auto;
  border: none;
  background: linear-gradient(-200deg, #fac0e7, #aac2ee);
  color: #fff;
  font-weight: bold;
  letter-spacing: 8px;
  border-radius: 10px;
  cursor: pointer;
  /*动画过渡*/
  transition: 0.5s;
}
.container button:hover {
  background: linear-gradient(-200deg, #aac2ee, #fac0e7);
  background-position-x: -280px;
}
</style>
