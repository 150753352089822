<template>
  <div class="meeting-pay-list" v-if="orderList.length">
    <div class="main">
      <ul class="list">
        <li class="item" v-for="(item, index) in orderList" :key="index" @click="navigate(item)">
          <p class="meeting-title">
            <i class="text">{{item.meeting_title}}</i>
          </p>
          <p class="order-number">
            <b class="type">订单号：</b>
            <i class="text">{{item.bill_number}}</i>
          </p>
          <div class="order-info order-info-1">
            <p class="name">
              <b class="type">参会人：</b>
              <i class="text">{{item.attend_people}}</i>
            </p>
            <p class="price">
              <b class="type">合计：</b>
              <i class="text">{{parseInt(item.total_amount).toFixed(2)}}</i>
            </p>
          </div>
          <div class="order-info order-info-2">
            <p class="time">
              <b class="type">下单时间：</b>
              <i class="text">{{item.create_time}}</i>
            </p>
            <p class="state">
              <i class="text" :class="item.is_pay == 1 || item.is_pay == 109 ? 'blue' : 'orange'">{{shateHandler(item.is_pay)}}</i>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <van-empty v-else image="search" description="列表为空" />
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      orderList: []
    }
  },
  methods: {
    // 报名状态判断
    shateHandler (state) {
      var str = ''
      if (typeof state === 'string') {
        state = parseInt(state)
      } else if (typeof state !== 'number') {
        return
      }
      switch (state) {
        case 0:
          str = '未支付'
          break
        case 1:
          str = '已支付'
          break
        case 2:
          str = '部分已退款'
          break
        case 109:
          str = '已退款'
          break
        case 99:
          str = '已取消订单'
      }
      return str
    },
    // 跳转支付订单详情页
    navigate (item) {
      if (!item) return
      this.$store.commit('setMeetingPayDetail', item)
      this.$router.push('/meetingpaydetail/' + this.$route.params.id)
    },
    // 请求数据，参数为会议id
    request (id) {
      axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetPayOrder?open_id=${this.$store.state.user.openid || localStorage.openid}&MeetingId=${id}`)
        .then((res) => {
          if (res.data.Data.length) {
            this.orderList = res.data.Data
          } else {
            console.log('获取数据为空')
          }

          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    },

      requestAll () {
      axios.get(this.$store.state.apiUrl + `/actionapi/TencentApi/GetPayOrder?open_id=${this.$store.state.user.openid || localStorage.openid}`)
        .then((res) => {
          if (res.data.Data.length) {
            this.orderList = res.data.Data
          } else {
            console.log('获取数据为空')
          }

          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('loadHandler', { // 结束加载动画
            _this: this,
            boolean: false
          })
        })
    }
  },
  beforeRouteEnter (to, from, next) { // 路由进入拦截
    next(vm => {
      vm.$store.commit('routerGuardWechat', { // 调用守卫
        fromPath: from.path,
        _this: vm
      })
    })
  },
  beforeMount () {
    if (this.$store.state.user.openid || localStorage.openid) {
      this.$store.commit('loadHandler', { // 调用加载动画
        _this: this,
        boolean: true
      })
      if(this.$route.params.id==0){
        this.requestAll() // 调用请求方法
      }else{
        this.request(this.$route.params.id) // 调用请求方法
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-pay-list {
  .main {
    padding : 0.5rem 0 0.5rem ;
    .list {
      .item {
        background-color : #FFFFFF ;
        padding : 0.15rem ;
        border-radius : 0.05rem ;
        box-shadow : 0 0 0.15rem 0rem rgba(0, 0, 0, 0.1) ;
        margin-top : 0.3rem ;
        &:first-of-type {
          margin-top : 0 ;
        }
        p {
          margin-top : 0.08rem ;
          &>:first-of-type {
            margin-top : 0 ;
          }
          .type {
            color : #8d8d8d ;
            font-size : 0.22rem ;
          }
          .text {
            font-size : 0.22rem ;
          }
        }
        .meeting-title {
          .text {
            font-weight : bold ;
            line-height : 0.3rem ;
          }
        }
        .order-info {
          display : flex ;
          justify-content : space-between ;
          &>p:nth-of-type(1) {
            flex-grow : 1 ;
            word-break : break-all ;
          }
          &>p:nth-of-type(2) {
            flex-shrink : 0 ;
          }
        }
        .order-info-1 {
          .name {
            .text {
              font-weight : bold ;
            }
          }
          .price {
            .text {
              color : #eb6337 ;
              font-size : 0.24rem ;
              &::before {
                content : '￥'
              }
            }
          }
        }
        .order-info-2 {
          .time {
            .text {}
          }
          .state {
            .text {
              &.orange {
                color : #FF9900 ;
              }
              &.blue {
                color : #33CCFF ;
              }
            }
          }
        }
      }
    }
  }
}
</style>
