<template>
  <div class="alipay-callback">
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      // 订单信息（因为回调到这个页面）
      out_trade_no: null, // 后台订单号
      trade_no: null, // 微信订单号
      obj:'',
      json:'',
      id:this.$route.params.id,
      order:this.$route.params.orderNo,
      str:'',
      show:false
    }
  },
  created(){
    this.json = window.location.href;
    this.orderInfo()
  },
  
  methods: {
    async orderInfo(){
      const res=await axios({
        method:'post',
        url:this.$store.state.apiUrl +
            `/actionapi/TencentApi/GetWXOrderMes?out_trade_no=${this.order}`,
      })
      try{
        var obj=JSON.parse(res.data)
        var _obj=obj.xml.result_code
      
        for (var key in _obj) {
         this.str=_obj[key]
        }
  
        if(this.str=='SUCCESS'){
          var js=obj.xml.transaction_id
          for (var item in js) {
           this.trade_no=js[item]
          }
          this.show=true
          this.updateOrder()
        }else{
          this.show=false
        }
        
      }catch(err){
         console.log(err);
         alert('订单获取失败')
      }
    },
    
  
    // 更新后台订单
    updateOrder () {
      var _obj = {
        pay_number: this.trade_no,
        bill_number: this.order
      }
      var json = JSON.stringify(_obj)
      this.json=json
      axios({
        method: 'post',
        url: this.$store.state.apiUrl + '/actionapi/TencentApi/PayOrderResult',
        headers: {
          'Content-Type': 'application/json',
        },
        data: json
      })
        .then((res) => {
          // 如果查询到有交易成功的字符，请求后端更新订单状态，用正则是因为又是一个转不了JSON的字符串类型
          if (res.data.ResultCode === 1) {
            console.log(res.data.Msg)
          } else {
            console.log(res.data.Msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.return('success')
    },
    // 返回方法
    return (state) {
      // eslint-disable-next-line no-redeclare
      var message
      // 如果支付成功返回个人中心，如果失败返回支付订单列表
      if (state === 'fail') {
        message = '支付失败'
        this.$store.commit('setSessionStorage', {
          type: 'meetingpaylist',
          meetingId: this.$route.params.id
        })
      } else if (state === 'success') {
        message = '支付成功'
        this.$store.commit('setSessionStorage', {
          type: 'meetingperson',
          meetingId: this.$route.params.id
        })
      }
      // 提示并跳转
      this.$toast.loading({
        type: state,
        forbidClick: true,
        message: message,
        onClose: () => {
          // 返回上一级
          if (this.$store.state.history.length > 1) {
            this.$store.commit('returnHandler', this)
          } else {
            this.$router.push('/')
          }
        }
      })
    }
  },
  // beforeMount () {
  //   // 如果地址有两个订单号，查询订单支付状态，如果成功，更新后台订单状态
  //   if (this.$route.query.out_trade_no && this.$route.query.trade_no) {
  //     this.out_trade_no = this.$route.query.out_trade_no
  //     this.trade_no = this.$route.query.trade_no

  //     var _obj = {
  //       orderNo: this.out_trade_no
  //     }
  //     this.obj=_obj
  //     var json = JSON.stringify(_obj)

  //     axios({
  //       method: 'post',
  //       url: this.$store.state.apiUrl + '/actionapi/TencentApi/PaymentOrderSearch',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       data: json
  //     })
  //       .then((res) => {
  //         // 如果查询到有交易成功的字符，请求后端更新订单状态，用正则是因为又是一个转不了JSON的字符串类型
  //         if (/TRADE_SUCCESS/.test(res.data)) {
  //           // 调用更新订单方法
  //           this.updateOrder()
  //         } else {
  //           console.log('支付失败')
  //           this.return('fail')
  //         }
  //       })
  //       .catch((err) => {
  //         alert('第一个接口失败')
  //         console.log(err)
          
  //         this.return('fail')
  //       })
  //   } else {
  //     // 如果地址没有订单号，就是下单页过来的，提交表单
  //     var form = document.getElementById('alipaysubmit')
  //     if (form) form.submit()
  //   }
  // }
}
</script>
<style scoped>
.alipay-callback{
  margin-top: 100px;
}
</style>
